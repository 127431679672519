<app-loader *ngIf="loading"></app-loader>
<app-loader *ngIf="loadingGruposGcad"></app-loader>
<app-loader *ngIf="loadingPedidoItem"></app-loader>

<div *ngFor="let item of pedidoItens">
  <p>oi2</p>
  <div class="card-body">
    <h5 class="card-title">{{ item.ds_descricao }}</h5>
    <p class="card-text">Altura: {{ item.alt }} mm</p>
    <p class="card-text">Largura: {{ item.larg }} mm</p>
    <p class="card-text">Preço: R$ {{ item.vl_total }}</p>
    <p class="card-text">Observações: {{ item.obs }}</p>

    <div class="row ficha-tecnica-row">
      <!-- Exibir ficha_tecnica_vidro -->
      <div class="col">
        <h6>Ficha Técnica Vidro</h6>
        <ul>
          <li *ngFor="let vidro of item.ficha_tecnica_vidro">
            {{ vidro.Descricao }} - {{ vidro.tamanho }}
          </li>
        </ul>
      </div>

      <!-- Exibir ficha_tecnica_perfil -->
      <div class="col">
        <h6>Ficha Técnica Perfil</h6>
        <ul>
          <li *ngFor="let perfil of item.ficha_tecnica_perfil">
            {{ perfil.CodProd }} - {{ perfil.Qtde_Pc }}
          </li>
        </ul>
      </div>

      <!-- Exibir ficha_tecnica_ferragem -->
      <div class="col">
        <h6>Ficha Técnica Ferragem</h6>
        <ul>
          <li *ngFor="let ferragem of item.ficha_tecnica_ferragem">
            {{ ferragem.DsCodigo }} - {{ ferragem.qtdeTotal }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="m-3">

      <section id="section">


    <app-pedido-form
      [isNewRegister]="isNewRegister"
      [ped_orca]="ped_orca"
      (notifyLoadEvent)="loadEvent($event)"
      (notifyDisableAllFieldsEvent)="disableAllFieldsEvent($event)"
      (notifyJustificationOrderEvent)="justificationOrderEvent($event)"
      (notifyFabricaConsultandoPedidoVidraceiroEvent)="fabricaConsultandoPedidoVidraceiroEvent($event)"
      (notifyChangedOrcamentoEvent)="changedOrcamentoEvent($event)"
      (redirectToPedidosdEvent)="redirectToPedidos()">
    </app-pedido-form>

    <!-- INICIO POGRESSBAR -->
    <ul class="progress-indicator d-print-none" *ngIf="visibilityStatusNavegation">
      <li class="{{ cssClassBubblePendente }}" (click)="openPopupMoverStatusPendente()">
        <span class="bubble"></span>
        <span class="badge">{{qtd_itens_pendentes}}</span>
        {{ "index.Pendente" | translate }}
      </li>
      <li class="{{ cssClassBubbleTecnico }}" (click)="openPopupMoveStatusTecnico()">
        <span class="bubble"></span>
        <span class="badge">{{qtd_itens_tecnico}}</span>
        {{ "index.Tecnico" | translate }}
      </li>
      <li class="{{ cssClassBubbleFabrica }}" (click)="openPopupMoveStatusFabrica()">
        <span class="bubble"></span>
        <span class="badge">{{qtd_itens_fabrica}}</span>
        {{ "index.Fabrica" | translate }}
      </li>
      <li class="{{ cssClassBubbleFabricando }}" (click)="openPopupMoveStatusFabricando()">
        <span class="bubble"></span>
        <span class="badge">{{qtd_itens_fabricando}}</span>
        {{ "index.Fabricando" | translate }}
      </li>
      <li class="{{ cssClassBubbleRecebido }}" (click)="openPopupMoveStatusRecebido()">
        <span class="bubble"></span>
        <span class="badge">{{qtd_itens_recebido}}</span>
        {{ "index.Recebido" | translate }}
      </li>
      <li class="{{ cssClassBubbleEntregue }}" (click)="openPopupMoveStatusEntregue()">
        <span class="bubble"></span>
        <span class="badge">{{qtd_itens_entregue}}</span>
        {{ "index.entregue" | translate }}
      </li>
    </ul>
    <!-- FIM POGRESSBAR -->

    <div *ngIf="orderStatusIsRejected"  class="mt-5 d-flex justify-content-center">
      <div class="card card-rejected">
        <div class="card-body">
          <h5 class="card-title text-center">{{ "index.pedido_recusado" | translate }}</h5>
          <div class="card-text">

            <dx-data-grid
              [dataSource]="dataSourceRefused"
              [columnAutoWidth]="true"
              [columnHidingEnabled]="true"
              showBorders="true"
              [allowColumnReordering]="true"
              [allowColumnResizing]="true">

              <dxo-scrolling mode="virtual"></dxo-scrolling>

              <dxi-column
                  dataField="ds_descricao"
                  dataType="string"
                  [caption]="'index.produto' | translate">
              </dxi-column>

              <dxi-column
                dataField="qtde_pecas"
                [caption]="'index.quantidade' | translate"
                dataType="number">
              </dxi-column>

              <dxi-column
                dataField="vl_unit"
                [caption]="'index.un_venda' | translate"
                dataType="number">
              </dxi-column>

          </dx-data-grid>

            <blockquote class="blockquote mt-2">
              <p class="mb-0">"{{ justificationOrder }}"</p>
              <div class="row">
                <div class="col-sm">
                  <footer class="blockquote-footer">{{ "index.justificativa_provedor" | translate }}</footer>
                </div>
                <div class="col-sm justify-content-end">
                  <dx-date-box
                    [value]="dateRefusedOrder"
                    type="datetime"
                    [readOnly]="true">
                  </dx-date-box>
                </div>
              </div>
            </blockquote>
          </div>
          <p class="font-italic">{{ "index.clique_botao_restaurar" | translate }}</p>
          <dx-button
            icon="undo"
            [text]="'index.restaurar' | translate"
            (onClick)="restoreOrderPending()">
          </dx-button>
        </div>
      </div>
    </div>

    <!-- INICIO DATA-GRID ITENS -->
    <div class="m-3" *ngIf="!loading">
      <section id="section">
        <dx-data-grid
          *ngIf="!isNewRegister"
          id="data-grid-itens"
          [dataSource]="dataSourceItens"
          showBorders="true"
          [columnHidingEnabled]="true"
          [allowColumnReordering]="true"
          [allowColumnResizing]="true"
          [elementAttr]="{ autocomplete: 'off' }"
          ShowGroupPanel="false"
          (onToolbarPreparing)="onToolbarPreparing($event)"
          (onRowUpdated)="update($event)"
          (onRowInserting)="insert($event)"
          (onRowRemoving)="delete($event)"
          [showColumnHeaders]="false"
          (onInitNewRow)="onInitNewRow($event)"
          (onContentReady)="onContentReadyDataGrid($event)"
          (onEditorPreparing)="onEditorPreparingDataGrid($event)"
          rowTemplate="rowTemplateName"
          width="100%"
        >
          <dxo-paging [pageSize]="5"></dxo-paging>
          <dxo-pager [showPageSizeSelector]="true" [showInfo]="true"></dxo-pager>
          <dxo-editing mode="form"></dxo-editing>

          <dxi-column dataField="imagemThumb" [caption]="'index.foto' | translate" cellTemplate="fotoTemplate" editCellTemplate="fotoTemplate" [allowEditing]="false" width="auto"></dxi-column>
          <dxi-column dataField="cd_produto" [caption]="'index.produto' | translate" [setCellValue]="setCellValueProd" width="15%">
            <dxi-validation-rule type="required"></dxi-validation-rule>
            <dxo-lookup [dataSource]="dataSourceProd" [displayExpr]="displayExprPrd"></dxo-lookup>
          </dxi-column>
          <dxi-column dataField="larg" [caption]="'index.largura' | translate" dataType="number" [editorOptions]="{ format: '###0', max: 2147483647, min: 0 }" width="10%">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dxi-column>
          <dxi-column dataField="alt" [caption]="'index.altura' | translate" dataType="number" [editorOptions]="{ format: '###0', max: 2147483647, min: 0 }" width="10%">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dxi-column>
          <dxi-column dataField="qtde_pecas" [caption]="'index.quantidade' | translate" dataType="number" [editorOptions]="{ format: '###0', max: 2147483647, min: 1 }" width="10%">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dxi-column>
          <dxi-column dataField="vl_unit" [caption]="'index.un_venda' | translate" dataType="number" [editorOptions]="{ min: 0 }" width="8%"></dxi-column>
          <dxi-column dataField="obs" [caption]="'index.observacao' | translate" dataType="text" [editorOptions]="{ maxLength: 250 }" width="auto"></dxi-column>
          <dxi-column dataField="produto" [caption]="'index.produto' | translate" [visible]="false" [formItem]="{ visible: false }" [allowEditing]="false"></dxi-column>

          <div *dxTemplate="let data of 'fotoTemplate'">
            <img class="img_ped_item" [src]="data.value" />
          </div>

          <div *dxTemplate="let data of 'dx-title'">
            <div *ngIf="fabricaConsultandoPedidoVidraceiro" class="text-center">
              <p class="title mb-0">{{ "index.pedido_gerado_pelo_vidraceiro" | translate }}</p>
            </div>
          </div>

          <tbody class="dx-row" *dxTemplate="let item of 'rowTemplateName'">
            <tr class="notes-row">
              <td colspan="9">
                <div class="container-prod">
                  <div [class]="item.data.cssClass">
                    <div class="row no-gutters border item-row">
                      <div id="img-pedido-item" class="col-sm-12 col-md-auto align-self-center">
                        <img id="{{item.data.id}}{{suffixGridElements}}" [alt]="item.data.ds_descricao" [src]="item.data.imagemThumb" class="img-prod border myImg" />
                      </div>

                      <div id="dados-pedido-item" class="col-sm-12 col-md">
                        <div class="container content">
                          <div class="row flex-wrap">
                            <div id="item_produto" class="col-sm-12 col-md-2">
                              <div class="form-group">
                                <label>{{ "index.produto" | translate }}</label>
                                <div>
                                  <dx-text-box [value]="item.data.ds_descricao" [readOnly]="true" [hoverStateEnabled]="false"></dx-text-box>
                                </div>
                              </div>
                            </div>
                            <div id="item_largura" class="col-sm-12 col-md-1">
                              <div class="form-group">
                                <label>{{ "index.largura" | translate }}</label>
                                <div>
                                  <dx-number-box [value]="item.data.larg" [readOnly]="true" [hoverStateEnabled]="false"></dx-number-box>
                                </div>
                              </div>
                            </div>
                            <div id="item_altura" class="col-sm-12 col-md-1">
                              <div class="form-group">
                                <label>{{ "index.altura" | translate }}</label>
                                <div>
                                  <dx-number-box [value]="item.data.alt" [readOnly]="true" [hoverStateEnabled]="false"></dx-number-box>
                                </div>
                              </div>
                            </div>
                            <div id="item_quantidade" class="col-sm-12 col-md-1">
                              <div class="form-group">
                                <label>{{ "index.quantidade" | translate }}</label>
                                <div>
                                  <dx-number-box [value]="item.data.qtde_pecas" [readOnly]="true" [hoverStateEnabled]="false" format="###0" [min]="1"></dx-number-box>
                                </div>
                              </div>
                            </div>
                            <div id="item_venda" class="col-sm-12 col-md-1">
                              <div class="form-group">
                                <label>{{ "index.un_venda" | translate }}</label>
                                <div>
                                  <dx-number-box [value]="item.data.vl_unit" [readOnly]="true" [hoverStateEnabled]="false"></dx-number-box>
                                </div>
                              </div>
                            </div>
                            <div id="item_total" class="col-sm-12 col-md-1">
                              <div class="form-group">
                                <label>{{ "index.total" | translate }}</label>
                                <div>
                                  <dx-number-box [value]="item.data.vl_final" [readOnly]="true" [hoverStateEnabled]="false"></dx-number-box>
                                </div>
                              </div>
                            </div>
                            <div id="item_obs" class="col-sm-12 col-md">
                              <div class="form-group">
                                <label>{{ "index.observacao" | translate }}</label>
                                <div>
                                  <dx-text-box [value]="item.data.obs" [readOnly]="true" [hoverStateEnabled]="false"></dx-text-box>
                                </div>
                              </div>
                            </div>
                          </div>
                            <div class="row ficha-tecnica-row">
                              <div *ngIf="item.data.ficha_tecnica_vidro && item.data.ficha_tecnica_vidro.length > 0" class="col-auto d-flex align-items-center">
                                <div class="vertical-container">
                                  <div class="vertical-text">Vidros</div>
                                </div>
                                <ul class="small-text ficha-tecnica-group">
                                  <li *ngFor="let vidro of item.data.ficha_tecnica_vidro">
                                    {{ vidro.Descricao }} - {{ vidro.tamanho }}
                                  </li>
                                </ul>
                              </div>

                              <div *ngIf="(item.data.ficha_tecnica_perfil && item.data.ficha_tecnica_perfil.length > 0) || (item.data.ficha_tecnica_ferragem && item.data.ficha_tecnica_ferragem.length > 0)" class="col-auto d-flex align-items-center">
                                <div class="vertical-container">
                                  <div class="vertical-text">Acessórios</div>
                                </div>
                                <ul class="small-text ficha-tecnica-group">
                                  <li *ngFor="let perfil of item.data.ficha_tecnica_perfil">
                                    {{ perfil.CodProd }} - {{ perfil.Qtde_Pc }}
                                  </li>
                                  <li *ngFor="let ferragem of item.data.ficha_tecnica_ferragem">
                                    {{ ferragem.DsCodigo }} - {{ ferragem.qtdeTotal }}
                                  </li>
                                </ul>
                            </div>


                            <div id="item_tipo" class="col-sm-12 col-md-6 col-lg-4">
                              <dx-text-box
                                  [value]="item.data.ds_tipo"
                                  [readOnly]="true"
                                  [hoverStateEnabled]="false">
                              </dx-text-box>
                          </div>
                            <!-- Botões de ação -->
                            <div class="col-auto d-flex align-items-center action-buttons">
                              <dx-button icon="edit" [hint]="'index.editar'" (onClick)="handleEdit(item)"></dx-button>
                              <dx-button icon="trash" [hint]="'index.deletar'" (onClick)="handleDelete(item)"></dx-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </dx-data-grid>
      </section>
    </div>

    <!-- FIM DATA-GRID ITENS -->

    <div class="modal fade" id="{{modalId}}{{suffixGridElements}}" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Close</span></button>
            <img src="" class="{{imagePreviewClass}}{{suffixGridElements}}" style="width: 100%;" >
            <div id="{{captionId}}{{suffixGridElements}}" class="caption"></div>
          </div>
        </div>
      </div>
    </div>

  </section>

  <dx-popup
    [showTitle]="true"
    [title]="'index.mover_status' | translate"
    [dragEnabled]="false"
    [closeOnOutsideClick]="false"
    [(visible)]="visibilityPopupMoverStatus"
    (onShown)="onShownPopupMoverStatus()"
    (onHidden)="onHiddenPopupMoverStatus()">
    <div *dxTemplate="let data of 'content'">

      <dx-scroll-view
        [scrollByContent]="true"
        [scrollByThumb]="true"
        [showScrollbar]="onScroll">

        <div id="panelStatus" *ngIf="visibilitypanelStatus">
          <div class="container">
            <div class="row">
              <div class="col-sm-6 col-md-3 col-lg-3">
                <div class="form-group">
                  <label for="de">{{ "index.de" | translate }}</label>
                  <div id="de">
                    <dx-text-box
                      [value]="statusPopupItems.name"
                      [readOnly]="true"
                      [hoverStateEnabled]="false">
                    </dx-text-box>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-3 col-lg-3">
                <div id="field-to" class="form-group">
                  <label for="para">{{ "index.para" | translate }}</label>
                  <div id="para">
                    <dx-text-box
                      [value]="statusPopupDestino"
                      [readOnly]="true"
                      [hoverStateEnabled]="false">
                    </dx-text-box>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-6">
                <div class="container">
                  <div class="row">
                    <div class="col-2 col-sm-3 align-self-center">
                      <div class="form-group" *ngIf="visibilityCbReverseStatus">
                        <label for="inverte">{{ "index.inverter" | translate }}</label>
                        <div id="inverte">
                          <dx-check-box
                            [(value)]="valueCbReverseStatus"
                            (onValueChanged)="reverseStatus($event)"
                            [disabled]="!enableCbReverseStatus">
                          </dx-check-box>
                        </div>
                      </div>
                    </div>
                    <div class="col-2 col-sm-3 align-self-center">
                      <div class="form-group" *ngIf="visibilityCbMoveAllParts">
                        <label for="todos">{{ "index.todos" | translate }}</label>
                        <div id="todos">
                          <dx-check-box
                            [(value)]="valueCbMoveAllParts"
                            (onValueChanged)="moveAllParts($event)"
                            [disabled]="!enableCbMoveAllParts">
                          </dx-check-box>
                        </div>
                      </div>
                    </div>
                    <div class="col-8 col-sm-6 align-self-center">
                      <div class="form-group">
                        <dx-button class="button-info" icon="save" [text]="'index.salvar' | translate" (onClick)="saveToNewStatus($event)"
                          [disabled]="dataSourceItens.length == 0">
                        </dx-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <dx-list [dataSource]="dataSourcePedidoItensFiltered" height="80%">
            <div *dxTemplate="let item of 'item'">
              <div class="container-prod">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="container content">
                      <div class="row">
                        <div class="col-md-6 col-lg-3 text-truncate">
                          <div class="form-group">
                            <div>
                              <img id="{{item.id}}{{suffixPopUpElements}}" alt="{{item.ds_descricao}}" class="img-fluid border myImg" width="110px" [src]="item.imagemThumb" />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 col-lg-3 text-truncate align-self-center">
                          <div class="form-group">
                            <label [for]="'item.id' - 'item.cd_produto'">{{ "index.produto" | translate }}</label>
                            <div [id]="'item.id' - 'item.cd_produto'">
                              <dx-text-box
                                [value]="item.ds_descricao"
                                [readOnly]="true"
                                [hoverStateEnabled]="false">
                              </dx-text-box>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 col-lg-1 text-truncate align-self-center">
                          <div class="form-group">
                            <label [for]="'item.id' - 'item.larg'">{{ "index.largura" | translate }}</label>
                            <div [id]="'item.id' - 'item.larg'">
                              <dx-number-box
                                [value]="item.larg"
                                [readOnly]="true"
                                [hoverStateEnabled]="false">
                              </dx-number-box>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 col-lg-1 text-truncate align-self-center">
                          <div class="form-group">
                            <label [for]="'item.id' - 'item.alt'">{{ "index.altura" | translate }}</label>
                            <div [id]="'item.id' - 'item.alt'">
                              <dx-number-box
                                [value]="item.alt"
                                [readOnly]="true"
                                [hoverStateEnabled]="false">
                              </dx-number-box>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-6 col-lg-1 text-truncate align-self-center">
                          <div class="form-group">
                            <label [for]="'item.id' - 'item.obs'">{{ "index.observacao" | translate }}</label>
                            <div [id]="'item.id' - 'item.obs'">
                              <dx-text-box
                                [value]="item.obs"
                                [readOnly]="true"
                                [hoverStateEnabled]="false">
                              </dx-text-box>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-6 col-lg-1 text-truncate align-self-center">
                          <div class="form-group">
                            <label [for]="'item.id' - 'item.qtde_pecas'">{{ "index.quantidade" | translate }}</label>
                            <div [id]="'item.id' - 'item.qtde_pecas'">
                              <dx-number-box
                                [value]="item.qtde_pecas"
                                [readOnly]="true"
                                [hoverStateEnabled]="false">
                              </dx-number-box>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-6 col-lg-1 align-self-center">
                          <div class="form-group">
                            <label [for]="'item.id' - 'item.qtde_aprovada'">{{ "index.qtde_aprovada" | translate }}</label>
                            <div [id]="'item.id' - 'item.qtde_aprovada'">
                              <dx-number-box
                                [value]="item.qtde_aprovada"
                                (onValueChanged)="onValueChangedApprovedQuantity($event, item)"
                                [readOnly]="!enableFieldApprovedQuantity"
                                [hoverStateEnabled]="enableFieldApprovedQuantity"
                                format="###0"
                                [min]="0"
                                [max]="item.qtde_pecas">
                              </dx-number-box>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="modal fade" id="{{modalId}}{{suffixPopUpElements}}" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-body">
                      <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Close</span></button>
                      <img src="" class="{{imagePreviewClass}}{{suffixPopUpElements}}" style="width: 100%;" >
                      <div id="{{captionId}}{{suffixPopUpElements}}" class="caption"></div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </dx-list>
          <br />
        </div>

        <div id="panelProvider" *ngIf="visibilitypanelProvider">

          <div class="container">
            <div class="mb-4">
              <div class="row">
                <div class="col-4 d-flex justify-content-start">
                  <dx-button
                      *ngIf="visibilityBtnBack"
                      class="button-info"
                      [text]="'index.voltar' | translate"
                      icon="back"
                      (onClick)="exibirPainelStatus()">
                  </dx-button>
                </div>
                <div class="col-8">
                  <h5>{{ "index.definir_fornecedor" | translate }}</h5>
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-6 col-sm-6 col-md-3 d-flex align-items-center">
                <span data-toggle="tooltip" title="{{ 'index.importado_pelo_fornecedor_em' | translate }}: {{ toDateString(c_pedidoFornecedor.dt_importacao_vidros) }}">
                  <i *ngIf="c_pedidoFornecedor.st_importado_vidros" class="dx-icon-info"></i>
                </span>
                <span>{{ "index.vidro" | translate }}</span>
              </div>
              <ng-template [ngIf]="dataSourceProvidersVidros.length > 0" [ngIfElse]="notShowVidros">
                <ng-template [ngIf]="c_pedidoFornecedor.st_vidros" [ngIfElse]="notShowPanelVidros">
                  <div class="col-6 col-sm-6 col-md-3">
                    <dx-select-box
                      [dataSource]="dataSourceProvidersVidros"
                      [searchEnabled]="true"
                      [disabled]="disableFornecGlass"
                      displayExpr="ds_nome"
                      valueExpr="cd_codigo"
                      [(value)]="c_pedidoFornecedor.cd_fornecedor_vidro">
                    </dx-select-box>
                  </div>
                  <div class="col-6 col-sm-4 col-md-2 text-center">
                    <dx-button class="button-info"
                        [disabled]="disableFornecGlass"
                        [text]="'index.preview' | translate"
                        (onClick)="previewVidros()">
                    </dx-button>
                  </div>
                  <div class="col-6 col-sm-4 col-md-2">
                    <dx-button class="button-info"
                        [disabled]="disableFornecGlass"
                        [text]="'index.enviar' | translate"
                        (onClick)="enviarMaterialFornecedor(C_TIPO_FORNECEDOR_VIDRO)">
                    </dx-button>
                  </div>
                  <div class="col-6 col-sm-4 col-md-2 d-flex align-items-center">
                    <ng-template [ngIf]="c_pedidoFornecedor.st_enviado_vidros" [ngIfElse]="notShowDataAcessorios">
                      <dx-date-box
                        [value]="c_pedidoFornecedor.dt_ultimo_envio_vidros"
                        type="datetime"
                        [readOnly]="true">
                      </dx-date-box>
                    </ng-template>
                    <ng-template #notShowDataAcessorios>
                      <span>{{ "index.nao_enviado" | translate }}</span>
                    </ng-template>
                  </div>
                </ng-template>
                <ng-template #notShowPanelVidros>
                  <div class="col-6 col-sm-6 col-md-9">
                    <span class="tooltiptext">{{ "index.sem_vidros_pedido" | translate }}</span>
                  </div>
                </ng-template>
              </ng-template>
              <ng-template #notShowVidros>
                <div class="col-6 col-sm-6 col-md-9">
                  <span class="tooltiptext">{{ "index.sem_cadastro" | translate }}</span>
                </div>
              </ng-template>
            </div>
            <div class="row mb-3">
              <div class="col-6 col-sm-6 col-md-3 d-flex align-items-center">
                <span data-toggle="tooltip" title="{{ 'index.importado_pelo_fornecedor_em' | translate }}: {{ toDateString(c_pedidoFornecedor.dt_importacao_ferragens) }}">
                  <i *ngIf="c_pedidoFornecedor.st_importado_ferragens" class="dx-icon-info"></i>
                </span>
                <span>{{ "index.ferragem" | translate }}</span>
              </div>
              <ng-template [ngIf]="dataSourceProvidersFerragens.length > 0" [ngIfElse]="notShowFerragens">
                <ng-template [ngIf]="c_pedidoFornecedor.st_ferragens" [ngIfElse]="notShowPanelFerragens">
                  <div class="col-6 col-sm-6 col-md-3">
                    <dx-select-box
                      [dataSource]="dataSourceProvidersFerragens"
                      [searchEnabled]="true"
                      [disabled]="disableFornecFerragem"
                      displayExpr="ds_nome"
                      valueExpr="cd_codigo"
                      [(value)]="c_pedidoFornecedor.cd_fornecedor_ferragem">
                    </dx-select-box>
                  </div>
                  <div class="col-6 col-sm-4 col-md-2 text-center">
                    <dx-button class="button-info"
                        [text]="'index.preview' | translate"
                        (onClick)="previewFerrangens()">
                    </dx-button>
                  </div>
                  <div class="col-6 col-sm-4 col-md-2">
                    <dx-button class="button-info"
                        [text]="'index.enviar' | translate"
                        [disabled]="disableFornecFerragem"
                        (onClick)="enviarMaterialFornecedor(C_TIPO_FORNECEDOR_FERRAGEM)">
                    </dx-button>
                  </div>
                  <div class="col-6 col-sm-4 col-md-2 d-flex align-items-center">
                    <ng-template [ngIf]="c_pedidoFornecedor.st_enviado_ferragens" [ngIfElse]="notShowDataAcessorios">
                      <dx-date-box
                        [value]="c_pedidoFornecedor.dt_ultimo_envio_ferragens"
                        type="datetime"
                        [readOnly]="true">
                      </dx-date-box>
                    </ng-template>
                    <ng-template #notShowDataAcessorios>
                      <span>{{ "index.nao_enviado" | translate }}</span>
                    </ng-template>
                  </div>
                </ng-template>
                <ng-template #notShowPanelFerragens>
                  <div class="col-6 col-sm-6 col-md-9">
                    <span class="tooltiptext">{{ "index.sem_ferragens_pedido" | translate }}</span>
                  </div>
                </ng-template>
              </ng-template>
              <ng-template #notShowFerragens>
                <div class="col-6 col-sm-6 col-md-9">
                  <span class="tooltiptext">{{ "index.sem_cadastro" | translate }}</span>
                </div>
              </ng-template>
            </div>
            <div class="row mb-3">
              <div class="col-6 col-sm-6 col-md-3 d-flex align-items-center">
                <span data-toggle="tooltip" title="{{ 'index.importado_pelo_fornecedor_em' | translate }}: {{ toDateString(c_pedidoFornecedor.dt_importacao_perfis) }}">
                  <i *ngIf="c_pedidoFornecedor.st_importado_perfis" class="dx-icon-info"></i>
                </span>
                <span>{{ "index.perfil" | translate }}</span>
              </div>
              <ng-template [ngIf]="dataSourceProvidersPerfis.length > 0" [ngIfElse]="notShowPerfis">
                <ng-template [ngIf]="c_pedidoFornecedor.st_perfis" [ngIfElse]="notShowPanelPerfis">
                  <div class="col-6 col-sm-6 col-md-3">
                    <dx-select-box
                      [dataSource]="dataSourceProvidersPerfis"
                      [searchEnabled]="true"
                      [disabled]="disableFornecPerfil"
                      displayExpr="ds_nome"
                      valueExpr="cd_codigo"
                      [(value)]="c_pedidoFornecedor.cd_fornecedor_perfil">
                    </dx-select-box>
                  </div>
                  <div class="col-6 col-sm-4 col-md-2 text-center">
                    <dx-button class="button-info"
                        [text]="'index.preview' | translate"
                        (onClick)="previewPerfis()">
                    </dx-button>
                  </div>
                  <div class="col-6 col-sm-4 col-md-2">
                    <dx-button class="button-info"
                        [text]="'index.enviar' | translate"
                        [disabled]="disableFornecPerfil"
                        (onClick)="enviarMaterialFornecedor(C_TIPO_FORNECEDOR_PERFIL)">
                    </dx-button>
                  </div>
                  <div class="col-6 col-sm-4 col-md-2 d-flex align-items-center">
                    <ng-template [ngIf]="c_pedidoFornecedor.st_enviado_perfis" [ngIfElse]="notShowDataPerfis">
                      <dx-date-box
                        [value]="c_pedidoFornecedor.dt_ultimo_envio_perfis"
                        type="datetime"
                        [readOnly]="true">
                      </dx-date-box>
                    </ng-template>
                    <ng-template #notShowDataPerfis>
                      <span>{{ "index.nao_enviado" | translate }}</span>
                    </ng-template>
                  </div>
                </ng-template>
                <ng-template #notShowPanelPerfis>
                  <div class="col-6 col-sm-6 col-md-9">
                    <span class="tooltiptext">{{ "index.sem_perfis_pedido" | translate }}</span>
                  </div>
                </ng-template>
              </ng-template>
              <ng-template #notShowPerfis>
                <div class="col-6 col-sm-6 col-md-9">
                  <span class="tooltiptext">{{ "index.sem_cadastro" | translate }}</span>
                </div>
              </ng-template>
            </div>
            <div class="row">
              <div class="col-6 col-sm-6 col-md-3 d-flex align-items-center">
                <span data-toggle="tooltip" title="{{ 'index.importado_pelo_fornecedor_em' | translate }}: {{ toDateString(c_pedidoFornecedor.dt_importacao_acessorios) }}">
                  <i *ngIf="c_pedidoFornecedor.st_importado_acessorios" class="dx-icon-info"></i>
                </span>
                <span>{{ "index.acessorios" | translate }}</span>
              </div>
              <ng-template [ngIf]="dataSourceProvidersAcessorios.length > 0" [ngIfElse]="notShowAcessorios">
                <ng-template [ngIf]="c_pedidoFornecedor.st_acessorios" [ngIfElse]="notShowPanelAcessorios">
                  <div class="col-6 col-sm-6 col-md-3">
                    <dx-select-box
                      [dataSource]="dataSourceProvidersAcessorios"
                      [searchEnabled]="true"
                      displayExpr="ds_nome"
                      [disabled]="disableFornecAcessorio"
                      valueExpr="cd_codigo"
                      [(value)]="c_pedidoFornecedor.cd_fornecedor_acessorio">
                    </dx-select-box>
                  </div>
                  <div class="col-6 col-sm-4 col-md-2 text-center">
                    <dx-button class="button-info"
                        [text]="'index.preview' | translate"
                        (onClick)="previewAcessorios()">
                    </dx-button>
                  </div>
                  <div class="col-6 col-sm-4 col-md-2">
                    <dx-button class="button-info"
                        [text]="'index.enviar' | translate"
                        [disabled]="disableFornecAcessorio"
                        (onClick)="enviarMaterialFornecedor(C_TIPO_FORNECEDOR_ACESSORIO)">
                    </dx-button>
                  </div>
                  <div class="col-6 col-sm-4 col-md-2 d-flex align-items-center">
                    <ng-template [ngIf]="c_pedidoFornecedor.st_enviado_acessorios" [ngIfElse]="notShowDataAcessorios">
                      <dx-date-box
                        [value]="c_pedidoFornecedor.dt_ultimo_envio_acessorios"
                        type="datetime"
                        [readOnly]="true">
                      </dx-date-box>
                    </ng-template>
                    <ng-template #notShowDataAcessorios>
                      <span>{{ "index.nao_enviado" | translate }}</span>
                    </ng-template>
                  </div>
                </ng-template>
                <ng-template #notShowPanelAcessorios>
                  <div class="col-6 col-sm-6 col-md-9">
                    <span class="tooltiptext">{{ "index.sem_acessorios_pedido" | translate }}</span>
                  </div>
                </ng-template>
              </ng-template>
              <ng-template #notShowAcessorios>
                <div class="col-6 col-sm-6 col-md-9">
                  <span class="tooltiptext">{{ "index.sem_cadastro" | translate }}</span>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </dx-scroll-view>
    </div>
  </dx-popup>

  <dx-popup
    [showTitle]="true"
    [title]="'index.explodir_e_otimizar' | translate"
    [(visible)]="visibilityPopupOptimization"
  >
    <div *dxTemplate="let data of 'content'">
      <app-pedido-otimizacao
        [orderId]="ped_orca"
        [value]="radomNumber"
        (notifyOptimizedEvent)="optimizedEvent()">
      </app-pedido-otimizacao>
    </div>
  </dx-popup>

  <dx-popup
    width="300"
    [height]="'auto'"
    [showTitle]="true"
    [title]="'index.desfazer_otimizacao' | translate"
    [(visible)]="visibilityPopupUndoOptimization"
  >
    <div *dxTemplate="let data of 'content'">
      <div class="option">
        <span>{{ "index.deseja_desfazer_otimizacao" | translate }}</span>
      </div>
      <br>
      <div class="row">
        <div class="col d-flex text-center">
          <dx-button class="button-info"
              [text]="'index.sim' | translate"
              (onClick)="undoOptimization()">
          </dx-button>
        </div>
        <div class="col d-flex text-center">
            <dx-button
              class="button-info"
              [text]="'index.cancelar' | translate"
              (onClick)="visibilityPopupUndoOptimization = false">
          </dx-button>
        </div>
      </div>
    </div>
  </dx-popup>

  <dx-popup
    width="350"
    [height]="'auto'"
    [showTitle]="true"
    [title]="'index.reenviar_email_fornecedor' | translate"
    [(visible)]="visibilityPopupResendEmail">
    <div *dxTemplate="let data of 'content'">
      <div class="option">
        <span>{{ "index.deseja_reenviar_email_fornecedor" | translate }}</span>
      </div>
      <br>
      <div class="row">
        <div class="col d-flex text-center">
          <dx-button class="button-info"
              [text]="'index.sim' | translate"
              (onClick)="enviarMaterialFornecedor(tipoFornecedorReenvio, true)">
          </dx-button>
        </div>
        <div class="col d-flex text-center">
            <dx-button
              class="button-info"
              [text]="'index.cancelar' | translate"
              (onClick)="visibilityPopupResendEmail = false">
          </dx-button>
        </div>
      </div>
    </div>
  </dx-popup>


</div>


