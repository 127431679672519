import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { LoadOptions } from 'devextreme/data/load_options';
import { LazyDataSource } from '../models/LazyDataSource';

@Injectable({
    providedIn: 'root'
})
export class LazySelectService {

    //If a user typed something in dxAutocomplete, dxSelectBox or dxLookup  
    setupLazyDataSource(
        key: string, columnSort: string,
        load: (loadOptions: LoadOptions) => Promise<any>,
        byKey: (formData: FormData, registerkey: string) => Promise<any>): LazyDataSource {
        const dataSource: LazyDataSource = {
            store: new CustomStore({
                key: key,
                load: (loadOptions: LoadOptions) => {
                    if (loadOptions.searchValue) {
                        const filter = [[loadOptions.searchExpr, loadOptions.searchOperation, loadOptions.searchValue]]
                        loadOptions.filter = filter;
                    }

                    const sortBy = [{ "selector": columnSort, "desc": false }];
                    loadOptions.sort = sortBy;

                    return load(loadOptions);
                },
                byKey: (registerkey) => {
                    const loadOptions = {
                        filter: [key, registerkey.toString()]
                    }
                    const formData = new FormData();
                    formData.append("loadOptions", JSON.stringify(loadOptions));
                    return byKey(formData, registerkey);
                }
            }),
            paginate: true,
            pageSize: 20
        };
        return dataSource;
    }
}