import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { Component } from '@angular/core';
import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';
import * as $ from "jquery";
import 'bootstrap';
import { LanguagueService } from 'src/app/shared/helpers/language.service';

@Component({
  selector: '[custom-toast-component]',
  styles: [`
      .btn-pink {
        -webkit-backface-visibility: hidden;
        -webkit-transform: translateZ(0);
      }
      .modal-body{
        max-height: calc(100vh - 200px);
        overflow-y: auto;
      }
    `],
  template: `
    <div class="ngx-toastr toast-error">
      <div class="row" [style.display]="state.value === 'inactive' ? 'none' : ''">
        <div class="col-9">
          <div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
            {{ title }}
          </div>
          <div *ngIf="message && options.enableHtml" role="alert" aria-live="polite"
            [class]="options.messageClass" [innerHTML]="message">
          </div>
          <div *ngIf="message && !options.enableHtml" role="alert" aria-live="polite"
            [class]="options.messageClass" [attr.aria-label]="message">
            {{ message }}
          </div>
        </div>
        <div class="col-3 text-right">
          <a *ngIf="options.closeButton" (click)="remove()" class="btn btn-pink btn-sm">
            <button aria-label="Close" class="toast-close-button ng-tns-c45-13 ng-star-inserted" style=""><span aria-hidden="true" class="ng-tns-c45-13">×</span></button>
          </a>
        </div>
      </div>
      <div *ngIf="errorDetailMessage" class="mt-2">
        <button type="button" class="btn btn-light" (click)="alert($event)">
          {{ "index.detalhes" | translate }}
        </button>
      </div>
      <div *ngIf="options.progressBar">
        <div class="toast-progress" [style.width]="width + '%'"></div>
      </div>

      <!-- Modal -->
      <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title text-dark" id="exampleModalLabel">{{ "index.detalhes_erro" | translate }}</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body text-dark" id="error-message">
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ "index.fechar" | translate }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    `,
  animations: [
    trigger('flyInOut', [
      state('inactive', style({ opacity: 0 })),
      state('active', style({ opacity: 1 })),
      state('removed', style({ opacity: 0 })),
      transition(
        'inactive => active',
        animate('{{ easeTime }}ms {{ easing }}')
      ),
      transition(
        'active => removed',
        animate('{{ easeTime }}ms {{ easing }}')
      )
    ])
  ],
  preserveWhitespaces: false,
})

export class CustomToast extends Toast {
  errorDetailMessage: string;

  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
    private language: LanguagueService,
  ) {
    super(toastrService, toastPackage);
    this.errorDetailMessage = this.options.easing;
  }

  alert(event: Event) {
    const tagArray: string[] = this.errorDetailMessage.match(/<translate>\S+<\/translate>/g);
    if (tagArray != null) {
      tagArray.forEach(tag => {
        const key = tag.replace("<translate>", "",).replace("</translate>", "").trim();
        const msg = this.language.translateMessage(key);
        this.errorDetailMessage = this.errorDetailMessage.replace(tag, msg);
      });
    }

    event.stopPropagation();
    $('#exampleModal').modal('toggle');
    $('#error-message').html(this.errorDetailMessage);
    this.toastPackage.triggerAction();
    return false;
  }
}
