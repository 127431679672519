import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from './../../config';
import { Observable } from 'rxjs';
import { UserProfile } from 'src/app/shared/models/UserProfile';
import { LanguagueService } from 'src/app/shared/helpers/language.service';

@Injectable({
    providedIn: 'root'
})
export class PainelService {

    constructor(
        private http: HttpClient,
        private language: LanguagueService
    ) { }

    isUserProfileReitech(): Observable<any> {
        return this.http.get(`${baseUrl}/isMasterUser`);
    }

    getPedidosPainel(formData: FormData): Observable<any> {
        return this.http.post(`${baseUrl}/BuscarPedidosParaImportar`, formData);
    }

    importarPedido(pedido): Observable<any> {
        return this.http.get(`${baseUrl}/ImportarPGCAD?obj=${pedido}`);
    }

    getGraficoPainelAdm(): Observable<any> {
        return this.http.get(`${baseUrl}/getGraficoPainelAdm`);
    }

    Read_painel(formData: FormData): Observable<any> {
        return this.http.post(`${baseUrl}/Read_painel`, formData);
    }

    getCompaniesType(): any[] {
        const companiesType = [
          {
            cd_codigo: UserProfile.FABRICA,
            ds_nome: this.language.translateMessage("Fabrica"),
          },
          {
            cd_codigo: UserProfile.VIDRACARIA,
            ds_nome: this.language.translateMessage("vidracaria"),
          },
          {
            cd_codigo: UserProfile.VIDRACARIA_INDEPENDENTE,
            ds_nome: this.language.translateMessage("vidracaria_independente"),
          },
          {
            cd_codigo: UserProfile.INDEFINIDO,
            ds_nome: this.language.translateMessage("indefinido"),
          }
        ];
        return companiesType;
      }
}