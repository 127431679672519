import { Injectable } from '@angular/core';
import { baseUrl } from 'src/app/config';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EquipeService {

  private url = baseUrl;

  constructor(private http: HttpClient) { }

  post(form: FormData): Observable<any> {
    return this.http.post<any>(`${this.url}/insertEquipe`, form)
  }

  put(form: FormData): Observable<any> {
    return this.http.post<any>(`${this.url}/updateEquipe`, form)
  }

  delete(form: FormData): Observable<any> {
    return this.http.post<any>(`${this.url}/deleteEquipe`, form)
  }

  getEquipe(form: FormData): Observable<any> {
    return this.http.post<any>(`${this.url}/CM_getEquipes`, form);
  }

  getEmployeesForTeams(form: FormData): Observable<any> {
    return this.http.post<any>(`${this.url}/getEmployeesForTeams`, form);
  }

}
