import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DxPieChartComponent } from 'devextreme-angular';
import { HomeService } from './home.service';
import { RequestCallbackService } from 'src/app/shared/helpers/request-callback.service';
import { traduzirStatus, sleep } from 'src/app/shared/helpers/util';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { HeaderNewService } from 'src/app/components/header-new/header_new.service';
import { STATUS_PENDENTE, STATUS_TECNICO, STATUS_FABRICA, STATUS_FABRICANDO, STATUS_RECEBIDO, STATUS_ENTREGUE, STATUS_RECUSADO } from 'src/app/shared/helpers/constants';
import { LanguagueService } from 'src/app/shared/helpers/language.service';
import { ToastService } from 'src/app/shared/helpers/toast.service';
import * as $ from "jquery";
import { Router } from '@angular/router';

// region tarefa #28142
export class PedidoChart {
  status: string
  qtde: number
  ds_status: string

  handleDescriptionStatus(pedidoChart: PedidoChart, language: LanguagueService) {
    this.status = pedidoChart.status
    this.qtde = pedidoChart.qtde
    this.ds_status = traduzirStatus(this.status, language);
  }
}
export class Historico {
  hora: string
  data_hora: string
  operacao: string
  ds_usuario: string
  id: number
  class: string
}

export class Noticia {
  descricao: string
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  @ViewChild("chart", { static: true }) chart: DxPieChartComponent;
  pedidosChart: PedidoChart[] = [];
  historicos: Historico[] = [];
  urlNoticias: SafeResourceUrl = null;
  content: boolean;
  showOrderChart: boolean;
  tasks: any[] = [];

  constructor(
    private service: HomeService,
    private sanitizer: DomSanitizer,
    private language: LanguagueService,
    private requestCallbackService: RequestCallbackService,
    private toast: ToastService,
    private router: Router,
    private headerService: HeaderNewService) {
    this.language.globalize();
  }

  customizeLabel(arg): string {
    return arg.valueText + " (" + arg.percentText + ")";
  }

  ngOnInit() {
    this.getUserData();
    this.getHistorico();
    this.getNoticias();
    this.getRejectedOrders();
  }

  getUserData() {
    this.headerService.getUserData().subscribe((res: ApiResponse<{ success: boolean, message: string }>) => {
      this.requestCallbackService.okResponse(res,
        () => {
          let isAdm = res.data.isAdm;
          this.showOrderChart = isAdm;
          if (isAdm) {
            this.getPedidosChart();
          } else {
            this.getCalendarTasks();
          }
        }
      );
    }, error => {
      this.notifyContent();
      this.requestCallbackService.failResponse(error, "falha_buscar_info_usuario");
    });
  }

  getPedidosChart() {
    this.service.getPedidosChart().subscribe((res: ApiResponse<{ success: boolean, message: string }>) => {
      this.requestCallbackService.okResponse(res,
        () => {
          let pedidosChart: PedidoChart[] = [];
          res.data.pedidosChart.forEach(pedidoResponse => {
            let newPedido = new PedidoChart();
            newPedido.handleDescriptionStatus(pedidoResponse, this.language);
            pedidosChart.push(newPedido);
          })
          this.pedidosChart = pedidosChart;
        }
      );
      this.notifyContent();
    }, error => {
      this.requestCallbackService.failResponse(error, "falhaBuscarPedidos");
      this.notifyContent();
    });
  }

  getHistorico() {
    this.service.getHistorico().subscribe((res: ApiResponse<{ success: boolean, message: string }>) => {
      this.requestCallbackService.okResponse(res,
        () => {
          const historicos = res.data.historico as any[];
          historicos.forEach(item => {
            item.data_hora = new Date(item.data_hora).toLocaleString();
          });
          this.historicos = res.data.historico;
        }
      );
      this.notifyContent();
    }, error => {
      this.requestCallbackService.failResponse(error, "falhaBuscarHistorico");
      this.notifyContent();
    });
  }

  getNoticias() {
    this.service.getNoticias().subscribe((res: ApiResponse<{ success: boolean, message: string }>) => {
      this.requestCallbackService.okResponse(res,
        () => {
          const dtoNoticia = res.data.dtoNoticia;
          if (dtoNoticia && dtoNoticia.descricao) {
            const url = dtoNoticia.descricao;
            this.urlNoticias = this.sanitizer.bypassSecurityTrustResourceUrl(url);
          }
        }
      );
      this.notifyContent();
    }, error => {
      this.requestCallbackService.failResponse(error, "falhaBuscarNoticias");
      this.notifyContent();
    });
  }

  // region tarefa #32237
  getRejectedOrders() {
    this.service.getRejectedOrders().subscribe((res: ApiResponse<{ success: boolean, message: string }>) => {
      this.requestCallbackService.okResponse(res,
        () => {
          const rejectedOrders: any[] = res.data.pedidos;
          const size = rejectedOrders.length;
          if (size > 0) {
            const msgTitle = this.language.translateMessage("atencao");
            const msgOrderRejected = this.language.translateMessage("pedidos_recusados");
            const msgClick = this.language.translateMessage("mensagemCliqueAqui");
            const msgToVerify = this.language.translateMessage("para_verificar");

            let customMessage = "<div class='text-center'> <h6>" + msgTitle + "</h6>";
            customMessage = customMessage.concat("<span>" + msgOrderRejected + "</span> <br>");
            customMessage = customMessage.concat("<span> <u class='click-redirect'>" + msgClick + "</u> " + msgToVerify + "</span> </div>");

            const generatedToast = this.toast.customWarning(customMessage);
            sleep(100).then(() => {
              $('.click-redirect').on("click", event => {
                event.stopPropagation();
                this.toast.clear(generatedToast.toastId);
                this.redirectToRejectOrders();
              });
            });
          }
        }
      );
    }, error => {
      this.requestCallbackService.failResponse(error, "falha_pedidos_recusados");
    });
  }

  redirectToRejectOrders() {
    let url = "/pagina/pedidos";
    let params = { st_orca: STATUS_RECUSADO };
    this.router.navigate([url, params]);
  }
  // end region #32237

  private notifyContent() {
    this.content = true;
  }

  getClassName(operacao: string): string {
    if (operacao.includes("ACESSOU")) {
      return "action-info";
    }

    if (operacao.includes("SALVOU")) {
      return "action-success";
    }

    if (operacao.includes("EXCLUIU")) {
      return "action-error";
    }

    if (operacao.includes("MODIFICADO")) {
      return "action-warning";
    }

    return "";
  }

  customizePoint(pointInfo: any): any {
    const status: string = pointInfo.data.status;
    switch (status) {
      case STATUS_PENDENTE:
        return { color: '#ff8351' } //Pendente
      case STATUS_TECNICO:
        return { color: '#65d074' } // Técnico
      case STATUS_FABRICANDO:
        return { color: '#a72dac' } // Fabricando
      case STATUS_FABRICA:
        return { color: '#ddd12b' } // Fabrica
      case STATUS_RECEBIDO:
        return { color: '#67a6ff' } // Recebido
      case STATUS_ENTREGUE:
        return { color: '#3f51b5' } // Entregue
      default:
        return {} // Outros
    }
  };

  // region tarefa #30159
  getCalendarTasks() {
    this.service.getCalendarTasks().subscribe((res: ApiResponse<{ success: boolean, message: string }>) => {
      this.requestCallbackService.okResponse(res,
        () => {
          const tasks = res.data.tasks as any[];
          tasks.forEach(task => {
            task.dt_inicio = new Date(task.dt_inicio).toLocaleString();
          });
          this.tasks = tasks;
        }
      );
      this.notifyContent();
    }, error => {
      this.requestCallbackService.failResponse(error, "falhaBuscarTarefas");
      this.notifyContent();
    });
  }
  // end region tarefa #30159

}
// end region tarefa #28142
