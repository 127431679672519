import { Component, OnInit } from '@angular/core';
import { HeaderNewService } from 'src/app/components/header-new/header_new.service';
import { RequestCallbackService } from 'src/app/shared/helpers/request-callback.service';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { isUserProfileFactory } from 'src/app/shared/models/UserProfile';

@Component({
  selector: 'app-clientes-redirect',
  templateUrl: './clientes-redirect.component.html',
  styleUrls: ['./clientes-redirect.component.scss']
})
export class ClientesRedirectComponent implements OnInit {

  content: boolean;
  screenToShow: number = -1;

  constructor(
    private requestCallbackService: RequestCallbackService,
    private headerService: HeaderNewService) { }

  ngOnInit() {
    this.getUserData();
  }

  getUserData() {
    this.headerService.getUserData().subscribe((res: ApiResponse<{ success: boolean, message: string }>) => {
      this.requestCallbackService.okResponse(res,
        () => {
          this.notifyContent();
          const m_CodigoPerfilUsuario = res.data.cd_perfil;
          const isManufactures = isUserProfileFactory(m_CodigoPerfilUsuario);
          this.screenToShow = isManufactures ? 1 : 2;
        }
      );
    }, error => {
      this.notifyContent();
      this.screenToShow = 0;
      this.requestCallbackService.failResponse(error, "falha_buscar_info_usuario");
    });
  }

  private notifyContent() {
    this.content = true;
  }
}
