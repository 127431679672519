import { Component, OnInit } from "@angular/core";
import { FinanceiroService } from "./shared/services/financeiro.service";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { LanguagueService } from './shared/helpers/language.service';
import { HASHCAD } from './shared/helpers/constants';
import { getItemStorage, setItemStorage } from './shared/helpers/util';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
  title = "HomeAngular";
  currentUser: string;
  hash: string;
  admin: string;
  currentUserSubject: BehaviorSubject<string>;
  currentUserObs: Observable<any>;
  constructor(
    private router: Router,
    private service: FinanceiroService,
    private route: ActivatedRoute,
    languageService: LanguagueService
  ) {
    this.currentUserSubject = new BehaviorSubject<string>(
      getItemStorage(HASHCAD)
    );

    this.currentUserObs = this.currentUserSubject.asObservable();
    languageService.loadLanguage();
  }

  ngOnInit() { }

  getParams() {
    this.route.queryParams.subscribe(params => {
      this.hash = params.hash;

      setItemStorage(HASHCAD, params.hash);
      this.currentUserSubject.next(params.hash);
    });
  }

  logOut() {
    this.service.logout();
    this.router.navigate([""]);
  }
}
