import { Injectable } from "@angular/core";
import { baseUrl } from "./../../config";
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs/internal/Observable';
@Injectable({
    providedIn: "root"
})
export class ConfigService {
    private url = baseUrl;

    constructor(private http: HttpClient) { }

    getConfigs(form: FormData): Observable<any> {
        return this.http.post<any>(`${this.url}/getConfigs`, form);
    }

    updateConfig(form: FormData): Observable<any> {
        return this.http.post(`${this.url}/updateConfig`, form);
    }
}