<app-loader *ngIf="loading"></app-loader>

<div class="m-3" *ngIf="!loading">
  <dx-data-grid
    id="data-grid-sales-per-day"
    [dataSource]="dataSource"
    [columnHidingEnabled]="true"
    showBorders="true"
    [remoteOperations]="true"
    [allowColumnReordering]="true"
    [allowColumnResizing]="true"
    (onToolbarPreparing)="onToolbarPreparing($event)"
  >
    <dxo-export
      [enabled]="true"
      [fileName]="'index.vendasdiario' | translate"
      [allowExportSelectedData]="false"
    ></dxo-export>

    <dxo-search-panel
      [visible]="true"
      [width]="240"
      [placeholder]="'index.procurar' | translate"
    >
    </dxo-search-panel>

    <dxo-scrolling mode="virtual"></dxo-scrolling>

    <dxi-column 
      dataField="ds_docto" 
      dataType="string"
      [caption]="'index.cd_pedido' | translate">
    </dxi-column>

    <dxi-column 
      dataField="dt_data" 
      dataType="date"
      sortOrder="desc"
      [groupIndex]="0"
      [caption]="'index.emissao' | translate">
    </dxi-column>

    <dxi-column 
      dataField="dt_entrega" 
      dataType="date"
      [caption]="'index.previsao' | translate"> 
    </dxi-column>

    <dxi-column 
      dataField="st_orca" 
      dataType="string"
      [caption]="'index.status' | translate"> 
    </dxi-column>

    <dxi-column 
      dataField="vl_m2" 
      dataType="number"
      [caption]="'index.m2' | translate">
    </dxi-column>

    <dxi-column 
      dataField="vl_venda_total" 
      dataType="number"
      [caption]="'index.valor' | translate">
    </dxi-column>

    <dxi-column 
      dataField="ds_obs" 
      dataType="string"
      [caption]="'index.observacoes' | translate">
    </dxi-column>

    <dxo-summary>
      <dxi-total-item
          column="vl_venda_total"
          [valueFormat]="{ precision: 2 }"
          summaryType="sum">
      </dxi-total-item>

      <dxi-group-item
        column="vl_venda_total"
        summaryType="sum"
        [showInGroupFooter]="true"
        [valueFormat]="{ precision: 2 }"
        [alignByColumn]="true">
        
      </dxi-group-item>
    </dxo-summary>

    <div *dxTemplate="let data of 'dx-title'">
      <div class="text-center">
        <p class="title mb-0">{{ "index.vendasdiario" | translate }}</p>
      </div>
    </div>
  </dx-data-grid>
</div>
