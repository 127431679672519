import { Injectable } from "@angular/core";
import { baseUrl } from "./../../config";
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { LazySelectService } from "src/app/shared/helpers/lazy-select.service";
import { RequestCallbackService } from "src/app/shared/helpers/request-callback.service";
import { LazyDataSource } from "src/app/shared/models/LazyDataSource";

@Injectable({
    providedIn: "root"
})
export class ClientesVidraceirosService {

    private url = baseUrl;

    constructor(
        private http: HttpClient,
        private requestCallbackService: RequestCallbackService,
        private lazyService: LazySelectService,
    ) { }

    getVidraceiros(form: FormData): Observable<any> {
        return this.http.post<any>(`${this.url}/getVidraceiros`, form);
    }

    insertCompany(formData: FormData): Observable<any> {
        return this.http.post<any>(`${this.url}/InsertCompany`, formData);
    }

    updateCompany(formData: FormData): Observable<any> {
        return this.http.post<any>(`${this.url}/UpdateCompany`, formData);
    }

    deleteCompany(formData: FormData): Observable<any> {
        return this.http.post<any>(`${this.url}/DeleteCompany`, formData);
    }

    getPlanos(form: FormData): Observable<any> {
        return this.http.post(`${this.url}/Planos`, form);
    }

    setupDataSourcePlanos(): LazyDataSource {
        return this.lazyService.setupLazyDataSource(
            "cd_plano",
            "ds_nome",
            (loadOptions) => {
                const formData = new FormData();
                formData.append("loadOptions", JSON.stringify(loadOptions));
                return this.loadPlanos(formData);
            },
            (formData) => {
                return this.loadPlanoByKey(formData);
            }
        );
    }

    loadPlanos(formData: FormData): Promise<any> {
        return this.requestCallbackService.load(
            this.getPlanos(formData),
            (response) => {
                return response.data.planos;
            },
            "falha_buscar_planos"
        );
    }

    private loadPlanoByKey(formData: FormData): Promise<any> {
        return this.requestCallbackService.load(
            this.getPlanos(formData),
            (response) => {
                return response.data.planos.data[0];
            },
            "falha_buscar_planos",
            true
        );
    }
}
