import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { baseUrl } from "src/app/config";

@Injectable({
    providedIn: "root"
})
export class RedefinirSenhaService {
    private url = baseUrl;
    constructor(private http: HttpClient) { }

    checkRecoveryHash(form: FormData): Observable<any> {
        return this.http.post<any>(`${this.url}/checkRecoveryHash`, form);
    }

    changePassword(form: FormData): Observable<any> {
        return this.http.post<any>(`${this.url}/changePassword`, form);
    }
}