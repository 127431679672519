import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { PedidoItemService } from '../pedido-item/pedidos-item.service';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { getTiposDePessoa, applyBlinkingEffectByClass, sleep, traduzirStatus, isIntegerNumber } from 'src/app/shared/helpers/util';
import { RequestCallbackService } from 'src/app/shared/helpers/request-callback.service';
import { Router } from '@angular/router';
import { WITHOUT_PERMISSION_KEY, iconInvisible, iconVisible, FORM_CLIENTES, ADD_PERMISSION, STATUS_PENDENTE, STATUS_RECUSADO, CONFIG_OCULTAR_CUSTOS } from 'src/app/shared/helpers/constants';
import { FinanceiroService } from 'src/app/shared/services/financeiro.service';
import { ClientesService } from '../clientes/clientes.service';
import * as $ from "jquery";
import { LazyDataSource } from 'src/app/shared/models/LazyDataSource';
import { ToastService } from 'src/app/shared/helpers/toast.service';
import { LanguagueService } from 'src/app/shared/helpers/language.service';
import { HeaderNewService } from 'src/app/components/header-new/header_new.service';
import { PedidoPreviewService } from '../pedido-preview/pedido-preview.service';
import { PedidoFormService } from './pedido-form.service';
import { isUserProfileFactory } from 'src/app/shared/models/UserProfile';

@Component({
  selector: 'app-pedido-form',
  templateUrl: './pedido-form.component.html',
  styleUrls: ['./pedido-form.component.scss']
})
export class PedidoFormComponent implements OnInit {

  @Input()
  isNewRegister: boolean;

  @Input()
  ped_orca: number;

  @Output()
  notifyLoadEvent = new EventEmitter();

  @Output()
  notifyDisableAllFieldsEvent = new EventEmitter();

  @Output()
  redirectToPedidosdEvent = new EventEmitter();

  @Output()
  notifyJustificationOrderEvent = new EventEmitter();

  @Output()
  notifyFabricaConsultandoPedidoVidraceiroEvent = new EventEmitter();

  @Output()
  notifyChangedOrcamentoEvent = new EventEmitter();

  form: any;

  dataSourcePedidoCabecalho: any;
  private stOrcaAtual: string = "";

  disableAllFields: boolean = true;

  popupDeletePedidoVisible: boolean = false;
  dataSourceMenuPrint: any[] = [];
  dataSourceMenuOthers: any[] = [];

  buttonsCost: any[] = [];
  fieldCostMode: string = "password";
  iconCost: string = iconInvisible;
  // tarefa #30164
  noDataCustom: any = "";
  popupNewClientVisible: boolean = false;
  btnSaveAndReturnOptions: any = {};
  newClient: any = {};
  tipoPessoa: any[] = [];
  cidades: LazyDataSource;
  havePermissionToAddClient: boolean = false;
  commandButtons: any[] = [
    "dropDown"
  ];

  dataSourceCond: any[] = [];
  dataSourceCondicoes: LazyDataSource;
  dataSourceSellers: LazyDataSource;
  dataSourceClient: LazyDataSource;
  dataSourceVidraceiros: LazyDataSource;
  private mustChangeSeller: boolean = true;
  lookUpToShow: number = -1;
  private isManufactures: boolean;
  fabricaConsultandoPedidoVidraceiro: boolean;

  visibilityPopupGraphBar: boolean;
  orcaproPerfis: any[];
  dataSourceOperacao: any[] = [];

  visibilityPopupEmail: boolean;
  ocultarCustos: boolean;

  constructor(
    private service: PedidoItemService,
    private toast: ToastService,
    private language: LanguagueService,
    private headerService: HeaderNewService,
    private requestCallbackService: RequestCallbackService,
    private router: Router,
    private financeiroService: FinanceiroService,
    private clientesService: ClientesService,
    private pedidoFormService: PedidoFormService,
    private pedidoPreviewService: PedidoPreviewService
  ) {
    this.language.globalize();
    this.setupButtonsCusto();
    this.getConfigOcultarCustos();
  }

  ngOnInit() {

    // request para cadastro cabecalho
    this.getUserData();
    //tarefa #30164
    this.checkPermissionToAddClient();
    this.setupBtnSaveAndReturnOptions();
    this.setupTipoPessoa();

    this.setupDataSourceCondicoes();
    this.setupDataSourceSellers();
    this.setupDataSourcePedidoCabecalho();

    this.setupDataSourceCidades();

    this.disableAllFields = !this.isNewRegister;
    this.notifyDisableAllFieldsEvent.emit(this.disableAllFields);
    if (!this.isNewRegister) {
      this.getPedidoCabecalho();
      this.mustChangeSeller = false;
    }
  }

  getUserData() {
    this.headerService.getUserData().subscribe((res: ApiResponse<{ success: boolean, message: string }>) => {
      this.requestCallbackService.okResponse(res,
        () => {
          const m_CodigoPerfilUsuario = res.data.cd_perfil;
          const isManufactures = isUserProfileFactory(m_CodigoPerfilUsuario);
          this.isManufactures = isManufactures;
          this.lookUpToShow = isManufactures ? 1 : 2;
          if (isManufactures) {
            this.setupDataSourceVidraceiros();
          } else {
            this.setupDataSourceClients();
          }
        }
      );
    }, error => {
      this.requestCallbackService.failResponse(error, "falha_buscar_info_usuario");
    });
  }

  private setupDataSourcePedidoCabecalho() {
    if (this.isNewRegister) {
      this.dataSourcePedidoCabecalho = {
        dt_data: new Date(), // inicializa a data emissao com data atual caso seja novo registro..
        dt_entrega: new Date() // #29662
      };
    }
  }

  savePedidoCabecalho(e) {

    // tarefa #30215
    e.preventDefault();

    if (this.fabricaConsultandoPedidoVidraceiro) {
      this.toast.warningToast("validaca_fabrica_pedido_vidraceiro");
      return;
    }

    const isNotStatusPendente = this.disableAllFields;
    if (isNotStatusPendente) {
      this.toast.warningToast("salvar_pedido_validacao");
      return;
    }

    let data: any;

    if (this.isNewRegister) {
      data = {
        ds_docto: this.form.getEditor("ds_docto").option("value"),
        dt_entrega: this.form.getEditor("dt_entrega").option("value"),
        dt_data: this.form.getEditor("dt_data").option("value"),
        cd_vendedor: this.form.getEditor("cd_vendedor").option("value"),
        cd_cond: this.form.getEditor("cd_cond").option("value"),
        st_orca: STATUS_PENDENTE,
        ds_obs: this.form.getEditor("ds_obs").option("value")
      };
    } else {
      data = this.dataSourcePedidoCabecalho;
    }

    if (this.isManufactures) {
      data.cd_empresa = this.form.getEditor("cd_empresa").option("value");
      data.cd_cliente = null;
    } else {
      data.cd_cliente = this.form.getEditor("cd_cliente").option("value");
      data.cd_empresa = null;
    }

    this.cadastraPedidoCabecalho(data);
  }

  private cadastraPedidoCabecalho(dataSource) {
    if (!this.isNewRegister) {
      dataSource.st_orca = this.stOrcaAtual;
      dataSource.vl_custo = null;
    }

    let pedidoCabecalhoJson = JSON.stringify(dataSource);
    const formData = new FormData();
    formData.append("p_obj_pedido", pedidoCabecalhoJson);

    this.showLoad();
    this.service.cadastraPedidoCabecalho(formData).subscribe(
      (res: ApiResponse<{ success: boolean, message: string }>) => {
        this.hideLoad();

        this.requestCallbackService.okResponse(res,
          () => {
            if (this.isNewRegister) {
              this.toast.successToast("added");
              let cd_orca = res.data.cd_orca;
              this.router.navigate(["/pagina/pedidos/itens/" + cd_orca]);
            } else {
              this.toast.successToast("edit");
              this.getPedidoCabecalho();
            }
          });
      },
      error => {
        this.hideLoad();
        if (this.isNewRegister) {
          this.requestCallbackService.failResponse(error, "errorA");
        } else {
          this.requestCallbackService.failResponse(error, "errorU");
        }
      }
    );
  }

  private getPedidoCabecalho() {
    const ped_orca = this.ped_orca ? this.ped_orca.toString() : "";
    if (!isIntegerNumber(ped_orca)) {
      return;
    }
    const formData = new FormData();
    formData.append("cd_orca", this.ped_orca.toString());
    this.showLoad();
    this.service.getPedido(formData).subscribe(
      (res: ApiResponse<{ success: boolean, message: string }>) => {
        this.hideLoad();
        this.requestCallbackService.okResponse(res,
          () => {
            const orc = res.data.pedido;
            if (orc) {
              this.stOrcaAtual = orc.st_orca;
              this.notifyChangedOrcamentoEvent.emit(Object.assign({}, orc));

              // tarefa #32237
              this.notifyJustificationOrderEvent.emit(orc);

              //tarefa #33483
              this.fabricaConsultandoPedidoVidraceiro = orc.st_pedido_vidraceiro;
              this.notifyFabricaConsultandoPedidoVidraceiroEvent.emit(this.fabricaConsultandoPedidoVidraceiro);

              const statusName = this.handleTranslateStatus(orc.st_orca,);
              orc.st_orca = statusName;

              this.dataSourcePedidoCabecalho = orc;

              //tarefa #29676
              this.disableCostField();
              this.setupDataSourceMenuPrint();
              this.setupDataSourceMenuOthers();

            } else {
              const msg = this.language.translateMessage("pedido_nao_encontrado");
              this.toast.errorToast(msg);
              this.router.navigate(["/pagina/pedidos"]);
            }
          });
      },
      error => {
        this.hideLoad();
        this.requestCallbackService.failResponse(error, "falha_buscar_pedido");
      }
    );
  }

  getPedidoTotais() {
    this.getPedidoCabecalho();
  }

  handleTranslateStatus(status: string): string {
    if (status == STATUS_PENDENTE && !this.fabricaConsultandoPedidoVidraceiro) {
      this.disableAllFields = false;
    } else {
      this.disableAllFields = true;
    }
    this.notifyDisableAllFieldsEvent.emit(this.disableAllFields);
    return traduzirStatus(status, this.language);
  }

  // region tarefa #29676
  disableCostField() {
    let costInput = $(".cost-field").find(".dx-texteditor-input-container");
    $(costInput[0]).addClass("dx-widget dx-state-disabled");
  }
  // end region tarefa #29676

  // tarefa #30971
  saveFormInstance(e) {
    this.form = e.component;
  }
  //end region tarefa #30971

  ngAfterViewInit(): void {
    // define o foco no primeiro item do form caso seja um novo registro
    if (this.isNewRegister) {
      //#31102 select-box cliente
      // try {
      //   sleep(1500).then(() => {
      //     this.form.getEditor("cd_cliente").focus();
      //   });
      // } catch (ex) {
      //   console.log(ex);
      // }
    }
  }

  private setupDataSourceMenuPrint() {
    this.dataSourceMenuPrint = [{
      id: "1",
      icon: 'print',
      template: 'root',
      name: this.language.translateMessage("imprimir"),
      items: [{
        id: "1_1",
        name: this.language.translateMessage("todos"),
        print: true
      }, {
        id: "1_2",
        name: this.language.translateMessage("itens_fabricar"),
        print: true
      }, {
        id: "1_3",
        name: this.language.translateMessage("itens_estoque"),
        print: true
      }, {
        id: "1_4",
        name: this.language.translateMessage("resumo_tecnico"),
        print: true
      }, {
        id: "1_5",
        name: this.language.translateMessage("resumo_tecnico_com_valores"),
        print: true
      }, {
        id: "1_6",
        name: this.language.translateMessage("desenho_tecnico"),
        print: true
      }]
    }];
  }

  itemClick(data) {
    const item = data.itemData;
    switch (item.id) {
      case "1_1": {
        this.print();
        break;
      }
      case "1_2": {
        this.print(1);
        break;
      }
      case "1_3": {
        this.print(2);
        break;
      }
      case "1_4": {
        this.generateTechnicalSummary();
        break;
      }
      case "1_5": {
        this.generateTechnicalSummary(true);
        break;
      }
      case "1_6": {
        const formData = new FormData();
        formData.append("orderId", this.ped_orca.toString());
        this.showLoad();
        this.service.technicalDrawing(formData).toPromise().then((response: Blob) => {
          this.hideLoad();
          this.service.pedidoPreviewService.processResponsePdf(response);
        }).catch(result => {
          this.hideLoad();
          this.requestCallbackService.failResponse(result, "falha_gerar_relatorio");
        });
        break;
      }
    }
  }

  private generateTechnicalSummary(p_custos: boolean = false) {
    const formData = new FormData();
    formData.append("orderId", this.dataSourcePedidoCabecalho.cd_orca.toString());
    formData.append("custos", p_custos.toString());
    this.showLoad();
    this.pedidoFormService.technicalSummary(formData).toPromise().then((response: Blob) => {
      this.hideLoad();
      this.pedidoPreviewService.processResponsePdf(response);
    }).catch(result => {
      this.hideLoad();
      this.requestCallbackService.failResponse(result, "falha_gerar_relatorio");
    });
  }

  private setupDataSourceMenuOthers() {
    this.dataSourceMenuOthers = [{
      id: "1",
      icon: 'more',
      template: 'rootOther',
      name: this.language.translateMessage("Outros"),
      items: [{
        id: "1_1",
        name: this.language.translateMessage("perfis"),
      }, {
        id: "1_2",
        name: this.language.translateMessage("enviar_por_email"),
      }]
    }];
  }

  itemClickMenuOthers(data) {
    const item = data.itemData;
    switch (item.id) {
      case "1_1": {
        this.printGraph();
        break;
      }
      case "1_2": {
        this.openFormEmail();
        break;
      }
    }
  }

  // tarefa #31304
  private noDataIsVisible(): boolean {
    return !!document.getElementsByClassName("dx-empty-message").length;
  }

  onEnterKeyClient = () => {
    if (this.havePermissionToAddClient && this.noDataIsVisible()) {
      this.handleClickNewClient();
    }
  }

  handleClickNewClient = () => {
    // BUSCA O NOME INSERIDO NO SELECTBOX DE CLIENTES E QUE NÃO FOI ENCONTRADO
    let inputs = $("#dxSelectBoxClient").find("input");
    let clientName: string;
    $(inputs).each((indexElement, element) => {
      clientName = $(element).val().toString();
    });
    // SETA POR DEFAULT NO FORMULARIO DE CADASTRO DE CLIENTE
    this.newClient.ds_nome = clientName;
    this.openFormNewClient();
  }
  //end region tarefa #31304

  //tarefa #29676
  setupButtonsCusto() {
    this.buttonsCost = [{
      name: "visibility",
      location: "after",
      options: {
        icon: this.iconCost,
        stylingMode: "underlined",
        onClick: () => {
          if (this.ocultarCustos)
            return;

          this.fieldCostMode = this.fieldCostMode === "text" ? "password" : "text";
          this.iconCost = this.fieldCostMode === "text" ? iconVisible : iconInvisible
          this.setupButtonsCusto();
        }
      }
    }];
  }

  // region tarefa #30164
  setupCustomNoData() {
    let noDataDiv = $("<div>").text(this.language.translateMessage("sem_dados"));
    let span = $("<span>").text(this.language.translateMessage("clique_btn_add_cliente"));
    let clickBtnDiv = $("<div>").addClass("break-text").append(span);
    let container: JQuery<HTMLElement> = $("<div>").append(noDataDiv).append(clickBtnDiv);
    this.noDataCustom = container;

    let withOutPermissionMsg = this.language.translateMessage("sem_permissao_add_cliente");
    let formMsg = "\nForm: ".concat(FORM_CLIENTES);
    let withOutPermissionMsgWithForm = !this.havePermissionToAddClient ? withOutPermissionMsg.concat(formMsg) : "";

    this.commandButtons = [
      "dropDown",
      {
        name: "addClient",
        location: "after",
        options: {
          icon: "plus",
          visible: false,
          hint: withOutPermissionMsgWithForm,
          disabled: !this.havePermissionToAddClient,
          elementAttr: {
            class: "add-hint"
          },
          onClick: () => {
            this.handleClickNewClient();
          }
        }
      }
    ];
  }

  setupBtnSaveAndReturnOptions() {
    this.language.translateMessageLazy("salvar_e_voltar", (value) => {
      this.btnSaveAndReturnOptions = {
        text: value,
        type: "success",
        useSubmitBehavior: true
      };
    });
  }

  openFormNewClient() {
    this.popupNewClientVisible = true;
  }

  handleNewClient(e) {
    e.preventDefault();
    this.newClient.st_fornece = true;
    this.newClient.st_cliente = true;

    let json = JSON.stringify(this.newClient);
    const formData = new FormData();
    formData.append("values", json);

    this.clientesService.insert(formData).subscribe(
      (res: ApiResponse<{ success: boolean, message: string }>) => {
        this.hideLoad();
        this.requestCallbackService.okResponse(res,
          () => {
            if (res.key == WITHOUT_PERMISSION_KEY) {
              this.toast.warningToast("perm");
              this.toast.warningPermission(res);
            } else {
              // CASO SEJA SUCESSO
              this.toast.successToast("added");
              // DEFINE O NOVO CLIENTE COMO SELECIONADO
              this.dataSourcePedidoCabecalho.cd_cliente = res.data.cliente.cd_codigo;
              // LIMPA A PROPRIEDADE
              this.newClient = {};
              // FECHA O DIALOG
              this.popupNewClientVisible = false;
            }
          });
      },
      error => {
        this.hideLoad();
        this.requestCallbackService.failResponse(error, "errorA");
      }
    );
  }

  private setupTipoPessoa() {
    sleep(500).then(() => {
      this.tipoPessoa = getTiposDePessoa(this.language);
    });
  }

  // tarefa #31033
  setupDataSourceCidades() {
    this.cidades = this.clientesService.getDataSourceCidade();
  }

  checkPermissionToAddClient() {

    const formData = new FormData();
    formData.append("action", ADD_PERMISSION);
    formData.append("form", FORM_CLIENTES);

    this.showLoad();
    this.service.checkPermission(formData).subscribe(
      (res: ApiResponse<{ success: boolean, message: string }>) => {
        this.hideLoad();
        this.requestCallbackService.okResponse(res,
          () => {

            if (res.key != WITHOUT_PERMISSION_KEY) {
              // no permission
              this.havePermissionToAddClient = true;
            }
            this.setupCustomNoData();
          }, () => {
            // sucesso = false.
            this.setupCustomNoData();
          });
      },
      error => {
        // error na request.
        this.setupCustomNoData();
        this.hideLoad();
        this.requestCallbackService.failResponse(error);
      }
    );
  }

  inputHandler = (e) => {
    sleep(1000).then(() => {
      if (this.noDataIsVisible()) {
        $(".break-text").css("white-space", "normal").css("margin-top", "2px").css("margin-bottom", "2px");
        e.component.getButton("addClient").option("visible", true);
      } else {
        e.component.getButton("addClient").option("visible", false);
      }
    });
  }

  closedHandler(e) {
    e.component.getButton("addClient").option("visible", false);
  }

  // region tarefa #31304
  onOpenedHandler = (e) => {
    if (this.noDataIsVisible()) {
      $(".break-text").css("white-space", "normal").css("margin-top", "2px").css("margin-bottom", "2px");
      e.component.getButton("addClient").option("visible", true);
    }
  }
  //end region tarefa #31304


  private setupDataSourceCondicoes() {
    this.dataSourceCondicoes = this.financeiroService.getDataSourceConditions();
  }

  private setupDataSourceSellers() {
    this.dataSourceSellers = this.financeiroService.getDataSourceSellers();
  }

  private setupDataSourceClients() {
    this.dataSourceClient = this.financeiroService.getDataSourceClients();
  }

  private setupDataSourceVidraceiros() {
    this.dataSourceVidraceiros = this.financeiroService.getDataSourceVidraceiros();
  }

  //tarefa #29663
  onValueChangedClient = () => {
    if (this.mustChangeSeller) {
      let cd_cliente = this.dataSourcePedidoCabecalho.cd_cliente;
      const formData = this.financeiroService.buildFormDataToFindByKey(cd_cliente);
      this.financeiroService.loadClientByKey(formData).then(client => {
        if (client && client.cd_vendedor2) {
          this.dataSourcePedidoCabecalho.cd_vendedor = client.cd_vendedor2;
          applyBlinkingEffectByClass("drop-down-vendedor");
        }
      });
    } else {
      this.mustChangeSeller = true;
    }
  }

  showDialogDeletePedido() {

    if (this.fabricaConsultandoPedidoVidraceiro) {
      this.toast.warningToast("validaca_fabrica_pedido_vidraceiro");
      return;
    }

    //tarefa 29702
    if (this.disableAllFields) {
      this.toast.warningToast("msg_warning_deletar_pedido");
    } else {
      this.popupDeletePedidoVisible = true;
    }
  }

  closeDialogDeletePedido() {
    this.popupDeletePedidoVisible = false;
  }

  deletePedido() {

    const formData = new FormData();
    let p_obj_pedido_json = JSON.stringify({ cd_orca: this.ped_orca });
    formData.append("p_obj_pedido", p_obj_pedido_json);

    this.showLoad();
    this.service.deletePedido(formData).subscribe(
      (res: ApiResponse<{ success: boolean, message: string }>) => {
        this.hideLoad();
        this.requestCallbackService.okResponse(res,
          () => {
            this.toast.successToast("pedido_deletado");
            this.redirectToPedidos();
          });
      },
      error => {
        this.requestCallbackService.failResponse(error, "falha_deletar_pedido");
        this.hideLoad();
      }
    );
  }

  onFocusInDtEntrega = (e) => {
    // abre o picker quando recebe o foco.
    if (!this.disableAllFields) {
      e.component.open();
    }
  }

  redirectToPedidos() {
    this.redirectToPedidosdEvent.emit();
  }

  private showLoad() {
    sleep(100).then(() => {
      this.notifyLoadEvent.emit(true);
    });
  }

  private hideLoad() {
    sleep(100).then(() => {
      this.notifyLoadEvent.emit(false);
    });
  }

  print(cd_tipo: number = 0) {

    if (this.fabricaConsultandoPedidoVidraceiro) {
      this.toast.warningToast("validaca_fabrica_pedido_vidraceiro");
      return;
    }

    // tarefa #32237
    if (this.stOrcaAtual == STATUS_RECUSADO) {
      this.toast.warningToast("restaurar_pedido_validacao_impressao");
      return;
    }

    const formData = new FormData();
    formData.append("cd_orca", this.ped_orca.toString());
    formData.append("cd_tipo", cd_tipo.toString());

    this.showLoad();
    this.service.getOrderReport(formData).toPromise().then((response: Blob) => {
      this.hideLoad();
      this.service.processResponsePdf(response);
    }).catch(result => {
      this.hideLoad();
      this.requestCallbackService.failResponse(result, "falha_gerar_relatorio");
    });
  }


  printGraph() {
    if (!this.dataSourcePedidoCabecalho.st_otimizado) {
      this.toast.warningToast("pedido_nao_otimizado");
      return;
    }
    this.visibilityPopupGraphBar = true;
    this.loadChartOptimization();
  }

  openFormEmail() {
    this.visibilityPopupEmail = true;
  }

  sentEmaildEvent() {
    this.visibilityPopupEmail = false;
  }

  loadChartOptimization() {
    const formData = new FormData();
    formData.append("orderId", this.dataSourcePedidoCabecalho.cd_orca.toString());

    this.showLoad();
    this.service.loadChartOptimization(formData).subscribe(
      (res: ApiResponse<{ success: boolean, message: string }>) => {
        this.hideLoad();
        this.requestCallbackService.okResponse(res,
          () => {
            this.setupDataSourceOperacao();
            const orcaproPerfis: any[] = res.data.orcaproPerfis;
            this.orcaproPerfis = orcaproPerfis;
          });
      }, error => {
        this.hideLoad();
        this.requestCallbackService.failResponse(error);
      });
  }

  private setupDataSourceOperacao() {
    this.dataSourceOperacao = this.service.operacoes();
  }

  customizeSeries = (cd_codigo: number) => {
    const firstUnion: any[] = this.orcaproPerfis.reduce((prev, next) => {
      return prev.concat(next.barras)
    }, []);
    const secondUnion: any[] = firstUnion.reduce((prev, next) => {
      return prev.concat(next)
    }, []);

    const piece = secondUnion.find(obj => obj.cd_codigo == cd_codigo);
    if (piece.st_sobra) {
      return { type: "stackedBar", name: "Sobra", label: { visible: true, customizeText: () => { return this.language.translateMessage("sobra") + ": " + piece.vl_tamanho } }, color: "#B3B3B3", showInLegend: false }
    } else {
      return { type: "stackedBar", name: "" + piece.vl_tamanho, label: { visible: true }, color: this.getRandomColor(), showInLegend: false }
    }
  }

  getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  private getConfigOcultarCustos() {
    this.financeiroService.getMasterConfig(CONFIG_OCULTAR_CUSTOS).subscribe((res: ApiResponse<{ success: boolean, message: string }>) => {
      this.requestCallbackService.okResponse(res,
        () => {
          const ocultar = res.data.config.valor == 1;
          this.ocultarCustos = ocultar;
        }
      );
    }, error => {
      this.requestCallbackService.failResponse(error, "falha_get_config");
    });
  }
}
