<app-loader *ngIf="!loading"></app-loader>
<div class="m-3">

    <div *ngIf="responseStatus == 1">
        <dx-data-grid 
            [dataSource]="dataSource" 
            [remoteOperations]="true"
            [columnAutoWidth]="true" 
            [columnHidingEnabled]="true"
            showBorders="true"
            [allowColumnReordering]="true" 
            [allowColumnResizing]="true"
            (onRowUpdating)="onRowUpdating($event)"
            (onToolbarPreparing)="onToolbarPreparing($event)">

            <dxo-search-panel 
                [visible]="true" 
                [width]="240" 
                [placeholder]="'index.procurar' | translate">
            </dxo-search-panel>

            <dxo-paging [pageSize]="10"></dxo-paging>
            <dxo-pager [showPageSizeSelector]="true" [showInfo]="true"> </dxo-pager>
        
            <dxo-editing
              mode="batch"
              [allowUpdating]="true">
            </dxo-editing>

            <dxi-column 
                dataField="campo"
                dataType="string"
                [allowEditing]="false"
                [caption]="'index.campo' | translate">
            </dxi-column>

            <dxi-column 
                dataField="descricao"
                dataType="string"
                [caption]="'index.descricao' | translate"
                cellTemplate="cellTemplateDescricao"
                editCellTemplate="editCellTemplateDescricao">

                <div *dxTemplate="let data of 'cellTemplateDescricao'">
                    <!--  Caso o campo seja idioma deve exibir valor do lookup (selectbox)-->
                    <dx-select-box
                        *ngIf="data.data.campo == 'idioma'"
                        [dataSource]="languages"
                        [readOnly]="true"
                        displayExpr="ds_nome"
                        valueExpr="ds_codigo" 
                        [value]="data.data.descricao">
                    </dx-select-box>
                    <!--  caso contrário deve exibir campo de texto normal -->
                    <span *ngIf="data.data.campo != 'idioma'">{{data.data.descricao}}</span>
                </div>

                <div *dxTemplate="let data of 'editCellTemplateDescricao'">
                    <!--  Caso o campo seja idioma deve exibir lookup (selectbox) -->
                    <dx-select-box
                        *ngIf="data.data.campo == 'idioma'"
                        [dataSource]="languages"
                        displayExpr="ds_nome"
                        valueExpr="ds_codigo" 
                        searchMode="contains"
                        [searchEnabled]="true"
                        (onSelectionChanged)="onLanguageSelected($event, data, 'descricao')"
                        [value]="data.data.descricao">
                    </dx-select-box>
                    <!--  caso contrário deve exibir campo de texto normal (text-box) -->
                    <dx-text-box 
                        *ngIf="data.data.campo != 'idioma'"
                        (onValueChanged)="data.setValue($event.value)"
                        [maxLength]="255"
                        [value]="data.value">
                    </dx-text-box>
                </div>
            </dxi-column>

            <dxi-column 
                dataField="valor"
                dataType="number"
                [caption]="'index.valor' | translate">
            </dxi-column>

            <div *dxTemplate="let data of 'dx-title'">
                <div class="text-center">
                    <p class="title mb-0">{{'index.configs' | translate}}</p>
                </div>
            </div>

        </dx-data-grid>
    </div>

    <div *ngIf="responseStatus == 2">
        <denied-access></denied-access>
    </div>

    <div *ngIf="responseStatus == 3">
        <div class="mt-4 box">
            <div>
                <h5 class="text-center">{{'index.loginM' | translate}}</h5>
                <p class="text-center">{{'index.loginErroServidor' | translate}}</p>
            </div>
        </div>
    </div>

</div>
