import { Component, OnInit } from "@angular/core";
import { ConfiguracoesService } from "../configuracoes/configuracoes.service";
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { RequestCallbackService } from 'src/app/shared/helpers/request-callback.service';
import { getServerDate } from 'src/app/shared/helpers/util';

@Component({
  selector: "app-comp-router",
  templateUrl: "./comp-router.component.html",
  styleUrls: ["./comp-router.component.scss"]
})
export class CompRouterComponent implements OnInit {
  urlBgImage: string = "assets/notepad.webp";
  licenseExpiring: boolean = false;

  constructor(
    private service: ConfiguracoesService,
    private requestCallbackService: RequestCallbackService) {
  }

  ngOnInit() {
    this.getImgBackGround();
    this.checkLinceseExpiring()
  }

  getImgBackGround() {
    this.service.getImgBackGround().subscribe(
      (res: ApiResponse<{ success: boolean, message: string }>) => {

        this.requestCallbackService.okResponse(res,
          () => {
            if (res.data && res.data.empresa) { // tarefa #31373
              const customImgBg = res.data.empresa.ds_logo_peq;

              this.imageExists(customImgBg, (exists) => {
                if (exists) {
                  this.urlBgImage = customImgBg + "?" + new Date().getTime();
                }
              });
            }
          });
      },
      error => {
        this.requestCallbackService.failResponse(error, "falha_buscar_bg_img");
      }
    );
  }

  checkLinceseExpiring() {
    this.service.licenseExpiring().subscribe(
      (res: ApiResponse<{ success: boolean, message: string }>) => {

        this.requestCallbackService.okResponse(res,
          () => {
            this.licenseExpiring = res.data.st_expirando;
          });
      },
      error => {
        this.requestCallbackService.failResponse(error);
      }
    );
  }

  imageExists(url: string, callback: (exists: boolean) => void) {
    const img = new Image();
    img.onload = function () { callback(true); };
    img.onerror = function () { callback(false); };
    img.src = url;
  }


  onActivate() {
    // evento disparado sempre que é feito a troca da tela (componente)
    // tarefa #30571
    getServerDate(this.requestCallbackService);
  }

}
