import { Injectable } from "@angular/core";
import { baseUrl } from "./../../config";
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: "root"
})
export class relpedidosService {
  constructor(private http: HttpClient) { }

  private url = baseUrl;

  getPedidos(formData: FormData): Observable<any> {
    return this.http.post(`${this.url}/getPedidos2`, formData)
  }

  // Percorrre os filtros para que ajuste as colunas de data para um formato que seja legível pelo banco de dados
  prepareDateFilters(filters: any[], columnsName: string[]) {
    filters.forEach(filter => {
      // verifica se o filtro é um array
      if (Array.isArray(filter,)) {
        // caso sim, inicia recursividade
        this.prepareDateFilters(filter, columnsName);
      } else {
        this.applyToDbDateFilterIfDateColumn(filters, filter, columnsName);
      }
    });
  }

  // identifica se o filtro é sobre as colunas de data
  private isDateColumn(filterName: string, columnsName: string[]): boolean {
    const index = columnsName.indexOf(filterName);
    return index > -1;
  }

  // aplica a conversão caso seja um coluna do tipo data
  private applyToDbDateFilterIfDateColumn(filterArray: any[], filterName: string, columnsName: string[]) {
    if (this.isDateColumn(filterName, columnsName)) {
      let filterDateValue = filterArray[2];
      filterArray[2] = this.mapToDateDbFilter(filterDateValue);
    }
  }

  private mapToDateDbFilter(dateGridFilter: Date): string {
    try {
      const date = new Date(dateGridFilter);
      let year = date.getFullYear().toString();
      let month = (date.getMonth() + 1).toString();
      let day = date.getDate().toString();
      let dateDbFilter = year.concat("-").concat(month).concat("-").concat(day);
      return dateDbFilter;
    } catch (e) {
      return dateGridFilter.toString();
    }
  }

}
