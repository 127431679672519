import { Component, OnInit, ViewChild } from "@angular/core";
import { PedidoItemService } from "./pedidos-item.service";
import { map } from "rxjs/operators";
import { RequestCallbackService } from "src/app/shared/helpers/request-callback.service";
import { getCompletePathNoImage, applyBlinkingEffectById, isIntegerNumber } from "src/app/shared/helpers/util";
import { DxDataGridComponent } from "devextreme-angular";
import { Router, ActivatedRoute } from "@angular/router";
import DataSource from "devextreme/data/data_source";
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { OPERACAO_FABRICAR, OPERACAO_ESTOQUE, STATUS_PENDENTE, STATUS_PENDENTE_INT, STATUS_TECNICO, STATUS_TECNICO_INT, STATUS_FABRICA, STATUS_FABRICA_INT, STATUS_FABRICANDO, STATUS_FABRICANDO_INT, STATUS_RECEBIDO, STATUS_RECEBIDO_INT, STATUS_ENTREGUE, STATUS_ENTREGUE_INT, STATUS_RECUSADO, TIPO_FORNECEDOR_FERRAGEM, TIPO_FORNECEDOR_PERFIL, TIPO_FORNECEDOR_ACESSORIO, TIPO_FORNECEDOR_VIDRO } from 'src/app/shared/helpers/constants';
import { PedidoFormComponent } from '../pedido-form/pedido-form.component';
import { forkJoin, Observable } from 'rxjs';
import { Status } from 'src/app/shared/models/Status';
import { LazyDataSource } from 'src/app/shared/models/LazyDataSource';
import { LazySelectService } from 'src/app/shared/helpers/lazy-select.service';
import { ToastService } from 'src/app/shared/helpers/toast.service';
import { LanguagueService } from 'src/app/shared/helpers/language.service';
import * as $ from "jquery";
import { PedidoOtimizacaoService } from "../pedido-otimizacao/pedido-otimizacao.service";
import { PedidoFornecedorDto } from "src/app/shared/models/PedidoFornecedorDto";
import { FinanceiroService } from "src/app/shared/services/financeiro.service";
import { Plano } from 'src/app/shared/models/Plano';
import { DxTemplateModule, DxTemplateHost, DxTemplateDirective } from 'devextreme-angular'; // Importe o módulo DxTemplateModule
import { DtoOrcapro } from '../../shared/models/DtoOrcapro';

@Component({
  selector: "app-pedido-item",
  templateUrl: "./pedido-item.component.html",
  styleUrls: ["./pedido-item.component.scss", "../../app.component.scss"]
})


export class PedidoItemComponent implements OnInit {

  @ViewChild('fichaTecnicaVidroTemplate') fichaTecnicaVidroTemplate!: DxTemplateDirective;
  @ViewChild('fichaTecnicaPerfilTemplate') fichaTecnicaPerfilTemplate!: DxTemplateDirective;
  @ViewChild('fichaTecnicaFerragemTemplate') fichaTecnicaFerragemTemplate!: DxTemplateDirective;
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  @ViewChild(PedidoFormComponent)
  pedidoFormComponent: PedidoFormComponent
  pedidoItens: DtoOrcapro[] = [];
  ped_orca: number;
  ped_orca_obs: Observable<string>;
  loading: boolean;
  loadingPedidoItem: boolean;
  dataSourceItens: any[] = [];
  dataSourceProd: LazyDataSource;

  isNewRegister: boolean;
  visibilityPopupMoverStatus: boolean;
  dataSourcePedidoItensFiltered: DataSource = new DataSource([]);

  statusPopupItems: Status;
  statusPopupDestino: string = "";

  qtd_itens_pendentes: number;
  qtd_itens_tecnico: number;
  qtd_itens_fabrica: number;
  qtd_itens_fabricando: number;
  qtd_itens_recebido: number;
  qtd_itens_entregue: number;

  visibilityStatusNavegation: boolean;

  enableFieldApprovedQuantity: boolean;
  valueCbMoveAllParts: boolean;
  enableCbMoveAllParts: boolean = true;
  visibilityCbMoveAllParts: boolean = true;
  valueCbReverseStatus: boolean;
  enableCbReverseStatus: boolean = true;
  visibilityCbReverseStatus: boolean = true;

  btnLibraryHint: string = "";
  btnAddPartHint: string = "";
  btnExplodeAndOptmizeHint: string = "";
  btnUndoOptmizeHint: string = "";

  cssClassBubblePendente: string;
  cssClassBubbleTecnico: string;
  cssClassBubbleFabrica: string;
  cssClassBubbleFabricando: string;
  cssClassBubbleRecebido: string;
  cssClassBubbleEntregue: string;

  dataSourceOperacao: any[] = [];
  disableAllFields: boolean;

  orderStatusIsRejected: boolean;
  justificationOrder: string;
  dateRefusedOrder: string;

  fabricaConsultandoPedidoVidraceiro: boolean;

  suffixGridElements = "grid";
  suffixPopUpElements = "popup";
  imagePreviewClass = "imagepreview";
  modalId = "modal";
  captionId = "caption";

  visibilityPopupOptimization: boolean;
  visibilityPopupUndoOptimization: boolean;
  orcamento: any;
  radomNumber: number;

  dataSourceProvidersVidros: any[] = [];
  dataSourceProvidersFerragens: any[] = [];
  dataSourceProvidersPerfis: any[] = [];
  dataSourceProvidersAcessorios: any[] = [];
  loadingGruposGcad: boolean;

  visibilitypanelStatus: boolean = true;
  visibilitypanelProvider: boolean;
  readonly C_TIPO_FORNECEDOR_VIDRO = TIPO_FORNECEDOR_VIDRO;
  readonly C_TIPO_FORNECEDOR_FERRAGEM = TIPO_FORNECEDOR_FERRAGEM;
  readonly C_TIPO_FORNECEDOR_PERFIL = TIPO_FORNECEDOR_PERFIL;
  readonly C_TIPO_FORNECEDOR_ACESSORIO = TIPO_FORNECEDOR_ACESSORIO;
  c_pedidoFornecedor: PedidoFornecedorDto = new PedidoFornecedorDto();
  visibilityBtnBack: boolean = true;
  disableFornecGlass: boolean = false;
  disableFornecFerragem: boolean = false;
  disableFornecPerfil: boolean = false;
  disableFornecAcessorio: boolean = false;
  visibilityPopupResendEmail: boolean;
  tipoFornecedorReenvio: number;
  planoEhPremium: boolean;


  dataSourceRefused: any[] = [];

  constructor(
    private pedidoItemService: PedidoItemService,
    private toast: ToastService,
    private language: LanguagueService,
    private router: Router,
    private route: ActivatedRoute,
    private requestCallbackService: RequestCallbackService,
    private lazyService: LazySelectService,
    private pedidoOtimizacaoService: PedidoOtimizacaoService,
    private financeiroService: FinanceiroService,
  ) {
    this.language.globalize();
    this.setupTranslatedStrings();
    this.ped_orca_obs = this.route.paramMap.pipe(
      map(param => param.get("pedido"))
    );
  }


  ngOnInit() {
    this.ped_orca_obs.subscribe(cd_pedido => {

      if (cd_pedido) {
        if (!isIntegerNumber(cd_pedido)) {
          this.redirectToPedidos();
        }
        this.ped_orca = parseInt(cd_pedido, 10);
      } else {
        this.isNewRegister = true;
      }
      // caso não seja um novo registro faz todas as requests
      if (!this.isNewRegister) {
        this.visibilityStatusNavegation = true;
        this.setupProductsDataSource();
        this.readPedidoItem();
      }
      this.getPlano();
    });
  }

  private getPlano() {
    this.financeiroService.getPlano().subscribe(
      (res: ApiResponse<{ success: boolean, message: string }>) => {
        this.requestCallbackService.okResponse(res,
          () => {
            const codigoPlano = res.data.cd_plano;
            this.planoEhPremium = codigoPlano == Plano.Premium;
            if (codigoPlano == Plano.Light && this.dataGrid)
              this.dataGrid.instance.columnOption("vl_unit", "allowEditing", false);
          });
      },
      error => {
        this.requestCallbackService.failResponse(error);
      }
    );
  }

  private setupProductsDataSource() {
    this.dataSourceProd = this.lazyService.setupLazyDataSource(
      "cd_codigo",
      "ds_descricao",
      (loadOptions) => {
        if (loadOptions.searchValue) {
          //tarefa #29663
          let filter = [["ds_descricao", loadOptions.searchOperation, loadOptions.searchValue], "or", ["ds_codigo", loadOptions.searchOperation, loadOptions.searchValue]]
          loadOptions.filter = filter;
        }
        const formData = new FormData();
        formData.append("loadOptions", JSON.stringify(loadOptions));
        return this.loadProducts(formData);
      },
      (formData) => {
        return this.loadProductByKey(formData);
      }
    );
  }

  private loadProducts(formData: FormData): Promise<any> {
    return this.requestCallbackService.load(
      this.pedidoItemService.getProductsWithFilter(formData),
      (response) => {
        return response.data.produtos;
      },
      "falha_buscar_produtos"
    );
  }

  private loadProductByKey(formData: FormData): Promise<any> {
    return this.requestCallbackService.load(
      this.pedidoItemService.getProducts(formData),
      (response) => {
        return response.data.produtos.data[0];
      },
      "falha_buscar_produtos",
      true
    );
  }

  setCellValueProd(newData, value) {
    newData.cd_produto = value.cd_codigo;
    newData.vl_unit = value.vl_venda;
    // tarefa #32144
    newData.vl_custo_unit = value.vl_custo;

    newData.imagemThumb = value.ds_imagem_server;

    //tarefa #29970
    if (value.is_geral) {
      newData.cd_tipo = OPERACAO_FABRICAR;
    } else {
      newData.cd_tipo = OPERACAO_ESTOQUE;
    }
    // end region tarefa #29970

    if (value.vl_medida1) {
      newData.larg = value.vl_medida1;
    } else {
      newData.larg = null;
    }
    if (value.vl_medida2) {
      newData.alt = value.vl_medida2;
    } else {
      newData.alt = null;
    }

    const vl_lart_alt = 1000;
    if (!value.st_alt) {
      newData.alt = vl_lart_alt;
    }

    if (!value.st_larg) {
      newData.larg = vl_lart_alt;
    }

    const column = (<any>this);
    column.defaultSetCellValue(newData, value);
  }

  onEditorPreparingDataGrid(e) {

    if (e.parentType !== "dataRow" || (e.dataField !== "larg" && e.dataField !== "alt")) {
      return;
    }

    const produtoSelecionado = e.row.data.cd_produto;
    const produtoOriginal = e.row.data.produto;

    if (!produtoSelecionado) {
      return
    }

    const produto = produtoSelecionado.ds_unimed ? produtoSelecionado : produtoOriginal
    if (e.dataField == "larg") {
      e.editorOptions.disabled = !produto.st_larg || produto.vl_medida1 > 0;
    }

    if (e.dataField == "alt") {
      e.editorOptions.disabled = !produto.st_alt || produto.vl_medida2 > 0;
    }
  }

  private setupDataSourceOperacao() {
    this.dataSourceOperacao = this.pedidoItemService.operacoes();
  }

  redirectToPedidos() {
    this.router.navigate(["/pagina/pedidos"]);
  }

  showLoad() {
    this.loading = true;
  }

  hideLoad() {
    this.loading = false;
  }

  showLoadgPedidoItem() {
    this.loadingPedidoItem = true;
  }

  hideLoadPedidoItem() {
    this.loadingPedidoItem = false;
  }

  loadEvent(showLoad: boolean) {
    this.loading = showLoad
  }

  showLoadGruposGcad() {
    this.loadingGruposGcad = true;
  }

  hideLoadGruposGcad() {
    this.loadingGruposGcad = false;
  }

  private readPedidoItem() {
    let cd_ped = this.ped_orca ? this.ped_orca.toString() : "";
    if (!isIntegerNumber(cd_ped)) {
      return;
    }
    let requestJson = JSON.stringify(this.getDefaultRequest());

    const formData = new FormData();
    formData.append("requestStr", requestJson);
    formData.append("cd_ped", cd_ped.toString());

    this.showLoadgPedidoItem();
    this.pedidoItemService.readPedidoItem(formData).subscribe(
      (res: ApiResponse<{ success: boolean, message: string }>) => {
        this.hideLoadPedidoItem();

        this.requestCallbackService.okResponse(res,
          () => {

            // inicializa o data source operacao
            this.setupDataSourceOperacao();

            const pedidoItens: any[] = res.data.orcapro;
            pedidoItens.forEach(pedidoItem => {
              // tarefa #32237
              const orderStatus = pedidoItem.st_orca;
              if (orderStatus == STATUS_RECUSADO) {
                this.orderStatusIsRejected = true;
                return;
              }
              pedidoItem.cssClass = this.getCssBorderClass(pedidoItem.cd_status);
              pedidoItem.ds_tipo = this.getDescTipoOperacaoById(pedidoItem.cd_tipo);
            });
            this.dataSourceItens = pedidoItens;
            this.dataSourceRefused = pedidoItens.filter(a => a.cd_status == -1);
            this.getCountStatusItens(pedidoItens);
          });
      },
      error => {
        this.hideLoadPedidoItem();
        this.requestCallbackService.failResponse(error, "falha_buscar_orcapro");
      }
    );
  }

  // tarefa #32237
  private statusIsRejected(): boolean {
    return this.orderStatusIsRejected;
  }

  private getCountStatusItens(pedidoItens: any[]) {
    let qtd_itens_pendentes = 0;
    let qtd_itens_tecnico = 0;
    let qtd_itens_fabrica = 0;
    let qtd_itens_fabricando = 0;
    let qtd_itens_recebido = 0;
    let qtd_itens_entregue = 0;

    pedidoItens.forEach(pedidoItem => {
      const qtde_pecas = pedidoItem.qtde_pecas;
      switch (pedidoItem.cd_status) {
        case STATUS_PENDENTE_INT: {
          qtd_itens_pendentes = qtd_itens_pendentes + qtde_pecas;
          break;
        }
        case STATUS_TECNICO_INT: {
          qtd_itens_tecnico = qtd_itens_tecnico + qtde_pecas;
          break;
        }
        case STATUS_FABRICA_INT: {
          qtd_itens_fabrica = qtd_itens_fabrica + qtde_pecas;
          break;
        }
        case STATUS_FABRICANDO_INT: {
          qtd_itens_fabricando = qtd_itens_fabricando + qtde_pecas;
          break;
        }
        case STATUS_RECEBIDO_INT: {
          qtd_itens_recebido = qtd_itens_recebido + qtde_pecas;
          break;
        }
        case STATUS_ENTREGUE_INT: {
          qtd_itens_entregue = qtd_itens_entregue + qtde_pecas;
          break;
        }
      }
    });

    this.qtd_itens_pendentes = qtd_itens_pendentes > 0 ? qtd_itens_pendentes : null;
    this.qtd_itens_tecnico = qtd_itens_tecnico > 0 ? qtd_itens_tecnico : null;
    this.qtd_itens_fabrica = qtd_itens_fabrica > 0 ? qtd_itens_fabrica : null;
    this.qtd_itens_fabricando = qtd_itens_fabricando > 0 ? qtd_itens_fabricando : null;
    this.qtd_itens_recebido = qtd_itens_recebido > 0 ? qtd_itens_recebido : null;
    this.qtd_itens_entregue = qtd_itens_entregue > 0 ? qtd_itens_entregue : null;

    // REGRA PARA PINTAR A BARRA DE STATUS CONFORME OS STATUS DO PEDIDO MAIS ALTO
    if (!this.statusIsRejected()) {
      let statusMaisAlto = this.getStatusMaisAltoPecas();
      this.defineCssBubleClass(statusMaisAlto);
    }
  }

  private getCssBorderClass(statusCode: number): string {
    switch (statusCode) {
      case STATUS_PENDENTE_INT:
        return "border-pendente";
      case STATUS_TECNICO_INT:
        return "border-tecnico";
      case STATUS_FABRICA_INT:
        return "border-fabrica";
      case STATUS_FABRICANDO_INT:
        return "border-fabricando";
      case STATUS_RECEBIDO_INT:
        return "border-recebido";
      case STATUS_ENTREGUE_INT:
        return "border-entregue";
    }
  }

  private getDefaultRequest() {
    return {
      take: 0,
      skip: 0,
      page: 1,
      Filters: null,
      pageSize: 0
    };
  }

  insert(e) {
    let data = e.data;

    let form = {
      id: data.id,
      cd_orca: this.ped_orca,
      cd_produto: data.cd_produto.cd_codigo,
      larg: data.larg == undefined ? 0 : data.larg,
      alt: data.alt == undefined ? 0 : data.alt,
      qtde_pecas: data.qtde_pecas,
      vl_unit: data.vl_unit == undefined ? 0 : data.vl_unit,
      vl_custo_unit: data.vl_custo_unit == undefined ? 0 : data.vl_custo_unit,
      cd_tipo: data.cd_tipo,
      obs: data.obs,
      vl_pago: data.vl_pago
    };

    let listOrcaPro = [form];
    let orcaproJson = JSON.stringify(listOrcaPro);
    let requestJson = JSON.stringify(this.getDefaultRequest());

    const formData = new FormData();
    formData.append("requestStr", requestJson);
    formData.append("obj", orcaproJson);
    formData.append("ds_logo", "");

    this.insertPedidoItem(formData);
  }

  private insertPedidoItem(formData: FormData) {
    this.showLoad();
    this.pedidoItemService.insertPedidoItem(formData).subscribe(
      (res: ApiResponse<{ success: boolean, message: string }>) => {
        this.hideLoad();
        this.requestCallbackService.okResponse(res,
          () => {
            this.toast.successToast("added");
          });
        this.readPedidoItem();
        // BUSCA NOVAMENTE OS DADOS DO CABECALHO...
        this.pedidoFormComponent.getPedidoTotais();
      },
      error => {
        this.hideLoad();
        this.requestCallbackService.failResponse(error, "errorA");
        this.readPedidoItem();
        // BUSCA NOVAMENTE OS DADOS DO CABECALHO...
        this.pedidoFormComponent.getPedidoTotais();
      }
    );
  }

  handleEdit(e) {

    if (this.fabricaConsultandoPedidoVidraceiro) {
      this.toast.warningToast("validaca_fabrica_pedido_vidraceiro");
      return;
    }

    if (this.isNotStatusPendente()) {
      this.toast.warningToast("editar_item_pedido_validacao");
      return;
    }

    const item = e.data;
    if (item.cd_status > STATUS_PENDENTE_INT) {
      return;
    }

    // region tarefa #30053
    // caso o item do pedido seja um conjunto (projeto) deve redirecionar para a tela 'EDITOR'

    if (item.cd_reitop) {
      let url = "/pagina/biblioteca/" + this.ped_orca + "/editor/" + item.cd_reitop;
      let params = { qtde_pecas: item.qtde_pecas, cd_produto: item.cd_produto, largura: item.larg, altura: item.alt };
      this.router.navigate([url, params]);       // redireciona...
    } else {
      this.dataGrid.instance.editRow(e.rowIndex);
    }
    // end region
  }

  handleDelete(e) {

    if (this.fabricaConsultandoPedidoVidraceiro) {
      this.toast.warningToast("validaca_fabrica_pedido_vidraceiro");
      return;
    }

    if (this.isNotStatusPendente()) {
      this.toast.warningToast("excluir_item_pedido_validacao");
      return;
    }

    const item = e.data;
    if (item.cd_status > STATUS_PENDENTE_INT) {
      return;
    }

    this.dataGrid.instance.deleteRow(e.rowIndex);
  }

  private isNotStatusPendente(): boolean {
    return this.disableAllFields;
  }

  update(e) {
    let data = e.data;
    let form = {
      id: data.id,
      cd_orca: data.cd_orca,
      cd_produto: data.cd_produto.cd_codigo,
      larg: data.larg,
      alt: data.alt,
      qtde_pecas: data.qtde_pecas,
      vl_unit: data.vl_unit,
      vl_custo_unit: data.vl_custo_unit,
      cd_tipo: data.cd_tipo,
      obs: data.obs,
      vl_pago: data.vl_pago
    };
    let requestJson = JSON.stringify(this.getDefaultRequest());
    let json = JSON.stringify(form);
    const formData = new FormData();
    formData.append("orcapro", json);
    formData.append("requestStr", requestJson);
    this.updatePedidoItem(formData);
  }

  private updatePedidoItem(formData: FormData) {
    this.showLoad();
    this.pedidoItemService.updatePedidoItem(formData).subscribe(
      (res: ApiResponse<{ success: boolean, message: string }>) => {
        this.requestCallbackService.okResponse(res,
          () => {
            this.toast.successToast("edit");
          });
        this.readPedidoItem();
        // BUSCA NOVAMENTE OS DADOS DO CABECALHO...
        this.pedidoFormComponent.getPedidoTotais();
      },
      error => {
        this.hideLoad();
        this.requestCallbackService.failResponse(error, "errorU");
        this.readPedidoItem();
        // BUSCA NOVAMENTE OS DADOS DO CABECALHO...
        this.pedidoFormComponent.getPedidoTotais();
      }
    );
  }

  delete(e) {
    let data = e.data;

    let form = {
      id: data.id,
      cd_orca: data.cd_orca
    };
    let requestJson = JSON.stringify(this.getDefaultRequest());
    let json = JSON.stringify(form);
    const formData = new FormData();
    formData.append("orcapro", json);
    formData.append("requestStr", requestJson);
    this.deletePedidoItem(formData);
  }

  private deletePedidoItem(formData: FormData) {
    this.showLoad();
    this.pedidoItemService.deletePedidoItem(formData).subscribe(
      (res: ApiResponse<{ success: boolean, message: string }>) => {
        this.hideLoad();
        this.requestCallbackService.okResponse(res,
          () => {
            this.toast.successToast("deleted");
          });
        this.readPedidoItem();
        // BUSCA NOVAMENTE OS DADOS DO CABECALHO...
        this.pedidoFormComponent.getPedidoTotais();
      },
      error => {
        this.hideLoad();
        this.requestCallbackService.failResponse(error, "errorD");
        this.readPedidoItem();
        // BUSCA NOVAMENTE OS DADOS DO CABECALHO...
        this.pedidoFormComponent.getPedidoTotais();
      }
    );
  }

  buildNeighborStatus(siglaStatus: string): Status {
    // TODO aplicado alteracoes em enableFieldApprovedQuantity e valueCbMoveAllParts devido ao card bloquear-movimentações-parciais
    const statusPeca = new Status();
    switch (siglaStatus) {
      case STATUS_PENDENTE: // PENDENTE
        statusPeca.name = this.language.translateMessage("Pendente");
        statusPeca.proximo = this.language.translateMessage("Tecnico");
        statusPeca.codigo = STATUS_PENDENTE_INT;
        this.enableFieldApprovedQuantity = false;
        this.enableCbReverseStatus = false;
        this.valueCbReverseStatus = false;
        this.enableCbMoveAllParts = false;
        this.valueCbMoveAllParts = true;
        this.visibilityCbReverseStatus = false;
        this.visibilityCbMoveAllParts = true;
        break;
      case STATUS_TECNICO: // TÉCNICO
        statusPeca.name = this.language.translateMessage("Tecnico");
        statusPeca.anterior = this.language.translateMessage("Pendente");
        statusPeca.proximo = this.language.translateMessage("Fabrica");
        statusPeca.codigo = STATUS_TECNICO_INT;
        this.enableFieldApprovedQuantity = false;
        this.enableCbReverseStatus = true;
        this.valueCbReverseStatus = false;
        this.enableCbMoveAllParts = false;
        this.valueCbMoveAllParts = true;
        this.visibilityCbReverseStatus = true;
        this.visibilityCbMoveAllParts = true;
        break;
      case STATUS_FABRICA: // Fabrica
        statusPeca.name = this.language.translateMessage("Fabrica");
        statusPeca.anterior = this.language.translateMessage("Tecnico");
        statusPeca.codigo = STATUS_FABRICA_INT;
        this.enableFieldApprovedQuantity = false;
        this.enableCbReverseStatus = false;
        this.valueCbReverseStatus = true;
        this.enableCbMoveAllParts = false;
        this.valueCbMoveAllParts = true;
        this.visibilityCbReverseStatus = true;
        this.visibilityCbMoveAllParts = true;
        break;
      case STATUS_FABRICANDO: // Fabricando
        statusPeca.name = this.language.translateMessage("Fabricando");
        statusPeca.proximo = this.language.translateMessage("Recebido");
        statusPeca.codigo = STATUS_FABRICANDO_INT;
        this.enableCbReverseStatus = false;
        this.enableFieldApprovedQuantity = false;
        this.valueCbReverseStatus = false;
        this.enableCbMoveAllParts = false;
        this.valueCbMoveAllParts = true;
        this.visibilityCbReverseStatus = true;
        this.visibilityCbMoveAllParts = true;
        break;
      case STATUS_RECEBIDO: // Recebido
        statusPeca.name = this.language.translateMessage("Recebido");
        statusPeca.proximo = this.language.translateMessage("entregue");
        statusPeca.anterior = this.language.translateMessage("Fabricando");
        statusPeca.codigo = STATUS_RECEBIDO_INT;
        this.enableCbReverseStatus = true;
        this.valueCbReverseStatus = false;
        this.enableFieldApprovedQuantity = false;
        this.enableCbMoveAllParts = false;
        this.valueCbMoveAllParts = true;
        this.visibilityCbReverseStatus = true;
        this.visibilityCbMoveAllParts = true;
        break;
      case STATUS_ENTREGUE: // ENTREGUE
        statusPeca.name = this.language.translateMessage("entregue");
        statusPeca.anterior = this.language.translateMessage("Recebido");
        statusPeca.codigo = STATUS_ENTREGUE_INT;
        this.enableCbReverseStatus = false;
        this.valueCbReverseStatus = true;
        this.enableFieldApprovedQuantity = false;
        this.enableCbMoveAllParts = false;
        this.valueCbMoveAllParts = true;
        this.visibilityCbReverseStatus = true;
        this.visibilityCbMoveAllParts = true;
        break;
    }
    return statusPeca;
  }

  onToolbarPreparing(e) {
    const toolbarItens: any[] = e.toolbarOptions.items;

    const btnLibrary = {
      location: "after",
      widget: "dxButton",
      name: "Biblioteca",
      options: {
        icon: "pinright",
        onClick: () => {
          if (this.fabricaConsultandoPedidoVidraceiro) {
            this.toast.warningToast("validaca_fabrica_pedido_vidraceiro");
            return;
          }
          if (this.isNotStatusPendente()) {
            this.toast.warningToast("acessar_biblioteca_validacao");
            return;
          }
          this.router.navigate(["/pagina/biblioteca/" + this.ped_orca]);
        },
        hint: this.btnLibraryHint
      }
    };
    const btnAdd = {
      location: "after",
      widget: "dxButton",
      name: "Novo",
      options: {
        icon: "plus",
        onClick: () => {
          if (this.fabricaConsultandoPedidoVidraceiro) {
            this.toast.warningToast("validaca_fabrica_pedido_vidraceiro");
            return;
          }
          if (this.isNotStatusPendente()) {
            this.toast.warningToast("criar_item_pedido_validacao");
            return;
          }
          this.dataGrid.instance.addRow();
        },
        hint: this.btnAddPartHint
      }
    }

    const title =
    {
      location: "center",
      template: "dx-title"
    };

    const btnExplode = {
      location: "after",
      widget: "dxButton",
      name: "Explodir",
      options: {
        icon: "fieldchooser",
        onClick: () => {

          if (!this.planoEhPremium) {
            this.toast.warningToast("erro_plano_invalido");
            return;
          }

          if (this.orcamento.st_orca != STATUS_TECNICO) {
            this.toast.warningToast("validaca_otimizar_itens");
            return;
          }

          if (this.orcamento.st_otimizado) {
            this.toast.warningToast("pedido_otimizado");
            return;
          }

          this.radomNumber = Math.random();
          this.visibilityPopupOptimization = true;
        },
        hint: this.btnExplodeAndOptmizeHint
      }
    };

    const btnUndoOptmize = {
      location: "after",
      widget: "dxButton",
      name: "Desfazer",
      options: {
        icon: "pulldown",
        onClick: () => {

          if (!this.planoEhPremium) {
            this.toast.warningToast("erro_plano_invalido");
            return;
          }

          if (this.orcamento.st_orca != STATUS_TECNICO) {
            this.toast.warningToast("validacao_desfazer_otimizacao");
            return;
          }

          if (!this.orcamento.st_otimizado) {
            this.toast.warningToast("pedido_nao_otimizado");
            return;
          }
          this.visibilityPopupUndoOptimization = true;
        },
        hint: this.btnUndoOptmizeHint
      }
    };

    toolbarItens.splice(1, 0, btnAdd, btnLibrary, btnExplode, btnUndoOptmize, title);
  }

  undoOptimization() {
    const formData = new FormData();
    formData.append("orderId", this.ped_orca.toString());

    this.showLoad();
    this.pedidoOtimizacaoService.undoOptimization(formData).subscribe(
      (res: ApiResponse<{ success: boolean, message: string }>) => {
        this.hideLoad();
        this.requestCallbackService.okResponse(res,
          () => {
            this.toast.successToast("otimizacao_desfeita");
            this.visibilityPopupUndoOptimization = false;
            this.orcamento.st_otimizado = false;
            this.pedidoFormComponent.getPedidoTotais();
          });
      }, error => {
        this.hideLoad();
        this.requestCallbackService.failResponse(error);
      });
  }

  optimizedEvent() {
    this.visibilityPopupOptimization = false;
    this.orcamento.st_otimizado = true;
    this.pedidoFormComponent.getPedidoTotais();
  }

  disableAllFieldsEvent(value: boolean) {
    this.disableAllFields = value;
  }

  openPopupMoverStatusPendente() {
    this.openPopupMoveStatus(STATUS_PENDENTE);
  }

  openPopupMoveStatusTecnico() {
    this.openPopupMoveStatus(STATUS_TECNICO);
  }

  openPopupMoveStatusFabrica() {
    this.openPopupMoveStatus(STATUS_FABRICA);
  }

  openPopupMoveStatusFabricando() {
    this.openPopupMoveStatus(STATUS_FABRICANDO);
  }

  openPopupMoveStatusRecebido() {
    this.openPopupMoveStatus(STATUS_RECEBIDO);
  }

  openPopupMoveStatusEntregue() {
    this.openPopupMoveStatus(STATUS_ENTREGUE);
  }

  private hidePopupMoverStatus() {
    this.visibilityPopupMoverStatus = false;
  }

  private showPopupMoverStatus() {
    this.visibilityPopupMoverStatus = true;
  }

  private openPopupMoveStatus(siglaStatus: string) {

    if (this.fabricaConsultandoPedidoVidraceiro) {
      this.toast.warningToast("validaca_fabrica_pedido_vidraceiro");
      return;
    }

    if (this.statusIsRejected()) {
      this.toast.warningToast("restaurar_pedido_validacao_alteracao");
      return;
    }

    const pedidoItens: any[] = this.dataSourceItens.slice();
    const pedidoItensFiltered = this.filterByStatus(siglaStatus, pedidoItens);

    if (this.consultandoStatusFabricaAposImportacao(siglaStatus)) {
      if (this.orcamento.st_orca != STATUS_FABRICA) {
        this.visibilityBtnBack = false;
      }
    } else if (pedidoItensFiltered.length == 0) {
      // caso não exista peças no status clicado não abre o dialog.
      this.toast.warningToast("msg_sem_itens_status_selecionado");
      return;
    }

    this.showPopupMoverStatus();
    this.valueCbMoveAllParts = false;

    this.statusPopupItems = this.buildNeighborStatus(siglaStatus);
    if (this.statusPopupItems.proximo) {
      this.statusPopupDestino = this.statusPopupItems.proximo;
    } else {
      this.statusPopupDestino = this.statusPopupItems.anterior;
    }

    pedidoItensFiltered.forEach(pedidoItem => {
      // TODO comentando devido ao card bloquear-movimentações-parciais
      // caso pedido seja pendente preencher campo peças aprovadas com a qtde peças.
      /* if (this.statusPopupItems.codigo == STATUS_PENDENTE_INT) {
        pedidoItem.qtde_aprovada = pedidoItem.qtde_pecas;
      } else {
        // caso contrário inicializar zerado
        pedidoItem.qtde_aprovada = 0;
      } */
      pedidoItem.qtde_aprovada = pedidoItem.qtde_pecas;
    });

    this.dataSourcePedidoItensFiltered = new DataSource(pedidoItensFiltered);
    this.dataSourcePedidoItensFiltered.paginate(false);

    if (siglaStatus == STATUS_FABRICA) {
      this.toast.warningToast("msg_status_fabrica");
      this.getInfoFornec();
    }
  }

  private consultandoStatusFabricaAposImportacao(siglaStatus: string): boolean {
    const statusAcimaFabrica = [STATUS_FABRICA, STATUS_FABRICANDO, STATUS_ENTREGUE, STATUS_RECEBIDO];
    const statusPedidoAcimaFabrica = statusAcimaFabrica.includes(this.orcamento.st_orca);
    const clicouStatusFabrica = siglaStatus == STATUS_FABRICA;
    return clicouStatusFabrica && statusPedidoAcimaFabrica;
  }

  onShownPopupMoverStatus() {
    let pedidoItensFiltered = this.dataSourcePedidoItensFiltered.items();
    // DEFINE O EVENTO DE CLIQUE QUANDO TODA VEZ QUE O POPUP É EXIBIDO NA TELA.
    this.setupImgFullScrenn(pedidoItensFiltered);
  }

  onHiddenPopupMoverStatus() {
    this.exibirPainelStatus();
    this.visibilityBtnBack = true;
  }

  private filterByStatus(status: String, itens: any[]) {
    switch (status) {
      case STATUS_PENDENTE:
        return itens.filter((item): boolean => {
          return item.cd_status == STATUS_PENDENTE_INT;
        });
      case STATUS_TECNICO:
        return itens.filter((item): boolean => {
          return item.cd_status == STATUS_TECNICO_INT;
        });
      case STATUS_FABRICA:
        return itens.filter((item): boolean => {
          return item.cd_status == STATUS_FABRICA_INT;
        });
      case STATUS_FABRICANDO:
        return itens.filter((item): boolean => {
          return item.cd_status == STATUS_FABRICANDO_INT;
        });
      case STATUS_RECEBIDO:
        return itens.filter((item): boolean => {
          return item.cd_status == STATUS_RECEBIDO_INT;
        });
      case STATUS_ENTREGUE:
        return itens.filter((item): boolean => {
          return item.cd_status == STATUS_ENTREGUE_INT;
        });
    }
  }

  saveToNewStatus() {
    let pedidoItens = this.dataSourcePedidoItensFiltered.items();

    // REGRA PARA VALIDAR SE AO MENOS UM ITEM POSSUI QTD APROVADA SETADA
    // PARA QUE SEJA FEITA A ALTERAÇÃO DE STATUS
    let isValidToNextStatus = false;
    pedidoItens.forEach(pedidoItem => {
      if (pedidoItem.qtde_aprovada > 0) {
        isValidToNextStatus = true;
        return;
      }
    });

    if (!isValidToNextStatus) {
      this.toast.warningToast("msg_qtd_aprovada_item");
      return;
    }

    this.updateItensOrcaPro(pedidoItens);
  }

  private getStatus(isTheLowest: boolean): number {

    let checkFunction;
    if (isTheLowest) {
      checkFunction = (prevVal: number, currentVal: number): boolean => {
        return prevVal > currentVal;
      }
    } else {
      checkFunction = (prevVal: number, currentVal: number): boolean => {
        return prevVal < currentVal;
      }
    }

    return this.dataSourceItens.reduce((prevVal: number, currentElem, index) => {
      const cd_status: number = currentElem.cd_status;
      if (index == 0) {
        return cd_status;
      }

      if (checkFunction(prevVal, cd_status)) {
        return cd_status;
      } else {
        return prevVal;
      }
    }, 0);
  }

  private getStatusMaisBaixoPecas(): number {
    return this.getStatus(true);
  }

  private getStatusMaisAltoPecas(): number {
    return this.getStatus(false);
  }

  updateItensOrcaPro(pedidoItens: any[]) {
    const itensRequest: any[] = [];
    let statusPecaAtual;
    let movingAllQtde: boolean = true;

    const isReversingStatus = this.valueCbReverseStatus;

    pedidoItens.forEach(pedidoItem => {
      // recupera o status atual da peça
      statusPecaAtual = pedidoItem.cd_status;
      // realiza atualização somente nos itens que a qtdAprovada for maior que zero
      if (pedidoItem.qtde_aprovada > 0) {
        itensRequest.push({
          id: pedidoItem.id,
          cd_orca: pedidoItem.cd_orca,
          qtde_pecas: pedidoItem.qtde_pecas,
          qtde_pecas_aprovadas: pedidoItem.qtde_aprovada,
          st_env_vidros: true,
          st_env_acessorios: true,
          // caso checkbox esteja marca volta 1 status, caso contrário sobe 1 status
          cd_statusNovo: (isReversingStatus ? pedidoItem.cd_status - 1 : pedidoItem.cd_status + 1),
          cd_produto: pedidoItem.cd_produto //#31097
        });
      }
      // REGRA PARA VERIFICAR SE ESTÁ MOVENDO TODAS AS QUANTIDADES DE PEÇAS
      if (pedidoItem.qtde_pecas != pedidoItem.qtde_aprovada) {
        movingAllQtde = false;
      }
    });

    // recupera o status mais baixo de todas as peças do pedido
    const statusMaisBaixo = this.getStatusMaisBaixoPecas();

    let novoStatusPedido: number;
    // verifica se o status atual das peças a atualizar é igual ao status mais baixo de todo o pedido
    if (statusPecaAtual == statusMaisBaixo) {
      // caso sim significa que é preciso atualizar o status do pedido
      // realiza checagem no checkbox para saber se as peças estão indo para nível mais baixo ou mais alto
      const isReversingStatus = this.valueCbReverseStatus;
      if (isReversingStatus) {
        novoStatusPedido = statusMaisBaixo - 1;
      } else if (movingAllQtde) {
        // SOBE O STATUS APENAS SE ESTÁ MOVENDO TODAS AS QUANTIDADES
        novoStatusPedido = statusMaisBaixo + 1;
      } else {
        novoStatusPedido = statusMaisBaixo;
      }
    } else {
      // caso contrário deve manter o status atual do pedido
      novoStatusPedido = statusMaisBaixo;
    }

    const orcaproListJson = JSON.stringify(itensRequest);
    const requestJson = JSON.stringify(this.getDefaultRequest());

    const formData = new FormData();
    formData.append("requestStr", requestJson);
    formData.append("novoStatusOrc", novoStatusPedido.toString());
    formData.append("movimentarOrcaproList", orcaproListJson);

    this.showLoad();
    this.pedidoItemService.updateOrcaPro(formData).subscribe(
      (res: ApiResponse<{ success: boolean, message: string }>) => {
        this.hideLoad();
        this.requestCallbackService.okResponse(res,
          () => {
            this.toast.successToast("status_alterado");

            const m_avancouParaStatusFabrica = !isReversingStatus && novoStatusPedido == STATUS_FABRICA_INT;
            if (m_avancouParaStatusFabrica) {
              this.visibilityBtnBack = false;
              this.getInfoFornec();
              this.exibirPainelFornecedores();
            } else {
              this.hidePopupMoverStatus();
            }

            this.pedidoFormComponent.getPedidoTotais();
            this.readPedidoItem();
          });
      },
      error => {
        this.requestCallbackService.failResponse(error, "falha_alterar_status");
        this.hideLoad();
      }
    );
  }

  private exibirPainelFornecedores() {
    this.visibilitypanelStatus = false;
    this.visibilitypanelProvider = true;
  }

  private exibirPainelStatus() {
    this.visibilitypanelStatus = true;
    this.visibilitypanelProvider = false;
  }

  reverseStatus(e) {
    //tarefa #29704
    applyBlinkingEffectById("field-to");

    const isReversingStatus = e.value;
    if (isReversingStatus) {
      this.statusPopupDestino = this.statusPopupItems.anterior;
    } else {
      this.statusPopupDestino = this.statusPopupItems.proximo;
    }

    /* TODO comentando devido ao card bloquear-movimentações-parciais
    // REGRA PARA RETORNAR DE TÉCNICO PARA PENDENTE DEVE OBRIGATORIAMENTE MOVER TODAS AS PEÇAS E QUANTIDADES.
    if (this.statusPopupItems.codigo == STATUS_TECNICO_INT) {
      // caso o checkbox inverter esteja marcado
      if (isReversingStatus) {
        // altera valor do checkbox todos para true.
        this.valueCbMoveAllParts = true;
        // desabilita checkbox todos.
        this.enableCbMoveAllParts = true;
        // desabilita edição da qtde aprovada
        this.enableFieldApprovedQuantity = true;
      } else {
        // altera valor do checkbox todos para false.
        this.valueCbMoveAllParts = false;
        // habilita checkbox todos.
        this.enableCbMoveAllParts = true;
        // habilita edição do qtde aprovada.
        this.enableFieldApprovedQuantity = true;
      }
    }
    */

    this.valueCbReverseStatus = isReversingStatus;
  }

  moveAllParts(e) {
    let pedidoItens: any[] = this.dataSourcePedidoItensFiltered.items();
    if (e.value) {
      pedidoItens.forEach(pedidoItem => {
        pedidoItem.qtde_aprovada = pedidoItem.qtde_pecas;
      });
    } else {
      pedidoItens.forEach(pedidoItem => {
        pedidoItem.qtde_aprovada = 0;
      });
    }
  }

  defineCssBubleClass(status: number) {
    this.resetCssClassBuble();
    switch (status) {
      case STATUS_PENDENTE_INT:
        this.defineCssBublePendente();
        break;
      case STATUS_TECNICO_INT:
        this.defineCssBubleTecnico();
        break;
      case STATUS_FABRICA_INT:
        this.defineCssBubleFabrica();
        break;
      case STATUS_FABRICANDO_INT:
        this.defineCssBubleFabricando();
        break;
      case STATUS_RECEBIDO_INT:
        this.defineCssBubleRecebido();
        break;
      case STATUS_ENTREGUE_INT:
        this.defineCssBubleEntregue();
        break;
    }
  }

  private resetCssClassBuble() {
    const cssClassIncomplete = "incomplete";
    this.cssClassBubblePendente = cssClassIncomplete;
    this.cssClassBubbleTecnico = cssClassIncomplete;
    this.cssClassBubbleFabrica = cssClassIncomplete;
    this.cssClassBubbleFabricando = cssClassIncomplete;
    this.cssClassBubbleRecebido = cssClassIncomplete;
    this.cssClassBubbleEntregue = cssClassIncomplete;
  }

  private defineCssBublePendente() {
    this.cssClassBubblePendente = "pendente";
  }

  private defineCssBubleTecnico() {
    this.defineCssBublePendente();
    this.cssClassBubbleTecnico = "completed";
  }

  private defineCssBubleFabrica() {
    this.defineCssBubleTecnico();
    this.cssClassBubbleFabrica = "fabrica";
  }

  private defineCssBubleFabricando() {
    this.defineCssBubleFabrica();
    this.cssClassBubbleFabricando = "fabricando";
  }

  private defineCssBubleRecebido() {
    this.defineCssBubleFabricando();
    this.cssClassBubbleRecebido = "recebido";
  }

  private defineCssBubleEntregue() {
    this.defineCssBubleRecebido();
    this.cssClassBubbleEntregue = "entregue";
  }

  onInitNewRow(e) {
    // inicializa novo registro com 'Fabricar' por default.
    e.data.cd_tipo = OPERACAO_FABRICAR;

    // tarefa #29662
    e.data.imagemThumb = getCompletePathNoImage();

    // refresca o grid para evitar bug de espremer form a esquerda
    this.dataGrid.instance.refresh();
  }

  onContentReadyDataGrid(e) {
    // DEFINE O FOCO NA COLUNA PRODUTOS AO CRIAR NOVO REGISTRO OU EDITAR..
    e.component.focus(e.component.getCellElement(0, 1));

    // SETA EVENTO DE CLIQUE NA IMAGEM
    // ESTÁ DEFINIDO AQUI PARA DETECTAR ALTERACOES NO GRID E SETAR AOS ELEMTNOS VISIVEIS...
    let itens = this.dataSourceItens.slice();
    this.setupImgFullScrenn(itens, true);
  }

  addSuffixGrid = (elementIdentifier: string) => elementIdentifier.concat(this.suffixGridElements);
  addSuffixPopUp = (elementIdentifier: string) => elementIdentifier.concat(this.suffixPopUpElements);

  setupImgFullScrenn(itens: any[], isGridItens: boolean = false) {
    itens.forEach(element => {
      if (!element.id) {
        return;
      }

      const elementOriginalId = element.id.toString();
      const elementId = isGridItens ? this.addSuffixGrid(elementOriginalId) : this.addSuffixPopUp(elementOriginalId);
      const imagePreviewClass = isGridItens ? this.addSuffixGrid(this.imagePreviewClass) : this.addSuffixPopUp(this.imagePreviewClass);
      const modalId = isGridItens ? this.addSuffixGrid(this.modalId) : this.addSuffixPopUp(this.modalId);
      const captionId = isGridItens ? this.addSuffixGrid(this.captionId) : this.addSuffixPopUp(this.captionId);

      let img = <HTMLImageElement>document.getElementById(elementId);
      if (!img) {
        return;
      }

      let captionText = document.getElementById(captionId);

      img.onclick = () => {
        $('.' + imagePreviewClass).attr('src', element.imagemDetail);
        $('#' + modalId).modal('show');
        if (captionText) {
          captionText.innerHTML = img.alt;
        }
      };
    });
  }

  //tarefa #29663
  setupTranslatedStrings() {
    this.language.translateMessageLazy("biblioteca", (value) => {
      this.btnLibraryHint = value;
    })
    this.language.translateMessageLazy("novo_registro", (value) => {
      this.btnAddPartHint = value;
    });
    this.language.translateMessageLazy("explodir_e_otimizar", (value) => {
      this.btnExplodeAndOptmizeHint = value;
    });
    this.language.translateMessageLazy("desfazer_otimizacao", (value) => {
      this.btnUndoOptmizeHint = value;
    });
  }

  //tarefa #29663
  displayExprPrd(data) {
    return data.ds_codigo + " - " + data.ds_descricao;
  }

  private getDescTipoOperacaoById(cd_tipo: number) {
    let tipoOperacao = this.dataSourceOperacao.find(item => item.cd_tipo == cd_tipo);
    if (tipoOperacao) {
      return tipoOperacao.ds_descricao;
    }
    return "";
  }

  onValueChangedApprovedQuantity(event, item) {
    item.qtde_aprovada = event.value;
  }

  // region tarefa #32237
  restoreOrderPending() {
    const formData = new FormData();
    formData.append("cd_orca", this.ped_orca.toString());
    this.showLoad();
    this.pedidoItemService.restoreOrderPending(formData).subscribe(
      (res: ApiResponse<{ success: boolean, message: string }>) => {
        this.hideLoad();
        this.requestCallbackService.okResponse(res,
          () => {
            this.toast.successToast("pedido_restaurado");
            window.location.reload();
          });
      },
      error => {
        this.hideLoad();
        this.requestCallbackService.failResponse(error, "falha_restaurar_pedido");
      }
    );
  }

  justificationOrderEvent(value) {
    this.justificationOrder = value.ds_justificativa;
    this.dateRefusedOrder = value.dt_recusa;
  }
  // end region tarefa #32237

  fabricaConsultandoPedidoVidraceiroEvent(value: boolean) {
    this.fabricaConsultandoPedidoVidraceiro = value;
  }

  changedOrcamentoEvent(orcamento: any) {
    this.orcamento = orcamento;
  }

  getInfoFornec() {

    const getFornListRequest = this.pedidoItemService.getFornList();
    const gruposCadPedidoRequest = this.gruposCadPedidoRequest();
    const isIndependentGlazingRequest = this.pedidoItemService.isIndependentGlazing();

    this.showLoadGruposGcad();
    forkJoin([getFornListRequest, gruposCadPedidoRequest, isIndependentGlazingRequest]).subscribe((responses: ApiResponse<{ success: boolean, message: string }>[]) => {
      this.hideLoadGruposGcad();

      const getFornListResponse = responses[0];
      const gruposCadPedidoResponse = responses[1];
      const isIndependentGlazingResponse = responses[2];

      this.requestCallbackService.okResponse(getFornListResponse,
        () => {
          const response = getFornListResponse.data;
          this.dataSourceProvidersVidros = response.vidros;
          this.dataSourceProvidersFerragens = response.ferragens;
          this.dataSourceProvidersPerfis = response.perfis;
          this.dataSourceProvidersAcessorios = response.acessorios;
        });

      this.requestCallbackService.okResponse(gruposCadPedidoResponse,
        () => {
          const pedidoForn = gruposCadPedidoResponse.data as PedidoFornecedorDto;
          this.c_pedidoFornecedor = pedidoForn;


          this.disableFornecFerragem = pedidoForn.st_importado_ferragens;
          this.disableFornecPerfil = pedidoForn.st_importado_perfis;
          this.disableFornecAcessorio = pedidoForn.st_importado_acessorios;
        });

      this.requestCallbackService.okResponse(isIndependentGlazingResponse,
        () => {
          const response = isIndependentGlazingResponse.data;
          this.disableFornecGlass = !response.st_independente;
        });

      this.definirFornecedorPadrao();
      this.exibirPainelFornecedores();
    }, error => {
      this.requestCallbackService.failResponse(error);
      this.hideLoadGruposGcad();
    });
  }

  toDateString(date: Date) {
    if (date) {
      return new Date(date).toLocaleString();
    }
    return ""
  }

  private definirFornecedorPadrao() {
    if (!this.c_pedidoFornecedor.cd_fornecedor_vidro) {
      this.c_pedidoFornecedor.cd_fornecedor_vidro = this.buscarCodigoQuandoHaSomenteUmRegistro(this.dataSourceProvidersVidros);
    }
    if (!this.c_pedidoFornecedor.cd_fornecedor_ferragem) {
      this.c_pedidoFornecedor.cd_fornecedor_ferragem = this.buscarCodigoQuandoHaSomenteUmRegistro(this.dataSourceProvidersFerragens);
    }
    if (!this.c_pedidoFornecedor.cd_fornecedor_perfil) {
      this.c_pedidoFornecedor.cd_fornecedor_perfil = this.buscarCodigoQuandoHaSomenteUmRegistro(this.dataSourceProvidersPerfis);
    }
    if (!this.c_pedidoFornecedor.cd_fornecedor_acessorio) {
      this.c_pedidoFornecedor.cd_fornecedor_acessorio = this.buscarCodigoQuandoHaSomenteUmRegistro(this.dataSourceProvidersAcessorios);
    }
  }

  private buscarCodigoQuandoHaSomenteUmRegistro(p_dataSourceRamo: any[]): number | null {
    if (p_dataSourceRamo.length == 1) {
      return p_dataSourceRamo[0].cd_codigo;
    }
    return null;
  }

  gruposCadPedidoRequest(): Observable<any> {
    const formData = new FormData();
    formData.append("orderId", this.ped_orca.toString());
    return this.pedidoItemService.gruposCadPedido(formData);
  }

  previewFerrangens() {
    const formData = new FormData();
    formData.append("orderId", this.ped_orca.toString());
    this.showLoad();
    this.pedidoItemService.previewFerragens(formData).toPromise().then((response: Blob) => {
      this.hideLoad();
      this.pedidoItemService.pedidoPreviewService.processResponsePdf(response);
    }).catch(result => {
      this.hideLoad();
      this.requestCallbackService.failResponse(result, "falha_gerar_relatorio");
    });
  }

  previewPerfis() {
    const formData = new FormData();
    formData.append("orderId", this.ped_orca.toString());
    this.showLoad();
    this.pedidoItemService.previewPerfis(formData).toPromise().then((response: Blob) => {
      this.hideLoad();
      this.pedidoItemService.pedidoPreviewService.processResponsePdf(response);
    }).catch(result => {
      this.hideLoad();
      this.requestCallbackService.failResponse(result, "falha_gerar_relatorio");
    });
  }

  previewAcessorios() {
    const formData = new FormData();
    formData.append("orderId", this.ped_orca.toString());
    this.showLoad();
    this.pedidoItemService.previewAcessorios(formData).toPromise().then((response: Blob) => {
      this.hideLoad();
      this.pedidoItemService.pedidoPreviewService.processResponsePdf(response);
    }).catch(result => {
      this.hideLoad();
      this.requestCallbackService.failResponse(result, "falha_gerar_relatorio");
    });
  }

  previewVidros() {

    if (this.disableFornecGlass) {
      return;
    }

    const formData = new FormData();
    formData.append("orderId", this.ped_orca.toString());
    this.showLoad();
    this.pedidoItemService.previewVidros(formData).toPromise().then((response: Blob) => {
      this.hideLoad();
      this.pedidoItemService.pedidoPreviewService.processResponsePdf(response);
    }).catch(result => {
      this.hideLoad();
      this.requestCallbackService.failResponse(result, "falha_gerar_relatorio");
    });
  }

  enviarMaterialFornecedor(p_TipoFornecedor: number, p_enviarNovamente: boolean = false) {

    if (this.orcamento.st_orca != STATUS_FABRICA) {
      //this.toast.warningToast("validacao_envio_fornecedor_status_fabrica");
      //return;
    }

    let m_st_valido: boolean = false;
    let m_cd_fornecedor: number;
    let m_assunto: string;
    let m_cd_ramo: number;
    let m_data_envio: Date;

    switch (p_TipoFornecedor) {
      case TIPO_FORNECEDOR_VIDRO: {

        if (this.disableFornecGlass) {
          return;
        }

        m_data_envio = this.c_pedidoFornecedor.dt_ultimo_envio_vidros;
        m_cd_fornecedor = this.c_pedidoFornecedor.cd_fornecedor_vidro;
        m_st_valido = this.validarCodigoFornecedor(m_cd_fornecedor);
        m_assunto = this.language.translateMessage("pedido_vidros");
        m_cd_ramo = TIPO_FORNECEDOR_VIDRO;
        break;
      }
      case TIPO_FORNECEDOR_FERRAGEM: {

        m_data_envio = this.c_pedidoFornecedor.dt_ultimo_envio_ferragens;
        m_cd_fornecedor = this.c_pedidoFornecedor.cd_fornecedor_ferragem;
        m_st_valido = this.validarCodigoFornecedor(m_cd_fornecedor);
        m_assunto = this.language.translateMessage("pedido_ferragens");
        m_cd_ramo = TIPO_FORNECEDOR_FERRAGEM;
        break;
      }
      case TIPO_FORNECEDOR_PERFIL: {

        m_data_envio = this.c_pedidoFornecedor.dt_ultimo_envio_perfis;
        m_cd_fornecedor = this.c_pedidoFornecedor.cd_fornecedor_perfil;
        m_st_valido = this.validarCodigoFornecedor(m_cd_fornecedor);
        m_assunto = this.language.translateMessage("pedido_perfis");
        m_cd_ramo = TIPO_FORNECEDOR_PERFIL;
        break;
      }
      case TIPO_FORNECEDOR_ACESSORIO: {

        m_data_envio = this.c_pedidoFornecedor.dt_ultimo_envio_acessorios;
        m_cd_fornecedor = this.c_pedidoFornecedor.cd_fornecedor_acessorio;
        m_st_valido = this.validarCodigoFornecedor(m_cd_fornecedor);
        m_assunto = this.language.translateMessage("pedido_acessorios");
        m_cd_ramo = TIPO_FORNECEDOR_ACESSORIO;
        break;
      }
    }

    if (!m_st_valido) {
      this.toast.warningToast("selecione_fornecedor");
      return;
    }

    if (this.exibirPoupReenviarEmailFornecedorCasoNecessario(m_data_envio, p_enviarNovamente, p_TipoFornecedor)) {
      return;
    }

    const m_fornecedor = this.dataSourceProvidersVidros.find(vidros => vidros.cd_codigo == m_cd_fornecedor);
    if (m_fornecedor && m_fornecedor.st_fabrica) {
      this.sendOrderToForn(m_cd_fornecedor, m_cd_ramo);
    } else {
      this.sendEmailToForn(m_cd_fornecedor, m_assunto, m_cd_ramo);
    }
  }

  private exibirPoupReenviarEmailFornecedorCasoNecessario(dataUltimoEnvio: Date, p_enviarNovamente: boolean, p_TipoFornecedor: number): boolean {
    const exibir = dataUltimoEnvio && !p_enviarNovamente;
    if (exibir) {
      this.exibirPopupReenviarEmail(p_TipoFornecedor);
    }
    return exibir;
  }

  private exibirPopupReenviarEmail(p_TipoFornecedor: number) {
    this.visibilityPopupResendEmail = true;
    this.tipoFornecedorReenvio = p_TipoFornecedor;
  }

  private validarCodigoFornecedor(p_CodigoFornecedor: number): boolean {
    if (!p_CodigoFornecedor) {
      return false;
    }
    return true;
  }

  private sendEmailToForn(p_CodigoFornecedor: number, p_assunto: string, p_cd_ramo: number) {
    const formData = new FormData();
    const labels = {
      ds_assunto: p_assunto,
      ds_mensagem: this.language.translateMessage("msg_email_fornecedor")
    };
    formData.append("orderId", this.ped_orca.toString());
    formData.append("fornId", p_CodigoFornecedor.toString());
    formData.append("labels", JSON.stringify(labels));
    formData.append("cd_ramo", p_cd_ramo.toString());
    formData.append("st_fabrica", "false");
    this.doRequestToOrderToForn(formData);
  }

  private sendOrderToForn(p_CodigoFornecedor: number, p_cd_ramo: number) {
    const formData = new FormData();
    formData.append("orderId", this.ped_orca.toString());
    formData.append("fornId", p_CodigoFornecedor.toString());
    formData.append("cd_ramo", p_cd_ramo.toString());
    formData.append("st_fabrica", "true");
    this.doRequestToOrderToForn(formData, true);
  }

  private doRequestToOrderToForn(formData: FormData, st_fabrica: boolean = false) {
    this.showLoad();
    this.pedidoItemService.sendMailToForn(formData).subscribe(
      (res: ApiResponse<{ success: boolean, message: string }>) => {
        this.hideLoad();
        this.requestCallbackService.okResponse(res,
          () => {
            const data = res.data;
            const dt_hora = data.dt_hora;
            switch (data.cd_material) {
              case TIPO_FORNECEDOR_VIDRO: {
                this.c_pedidoFornecedor.st_enviado_vidros = true;
                this.c_pedidoFornecedor.dt_ultimo_envio_vidros = dt_hora;
                break;
              }
              case TIPO_FORNECEDOR_FERRAGEM: {
                this.c_pedidoFornecedor.st_enviado_ferragens = true;
                this.c_pedidoFornecedor.dt_ultimo_envio_ferragens = dt_hora;
                break;
              }
              case TIPO_FORNECEDOR_PERFIL: {
                this.c_pedidoFornecedor.st_enviado_perfis = true;
                this.c_pedidoFornecedor.dt_ultimo_envio_perfis = dt_hora;
                break;
              }
              case TIPO_FORNECEDOR_ACESSORIO: {
                this.c_pedidoFornecedor.st_enviado_acessorios = true;
                this.c_pedidoFornecedor.dt_ultimo_envio_acessorios = dt_hora;
                break;
              }
            }

            const msg = st_fabrica ? "pedido_enviado_fabrica" : "email_enviado_sucesso";
            this.toast.successToast(msg);
            this.visibilityPopupResendEmail = false;
          });
      }, error => {
        this.hideLoad();
        this.requestCallbackService.failResponse(error);
      });
  }

}
