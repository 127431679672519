import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { baseUrl } from "src/app/config";

@Injectable({
    providedIn: "root"
})
export class FerragensService {
    private url = baseUrl;
    constructor(private http: HttpClient) { }

    getFerragens(form: FormData): Observable<any> {
        return this.http.post<any>(`${this.url}/Ferragens`, form);
    }

    getDXFById(formData: FormData): Observable<any> {
        return this.http.post<any>(`${this.url}/DXFById`, formData);
    }

    insertFerragem(formData: FormData): Observable<any> {
        return this.http.post<any>(`${this.url}/InsertFerragem`, formData);
    }

    updateFerragem(formData: FormData): Observable<any> {
        return this.http.post<any>(`${this.url}/UpdateFerragem`, formData);
    }

    deleteFerragem(formData: FormData): Observable<any> {
        return this.http.post<any>(`${this.url}/DeleteFerragem`, formData);
    }
}