import { Component, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { LoadOptions } from 'devextreme/data/load_options';
import { BaseLazyComponent } from 'src/app/shared/helpers/BaseLazyComponent';
import { LanguagueService } from 'src/app/shared/helpers/language.service';
import { RequestCallbackService } from 'src/app/shared/helpers/request-callback.service';
import { sleep } from 'src/app/shared/helpers/util';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { LazyDataSource } from 'src/app/shared/models/LazyDataSource';
import { isUserProfileIndependentGlazing, UserProfile } from 'src/app/shared/models/UserProfile';
import { ClientesService } from '../clientes/clientes.service';
import { PainelService } from '../painel/painel.service';
import { ClientesVidraceirosService } from './clientes-vidraceiros.service';

@Component({
  selector: 'app-clientes-vidraceiros',
  templateUrl: './clientes-vidraceiros.component.html',
  styleUrls: ['./clientes-vidraceiros.component.scss']
})
export class ClientesVidraceirosComponent extends BaseLazyComponent implements OnInit {

  loading: boolean = false;
  havePermission: boolean = true;
  formCode: number;
  dataSourceCountries: LazyDataSource;
  dataSourcePlanos: LazyDataSource;
  dataSourceCities: LazyDataSource;
  dataSourceCompaniesType: any[] = [];

  @ViewChild(DxDataGridComponent, { static: false })
  dataGridVidraceiros: DxDataGridComponent;

  c_allowAdding: boolean = false;
  c_allowEditing: boolean = false;

  constructor(
    private service: ClientesVidraceirosService,
    private requestCallbackService: RequestCallbackService,
    private language: LanguagueService,
    private clientesService: ClientesService,
    private painelService: PainelService,
  ) {
    super("cd_empresa");
    this.language.globalize();
  }

  ngOnInit() {
    this.setupDataSourceCountries();
    this.setupDataSourceCidades();
    this.setupDataSourcePlanos();
    this.isUserProfileReitech();
    this.setupDataSourceCompaniesType();
  }

  private isUserProfileReitech() {
    this.loading = true;
    this.painelService.isUserProfileReitech().subscribe(
      (res: ApiResponse<{ success: boolean, message: string }>) => {
        this.loading = false;
        this.requestCallbackService.okResponse(res,
          () => {
            const st_reitech = res.data.st_reitech;
            if (st_reitech) {
              this.dataGridVidraceiros.instance.columnOption("cd_pais", "visible", false);
              this.dataGridVidraceiros.instance.columnOption("ds_cidade", "visible", false);
              this.dataGridVidraceiros.instance.columnOption("ds_endereco", "visible", false);
              this.dataGridVidraceiros.instance.columnOption("ds_bairro", "visible", false);
              this.dataGridVidraceiros.instance.columnOption("ds_empresa_pai", "visible", true);
              this.dataGridVidraceiros.instance.columnOption("ds_empresa_pai", "groupIndex", 0);
              this.dataGridVidraceiros.instance.columnOption("tipo_empresa", "visible", true);
              this.dataGridVidraceiros.instance.columnOption("cd_reitech", "visible", true);
              this.c_allowAdding = true;
            }
            this.c_allowEditing = true;
          });
      },
      error => {
        this.loading = false;
        this.requestCallbackService.failResponse(error);
      }
    );
  }

  setupDataSourceCidades() {
    this.dataSourceCities = this.clientesService.getDataSourceCidade();
  }

  load(loadOptions: LoadOptions): Promise<any> {
    const formData = this.createFormData([{ key: "loadOptions", value: loadOptions }]);
    return this.requestCallbackService.load(
      this.service.getVidraceiros(formData),
      (response) => {
        if (this.requestCallbackService.isResponseWithoutPermission(response)) {
          this.formCode = response.data.form;
          this.havePermission = false;
        }
        return response.data.empresas;
      },
      "falha_buscar_clientes",
    );
  }

  setupDataSourceCountries() {
    this.dataSourceCountries = this.clientesService.setupDataSourceCountries();
  }

  setupDataSourcePlanos() {
    this.dataSourcePlanos = this.service.setupDataSourcePlanos();
  }

  onInitNewRow(e) {
    e.data.tipo_empresa = UserProfile.VIDRACARIA_INDEPENDENTE;
  }

  insert(values: any): Promise<void> {
    const formData = this.createFormData([{ key: "values", value: values }]);
    return this.requestCallbackService.insert(this.service.insertCompany(formData), this.dataGridVidraceiros);
  }

  update(key: any, values: any): Promise<void> {
    const formData = this.createFormData([{ key: "key", value: key }, { key: "values", value: values }]);
    return this.requestCallbackService.update(this.service.updateCompany(formData), this.dataGridVidraceiros);
  }

  delete(key: any): Promise<void> {
    const formData = this.createFormData([{ key: "key", value: key }]);
    return this.requestCallbackService.delete(this.service.deleteCompany(formData), this.dataGridVidraceiros);
  }

  onRowUpdating(options) {
    options.newData = Object.assign(options.oldData, options.newData);
  }

  setupDataSourceCompaniesType() {
    sleep(200).then(() => {
      this.dataSourceCompaniesType = this.painelService.getCompaniesType();
    });
  }

  cm_allowDelete = (e): boolean => {
    const m_CompanyType = e.row.data.tipo_empresa;
    return isUserProfileIndependentGlazing(m_CompanyType) && this.c_allowEditing;
  }

  onEditorPreparing(e) {
    if (
      e.dataField != "cd_plano"
      && e.dataField != "dt_expiracao"
    ) {
      if (e.row != null && e.row.data != null){
        const m_CompanyType = e.row.data.tipo_empresa;
        e.editorOptions.disabled = !(isUserProfileIndependentGlazing(m_CompanyType) && this.c_allowEditing);
      }
    }
  }

  onToolbarPreparing(e) {

    const items: any[] = e.toolbarOptions.items;

    // busca a barra de pesquisa
    const searchItem = items[items.length - 1];
    // permite collapse menu
    searchItem.locateInMenu = "auto";

    items.splice(
      1,
      0,
      {
        location: "after",
        widget: "dxButton",
        name: "Imprimir",
        options: {
          icon: "print",
          onClick: () => {
            window.print();
          }
        }
      },
      {
        location: "center",
        template: "dx-title"
      }
    );
  }

}
