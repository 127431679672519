import { Component, OnInit, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { EditorService } from '../editor/editor.service';
import { RequestCallbackService } from 'src/app/shared/helpers/request-callback.service';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { getCompletePathNoImage, sleep } from 'src/app/shared/helpers/util';
import { CabecalhoImpressao } from 'src/app/shared/models/CabecalhoImpressao';
import { LanguagueService } from 'src/app/shared/helpers/language.service';
import { ResumoTecnicoService } from './resumo-tecnico.service';
import { PedidoPreviewService } from '../pedido-preview/pedido-preview.service';
import { CONFIG_OCULTAR_CUSTOS } from 'src/app/shared/helpers/constants';
import { FinanceiroService } from 'src/app/shared/services/financeiro.service';

@Component({
  selector: 'app-resumo-tecnico',
  templateUrl: './resumo-tecnico.component.html',
  styleUrls: ['./resumo-tecnico.component.scss']
})
export class ResumoTecnicoComponent implements OnInit {

  technicalSummary: any;
  cabecalhoImpressao: CabecalhoImpressao;

  @Input()
  conjunto: any;

  @Output()
  notifyLoadEvent = new EventEmitter();

  ocultarCustos: boolean;

  constructor(
    private service: EditorService,
    private language: LanguagueService,
    private requestCallbackService: RequestCallbackService,
    private changeDetectorRefs: ChangeDetectorRef,
    private resumoTecnicoService: ResumoTecnicoService,
    private pedidoPreviewService: PedidoPreviewService,
    private financeiroService: FinanceiroService
  ) {
    this.language.globalize();
    this.cabecalhoImpressao = new CabecalhoImpressao();
  }

  ngOnInit() {
    this.getConfigOcultarCustos();
  }

  showLoadTechnicalSummary() {
    this.notifyLoadEvent.emit(true);
  }

  hideLoadTechnicalSummary() {
    this.notifyLoadEvent.emit(false);
  }

  load() {
    if (!this.conjunto) {
      return;
    }

    const conjunto = {
      p_gcad: this.conjunto.p_gcad
    }
    let objJson = JSON.stringify(conjunto);
    const formData = new FormData();
    formData.append("obj", objJson);

    this.showLoadTechnicalSummary();
    this.service.getResumoTecnico(formData).subscribe(
      (res: ApiResponse<{ success: boolean, message: string }>) => {
        this.hideLoadTechnicalSummary();

        this.requestCallbackService.okResponse(res,
          () => {
            this.technicalSummary = res.data;
          });
      }, error => {
        this.hideLoadTechnicalSummary();
        this.requestCallbackService.failResponse(error, "falha_resumo_tecnico");
      }
    );
  }

  printTechnicalSummary() {
    const formData = new FormData();
    formData.append("p_gcad", this.conjunto.p_gcad);
    this.showLoadTechnicalSummary();
    this.resumoTecnicoService.technicalSummary(formData).toPromise().then((response: Blob) => {
      this.hideLoadTechnicalSummary();
      this.pedidoPreviewService.processResponsePdf(response);
    }).catch(result => {
      this.hideLoadTechnicalSummary();
      this.requestCallbackService.failResponse(result, "falha_gerar_relatorio");
    });
  }

  private getConfigOcultarCustos() {
    this.financeiroService.getMasterConfig(CONFIG_OCULTAR_CUSTOS).subscribe((res: ApiResponse<{ success: boolean, message: string }>) => {
      this.requestCallbackService.okResponse(res,
        () => {
          const ocultar = res.data.config.valor == 1;
          this.ocultarCustos = ocultar;
        }
      );
    }, error => {
      this.requestCallbackService.failResponse(error, "falha_get_config");
    });
  }
}
