<dx-select-box
    [dataSource]="countries"
    valueExpr="cd_codigo"
    [placeholder]="'index.selecione_pais' | translate" 
    [searchEnabled]="true"
    searchMode="contains"
    displayExpr="ds_nome"
    [showClearButton]="true"
    (onSelectionChanged)="countrySelected($event, data)">
</dx-select-box>

<dx-text-box
    [id]="data.item ? data.item.dataField.concat(data.rowIndex) : data"
    [maxLength]="maxLength"
    [mask]="mask"
    [value]="data.value"
    [useMaskedValue]="true"
    (onValueChanged)="data.setValue($event.value)">
</dx-text-box>

<input *ngIf="data.item" hidden [id]="data.item.dataField.concat(suffixRegex)" type="text" [value]="ds_regex">
