<app-loader *ngIf="loading"></app-loader>
<div *ngIf="havePermission" class="m-3">
  
  <div class="form panel-tools">

    <form (submit)="submit($event)">
      <dx-button id="button" icon="save" useSubmitBehavior="true" validationGroup="validationConfig"> </dx-button>
      <dx-button id="button" icon="refresh" (onClick)="reload()"> </dx-button>
      
      <dx-form id="form" [formData]="company" validationGroup="validationConfig">
        <dxi-item itemType="group" cssClass="first-group" [colCount]="2">
          <dxi-item>
            <img
              [src]="sanitizer.bypassSecurityTrustUrl(ds_logo)"
              class="form-avatar"
            />
            <dxi-item>
              <h6>{{ "index.imagem_logo" | translate }}</h6> 
              <dx-file-uploader
                labelText=""
                accept="image/*"
                [showFileList]="false"
                uploadMode="useForm"
                (onValueChanged)="getBase64($event, 'ds_logo')"
                [allowedFileExtensions]="['.jpg', '.jpeg', '.gif', '.png']">
              </dx-file-uploader>
            </dxi-item>
          </dxi-item>
    
          <dxi-item>
            <img
              [src]="sanitizer.bypassSecurityTrustUrl(ds_logo_peq)"
              class="form-avatar"
            />
            <dxi-item>
              <h6>{{ "index.imagem_fundo" | translate }}</h6>
              <dx-file-uploader
                labelText=""
                accept="image/*"
                [showFileList]="false"
                uploadMode="useForm"
                (onValueChanged)="getBase64($event, 'ds_logo_peq')"
                [allowedFileExtensions]="['.jpg', '.jpeg', '.gif', '.png']">
              </dx-file-uploader>
            </dxi-item>
          </dxi-item>
        </dxi-item>

        <dxi-item itemType="group">
            <dxi-item
              [visible]="showAllFields"
              dataField="ds_fantasia"
              [label]="{ text: 'index.nomeFantasia' | translate }">
              <dxi-validation-rule 
                  type="required">
              </dxi-validation-rule>
            </dxi-item>
            <dxi-item
              [visible]="showAllFields"
              dataField="ds_email"
              [label]="{ text: 'index.email' | translate }">
              <dxi-validation-rule 
                  type="required">
              </dxi-validation-rule>
              <dxi-validation-rule 
                  type="email">
              </dxi-validation-rule>
            </dxi-item>
            <dxi-item
              [visible]="showAllFields"
              dataField="ds_fone"
              [label]="{ text: 'index.telefone' | translate }">
              <dxi-validation-rule 
                  type="required">
              </dxi-validation-rule>
            </dxi-item>
            <dxi-item 
              [visible]="showAllFields"
              dataField="ds_home" 
              [label]="{ text: 'HomePage' }">
            </dxi-item>
        </dxi-item>
      </dx-form>
    </form>
  </div>

  
</div>
<denied-access *ngIf="!loading && !havePermission" [formCode]="formCode"></denied-access>

