import { Component, OnInit, ViewChild } from "@angular/core";
import { PainelService } from "./painel.service";
import { saveDataAsFile, sleep, traduzirStatus } from "src/app/shared/helpers/util";
import { RequestCallbackService } from "src/app/shared/helpers/request-callback.service";
import { DxDataGridComponent } from 'devextreme-angular';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { WITHOUT_PERMISSION_KEY } from 'src/app/shared/helpers/constants';
import { ToastService } from 'src/app/shared/helpers/toast.service';
import { LanguagueService } from 'src/app/shared/helpers/language.service';
import { BaseLazyComponent } from 'src/app/shared/helpers/BaseLazyComponent';
import { LoadOptions } from 'devextreme/data/load_options';
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import { ProdutosService } from "../produtos/produtos.service";

class HistoricChart {
  data: string;
  qtdAcesso: number;
  qtdPedido: number;
}

class LabelChart {
  value: string;
  name: string;
}

@Component({
  selector: "app-painel",
  templateUrl: "./painel.component.html",
  styleUrls: ["./painel.component.scss"]
})
export class PainelComponent extends BaseLazyComponent implements OnInit {

  content: boolean;
  responseStatus: number;
  dataSourcePedidos: any = [];
  dataSourceLogin: DataSource;
  historicoChart: HistoricChart[] = [];
  labelChart: LabelChart[] = [];
  st_reitech: boolean;
  dataSourceCompaniesType: any[] = [];
  dataSourceRamos: any[] = [];

  @ViewChild("data_grid", { static: true }) data_grid: DxDataGridComponent;
  constructor(
    private painelService: PainelService,
    private toast: ToastService,
    private language: LanguagueService,
    private requestCallbackService: RequestCallbackService,
    private produtosService: ProdutosService
  ) {
    super("cd_orca");
    this.language.globalize();
  }

  ngOnInit() {
    this.showLoad();
    this.setupLabelChart();
    this.isUserProfileReitech();
    this.setupDataSourceCompaniesType();
    this.setupDataSourceRamos();
  }

  private isUserProfileReitech() {
    this.painelService.isUserProfileReitech().subscribe(
      (res: ApiResponse<{ success: boolean, message: string }>) => {
        this.requestCallbackService.okResponse(res,
          () => {
            this.hideLoad();
            if (res.key == WITHOUT_PERMISSION_KEY) {
              this.responseStatus = 2;
            } else {
              this.responseStatus = 1;
              this.getGraficoPainelAdm();
              this.st_reitech = res.data.st_reitech;
              if (this.st_reitech) {
                this.Read_painel();
              }
            }
          });
      },
      error => {
        this.hideLoad();
        this.responseStatus = 3;
        this.requestCallbackService.failResponse(error);
      }
    );
  }

  private setupLabelChart() {
    this.language.translateMessageLazy("acessos", (value) => {
      this.labelChart.push(
        { value: "qtdAcesso", name: value },
      );
    });

    this.language.translateMessageLazy("pedidos", (value) => {
      this.labelChart.push(
        { value: "qtdPedido", name: value }
      );
    });
  }

  private showLoad() {
    this.content = false;
  }

  private hideLoad() {
    this.content = true;
  }

  load(loadOptions: LoadOptions): Promise<any> {
    const formData = this.createFormData([{ key: "loadOptions", value: loadOptions }]);
    return this.requestCallbackService.load(
      this.painelService.getPedidosPainel(formData),
      (response) => {
        const pedidos = response.data.pedidosPainel.data;
        return response.data.pedidosPainel;
      },
      "falhaBuscarPedidos",
    );
  }

  private getGraficoPainelAdm() {
    this.showLoad();
    this.painelService.getGraficoPainelAdm().subscribe(
      (response: ApiResponse<{ success: boolean, message: string }>) => {
        this.hideLoad();
        this.requestCallbackService.okResponse(response,
          () => {
            let pedidos: any[] = response.data.listaPedidos;
            let acessos: any[] = response.data.listaAcessos;
            for (let key in pedidos) {
              let qtdPedido = pedidos[key];
              let qtdAcesso = acessos[key];
              this.historicoChart.push({
                data: key,
                qtdAcesso: qtdAcesso,
                qtdPedido: qtdPedido
              });
            }
          });
      },
      error => {
        this.hideLoad();
        this.requestCallbackService.failResponse(error, "falhaBuscarAcessos");
      }
    );
  }

  handleImport = (e) => {
    const data = e.row.data;
    const pedido = {
      p_pedido: data.cd_orca,
      p_material: data.cd_material,
      p_atualiza: true
    };
    const pedidoJson = JSON.stringify(pedido);
    this.showLoad();
    this.painelService.importarPedido(pedidoJson).subscribe(
      (res: ApiResponse<{ success: boolean, message: string }>) => {
        this.hideLoad();
        this.requestCallbackService.okResponse(res,
          () => {
            const text: string = res.data.PGCAD;
            const pgcad_serialized = res.data.pgcad_serialized;

            const material = this.dataSourceRamos.find(a => a.cd_codigo == data.cd_material);
            const fileName = text.replace(".pgcad", "")
              .concat("_")
              .concat(material != null ? material.ds_nome : data.cd_material)
              .concat(".pgcad");

            saveDataAsFile(pgcad_serialized, fileName);
            this.toast.successToast("pedidoImportado");
          });
      },
      error => {
        this.hideLoad();
        this.requestCallbackService.failResponse(error, "falhaImportarPedido");
      }
    );
  }

  getStatusDescription(acronymStatus: string): string {
    return traduzirStatus(acronymStatus, this.language);
  }

  setupDataSourceCompaniesType() {
    sleep(200).then(() => {
      this.dataSourceCompaniesType = this.painelService.getCompaniesType();
    });
  }

  setupDataSourceRamos() {
    sleep(200).then(() => {
      this.dataSourceRamos = this.produtosService.getGruposGcad();
    });
  }

  onToolbarPreparing(e) {

    const items: any[] = e.toolbarOptions.items;

    // busca a barra de pesquisa
    const searchItem = items[items.length - 1];
    // permite collapse menu
    searchItem.locateInMenu = "auto";

    items.unshift({
      location: "center",
      template: "dx-title"
    });
  }

  private Read_painel() {
    const store = new CustomStore({
      key: "cd_codigo",
      load: (loadOptions: LoadOptions) => {
        const formData = this.createFormData([{ key: "loadOptions", value: loadOptions }]);
        return this.requestCallbackService.load(
          this.painelService.Read_painel(formData),
          (response) => {
            return response.data.empresas;
          },
          "",
        );
      }
    });
    this.dataSourceLogin = new DataSource({ store });

    // this.showLoad();
    // this.painelService.Read_painel().subscribe(
    //   (response: ApiResponse<{ success: boolean, message: string }>) => {
    //     this.hideLoad();
    //     this.requestCallbackService.okResponse(response,
    //       () => {
    //         const empresas: any[] = response.data.empresas;
    //         this.dataSourceLogin = empresas;
    //       });
    //   },
    //   error => {
    //     this.hideLoad();
    //     this.requestCallbackService.failResponse(error);
    //   }
    // );
  }
}
