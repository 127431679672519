
export const PATH_NO_IMAGE: string = "conjuntos/noimage.png";
export const WITHOUT_PERMISSION_KEY: string = "without_permission";
export const WITHOUT_AUTHENTICATION_KEY: string = "login";
export const iconVisible: string = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAHdElNRQfkAxoRKDkmudH6AAABSUlEQVQ4y93Sv0ocURgF8N+EFO6UrhsQLFWIIpF0QUlpIxbB0sfQfYGonUTyClGJWWMXhVR5AndwieID2Cg4skawUJkUXq+TXbE3323ud865f87H4flX0oVUzRg3gGOZH86eOj6m4UZRWte+GX1cnPrsVqHQtmbegjVthcKtVZVO+bDDcOemakT7NAJ6YKgsn3Qe5YnUskxmSSqxFZjcxL38vcsAtlWlWk79Uii0pGouAntpEkbk0eIXLDvR70NAFrEe+dwIR6WZLCDz04yrgDRRLymyl6665jVlKu6LDq7nhTnnsX2DnX8EOxiPXW72bkYPpvukWvED+ypeRdN/HuY0EY03JCoWNTV9VJHYDsyZd+WnhxwEYkstojXfA/rbYHc0PoVoXFhXV7cRPnNjpTsadzVq03VH+L56/XS8e017G+K9Z1fuv6u/LnGn0KoaVLwAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjAtMDMtMjZUMTc6NDA6NTcrMDA6MDD1hb0+AAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIwLTAzLTI2VDE3OjQwOjU3KzAwOjAwhNgFggAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAAASUVORK5CYII=";
export const iconInvisible: string = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAHdElNRQfkAxoRLTO7G8yhAAABeElEQVQ4y53Uz0uUURTG8c/k4KKhAqdEiZAIpGXLoB+bcGULMSlGGogKElroRqioRS0CKQjxDxjShfgXtKiN0KYIJYRaKklQku3VmTster3emYGY6dzVe87zfd73cM59+a843Zn8tl3ldsV5HJVXwQJyzrnijD45275676PQik0JqsooWlVvOOseKvwbWWlC6r4ZbgYmBfUM6XFZQcFF80KG1DxO5U+jV9V4g9Go3Vh7sp+8Hn0OkLzn7shhOqldg16/YmLbtJAhRSteoNtWrG85wcvEYQkP1FWVUPRJEUuJYoaN5HEWF7IPK6HHKcwlio1DKkmL/fhiD10WlPy2iZOJ4jXHbCY9HMaj2H4JLCfzOAJDajH1DFxV8SbpZTUzGNp/0UQEau4l00+R4H46oLt2IvTWmEFnjfueIDeal+O8zy071NhLS3S55UOTOFgTUiTXgg24ZNBxwU/r3vlh0itB2WK7l+zvLlfdbB9gyo6RToCOfxQx/gAUl9Y+isex4QAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMC0wMy0yNlQxNzo0NTo1MSswMDowMHB8Q0AAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjAtMDMtMjZUMTc6NDU6NTErMDA6MDABIfv8AAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAABJRU5ErkJggg==";
//tarefa #29970
export const OPERACAO_FABRICAR: number = 1;
export const OPERACAO_ESTOQUE: number = 2;
// end region tarefa #29970
//tarefa #29971
export const PT_BR_LANGUAGE: string = "pt-BR";
export const EN_US_LANGUAGE: string = "en-US";
export const ES_ES_LANGUAGE: string = "es-ES";
export const ES_PY_LANGUAGE: string = "es-PY";
// end region tarefa #29971

// region tarefa #30164
export const MENU_PERMISSION: string = "st_menu";
export const ADD_PERMISSION: string = "st_adicionar";
export const FORM_CLIENTES: string = "5";
// end region tarefa #30164

// region tarefa #30694
export const ERRO_ORIGEM_REQUISICAO: string = "erro_origin_request";
// end region tarefa #30694

// region tarefa #31112
export const CURRENCY_SYMBOL: string = "$";
// end region tarefa #31112

export const SENTRY_DSN: string = "https://cc4f919cc2d54ddb9b17befa8d960a60@o412941.ingest.sentry.io/5301799";

// region session KEYS
export const HASHCAD: string = "hashcad";
export const USER_NAME: string = "ds_apelido";
export const USER_EMAIL: string = "ds_nome";
export const LANGUAGE: string = "lng";

export const STATUS_PENDENTE_INT: number = 0;
export const STATUS_TECNICO_INT: number = 1;
export const STATUS_FABRICA_INT: number = 2;
export const STATUS_FABRICANDO_INT: number = 3;
export const STATUS_RECEBIDO_INT: number = 4;
export const STATUS_ENTREGUE_INT: number = 5;

export const STATUS_PENDENTE: string = "P";
export const STATUS_TECNICO: string = "T";
export const STATUS_FABRICA: string = "L";
export const STATUS_FABRICANDO: string = "A";
export const STATUS_RECEBIDO: string = "R";
export const STATUS_ENTREGUE: string = "E";
export const STATUS_RECUSADO: string = "X";

export const ENTIDADE_CLIENTE: number = 1;
export const ENTIDADE_FORNECEDOR: number = 2;
export const ENTIDADE_CLIENTE_FORNECEDOR: number = 3;

export const VENDEDOR_TODOS: number = 0;
export const VENDEDOR_INTERNO: number = 1;
export const VENDEDOR_EXTERNO: number = 2;

export const VENDEDOR_ATIVO: number = 1;
export const VENDEDOR_INATIVO: number = 0;


export const STATUS_ETIQUETA_ENTREGUE: number = -1;
export const STATUS_ETIQUETA_CANCELADA: number = -2;
export const STATUS_ETIQUETA_EXCLUIDA: number = -3;
export const STATUS_ETIQUETA_DEVOLVIDA: number = -4;
export const STATUS_ETIQUETA_CREDITO: number = -5;
export const STATUS_ETIQUETA_PENDENTE: number = 1;

export const CONFIG_EXIBIR_TODAS_FORMULAS: string = "exibir_todas_formulas";
export const CONFIG_IDIOMA: string = "idioma";
export const CONFIG_OCULTAR_CUSTOS: string = "vendas_ocultar_custos";
export const CONFIG_MOEDA_PADRAO: string = "moeda_padrao";

export const TIPO_ITEM_CONJUNTO_ARVORE: number = -1;
export const TIPO_ITEM_CONJUNTO_FERRAGEM: number = 2;
export const TIPO_ITEM_CONJUNTO_FURO: number = 3;
export const TIPO_ITEM_CONJUNTO_VIDRO: number = 4;
export const TIPO_ITEM_CONJUNTO_SHAPE: number = 9;
export const TIPO_ITEM_COTA_ESCRITA: number = 1;

export const CONTEXT_MENU_ITEM_ADD_FERRAGEM: number = 1;
export const CONTEXT_MENU_ITEM_EDIT_FERRAGEM: number = 2;
export const CONTEXT_MENU_ITEM_DELETE_FERRAGEM: number = 3;
export const CONTEXT_MENU_ITEM_ESPELHAR_VERTICAL: number = 4;
export const CONTEXT_MENU_ITEM_ESPELHAR_HORIZONTAL: number = 5;
export const CONTEXT_MENU_ITEM_EDIT_ESQUADRO: number = 6;
export const CONTEXT_MENU_ITEM_ADD_RECORTES: number = 7;
export const CONTEXT_MENU_ITEM_EDIT_RECORTES: number = 8;
export const CONTEXT_MENU_ITEM_DELETE_RECORTES: number = 9;
export const CONTEXT_MENU_ITEM_EDIT_CANTOS: number = 10;
export const CONTEXT_MENU_ITEM_ADD_ESCRITA: number = 11;
export const CONTEXT_MENU_ITEM_EDIT_ESCRITA: number = 12;
export const CONTEXT_MENU_ITEM_DELETE_ESCRITA: number = 13;

export const GRUPO_GCAD_INDEFINIDO: number = 0;
export const GRUPO_GCAD_ACESSORIOS_GERAIS: number = 1;
export const GRUPO_GCAD_VIDRO: number = 2;
export const GRUPO_GCAD_FERRAGEM: number = 3;
export const GRUPO_GCAD_PERFIL: number = 4;

export const TIPO_FORNECEDOR_VIDRO: number = GRUPO_GCAD_VIDRO;
export const TIPO_FORNECEDOR_FERRAGEM: number = GRUPO_GCAD_FERRAGEM;
export const TIPO_FORNECEDOR_PERFIL: number = GRUPO_GCAD_PERFIL;
export const TIPO_FORNECEDOR_ACESSORIO: number = GRUPO_GCAD_ACESSORIOS_GERAIS;
