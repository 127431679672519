<dx-popup
  width="700"
  [height]="'auto'"
  [showTitle]="true"
  [title]="popupTitle"
  [dragEnabled]="false"
  [closeOnOutsideClick]="true"
  [(visible)]="popupHistoricoVisible"
>
  <div *dxTemplate="let data of 'content'">
    <div *ngIf="historicoLoading">Carregando histórico...</div>
    <div *ngIf="!historicoLoading && historico.length > 0">
      <table class="table">
        <thead>
          <tr>
            <th>Hora</th>
            <th>Operação</th>
            <th>Usuário</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of historico">
            <td>
              {{ item.hora | date:'dd/MM/yyyy' }}<br>
              {{ item.hora | date:'HH:mm:ss' }}
            </td>
                        <td [innerHTML]="item.operacao"></td>

            <td>{{ item.ds_usuario }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="!historicoLoading && historico.length === 0">
      Nenhum histórico encontrado.
    </div>
  </div>
</dx-popup>

<dx-popup
  width="300"
  [height]="'auto'"
  [showTitle]="true"
  [title]="'index.tooltip' | translate"
  [dragEnabled]="false"
  [closeOnOutsideClick]="true"
  [(visible)]="popupVisible"
>
  <div *dxTemplate="let data of 'content'">
    <div class="option">
      <form (submit)="inject($event)">
        <dx-form
            [formData]="percentageForm">
            <dxi-item
              dataField="width"
              editorType="dxNumberBox"
              [label]="{ text: 'index.insiraValor' | translate }"
              [editorOptions]="{ mode: 'text', showSpinButtons: true, max: 9999, min: 0.0000, value: null }">
              <dxi-validation-rule
                  type="required">
              </dxi-validation-rule>
          </dxi-item>

          <dxi-item
            itemType="button"
            horizontalAlignment="center"
            [buttonOptions]="{ useSubmitBehavior: true, text: 'index.aplicar' | translate }">
          </dxi-item>
        </dx-form>
      </form>
    </div>
  </div>
</dx-popup>

<app-loader *ngIf="loading"></app-loader>

<div *ngIf="havePermission" class="m-3">
    <!-- [columnHidingEnabled]="true" #29629-->
    <dx-data-grid
      id="data-grid-products"
      [dataSource]="dataSourceProdutos"
      [allowColumnReordering]="true"
      [columnAutoWidth]="true"
      [showBorders]="true"
      [remoteOperations]="true"
      [allowColumnResizing]="true"
      (onRowUpdating)="onRowUpdating($event)"
      (onToolbarPreparing)="onToolbarPreparing($event)"
      (onEditorPreparing)="onEditorPreparing($event)"
      (onCellPrepared)="onCellPrepared($event)"
      (onInitNewRow)="onInitNewRow($event)"
      (onContentReady)="contentReady()">
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-header-filter [visible]="true"></dxo-header-filter>
      <dxo-scrolling mode="virtual"></dxo-scrolling>

      <!-- #29629 -->
      <div *dxTemplate="let item of 'isGeralTemplate'">
        <span *ngIf="item === true">
          <i class="dx-icon-globe"></i>
        </span>
        <span *ngIf="item === false">
          <i class="dx-icon-key"></i>
        </span>
        <span *ngIf="item === null">
          <i class="dx-icon-product"></i>
        </span>
      </div>

      <!-- mode="form" #29629 -->
      <dxo-editing
        mode="batch"
        refreshMode="reshape"
        [allowAdding]="!userProfileIsOnlyFactory"
        [allowUpdating]="!userProfileIsOnlyFactory"
        [allowDeleting]="allowDeleting"
      >
      </dxo-editing>

      <dxo-grouping [autoExpandAll]="false"></dxo-grouping>

      <dxo-export
        [enabled]="true"
        [fileName]="'index.produtos' | translate"
        [allowExportSelectedData]="false"
      ></dxo-export>

      <!-- cssClass="icon_geral" #29629-->
      <dxi-column
        [width]="45"
        dataField="is_geral"
        [allowEditing]="false"
        cellTemplate="cellTemplate"
        editCellTemplate="cellTemplate"
        headerCellTemplate="titleHeaderTemplate"
        [caption]="'index.tipoc' | translate"
      >
        <dxo-header-filter [dataSource]="dataSourceIsGeralHeaderFilter"></dxo-header-filter>
      </dxi-column>

      <div id="header_filter_geral" *dxTemplate="let info of 'titleHeaderTemplate'">
        <!-- <span>{{info.column.caption}}</span> -->
      </div>

      <div *dxTemplate="let data of 'cellTemplate'" class="custom-tooltip">
        <ng-template [ngIf]="data.value == true" [ngIfElse]="notShow">
          <i class="dx-icon-globe"></i>
          <span class="tooltiptext">{{ "index.global" | translate }}</span>
        </ng-template>
        <ng-template #notShow>
          <i class="dx-icon-key"></i>
          <span class="tooltiptext">{{ "index.privado" | translate }}</span>
        </ng-template>
      </div>

      <dxi-column
        dataField="ds_codigo"
        [caption]="'index.codigo' | translate"
        [editorOptions]="{ maxLength: 40 }"
      >
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-column>

      <dxi-column
        dataField="ds_descricao"
        [caption]="'index.descricao' | translate"
        [editorOptions]="{ maxLength: 100 }"
      >
      </dxi-column>

      <dxi-column
        dataField="cd_grupo"
        [caption]="'index.grupo' | translate"
        editCellTemplate="editCellTemplateGrupo">
        <dxo-lookup
          [dataSource]="grupos"
          valueExpr="cd_grupo"
          displayExpr="ds_grupo">
        </dxo-lookup>
      </dxi-column>

      <div *dxTemplate="let data of 'editCellTemplateGrupo'">
        <dx-select-box
            [dataSource]="grupos"
            displayExpr="ds_grupo"
            valueExpr="cd_grupo"
            [value]="data.value"
            [searchEnabled]="true"
            [disabled]="data.data.is_geral"
            (onValueChanged)="data.setValue($event.value)"
            [dropDownOptions]="{ minWidth: minWidthSelectBox }">
        </dx-select-box>
      </div>

      <dxi-column
        dataField="cd_subgrupo"
        [caption]="'index.subGrupo' | translate"
        editCellTemplate="editCellTemplateSubgrupo">
        <dxo-lookup
          [dataSource]="subgrupos"
          valueExpr="cd_codigo"
          displayExpr="ds_subgrupo">
        </dxo-lookup>
      </dxi-column>

      <div *dxTemplate="let data of 'editCellTemplateSubgrupo'">
        <dx-select-box
            [dataSource]="subgrupos"
            displayExpr="ds_subgrupo"
            valueExpr="cd_codigo"
            [value]="data.value"
            [searchEnabled]="true"
            [disabled]="data.data.is_geral"
            (onValueChanged)="data.setValue($event.value)"
            [dropDownOptions]="{ minWidth: minWidthSelectBox }">
        </dx-select-box>
      </div>

      <dxi-column
        dataField="cd_tipo"
        [caption]="'index.tipoc' | translate"
        editCellTemplate="editCellTemplateTipo">
        <dxo-lookup
          [dataSource]="tipos"
          valueExpr="cd_codigo"
          displayExpr="ds_produto">
        </dxo-lookup>
      </dxi-column>

      <div *dxTemplate="let data of 'editCellTemplateTipo'">
        <dx-select-box
            [dataSource]="tipos"
            displayExpr="ds_produto"
            valueExpr="cd_codigo"
            [value]="data.value"
            [searchEnabled]="true"
            [disabled]="data.data.is_geral"
            (onValueChanged)="data.setValue($event.value)"
            [dropDownOptions]="{ minWidth: minWidthSelectBox }">
        </dx-select-box>
      </div>

      <dxi-column
        dataField="cd_grupo_cad"
        [caption]="'index.grupoc' | translate"
        editCellTemplate="editCellTemplateGrupoCad">
        <dxi-validation-rule type="required"></dxi-validation-rule>
        <dxo-lookup
          [dataSource]="gruposS"
          valueExpr="cd_codigo"
          displayExpr="ds_nome">
        </dxo-lookup>
      </dxi-column>

      <div *dxTemplate="let data of 'editCellTemplateGrupoCad'">
        <dx-select-box
            [dataSource]="gruposS"
            displayExpr="ds_nome"
            valueExpr="cd_codigo"
            [value]="data.value"
            [searchEnabled]="true"
            [disabled]="data.data.is_geral"
            (onValueChanged)="data.setValue($event.value)"
            [dropDownOptions]="{ minWidth: minWidthSelectBox }">
        </dx-select-box>
      </div>

      <dxi-column
        dataField="cd_unidade"
        [caption]="'index.unidade' | translate"
        editCellTemplate="editCellTemplatUnidade">
        <dxi-validation-rule type="required"></dxi-validation-rule>
        <dxo-lookup
          [dataSource]="unimeds"
          valueExpr="cd_unimed"
          displayExpr="ds_unimed">
        </dxo-lookup>
      </dxi-column>

      <div *dxTemplate="let data of 'editCellTemplatUnidade'">
        <dx-select-box
            [dataSource]="unimeds"
            displayExpr="ds_unimed"
            valueExpr="cd_unimed"
            [value]="data.value"
            [searchEnabled]="true"
            [disabled]="data.data.is_geral"
            (onValueChanged)="data.setValue($event.value)"
            [dropDownOptions]="{ minWidth: minWidthSelectBox }">
        </dx-select-box>
      </div>

      <dxi-column
        dataField="vl_custo"
        [caption]="'index.custo' | translate"
        dataType="number"
        [editorOptions]="{ format: '####################.##', min: 0 }"
      >
        <dxi-validation-rule type="required"></dxi-validation-rule>
        <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
      </dxi-column>

      <dxi-column
        dataField="vl_venda"
        dataType="number"
        [caption]="'index.venda' | translate"
        [editorOptions]="{ format: '####################.##', min: 0 }"
      >
        <dxi-validation-rule type="required"></dxi-validation-rule>
        <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
      </dxi-column>

      <dxi-column
        dataField="vl_perc_venda"
        caption="Markup"
        dataType="number"
        [editorOptions]="{ disabled: true}"
      >
    </dxi-column>

      <dxi-column
        dataField="vl_estoque"
        [caption]="'index.estoque' | translate"
        [editorOptions]="{ format: '###0', min: 0 }"
        dataType="number"
      >
      </dxi-column>

      <dxi-column
        dataField="vl_estoque_empenhado"
        [caption]="'index.empenhado' | translate"
        dataType="number"
        [editorOptions]="{ disabled: true}"
      >
      </dxi-column>

      <dxi-column
        dataField="vl_estoque_previsto"
        [caption]="'index.previsto' | translate"
        dataType="number"
        [editorOptions]="{ disabled: true}"
      >
      </dxi-column>

      <dxi-column
    [width]="50"
    cellTemplate="historicoTemplate"
    [allowEditing]="false"
></dxi-column>

<div *dxTemplate="let data of 'historicoTemplate'">
    <button class="btn btn-sm btn-outline-secondary" (click)="abrirPopupHistorico(data.data)">
        <i class="dx-icon-clock"></i>
    </button>
</div>



      <dxo-summary>
        <dxi-total-item
          column="vl_estoque_previsto"
          summaryType="count"
          [customizeText]="countColumnCustomizeText"
        >
        </dxi-total-item>
      </dxo-summary>

      <div *dxTemplate="let data of 'dx-title'">
        <div class="text-center">
          <p class="title mb-0">{{ "index.produtos" | translate }}</p>
        </div>
      </div>
    </dx-data-grid>
</div>

<denied-access *ngIf="!loading && !havePermission" [formCode]="formCode"></denied-access>
