import { Component, OnInit, ViewChild } from '@angular/core';
import { FuncionariosService } from './funcionarios.service';
import { RequestCallbackService } from 'src/app/shared/helpers/request-callback.service';
import { BaseLazyComponent } from 'src/app/shared/helpers/BaseLazyComponent';
import { LoadOptions } from 'devextreme/data/load_options';
import { DxDataGridComponent } from 'devextreme-angular';
import { LazyDataSource } from 'src/app/shared/models/LazyDataSource';
import { LazySelectService } from 'src/app/shared/helpers/lazy-select.service';
import { LanguagueService } from 'src/app/shared/helpers/language.service';

@Component({
  selector: 'app-funcionarios',
  templateUrl: './funcionarios.component.html',
  styleUrls: ['./funcionarios.component.scss']
})
export class FuncionariosComponent extends BaseLazyComponent implements OnInit {

  dataSourceUsers: LazyDataSource;
  havePermission: boolean = true;
  formCode: number;

  @ViewChild(DxDataGridComponent, { static: false })
  dataGridEmployees: DxDataGridComponent;

  constructor(
    private funcionariosService: FuncionariosService,
    private requestCallbackService: RequestCallbackService,
    private lazyService: LazySelectService,
    private language: LanguagueService) {
    super("id");
    this.language.globalize();
  }

  ngOnInit() {
    this.setupDataSourceUsers();
  }

  load(loadOptions: LoadOptions): Promise<any> {
    const formData = this.createFormData([{ key: "loadOptions", value: loadOptions }]);
    return this.requestCallbackService.load(
      this.funcionariosService.getEmployees(formData),
      (response) => {
        if (this.requestCallbackService.isResponseWithoutPermission(response)) {
          this.formCode = response.data.form;
          this.havePermission = false;
        }
        return response.data.employees;
      },
      "falha_buscar_funcionarios",
    );
  }

  insert(values: any): Promise<void> {
    const formData = this.createFormData([{ key: "values", value: values }]);
    return this.requestCallbackService.insert(this.funcionariosService.insertEmployee(formData), this.dataGridEmployees);
  }

  update(key: any, values: any): Promise<void> {
    const formData = this.createFormData([{ key: "key", value: key }, { key: "values", value: values }]);
    return this.requestCallbackService.update(this.funcionariosService.updateEmployee(formData), this.dataGridEmployees);
  }

  delete(key: any): Promise<void> {
    const formData = this.createFormData([{ key: "key", value: key }]);
    return this.requestCallbackService.delete(this.funcionariosService.deleteEmployee(formData), this.dataGridEmployees);
  }

  onRowUpdating(options) {
    options.newData = Object.assign(options.oldData, options.newData);
  }

  private setupDataSourceUsers() {
    this.dataSourceUsers = this.lazyService.setupLazyDataSource(
      "cd_codigo",
      "ds_nome",
      (loadOptions) => {
        const formData = new FormData();
        formData.append("loadOptions", JSON.stringify(loadOptions));
        return this.loadUsers(formData);
      },
      (formData) => {
        return this.loadUserByKey(formData);
      }
    );
  }

  private loadUsers(formData: FormData): Promise<any> {
    return this.requestCallbackService.load(
      this.funcionariosService.getUserForEmployees(formData),
      (response) => {
        return response.data.users;
      },
      "falha_buscar_usuarios"
    );
  }

  private loadUserByKey(formData: FormData): Promise<any> {
    return this.requestCallbackService.load(
      this.funcionariosService.getUserForEmployees(formData),
      (response) => {
        return response.data.users.data[0];
      },
      "falha_buscar_usuarios",
      true
    );
  }

  onToolbarPreparing(e) {

    let items: any[] = e.toolbarOptions.items;

    // busca a barra de pesquisa
    const searchItem = items[items.length - 1];
    // permite collapse menu
    searchItem.locateInMenu = "auto";

    items.unshift({
      location: "center",
      template: "dx-title"
    });
  }

}
