import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { baseUrl } from "src/app/config";

@Injectable({
    providedIn: "root"
})
export class ProdutosVidraceirosService {
    private url = baseUrl;
    constructor(private http: HttpClient) { }

    getProdutosVidraceiros(form: FormData): Observable<any> {
        return this.http.post<any>(`${this.url}/ProdutosVidraceiros`, form);
    }
}