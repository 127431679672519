import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { baseUrl } from "src/app/config";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: "root"
})
export class BibliotecaService {

  constructor(private http: HttpClient) { }

  private url = baseUrl;

  getGruposBiblioteca(obj: string): Observable<any> {
    return this.http.get(`${this.url}/getGruposBibliotecas?obj=${obj}&&size=50`);
  }

  insertGrupos(form: FormData): Observable<any> {
    return this.http.post(`${this.url}/Insert_top_grupos`, form);
  }

  updateGrupos(form: FormData): Observable<any> {
    return this.http.post(`${this.url}/Update_top_grupos`, form);
  }

  deleteGrupos(form: FormData): Observable<any> {
    return this.http.post(`${this.url}/Delete_top_grupos`, form);
  }

  excluiConjuntoBib(form: FormData): Observable<any> {
    return this.http.post<any>(`${this.url}/excluiConjuntoBib`, form);
  }
}
