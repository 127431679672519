import { BrowserModule } from "@angular/platform-browser";
import { NgModule, LOCALE_ID, ErrorHandler } from "@angular/core";
import localePt from "@angular/common/locales/pt";
import { registerLocaleData } from "@angular/common";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ContasReceberComponent } from "./containers/contas-receber/contas-receber.component";
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient
} from "@angular/common/http";
import { JwtInterceptor } from "./shared/helpers/jwt.interceptor";
import { ContasPagarComponent } from "./containers/contas-pagar/contas-pagar.component";
import { LoginComponent } from "./containers/login/login.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { ToastrModule } from "ngx-toastr";
import { CommonModule } from "@angular/common";
import { CompRouterComponent } from "./containers/comp-router/comp-router.component";
import { ErrorComponent } from "./containers/error/error.component";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { PedidosComponent } from "./containers/pedidos/pedidos.component";
import { VendasDiaComponent } from "./containers/vendas-dia/vendas-dia.component";
import { AgendaComponent } from "./containers/agenda/agenda.component";
import { BibliotecaComponent } from "./containers/biblioteca/biblioteca.component";
import {
  DxSchedulerModule,
  DxTemplateModule,
  DxSelectBoxModule,
  DxTextBoxModule,
  DxTreeViewModule,
  DxDropDownBoxModule,
  DxDataGridModule,
  DxDrawerModule,
  DxToolbarModule,
  DxFormModule,
  DxTileViewModule,
  DxTabsModule,
  DxCheckBoxModule,
  DxTabPanelModule,
  DxScrollViewModule,
  DxMenuModule,
  DxTagBoxModule,
  DxTooltipModule,
  DxContextMenuModule
} from "devextreme-angular";
import { EquipesComponent } from "./containers/equipes/equipes.component";
import { FinanceiroComponent } from "./containers/financeiro/financeiro.component";
import { PermissoesComponent } from "./containers/permissoes/permissoes.component";
import { UsuariosComponent } from "./containers/usuarios/usuarios.component";
import { AcompanhamentoComponent } from "./containers/acompanhamento/acompanhamento.component";
import { ProdutosComponent } from "./containers/produtos/produtos.component";
import { HomeComponent } from "./containers/home/home.component";
import { DxPieChartModule } from "devextreme-angular";
import { DxListModule } from "devextreme-angular";
import { ClientesComponent } from "./containers/clientes/clientes.component";
import { VendedoresComponent } from "./containers/vendedores/vendedores.component";
import { CondicoesComponent } from "./containers/condicoes/condicoes.component";
import { HeaderNewComponent } from "./components/header-new/header-new.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import {
  DxPopupModule,
  DxButtonModule,
  DxNumberBoxModule,
  DxValidatorModule,
  DxColorBoxModule,
  DxChartModule,
  DxFileUploaderModule,
  DxDateBoxModule,
  DxSwitchModule
} from "devextreme-angular";
import { PainelComponent } from "./containers/painel/painel.component";
import { ConfiguracoesComponent } from "./containers/configuracoes/configuracoes.component";
import { PerfilComponent } from "./containers/perfil/perfil.component";
import { PedidoItemComponent } from "./containers/pedido-item/pedido-item.component";
import { UploadgcadComponent } from "./containers/uploadgcad/uploadgcad.component";
import { RelpedidosComponent } from "./containers/relpedidos/relpedidos.component";
import { EditorComponent } from './containers/editor/editor.component';
import { DeniedAccessComponent } from './components/denied-access/denied-access.component';
import { CustomToast } from './components/custom-toast/custom.toast';
import { ConfigComponent } from './containers/config/config.component';
import { FuncionariosComponent } from './containers/funcionarios/funcionarios.component';
import { TelefoneMascaraComponent } from './components/telefone-mascara/telefone-mascara.component';
import { SentryErrorHandler } from './shared/services/SentryErrorHandler';
import { PerfisAcessoriosComponent } from './containers/perfis-acessorios/perfis-acessorios.component';
import { ResumoTecnicoComponent } from './containers/resumo-tecnico/resumo-tecnico.component';
import { PedidoFormComponent } from './containers/pedido-form/pedido-form.component';
import { ClientesRedirectComponent } from './containers/clientes-redirect/clientes-redirect.component';
import { ClientesVidraceirosComponent } from './containers/clientes-vidraceiros/clientes-vidraceiros.component';
import { PedidoOtimizacaoComponent } from './containers/pedido-otimizacao/pedido-otimizacao.component';
import { PedidoEmailComponent } from './containers/pedido-email/pedido-email.component';
import { NotfoundComponent } from './containers/notfound/notfound.component';
import { PedidoPreviewComponent } from './containers/pedido-preview/pedido-preview.component';
import { FuncoesComponent } from './containers/funcoes/funcoes.component';
import { FornecedoresGrupoCadComponent } from './containers/fornecedores-grupo-cad/fornecedores-grupo-cad.component';
import { UnimedComponent } from './containers/unimed/unimed.component';
import { GruposComponent } from './containers/grupos/grupos.component';
import { SubgruposComponent } from './containers/subgrupos/subgrupos.component';
import { TiposComponent } from './containers/tipos/tipos.component';
import { PortadoresComponent } from "./containers/portadores/portadores.component";
import { PaisesComponent } from './containers/paises/paises.component';
import { EstadosComponent } from './containers/estados/estados.component';
import { CidadesComponent } from './containers/cidades/cidades.component';
import { ProdutosVidraceirosComponent } from './containers/produtos-vidraceiros/produtos-vidraceiros.component';
import { FerragensComponent } from './containers/ferragens/ferragens.component';
import { RecuperarSenhaComponent } from './containers/recuperar-senha/recuperar-senha.component';
import { RedefinirSenhaComponent } from './containers/redefinir-senha/redefinir-senha.component';

registerLocaleData(localePt);
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    ContasReceberComponent,
    ContasPagarComponent,
    LoginComponent,
    LoaderComponent,
    CompRouterComponent,
    ErrorComponent,
    PedidosComponent,
    BibliotecaComponent,
    VendasDiaComponent,
    AgendaComponent,
    BibliotecaComponent,
    EquipesComponent,
    FinanceiroComponent,
    PermissoesComponent,
    UsuariosComponent,
    AcompanhamentoComponent,
    ProdutosComponent,
    ClientesComponent,
    VendedoresComponent,
    CondicoesComponent,
    HomeComponent,
    PainelComponent,
    HeaderNewComponent,
    ConfiguracoesComponent,
    PerfilComponent,
    PedidoItemComponent,
    UploadgcadComponent,
    RelpedidosComponent,
    EditorComponent,
    DeniedAccessComponent,
    CustomToast,
    ConfigComponent,
    FuncionariosComponent,
    TelefoneMascaraComponent,
    PerfisAcessoriosComponent,
    ResumoTecnicoComponent,
    PedidoFormComponent,
    ClientesRedirectComponent,
    ClientesVidraceirosComponent,
    PedidoOtimizacaoComponent,
    PedidoEmailComponent,
    NotfoundComponent,
    PedidoPreviewComponent,
    FuncoesComponent,
    FornecedoresGrupoCadComponent,
    UnimedComponent,
    GruposComponent,
    SubgruposComponent,
    TiposComponent,
    PortadoresComponent,
    PaisesComponent,
    EstadosComponent,
    CidadesComponent,
    ProdutosVidraceirosComponent,
    FerragensComponent,
    RecuperarSenhaComponent,
    RedefinirSenhaComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    DxSchedulerModule,
    DxSelectBoxModule,
    DxTextBoxModule,
    DxTreeViewModule,
    DxDropDownBoxModule,
    DxDataGridModule,
    DxPieChartModule,
    DxListModule,
    FontAwesomeModule,
    DxPopupModule,
    DxButtonModule,
    DxTemplateModule,
    DxNumberBoxModule,
    DxValidatorModule,
    DxColorBoxModule,
    DxChartModule,
    DxDrawerModule,
    DxToolbarModule,
    DxFormModule,
    DxFileUploaderModule,
    DxTileViewModule,
    DxCheckBoxModule,
    DxTabsModule,
    DxTabPanelModule,
    DxScrollViewModule,
    DxMenuModule,
    DxTagBoxModule,
    DxDateBoxModule,
    DxTooltipModule,
    DxContextMenuModule,
    DxSwitchModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: "pt-BR" },
    { provide: ErrorHandler, useClass: SentryErrorHandler }
  ],
  entryComponents: [CustomToast],
  bootstrap: [AppComponent]
})
export class AppModule { }

//         RouterModule
