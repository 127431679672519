import { Component, OnInit } from "@angular/core";
import { ViewChild } from "@angular/core";
import { DxDrawerComponent } from "devextreme-angular";
import { Router } from "@angular/router";
import { MenuItem, HeaderNewService } from "./header_new.service";
import { FinanceiroService } from "src/app/shared/services/financeiro.service";
import { isEnvironmentDebugOrTest, sleep } from 'src/app/shared/helpers/util';
import { RequestCallbackService } from 'src/app/shared/helpers/request-callback.service';
import { environment } from 'src/environments/environment';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { PT_BR_LANGUAGE, EN_US_LANGUAGE, ES_PY_LANGUAGE } from 'src/app/shared/helpers/constants';
import * as $ from "jquery";
import { LanguagueService } from 'src/app/shared/helpers/language.service';
import { ToastService } from 'src/app/shared/helpers/toast.service';

@Component({
  selector: "app-header-new",
  templateUrl: "./header-new.component.html",
  styleUrls: ["./header-new.component.scss"]
})
export class HeaderNewComponent implements OnInit {
  @ViewChild(DxDrawerComponent, { static: false }) drawer: DxDrawerComponent;

  menu_itens: MenuItem[];
  logoUrl: string;
  urlLogoReitech: string;
  drawerIsOpened: boolean = true;
  loading: boolean = false;
  toolbarContent: any = [];

  constructor(
    private router: Router,
    private service: FinanceiroService,
    private language: LanguagueService,
    private headerService: HeaderNewService,
    private toast: ToastService,
    private requestCallbacService: RequestCallbackService
  ) {
    this.setupItemLoadOnMenu();
    this.setupToolbarContent();
  }

  ngOnInit() {
    this.getUserData();
    // tarefa #28143
    this.getFabricaLogo();

    this.urlLogoReitech = this.headerService.getUrlWs() + "/logo/glasscad_bw.png";
  }

  setupItemLoadOnMenu() {
    this.menu_itens = [
      {
        ID: "1",
        name: "Loading",
        icon: "fas fa-refresh"
      }
    ];
  }

  getUserData() {
    this.headerService.getUserData().subscribe((res: ApiResponse<{ success: boolean, message: string }>) => {
      this.hideLoad();
      this.requestCallbacService.okResponse(res,
        () => {
          this.menu_itens = this.headerService.getMenuItens(res.data);
        }
      );
    }, error => {
      this.hideLoad();
      this.requestCallbacService.failResponse(error, "falha_buscar_info_usuario");
    });
  }

  showLoad() {
    this.loading = true;
  }

  hideLoad() {
    this.loading = false;
  }

  //region tarefa #29842
  setupToolbarContent() {
    this.toolbarContent.push(this.buildBurgerButton());
    this.addEnvironmentInfo();
  }

  private toogleBurgerButton() {
    if (isEnvironmentDebugOrTest()) {
      this.toolbarContent = [];
      this.toolbarContent.push(this.buildBurgerButton());
    }
  }

  private getCssClassToBurgerButton(): string {
    if (isEnvironmentDebugOrTest() && !this.drawerIsOpened) {
      return "bg-debug-test"
    }
    return "bg-prd";
  }

  private buildBurgerButton() {
    return {
      widget: "dxButton",
      location: "before",
      cssClass: this.getCssClassToBurgerButton(),
      options: {
        icon: "menu",
        onClick: () => {
          this.drawer.instance.toggle();

          if (!this.drawerIsOpened) {
            sleep(200).then(() => {
              this.toogleDrawer();
              this.toogleBurgerButton();
              this.addEnvironmentInfo();
            });
            // definido width conforme especificado no css.
            let screenWidth = screen.width;
            if (screenWidth >= 1400) {
              $('#content').css('width', "80%");
            } else {
              $('#content').css('width', "75%");
            }
          } else {
            this.toogleDrawer();
            this.removeEnvironmentInfo();
            this.toogleBurgerButton();
            $('#content').css('width', "95%");
          }
        }
      }
    }
  }

  private addEnvironmentInfo() {
    if (isEnvironmentDebugOrTest()) {
      this.toolbarContent.push(this.environmentInfo());
    }
  }

  private removeEnvironmentInfo() {
    if (isEnvironmentDebugOrTest()) {
      this.toolbarContent.pop();
    }
  }

  private environmentInfo() {
    return {
      text: environment.environmentName,
      location: 'before',
      cssClass: 'environment-version'
    }
  }
  //end region

  private toogleDrawer() {
    this.drawerIsOpened = !this.drawerIsOpened;
  }

  selectItem(e) {
    // expande no clique
    e.component.expandItem(e.itemElement);

    // navega até o link
    if (e.itemData.link) {
      this.router.navigate([e.itemData.link]);
      //e.component.collapseItem(e.itemData.categoryId);
      e.component.collapseAll();
      return;
    }

    // efetua logoff
    if (e.itemData.logoff) {
      this.logOut();
      return;
    }

    // idiomas
    if (e.itemData.idioma) {
      switch (e.itemData.idioma) {
        case 1: {
          this.handleChangeIdioma(PT_BR_LANGUAGE);
          break;
        }
        case 2: {
          this.handleChangeIdioma(EN_US_LANGUAGE);
          break;
        }
        case 3: {
          this.handleChangeIdioma(ES_PY_LANGUAGE);
          break;
        }
      }
    }
  }

  // region tarefa #29347
  private handleChangeIdioma(language: string) {
    const formData = this.setupConfigRequest(language);
    this.setConfig(formData, language);
  }

  private setupConfigRequest(language: string): FormData {
    let config = {
      campo: "idioma",
      descricao: language
    }
    let configJson = JSON.stringify(config);

    const formData = new FormData();
    formData.append("config", configJson);
    return formData;
  }

  private setConfig(formData, language) {
    this.showLoad();
    this.headerService.setConfig(formData).subscribe((res: any) => {
      this.hideLoad();

      this.requestCallbacService.okResponse(res,
        () => {
          this.toast.successToast("edit");
          this.language.setLanguage(language);
        });
    }, error => {
      this.hideLoad();
      this.requestCallbacService.failResponse(error, "falha_set_config_idioma");
    });
  }
  // end region

  // region tarefa #28143
  getFabricaLogo() {
    this.headerService.getFabricaLogo().subscribe((res: ApiResponse<{ success: boolean, message: string }>) => {

      this.requestCallbacService.okResponse(res,
        () => {
          let dtoLogo = res.data.dtoLogo;
          if (dtoLogo.ds_logo == null) {
            this.logoUrl = "./assets/default_logo.png" + '?' + new Date().getTime();
          } else {
            this.logoUrl = dtoLogo.ds_path.concat(dtoLogo.ds_logo) + '?' + new Date().getTime();
          }
        }
      );
    }, error => {
      this.hideLoad();
      this.requestCallbacService.failResponse(error, "falha_buscar_logo_empresa");
    });
  }
  // end region tarefa #28143

  private logOut() {
    this.showLoad();
    this.service.logout().subscribe((res: ApiResponse<{ success: boolean, message: string }>) => {
      this.hideLoad();
      this.requestCallbacService.okResponse(res,
        () => {
          this.router.navigate([""]);
        }
      );
    }, error => {
      this.requestCallbacService.failResponse(error);
      this.hideLoad();
    });
  }


}
