<app-loader *ngIf="loading"></app-loader>

<div *ngIf="havePermission" class="m-3">
  <dx-data-grid
    id="data-grid-clients"
    [dataSource]="dataSource"
    showBorders="true"
    [remoteOperations]="true"
    [columnHidingEnabled]="true"
    [allowColumnReordering]="true"
    [allowColumnResizing]="true"
    [elementAttr]="{ autocomplete: 'off' }"
    (onRowUpdating)="onRowUpdating($event)"
    (onEditorPreparing)="onEditorPreparing($event)"
    (onToolbarPreparing)="onToolbarPreparing($event)"
  >
    <dxo-export
      [enabled]="true"
      [allowExportSelectedData]="false"
      [fileName]="'index.clientesFornecedores' | translate"
    >
    </dxo-export>

    <dxo-search-panel
      [visible]="true"
      [width]="240"
      placeholder="Procurar..."
    ></dxo-search-panel>
    
    <dxo-editing
      mode="row"
      [allowAdding]="true"
      [allowUpdating]="true"
      [allowDeleting]="true"
    >
    </dxo-editing>

    <dxo-scrolling mode="virtual"></dxo-scrolling>

    <dxi-column
      dataField="ds_nome"
      [caption]="'index.nome' | translate"
      [editorOptions]="{ maxLength: 60 }"
    >
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column
      dataField="ds_email"
      [caption]="'index.email' | translate"
      [editorOptions]="{ maxLength: 100 }"
    >
      <dxi-validation-rule type="email"></dxi-validation-rule>
    </dxi-column>

    <dxi-column
      dataField="ds_fantasia"
      [caption]="'index.nomeFantasia' | translate"
      dataType="text"
      [editorOptions]="{ maxLength: 60 }"
    >
    </dxi-column>

    <dxi-column
      dataField="cd_pais"
      [caption]="'index.pais' | translate"
      calculateDisplayValue="ds_pais"
      editCellTemplate="editCellTemplateCountry">
      <dxo-lookup
        [dataSource]="dataSourceCountries"
        valueExpr="cd_codigo"
        displayExpr="ds_nome">
      </dxo-lookup>
    </dxi-column>

    <div *dxTemplate="let data of 'editCellTemplateCountry'">
      <dx-select-box
          [dataSource]="dataSourceCountries"
          displayExpr="ds_nome"
          valueExpr="cd_codigo" 
          [value]="data.value"
          [searchEnabled]="true"
          (onValueChanged)="data.setValue($event.value)"
          [dropDownOptions]="{ minWidth: minWidthSelectBox }">
      </dx-select-box>
    </div>

    <dxi-column 
      dataField="cd_vendedor2"
      [caption]="'index.vendedor' | translate"
      calculateDisplayValue="ds_nome_vendedor"
      editCellTemplate="editCellTemplateSellers">
      <dxo-lookup
        [dataSource]="dataSourceSellers"
        valueExpr="cd_codigo"
        displayExpr="ds_nome">
      </dxo-lookup>
    </dxi-column>

    <div *dxTemplate="let data of 'editCellTemplateSellers'">
      <dx-select-box
          [dataSource]="dataSourceSellers"
          displayExpr="ds_nome"
          valueExpr="cd_codigo" 
          [value]="data.value"
          [searchEnabled]="true"
          (onValueChanged)="data.setValue($event.value)"
          [dropDownOptions]="{ minWidth: minWidthSelectBox }">
      </dx-select-box>
    </div>

    <dxi-column
      dataField="ds_tipo"
      [caption]="'index.tipo' | translate"
      dataType="text"
      editCellTemplate="editCellTemplateKindPerson">
      <dxo-lookup
        [dataSource]="tipoPessoa"
        valueExpr="id"
        displayExpr="ds_tipo">
      </dxo-lookup>
    </dxi-column>

    <div *dxTemplate="let data of 'editCellTemplateKindPerson'">
      <dx-select-box
          [dataSource]="tipoPessoa"
          displayExpr="ds_tipo"
          valueExpr="id" 
          [value]="data.value"
          [searchEnabled]="true"
          (onValueChanged)="data.setValue($event.value)"
          [dropDownOptions]="{ minWidth: minWidthSelectBox }">
      </dx-select-box>
    </div>

    <dxi-column
      dataField="ds_cpf"
      [caption]="'index.cpfCnpj' | translate"
      dataType="text"
      [editorOptions]="{ maxLength: 14 }"
    >
    </dxi-column>

    <dxi-column
      dataField="ds_endereco1"
      [caption]="'index.endereco' | translate"
      dataType="text"
      [editorOptions]="{ maxLength: 50 }"
    >
    </dxi-column>

    <dxi-column
      dataField="ds_numero1"
      [caption]="'index.numero' | translate"
      dataType="text"
      [editorOptions]="{ maxLength: 10 }">
    </dxi-column>

    <dxi-column
      dataField="ds_bairro1"
      [caption]="'index.bairro' | translate"
      dataType="text"
      [editorOptions]="{ maxLength: 30 }"
    >
    </dxi-column>

    <dxi-column
      dataField="cd_cidade"
      [caption]="'index.cidade' | translate"
      calculateDisplayValue="ds_cidade1"
      dataType="text"
      editCellTemplate="editCellTemplateCity">
      <dxo-lookup
        [dataSource]="dataSourceCities"
        valueExpr="cd_cidade"
        displayExpr="ds_cidade">
      </dxo-lookup>
    </dxi-column>

    <div *dxTemplate="let data of 'editCellTemplateCity'">
      <dx-select-box
          [dataSource]="dataSourceCities"
          displayExpr="ds_cidade"
          valueExpr="cd_cidade" 
          [value]="data.value"
          [searchEnabled]="true"
          (onValueChanged)="data.setValue($event.value)"
          [dropDownOptions]="{ minWidth: minWidthSelectBox }">
      </dx-select-box>
    </div>

    <dxi-column
      dataField="ds_cep1"
      [caption]="'index.cep' | translate"
      dataType="text"
      [editorOptions]="{ maxLength: 10 }"
    >
    </dxi-column>

    <dxi-column
      dataField="ds_fone1"
      [caption]="'index.telefone' | translate"
      dataType="phone"
      editCellTemplate="editCellTemplate"
    >
      <div *dxTemplate="let data of 'editCellTemplate'">
        <telefone-mascara [data]="data" maxLength="30"></telefone-mascara>
      </div>
      <dxi-validation-rule
        type="custom"
        [validationCallback]="regex"
        message=""
      >
      </dxi-validation-rule>
    </dxi-column>

    <dxi-column
      dataField="ds_contato1"
      [caption]="'index.contato' | translate"
      dataType="text"
      [editorOptions]="{ maxLength: 20 }"
    >
    </dxi-column>

    <dxi-column
      dataField="tipo_entidade"
      [allowFiltering]="false"
      [caption]="'index.entidade' | translate"
    >
      <dxo-lookup [dataSource]="tipo" valueExpr="ID" displayExpr="Name">
      </dxo-lookup>

      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column
      dataField="cd_ramo"
      [caption]="'index.ramo' | translate"
      [editorOptions]="{ showClearButton: true }"
    >
      <dxo-lookup 
        [dataSource]="ramos" 
        valueExpr="ID" 
        displayExpr="Name">
      </dxo-lookup>
    </dxi-column>

    <div *dxTemplate="let data of 'dx-title'">
      <div class="text-center">
        <p class="title mb-0">{{ "index.clientesFornecedores" | translate }}</p>
      </div>
    </div>
  </dx-data-grid>
</div>
<denied-access *ngIf="!havePermission" [formCode]="formCode"></denied-access>
