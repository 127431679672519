<app-loader *ngIf="loading"></app-loader>

<dx-popup
  width="300"
  [height]="'auto'"
  [showTitle]="true"
  [title]="titlePopUpSaveGroup"
  [dragEnabled]="false"
  [closeOnOutsideClick]="true"
  [(visible)]="visibilityPopupSaveGroup"
>
  <div *dxTemplate="let data of 'content'">
    <div class="option">
      <form (submit)="handleSaveGrupo($event)">
        <dx-form 
            [formData]="itemPopupGroup">
            <dxi-item 
              dataField="ds_grupo"
              editorType="dxTextBox"
              [label]="{ text: 'index.informe_nome_grupo' | translate }"
              [editorOptions]="{ maxLength: 25 }">
              <dxi-validation-rule 
                  type="required">
              </dxi-validation-rule>
          </dxi-item>

          <dxi-item 
            itemType="button"
            horizontalAlignment="center"
            [buttonOptions]="{ useSubmitBehavior: true, text: 'index.salvar' | translate }">
          </dxi-item>
        </dx-form>
      </form>
    </div>
  </div>
</dx-popup>

<dx-popup
  [width]="300"
  [height]="'auto'"
  [showTitle]="true"
  [title]="'index.deletar_grupo' | translate" 
  [dragEnabled]="false"
  [closeOnOutsideClick]="true"
  [(visible)]="visibilityPopupDeleteGroup"
>
  <div *dxTemplate="let data of 'content'">
    <div class="option">
      <span>{{ "index.msg_delete_group" | translate }} {{itemPopupGroup.ds_grupo}} ?</span> 
    </div>
    <br>
    <div class="row">
      <div class="col d-flex text-center">
        <dx-button
          class="button-info"
          [text]="'index.sim' | translate"
          (onClick)="deleteGrupos(itemPopupGroup)">
      </dx-button>
      </div>
      <div class="col d-flex text-center">
          <dx-button
            class="button-info"
            [text]="'index.cancelar' | translate"
            (onClick)="closePopupDeleteGroup()">
        </dx-button>
      </div>
    </div>
  </div>
</dx-popup>

<div class="panel">
  <div class="text-center">
      <span class="pull-left">
          <dx-button class="btn-back" icon="product" [text]="'index.pedidoA' | translate" (onClick)="redirectToPedido()" *ngIf="ped_orca != '0'"></dx-button>
      </span>
      <span class="title">{{'index.biblioteca' | translate }}</span>
      <br/><br/>
  </div>
  <div class="custom-container">
    <div class="row no-gutters">
          <div class="col-sm-12 col-md-6 col-lg-3 grupos">
            <div class="menusgrupos">

              <div class="border config">
                <span>{{'index.filtrar' | translate }}</span>
                <dx-select-box
                  id="useFilterApplyButton"
                  [items]="filters"
                  [(value)]="currentFilter"
                  valueExpr="key"
                  displayExpr="name"
                  (onValueChanged)="getData()">
                </dx-select-box>
              </div>

              <div class="border config">
                <span>{{'index.ordernar' | translate }}</span>
                <dx-select-box
                  id="useFilterApplyButton"
                  [items]="ordering"
                  [(value)]="currentOrder"
                  valueExpr="key"
                  displayExpr="name"
                  (onValueChanged)="getData()">
                </dx-select-box>
              </div>

              <div id="menuPublico" class="border config">
                <dx-tree-view class="treeview3" [items]="gruposFabrica" dataStructure="plain" parentIdExpr="cd_pai" keyExpr="id"
                  displayExpr="ds_grupo" width="100%">
                  <div *dxTemplate="let data of 'item'" class="item">
                    <span class="group-name" (click)="onGrupoClick(data)">
                      {{data.ds_grupo}}
                    </span>
                  </div>
                </dx-tree-view>
              </div>
            
              <div id="menuPublico2" class="border config">
                <dx-tree-view class="treeview3" [items]="gruposProprios" dataStructure="plain" parentIdExpr="cd_pai" keyExpr="id"
                  displayExpr="ds_grupo" width="100%">
                  <div *dxTemplate="let data of 'item'" class="item">
                    <span class="group-name" (click)="onGrupoClick(data)">
                      {{data.ds_grupo}}
                    </span>
                    <span class="action-buttons pull-right">
                      <i class="dx-icon dx-icon-plus" (click)="handleAddGroup(data)"></i>
                      <i class="dx-icon dx-icon-edit" (click)="handleEditGroup(data)" *ngIf="data.id != -2"></i>
                      <i class="dx-icon dx-icon-trash" (click)="handleDeleteGroup(data)"*ngIf="data.id != -2"></i>
                    </span>
                  </div>
                </dx-tree-view>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-9">
            <div class="config border biblioteca">
              <div class="dx-field">
                <div class="dx-field-value btn-search">
                  <dx-text-box 
                    width="152%" 
                    (onValueChanged)="pesquisar($event)" 
                    [placeholder]="'index.procurar' | translate"
                    [showClearButton]="true">
                  </dx-text-box>
                </div>
              </div>
              <dx-tile-view
                id="tileView"
                [dataSource]="dataSourceConjuntos" 
                [baseItemHeight]="180" 
                [baseItemWidth]="250"  
                height="75vh"
                [itemMargin]="10" 
                [direction]="'vertical'" 
                showScrollbar="true">
                <dxi-item *ngFor="let conjunto of dataSourceConjuntos.items()" class="item-conjunto">
                  <div (click)="onConjuntoClick(conjunto)">
                    <dx-button width="100%" height="100%"
                      [style.background-image]="'url(' + conjunto.Imagem + ')'"></dx-button>
                    <div class="bibNome" [id]="conjunto.Nome">{{ conjunto.Descricao }}</div>
                  </div>
                  <span id="action-buttons-conjunto">
                    <i class="dx-icon dx-icon-image pointer" (click)="openModalFullScreenConjunto(conjunto)"></i>
                    <i class="dx-icon dx-icon-trash pointer" (click)="openPopupDeleteConjunto(conjunto.Nome)" *ngIf="conjunto.Proprio"></i> 
                  </span>
                </dxi-item>
              </dx-tile-view>
            </div> 
          </div>
    </div>
  </div>

  <!-- The Modal -->
  <div id="myModal" class="modal">
    <!-- Modal Content -->
    <div class="modal-content">
      <!-- The Close Button -->
      <span id="close" class="close">&times;</span>
      <!-- (The Image)-->
      <img id="img01" class="img-detail">
    </div>
    <!-- Modal Caption (Image Text) -->
    <div id="caption"></div>
  </div>
</div>

<dx-popup
  width="300"
  [height]="'auto'"
  [showTitle]="true"
  [title]="'index.deletar_conjunto' | translate" 
  [dragEnabled]="false"
  [closeOnOutsideClick]="true"
  [(visible)]="visibilityPopupDeleteConjunto"
>
  <div *dxTemplate="let data of 'content'">
    <div class="option">
      <span>{{ "index.msg_delete_conjunto" | translate }}</span>
    </div>
    <br>
    <div class="row">
      <div class="col d-flex text-center">
        <dx-button class="button-info"
            [text]="'index.sim' | translate"
            (onClick)="deleteConjunto()">
        </dx-button>
      </div>
      <div class="col d-flex text-center">
          <dx-button
            class="button-info"
            [text]="'index.cancelar' | translate"
            (onClick)="closePopupDeleteConjunto()">
        </dx-button>
      </div>
    </div>
  </div>
</dx-popup>


