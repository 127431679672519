import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { baseUrl } from 'src/app/config';

@Injectable({
    providedIn: 'root'
})
export class PedidoOtimizacaoService {

    private url = baseUrl;

    constructor(private http: HttpClient) { }

    explodeItens(form: FormData): Observable<any> {
        return this.http.post<any>(`${this.url}/ExplodeItens`, form)
    }

    optimizeItens(form: FormData): Observable<any> {
        return this.http.post<any>(`${this.url}/OptimizeItens`, form)
    }

    saveOptimization(form: FormData): Observable<any> {
        return this.http.post<any>(`${this.url}/SaveOptimization`, form)
    }

    undoOptimization(form: FormData): Observable<any> {
        return this.http.post<any>(`${this.url}/UndoOptimization`, form)
    }
}