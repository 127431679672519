import { Component, OnInit, Input } from '@angular/core';
import { RequestCallbackService } from 'src/app/shared/helpers/request-callback.service';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { TelefoneMascaraService } from './telefone-mascara.service';
import * as $ from "jquery";
import { LanguagueService } from 'src/app/shared/helpers/language.service';
import { sleep } from 'src/app/shared/helpers/util';
import { SUFFIX_REGEX } from './TelefoneMascaraUtils';

@Component({
  selector: 'telefone-mascara',
  templateUrl: './telefone-mascara.component.html',
  styleUrls: ['./telefone-mascara.component.scss']
})
export class TelefoneMascaraComponent implements OnInit {

  @Input()
  data: any = {};
  @Input()
  maxLength: number;
  mask: string = "";
  countries: any = [];
  ds_regex: string = "";
  suffixRegex: string;

  constructor(
    private requestCallbackService: RequestCallbackService,
    private language: LanguagueService,
    private service: TelefoneMascaraService) {
    this.language.globalize();
    this.suffixRegex = SUFFIX_REGEX;
  }

  ngOnInit() {
    this.setupContries();
  }

  // region tarefa #29487
  setupContries() {
    this.service.getCountriesWithMask().subscribe((res: ApiResponse<{ success: boolean, message: string }>) => {
      this.requestCallbackService.okResponse(res,
        () => {
          this.countries = res.data.countries;
        })
    },
      error => {
        this.requestCallbackService.failResponse(error, "falha_buscar_paises");
      }
    );
  }

  countrySelected(e: any, data: any) {
    const countrySelected = e.selectedItem;
    const dataField = data.item.dataField;
    const noCountrySelected = countrySelected == null;

    if (noCountrySelected) {
      this.mask = "";
      this.ds_regex = "";
    } else {
      this.mask = countrySelected.ds_mascara;
      this.ds_regex = countrySelected.ds_regex;
    }

    sleep(200).then(() => {

      data.value = data.value + " ";

      const elementId = dataField + data.rowIndex;
      // foca no input de telefone
      $("#" + elementId).find("input").focus();

      sleep(100).then(() => {
        data.value = data.value ? data.value.trim() : data.value;
      });
    });
  }

  // end region tarefa #29487

}
