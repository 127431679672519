import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ContasReceberComponent } from "./containers/contas-receber/contas-receber.component";
import { ContasPagarComponent } from "./containers/contas-pagar/contas-pagar.component";
import { LoginComponent } from "./containers/login/login.component";
import { CompRouterComponent } from "./containers/comp-router/comp-router.component";
import { AuthGuard } from "./shared/guards/auth";
import { ErrorComponent } from "./containers/error/error.component";
import { PedidosComponent } from "./containers/pedidos/pedidos.component";
import { BibliotecaComponent } from "./containers/biblioteca/biblioteca.component";
import { VendasDiaComponent } from "./containers/vendas-dia/vendas-dia.component";
import { AgendaComponent } from "./containers/agenda/agenda.component";
import { EquipesComponent } from "./containers/equipes/equipes.component";
import { FinanceiroComponent } from "./containers/financeiro/financeiro.component";
import { PermissoesComponent } from "./containers/permissoes/permissoes.component";
import { UsuariosComponent } from "./containers/usuarios/usuarios.component";
import { AcompanhamentoComponent } from "./containers/acompanhamento/acompanhamento.component";
import { ProdutosComponent } from "./containers/produtos/produtos.component";
import { HomeComponent } from "./containers/home/home.component";
import { VendedoresComponent } from "./containers/vendedores/vendedores.component";
import { CondicoesComponent } from "./containers/condicoes/condicoes.component";
import { PainelComponent } from "./containers/painel/painel.component";
import { ConfiguracoesComponent } from "./containers/configuracoes/configuracoes.component";
import { PerfilComponent } from "./containers/perfil/perfil.component";
import { PedidoItemComponent } from "./containers/pedido-item/pedido-item.component";
import { UploadgcadComponent } from "./containers/uploadgcad/uploadgcad.component";
import { RelpedidosComponent } from "./containers/relpedidos/relpedidos.component";
import { EditorComponent } from './containers/editor/editor.component';
import { ConfigComponent } from './containers/config/config.component';
import { FuncionariosComponent } from './containers/funcionarios/funcionarios.component';
import { ClientesRedirectComponent } from './containers/clientes-redirect/clientes-redirect.component';
import { NotfoundComponent } from "./containers/notfound/notfound.component";
import { PedidoPreviewComponent } from "./containers/pedido-preview/pedido-preview.component";
import { FuncoesComponent } from "./containers/funcoes/funcoes.component";
import { FornecedoresGrupoCadComponent } from "./containers/fornecedores-grupo-cad/fornecedores-grupo-cad.component";
import { UnimedComponent } from "./containers/unimed/unimed.component";
import { GruposComponent } from "./containers/grupos/grupos.component";
import { SubgruposComponent } from "./containers/subgrupos/subgrupos.component";
import { TiposComponent } from "./containers/tipos/tipos.component";
import { PortadoresComponent } from "./containers/portadores/portadores.component";
import { PaisesComponent } from "./containers/paises/paises.component";
import { EstadosComponent } from "./containers/estados/estados.component";
import { CidadesComponent } from "./containers/cidades/cidades.component";
import { ProdutosVidraceirosComponent } from "./containers/produtos-vidraceiros/produtos-vidraceiros.component";
import { FerragensComponent } from "./containers/ferragens/ferragens.component";
import { RecuperarSenhaComponent } from "./containers/recuperar-senha/recuperar-senha.component";
import { RedefinirSenhaComponent } from "./containers/redefinir-senha/redefinir-senha.component";

const routes: Routes = [
  { path: "error", component: ErrorComponent },
  { path: "", redirectTo: "login", pathMatch: "full" },
  { path: "login", component: LoginComponent },
  {
    path: "pagina",
    component: CompRouterComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "", component: HomeComponent, pathMatch: "full" },
      {
        path: "financeiro",
        component: FinanceiroComponent,
        children: [
          { path: "", redirectTo: "receber", pathMatch: "full" },
          { path: "receber", component: ContasReceberComponent },
          { path: "pagar", component: ContasPagarComponent }
        ]
      },
      { path: "relpedidos", component: PedidosComponent },
      { path: "biblioteca", component: BibliotecaComponent },
      { path: "vendasdiario", component: VendasDiaComponent },
      { path: "agenda", component: AgendaComponent },
      { path: "agenda/:equipe", component: AgendaComponent },
      { path: "agenda/:equipe/:data", component: AgendaComponent },
      { path: "equipes", component: EquipesComponent },
      { path: "permissoes", component: PermissoesComponent },
      { path: "usuarios", component: UsuariosComponent },
      { path: "acompanhamento", component: AcompanhamentoComponent },
      { path: "produtos", component: ProdutosComponent },
      { path: "clientes", component: ClientesRedirectComponent },
      { path: "vendedores", component: VendedoresComponent },
      { path: "condicoes", component: CondicoesComponent },
      { path: "painel", component: PainelComponent },
      { path: "configuracoes", component: ConfiguracoesComponent },
      { path: "perfil", component: PerfilComponent },
      { path: "pedidos", component: RelpedidosComponent },
      { path: "pedidos/itens", component: PedidoItemComponent },
      { path: "pedidos/itens/:pedido", component: PedidoItemComponent },
      { path: "biblioteca/:pedido", component: BibliotecaComponent },
      { path: "upload", component: UploadgcadComponent },
      { path: "biblioteca/:pedido/editor/:grupo", component: EditorComponent },
      { path: "configs", component: ConfigComponent },
      { path: "funcionarios", component: FuncionariosComponent },
      { path: "funcoes", component: FuncoesComponent },
      { path: "fornecedores/grupocad", component: FornecedoresGrupoCadComponent },
      { path: "unimed", component: UnimedComponent },
      { path: "grupos", component: GruposComponent },
      { path: "subgrupos", component: SubgruposComponent },
      { path: "tipos", component: TiposComponent },
      { path: "portadores", component: PortadoresComponent },
      { path: "paises", component: PaisesComponent },
      { path: "estados", component: EstadosComponent },
      { path: "cidades", component: CidadesComponent },
      { path: "produtos/vidraceiros", component: ProdutosVidraceirosComponent },
      { path: "ferragens", component: FerragensComponent }
    ]
  },
  { path: "pedido/preview", component: PedidoPreviewComponent },
  { path: "recuperar/senha", component: RecuperarSenhaComponent },
  { path: "redefinir/senha", component: RedefinirSenhaComponent },
  { path: '404', component: NotfoundComponent },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],

  exports: [RouterModule]
})
export class AppRoutingModule { }
