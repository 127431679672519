<div id="container" class="m-3">
    <app-loader *ngIf="!content"></app-loader>

    <div [ngSwitch]="screenToShow">
        <div *ngSwitchCase="0">
            <div class="mt-4 box">
                <div>
                    <h5 class="text-center">{{'index.loginM' | translate}}</h5>
                    <p class="text-center">{{'index.loginErroServidor' | translate}}</p>
                </div>
            </div>
        </div>
        <div *ngSwitchCase="1">
            <app-clientes-vidraceiros></app-clientes-vidraceiros>
        </div>
        <div *ngSwitchCase="2">
            <app-clientes></app-clientes>
        </div>
        <!-- <div *ngSwitchDefault>
            <h1>Carregando</h1>
        </div> -->
    </div>
</div>