<div class="m-3">
  <app-loader *ngIf="loading"></app-loader>
  <div>
    <div class="container-fluid m-3" *ngIf="havePermission">
      <dx-data-grid
        id="data-grid-permissions"
        [columnAutoWidth]="true"
        [columnHidingEnabled]="true"
        [dataSource]="dataSource"
        [allowColumnReordering]="true"
        [allowColumnResizing]="true"
        [showBorders]="true"
        [remoteOperations]="true"
        (onRowUpdating)="onRowUpdating($event)"
        (onToolbarPreparing)="onToolbarPreparing($event)">
        
        <dxo-editing 
          mode="batch"
          [allowUpdating]="true">
        </dxo-editing>

        <dxo-search-panel [visible]="true"></dxo-search-panel>

        <dxo-grouping [autoExpandAll]="false"></dxo-grouping>

        <dxi-column
          dataField="ds_tags"
          [caption]="'index.formulario' | translate"
          [allowEditing]="false">
        </dxi-column>

        <dxi-column
          dataField="cd_form"
          [caption]="'index.codigo' | translate"
          [allowEditing]="false">
        </dxi-column>

        <dxi-column
          dataField="st_menu"
          [caption]="'index.menu' | translate">
        </dxi-column>

        <dxi-column
          dataField="st_adicionar"
          [caption]="'index.adicionar' | translate">
        </dxi-column>

        <dxi-column
          dataField="st_edita"
          [caption]="'index.editar' | translate">
        </dxi-column>
        
        <dxi-column
          dataField="st_excluir"
          [caption]="'index.excluir' | translate">
        </dxi-column>

        <dxi-column
          dataField="ds_nome"
          [caption]="'index.nome' | translate"
          [groupIndex]="0"
          groupCellTemplate="groupCellTemplateNome">

          <div *dxTemplate="let data of 'groupCellTemplateNome'">
            <span >{{ "index.nome" | translate }}: {{data.key}}</span>
            <dx-button
              id="btnCheckAllPermissions"
              [hint]="'index.marcar_tudo' | translate"
              icon="check"
              (onContentReady)="onContentReady($event, data)"
              (onClick)="toogleAllPermissions(data, true)">
            </dx-button>
            <dx-button
              id="btnCheckAllPermissions"
              [hint]="'index.desmarcar_tudo' | translate"
              icon="close"
              (onContentReady)="onContentReady($event, data)"
              (onClick)="toogleAllPermissions(data, false)">
            </dx-button>
          </div>
        </dxi-column>

        <div *dxTemplate="let data of 'dx-title'">
          <div class="text-center">
            <p class="title mb-0">{{ "index.permissoes" | translate }}</p>
          </div>
        </div>

      </dx-data-grid>
    </div>
    <denied-access *ngIf="!havePermission"></denied-access>
  </div>
</div>
