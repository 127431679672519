<app-loader *ngIf="loading"></app-loader>

<section class="container-fluid">

      <div class="col-12">
        <div class="card py-3 px-3">
          <div>
            <h4 class="logo">
              <a>{{ "index.recuperar_senha" | translate }}</a>
            </h4>
            <p class="mx-4 mt-4 p-1 text-center msg" *ngIf="!successRecoverySend">
              {{ "index.msg_recuperar_senha" | translate }}
            </p>
          </div>

          <form (submit)="onSubmit($event)">
            <dx-form id="form"
                [formData]="recoveryForm"
                [showColonAfterLabel]="true"
                [showValidationSummary]="true"
                *ngIf="!successRecoverySend">
                
                <dxi-item
                    dataField="email"
                    [label]="{ text: 'index.email' | translate }">
                    <dxi-validation-rule 
                        type="required">
                    </dxi-validation-rule>
                    <dxi-validation-rule 
                        type="email">
                    </dxi-validation-rule>
                </dxi-item>

                <dxi-item
                  itemType="button"
                  horizontalAlignment="center"
                  [buttonOptions]="buttonOptions">
               </dxi-item>
            </dx-form>

            <div class="text-center p-2 m-3 msg" *ngIf="successRecoverySend">
              <span >
                {{ "index.msg_email_enviado_recuperar_senha" | translate }}
              </span>
            </div>

            <div class="text-center" *ngIf="showEnvironment">
              <span class="environment-version">
                {{environmentName}}
              </span>
            </div>
          </form>
        </div>
      </div>

</section>