import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { baseUrl } from "src/app/config";
import { Observable, of } from 'rxjs'; // Importe 'of' aqui
import { LanguagueService } from 'src/app/shared/helpers/language.service';
import { GRUPO_GCAD_FERRAGEM, GRUPO_GCAD_PERFIL, GRUPO_GCAD_ACESSORIOS_GERAIS, GRUPO_GCAD_VIDRO, GRUPO_GCAD_INDEFINIDO } from 'src/app/shared/helpers/constants';
import { catchError, map } from 'rxjs/operators';
import { Espiao } from 'src/app/shared/models/Espiao';

@Injectable({
  providedIn: "root"
})
export class ProdutosService {

  private url = baseUrl;

  constructor(
    private http: HttpClient,
    private language: LanguagueService
  ) { }

  checkPermissionProducts(): Observable<any> {
    return this.http.get(`${this.url}/checkPermissionProducts`);
  }

  getSubGrupos(): Observable<any> {
    return this.http.get(`${this.url}/getSubgruposAngular`);
  }

  getGrupos(): Observable<any> {
    return this.http.get(`${this.url}/getGruposAngular`);
  }

  getUnimed(): Observable<any> {
    return this.http.get(`${this.url}/getUnimed`);
  }

  loadProdutos(form: FormData): Observable<any> {
    return this.http.post(`${this.url}/GetProducts`, form);
  }

  insertProduto(form: FormData): Observable<any> {
    return this.http.post(`${this.url}/InsertProducts`, form);
  }

  updateProduto(form: FormData): Observable<any> {
    return this.http.post(`${this.url}/UpdateProducts`, form);
  }

  deleteProduto(form: FormData): Observable<any> {
    return this.http.post(`${this.url}/DeleteProducts`, form);
  }

  ajustar(ids: string, perc: string): Observable<any> {
    return this.http.get(`${this.url}/ajustarPerc?obj=${ids}&perc=${perc}`);
  }

  GetHist(codigoProduto: number): Observable<Espiao[]> {
    return this.http.get<Espiao[]>(`${this.url}/getHist?request=${codigoProduto}`)
      .pipe(
        catchError(error => {
          console.error('Erro na requisição:', error);
          return of([]);
        })
      );
  }

  getTipos(): Observable<any> {
    return this.http.get(`${this.url}/getTiposProduto`);
  }

  getGruposGcad(): any[] {
    const gruposGcad = [
      {
        cd_codigo: GRUPO_GCAD_INDEFINIDO,
        ds_nome: this.language.translateMessage("indefinido"),
      },
      {
        cd_codigo: GRUPO_GCAD_ACESSORIOS_GERAIS,
        ds_nome: this.language.translateMessage("acessorios_gerais"),
      },
      {
        cd_codigo: GRUPO_GCAD_VIDRO,
        ds_nome: this.language.translateMessage("vidro"),
      },
      {
        cd_codigo: GRUPO_GCAD_FERRAGEM,
        ds_nome: this.language.translateMessage("ferragem"),
      },
      {
        cd_codigo: GRUPO_GCAD_PERFIL,
        ds_nome: this.language.translateMessage("perfil"),
      }
    ];
    return gruposGcad;
  }
}
