<app-loader *ngIf="loading"></app-loader>

<div class="m-3" *ngIf="havePermission">
  <dx-data-grid
    id="data-grid-user"
    [dataSource]="dataSource"
    [columnHidingEnabled]="true"
    [allowColumnReordering]="true"
    [allowColumnResizing]="true"
    [showBorders]="true"
    [remoteOperations]="true"
    (onToolbarPreparing)="onToolbarPreparing($event)"
    (onInitNewRow)="onInitNewRow($event)"
    (onEditingStart)="onEditingStart($event)"
    (onRowUpdating)="onRowUpdating($event)">

    <dxo-search-panel
      [visible]="true"
      [width]="240"
      [placeholder]="'index.procurar' | translate">
    </dxo-search-panel>

    <dxo-export
      [enabled]="true"
      [allowExportSelectedData]="false"
      [fileName]="'index.usuarios' | translate">
    </dxo-export>

    <dxo-editing
      mode="row"
      [allowUpdating]="true"
      [allowDeleting]="true"
      [allowAdding]="true">
    </dxo-editing>

    <!-- Define se haverá scroll infinito ou paginacao -->
    <dxo-scrolling mode="virtual"></dxo-scrolling>

    <dxi-column
      dataField="ds_nome"
      [caption]="'index.usuario' | translate"
      [editorOptions]="{ maxLength: 50 }">
      <dxi-validation-rule type="required"></dxi-validation-rule>
      <dxi-validation-rule type="email"></dxi-validation-rule>
    </dxi-column>

    <dxi-column
      dataField="ds_apelido"
      [caption]="'index.nome' | translate"
      [editorOptions]="{ maxLength: 20 }">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column 
      dataField="st_admin" 
      [caption]="'index.admin' | translate" 
      dataType="boolean">
    </dxi-column>

    <dxi-column 
      dataField="ds_empresa" 
      [caption]="'index.empresa' | translate" 
      [groupIndex]="0">
    </dxi-column>

    <dxi-column 
      dataField="cd_empresa" 
      [caption]="'index.empresa' | translate" 
      calculateDisplayValue="ds_empresa"
      editCellTemplate="editCellTemplateCompany">
      <dxo-lookup
        [dataSource]="dataSourceCompanies"
        valueExpr="cd_empresa"
        displayExpr="ds_empresa">
      </dxo-lookup>
      <dxi-validation-rule type="required" ></dxi-validation-rule>
    </dxi-column>

    <div *dxTemplate="let data of 'editCellTemplateCompany'">
      <dx-select-box
          [dataSource]="dataSourceCompanies"
          displayExpr="ds_empresa"
          valueExpr="cd_empresa" 
          [value]="data.value"
          [searchEnabled]="true"
          (onValueChanged)="data.setValue($event.value)"
          [dropDownOptions]="{ minWidth: minWidthSelectBox }">
      </dx-select-box>
    </div>

    <dxi-column 
      dataField="cd_vendedor" 
      [caption]="'index.vendedor' | translate" 
      calculateDisplayValue="ds_vendedor"
      editCellTemplate="editCellTemplateSeller">
      <dxo-lookup
        [dataSource]="dataSourceSellers"
        valueExpr="cd_codigo"
        displayExpr="ds_nome">
      </dxo-lookup>
    </dxi-column>

    <div *dxTemplate="let data of 'editCellTemplateSeller'">
      <dx-select-box
          [dataSource]="dataSourceSellers"
          displayExpr="ds_nome"
          valueExpr="cd_codigo" 
          [value]="data.value"
          [searchEnabled]="true"
          (onValueChanged)="data.setValue($event.value)"
          [dropDownOptions]="{ minWidth: minWidthSelectBox }">
      </dx-select-box>
    </div>

    <dxi-column
      dataField="vl_max_sessoes"
      [caption]="'index.sessoes' | translate"
      dataType="number"
      [editorOptions]="{ max: 99, min: 1, format: '###0' }">
    </dxi-column>

    <dxi-column
      dataField="ds_senha"
      [caption]="'index.senha' | translate"
      cellTemplate="cellPasswordTemplate"
      editCellTemplate="editPasswordTemplate">
      <dxi-validation-rule type="required" *ngIf="passwordIsRequired"></dxi-validation-rule>
    </dxi-column>

    <div *dxTemplate="let data of 'cellPasswordTemplate'">
      <span> {{data.value}}</span>
    </div>

    <div *dxTemplate="let data of 'editPasswordTemplate'">
        <dx-text-box 
          [value]="data.value"
          stylingMode="outlined"
          [maxLength]="32"
          (onValueChanged)="passwordValueChanged($event, data)"
          [(mode)]="passwordMode">
            <dxi-button name="today" location="after" [options]="passwordButton"></dxi-button>
        </dx-text-box>
    </div>

    <div *dxTemplate="let data of 'dx-title'">
      <div class="text-center">
        <p class="title mb-0">{{ "index.usuarios" | translate }}</p>
      </div>
    </div>
  </dx-data-grid>

</div>
<denied-access *ngIf="!havePermission" [formCode]="formCode"></denied-access>
