import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/shared/models/User';
import { Router } from '@angular/router';
import { FinanceiroService } from 'src/app/shared/services/financeiro.service';
import { LanguagueService } from 'src/app/shared/helpers/language.service';
import { isEnvironmentDebugOrTest, sleep, getItemStorage, setItemStorage, getServerDate, cleanLocalStorage } from 'src/app/shared/helpers/util';
import { RequestCallbackService } from 'src/app/shared/helpers/request-callback.service';
import { environment } from '../../../environments/environment';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { HASHCAD, LANGUAGE, CONFIG_IDIOMA } from 'src/app/shared/helpers/constants';
import { UserProfile } from 'src/app/shared/models/UserProfile';
import { ToastService } from 'src/app/shared/helpers/toast.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  currentUser: User;
  loading: boolean = false;
  showEnvironment: boolean = false;
  environmentName: string = "";
  loginForm: any = {};
  buttonOptions = {};

  constructor(private service: FinanceiroService,
    private router: Router,
    private language: LanguagueService,
    private requestCallbackService: RequestCallbackService,
    private toastService: ToastService) {
    this.language.globalize();
    //region tarefa #29842
    this.showEnvironment = isEnvironmentDebugOrTest();
    this.environmentName = environment.environmentName;
    // end region
  }


  ngOnInit() {
    if (getItemStorage(HASHCAD)) {
      this.router.navigate(['/pagina']);
    } else {
      // tarefa #30571
      getServerDate(this.requestCallbackService);
    }

    this.language.translateMessageLazy("entrar", (value) => {
      this.buttonOptions = {
        text: value,
        useSubmitBehavior: true
      }
    });
  }

  onForgotPassowordClick(event) {
    event.preventDefault();
    this.router.navigate(['/recuperar/senha']);
  }

  onSubmit(e) {
    e.preventDefault();

    // tarefa #30571
    getServerDate(this.requestCallbackService);

    this.loading = true;
    const usuario = {
      "ds_nome": this.loginForm.username,
      "ds_senha": this.loginForm.password
    };

    const formData = new FormData();
    formData.append("usuario", JSON.stringify(usuario));

    sleep(100).then(() => {
      this.doLogin(formData);
    });
  }

  private doLogin(data: FormData) {
    this.service.login(data).subscribe((data: any) => {
      if (data) {
        switch (data.cd_perfil) {
          case UserProfile.VIDRACARIA:
          case UserProfile.VIDRACARIA_INDEPENDENTE: {
            this.getIdiomaConfig(this.redirectToHome.bind(this));
            break;
          }
          case UserProfile.REITECH:
          case UserProfile.FABRICA: {
            this.getIdiomaConfig(this.redirectToPanel.bind(this));
            break;
          }
          default: {
            this.toastService.warningPermission("perfi_usuario_desconhecido");
            cleanLocalStorage();
            break;
          }
        }
      }
      this.loading = false;
    }, error => {
      this.loading = false;
      this.requestCallbackService.failResponse(error);
    });
  }


  getIdiomaConfig(callback: () => void) {
    this.service.getConfig(CONFIG_IDIOMA).subscribe((response: ApiResponse<{ success: boolean, message: string }>) => {
      this.loading = false;

      this.requestCallbackService.okResponse(response,
        () => {
          let conf = response.data.config.descricao;
          this.language.loadLanguage(conf);
          setItemStorage(LANGUAGE, conf);
          callback();
        }
      );

    }, error => {
      this.loading = false;
      this.requestCallbackService.failResponse(error, "falha_config_idioma");
    });
  }

  redirectToHome() {
    if (getItemStorage(HASHCAD) != null) {
      this.router.navigate(['/pagina']);
    }
  }

  redirectToPanel() {
    if (getItemStorage(HASHCAD) != null) {
      this.router.navigate(['/pagina/painel']);
    }
  }
}
