<app-loader *ngIf="loading"></app-loader>

<section class="container-fluid">

      <div class="col-12">
        <div class="card py-3 px-3">
          <div>
            <h4 class="logo">
              <a>{{ "index.redefinir_senha" | translate }}</a>
            </h4>
            <p class="mx-4 mt-4 p-1 text-center msg" *ngIf="showFormRecovery">
                {{ "index.msg_nova_senha" | translate }}
            </p>
          </div>

          <form (submit)="onSubmit($event)">
            <dx-form id="form"
                [formData]="recoveryForm"
                [showColonAfterLabel]="true"
                [showValidationSummary]="true"
                *ngIf="showFormRecovery">
                
                <dxi-item
                    dataField="ds_senha"
                    [label]="{ text: 'index.senha' | translate }"
                    [editorOptions]="{ mode: fieldPasswordMode, autocomplete: 'none', maxLength: '32', buttons: buttonPasswordEye}">
                    <dxi-validation-rule 
                        type="required">
                    </dxi-validation-rule>
                </dxi-item>

                <dxi-item
                    dataField="ds_confirma_senha"
                    [label]="{ text: 'index.confirmeSenha' | translate }"
                    [editorOptions]="{ mode: fieldConfirmPasswordMode, maxLength: '32', buttons: buttonConfirmPasswordEye}">
                    <dxi-validation-rule 
                        type="required">
                    </dxi-validation-rule>

                    <dxi-validation-rule
                        type="compare"
                        [comparisonTarget]="passwordComparison"
                        [message]="'index.senhasNaoConferem' | translate"> 
                    </dxi-validation-rule>
                </dxi-item>

                <dxi-item
                  itemType="button"
                  horizontalAlignment="center"
                  [buttonOptions]="buttonOptions">
               </dxi-item>
            </dx-form>

            <div class="text-center p-2 m-3 msg" *ngIf="showErrorHash">
              <span >
                {{ "index.erro_hash_invalido" | translate }}
              </span>
            </div>

            <div class="text-center p-2 m-3 msg" *ngIf="showSuccessMsg">
                <div>
                    <span >
                        {{ "index.msg_senha_redefinida_sucesso" | translate }}
                      </span>
                </div>
                <div class="mt-3">
                    <dx-button
                        stylingMode="contained"
                        text="Login"
                        type="default"
                        [width]="200"
                        (onClick)="goToLogin()">
                    </dx-button>
                </div>
            </div>

            <div class="text-center" *ngIf="showEnvironment">
              <span class="environment-version">
                {{environmentName}}
              </span>
            </div>
          </form>
        </div>
      </div>

</section>