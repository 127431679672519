<app-loader *ngIf="loading"></app-loader>

<div *ngIf="havePermission" class="m-3">
  <dx-data-grid
    id="data-grid-clients-vidraceiros"
    [dataSource]="dataSource"
    showBorders="true"
    [remoteOperations]="true"
    [columnHidingEnabled]="true"
    [allowColumnReordering]="true"
    [allowColumnResizing]="true"
    (onToolbarPreparing)="onToolbarPreparing($event)"
    (onEditorPreparing)="onEditorPreparing($event)"
    (onRowUpdating)="onRowUpdating($event)"
    (onInitNewRow)="onInitNewRow($event)"
  >

    <dxo-editing
      mode="row"
      [allowAdding]="c_allowAdding"
      [allowUpdating]="c_allowEditing"
      [allowDeleting]="cm_allowDelete"
    >
    </dxo-editing>

    <dxo-export
      [enabled]="true"
      [allowExportSelectedData]="false"
      [fileName]="'index.clientesFornecedores' | translate"
    >
    </dxo-export>

    <dxo-search-panel
      [visible]="true"
      [width]="240"
      [placeholder]="'index.procurar' | translate"
    ></dxo-search-panel>

    <dxo-scrolling mode="virtual"></dxo-scrolling>

    <dxi-column
      dataField="ds_empresa"
      [caption]="'index.nome' | translate"
      dataType="text"
      [editorOptions]="{ maxLength: 70 }"
    >
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column
      dataField="ds_fantasia"
      [caption]="'index.nomeFantasia' | translate"
      dataType="text"
      [editorOptions]="{ maxLength: 70 }"
    >
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column
      dataField="ds_cnpj"
      [caption]="'index.cpfCnpj' | translate"
      [editorOptions]="{ maxLength: 18 }"
      dataType="text"
    >
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column 
      dataField="cd_plano" 
      [caption]="'index.ds_plano' | translate"
      calculateDisplayValue="ds_plano">
      <dxi-validation-rule type="required"></dxi-validation-rule>
      <dxo-lookup
        [dataSource]="dataSourcePlanos"
        valueExpr="cd_plano"
        displayExpr="ds_nome">
      </dxo-lookup>
    </dxi-column>

    <dxi-column 
      dataField="dt_expiracao" 
      dataType="date" 
      [caption]="'index.dt_expiracao' | translate">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column 
      dataField="ds_empresa_pai" 
      [caption]="'index.empresaPai' | translate"
      [visible]="false">
    </dxi-column>

    <dxi-column 
        dataField="tipo_empresa" 
        [caption]="'index.tipoEmpresa' | translate"
        [visible]="false"
        [allowEditing]="false">
        <dxo-lookup
            [dataSource]="dataSourceCompaniesType"
            valueExpr="cd_codigo"
            displayExpr="ds_nome">
        </dxo-lookup>
    </dxi-column>

    <dxi-column 
      dataField="cd_reitech" 
      [caption]="'index.codigoReitech' | translate"
      dataType="number"
      [editorOptions]="{ maxLength: 6 }"
      [visible]="false">
    </dxi-column>

    <dxi-column
    dataField="cd_pais"
    [caption]="'index.pais' | translate">
        <dxo-lookup
            [dataSource]="dataSourceCountries"
            valueExpr="cd_codigo"
            displayExpr="ds_nome">
        </dxo-lookup>
    </dxi-column>

    <dxi-column
      dataField="ds_cidade"
      [caption]="'index.cidade' | translate"
      calculateDisplayValue="ds_cidade"
      dataType="text">
      <dxo-lookup
        [dataSource]="dataSourceCities"
        valueExpr="cd_cidade"
        displayExpr="ds_cidade">
      </dxo-lookup>
  </dxi-column>

    <dxi-column
      dataField="ds_endereco"
      [caption]="'index.endereco' | translate"
      dataType="text"
    >
    </dxi-column>

    <dxi-column
      dataField="ds_bairro"
      [caption]="'index.bairro' | translate"
      dataType="text"
    >
    </dxi-column>

    <dxi-column
      dataField="ds_email"
      [caption]="'index.email' | translate"
      [editorOptions]="{ maxLength: 60 }"
      dataType="text"
    >
      <dxi-validation-rule type="email"></dxi-validation-rule>
    </dxi-column>

    <dxi-column
      dataField="ds_fone"
      [caption]="'index.telefone' | translate"
      [editorOptions]="{ maxLength: 20 }"
      dataType="phone"
    >
    </dxi-column>

    <div *dxTemplate="let data of 'dx-title'">
      <div class="text-center">
        <p class="title mb-0">{{ "index.clientesFornecedores" | translate }}</p>
      </div>
    </div>
  </dx-data-grid>
</div>
<denied-access *ngIf="!havePermission" [formCode]="formCode"></denied-access>
