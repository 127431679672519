import { Injectable } from "@angular/core";

import { baseUrl } from "./../../config";
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: "root"
})
export class ConfiguracoesService {
  
  constructor(private http: HttpClient) {}

  private url = baseUrl;

  load(): Observable<any> {
    return this.http.get(`${this.url}/GetConfiguracoes`);
  }

  getImgBackGround(): Observable<any> {
    return this.http.get(`${this.url}/ReadImageBackGround`);
  }

  update(form: FormData): Observable<any> {
    return this.http.post(`${this.url}/Update_empresa_Glass`, form);
  }

  licenseExpiring(): Observable<any> {
    return this.http.get(`${this.url}/LicenseExpiring`);
  }
}
