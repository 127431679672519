<app-loader *ngIf="loading"></app-loader>

<div class="m-3">

    <div *ngIf="responseStatus == 1">
        <div class="jumbotron">
            <h3 class="display-5">{{ "index.funcoes" | translate }}</h3>
            <p class="lead">Funcionalidades exclusivas para usuários de nível ADM.</p>
            <hr class="my-4">
            <p>Varre a lista de conjuntos de biblioteca e conjuntos de pedidos gerando o xml (persisintindo na tabela base.arquivos) e renderizando suas respectivas imagens na máquina hospedeira.</p>
            <p>Varre a lista de empresas e gera as imagens de logo e background (caso não existam, gera default) na máquina hospedeira.</p>
            <p class="lead">
                <dx-button
                    class="button-info"
                    [text]="'index.iniciar' | translate"
                    (onClick)="persistirConjuntos()">
                </dx-button>
            </p>
        </div>
    </div>

    <div *ngIf="responseStatus == 2">
        <denied-access></denied-access>
    </div>

    <div *ngIf="responseStatus == 3">
        <div class="mt-4 box">
            <div>
                <h5 class="text-center">{{'index.loginM' | translate}}</h5>
                <p class="text-center">{{'index.loginErroServidor' | translate}}</p>
            </div>
        </div>
    </div>

</div>