import { Injectable } from "@angular/core";
import { baseUrl } from "./../../config";
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';

@Injectable({
    providedIn: "root"
})
export class FuncionariosService {
    private url = baseUrl;
    constructor(private http: HttpClient) { }

    getEmployees(form: FormData): Observable<any> {
        return this.http.post<any>(`${this.url}/getEmployees`, form);
    }

    getUserForEmployees(form: FormData): Observable<any> {
        return this.http.post<any>(`${this.url}/getUserForEmployees`, form);
    }

    insertEmployee(form: FormData): Observable<any> {
        return this.http.post(`${this.url}/insertEmployee`, form);
    }

    updateEmployee(form: FormData): Observable<any> {
        return this.http.post(`${this.url}/updateEmployee`, form);
    }

    deleteEmployee(form: FormData): Observable<any> {
        return this.http.post(`${this.url}/deleteEmployee`, form);
    }
}