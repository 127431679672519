import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { baseUrl } from "./../../config";
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: "root"
})
export class PermissaoService {

  private url = baseUrl;

  constructor(private http: HttpClient) { }

  getPermissoes(formData: FormData): Observable<any> {
    return this.http.post(`${this.url}/getUsuariosPerm`, formData);
  }

  update(formData: FormData): Observable<any> {
    return this.http.post(`${this.url}/updatePerm`, formData);
  }

  getUsuarios(formData: FormData = new FormData): Observable<any> {
    return this.http.post(`${this.url}/getUsuarios`, formData);
  }

  getForms(): Observable<any> {
    return this.http.get(`${this.url}/getForms`);
  }
}
