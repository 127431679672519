import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { iconInvisible, iconVisible } from 'src/app/shared/helpers/constants';
import { LanguagueService } from 'src/app/shared/helpers/language.service';
import { RequestCallbackService } from 'src/app/shared/helpers/request-callback.service';
import { ToastService } from 'src/app/shared/helpers/toast.service';
import { isEnvironmentDebugOrTest } from 'src/app/shared/helpers/util';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { environment } from 'src/environments/environment';
import { RedefinirSenhaService } from './redefinir-senha.service';

@Component({
  selector: 'app-redefinir-senha',
  templateUrl: './redefinir-senha.component.html',
  styleUrls: ['./redefinir-senha.component.scss']
})
export class RedefinirSenhaComponent implements OnInit {

  loading: boolean = false;
  showEnvironment: boolean = false;
  environmentName: string = "";
  recoveryForm: any = {};
  buttonOptions = {};

  fieldPasswordMode: string;
  iconPassword: string;
  buttonPasswordEye: any = [];
  fieldConfirmPasswordMode: string;
  iconConfirmPassword: string;
  buttonConfirmPasswordEye: any = [];

  showFormRecovery: boolean;
  showErrorHash: boolean;
  showSuccessMsg: boolean;
  hash: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private language: LanguagueService,
    private requestCallbackService: RequestCallbackService,
    private toastService: ToastService,
    private service: RedefinirSenhaService
  ) {
    this.language.globalize();
    this.setupEnvironment()
  }

  private setupEnvironment() {
    this.showEnvironment = isEnvironmentDebugOrTest();
    this.environmentName = environment.environmentName;
  }

  ngOnInit() {
    this.getParamHash();
    this.setupButtonOptions();
    this.setupBtnPasswordEye();
    this.setupBtnPasswordEye(false);
  }

  private getParamHash() {
    const hash = this.route.snapshot.queryParamMap.get('hash');
    if (hash) {
      this.hash = hash;
      this.checkRecoveryHash(hash);
    } else {
      this.router.navigate(["/404"]);
    }
  }

  private checkRecoveryHash(hash: string) {

    const formData = new FormData();
    formData.append("hash", hash);

    this.loading = true;
    this.service.checkRecoveryHash(formData)
      .subscribe((response: ApiResponse<{ success: boolean, message: string }>) => {
        this.loading = false;
        this.requestCallbackService.okResponse(response,
          () => {
            this.showFormRecovery = true;
          },
          () => {
            this.showErrorHash = true;
          }
        );
      }, error => {
        this.loading = false;
        this.requestCallbackService.failResponse(error);
      });
  }

  private setupButtonOptions() {
    this.language.translateMessageLazy("salvar", (value) => {
      this.buttonOptions = {
        text: value,
        useSubmitBehavior: true
      }
    });
  }

  setupBtnPasswordEye(isBtnPassword: boolean = true) {

    this.fieldPasswordMode = "password"
    this.iconPassword = iconInvisible;
    this.fieldConfirmPasswordMode = "password";
    this.iconConfirmPassword = iconInvisible;

    const builtButton = [
      {
        name: isBtnPassword ? "passwordEye" : "confirmPasswordEye",
        location: "after",
        options: {
          icon: isBtnPassword ? this.iconPassword : this.iconConfirmPassword,
          stylingMode: "underlined",
          onClick: () => {

            if (isBtnPassword) {
              this.fieldPasswordMode =
                this.fieldPasswordMode === "text" ? "password" : "text";
              this.iconPassword =
                this.fieldPasswordMode === "text"
                  ? iconVisible
                  : iconInvisible;
              this.buttonPasswordEye[0].options.icon = this.iconPassword;
            } else {
              this.fieldConfirmPasswordMode =
                this.fieldConfirmPasswordMode === "text"
                  ? "password"
                  : "text";
              this.iconConfirmPassword =
                this.fieldConfirmPasswordMode === "text"
                  ? iconVisible
                  : iconInvisible;
              this.buttonConfirmPasswordEye[0].options.icon = this.iconConfirmPassword;
            }
          }
        }
      }
    ];

    if (isBtnPassword) {
      this.buttonPasswordEye = builtButton;
    } else {
      this.buttonConfirmPasswordEye = builtButton;
    }
  }

  passwordComparison = () => {
    const ds_senha = this.recoveryForm.ds_senha;
    return ds_senha;
  };

  onSubmit(event) {
    event.preventDefault();
    this.changePassword();
  }


  private changePassword() {

    const formData = new FormData();
    const data = {
      ds_hash: this.hash,
      ds_senha: this.recoveryForm.ds_senha
    }
    formData.append("data", JSON.stringify(data));

    this.loading = true;
    this.service.changePassword(formData)
      .subscribe((response: ApiResponse<{ success: boolean, message: string }>) => {
        this.loading = false;
        this.requestCallbackService.okResponse(response,
          () => {
            this.toastService.successToast("salvar_sucesso");
            this.showFormRecovery = false;
            this.showSuccessMsg = true;
          }
        );
      }, error => {
        this.loading = false;
        this.requestCallbackService.failResponse(error);
      });
  }

  goToLogin() {
    this.router.navigate(["/login"]);
  }

}
