export class PedidoFornecedorDto {
    cd_fornecedor_vidro: number
    cd_fornecedor_ferragem: number
    cd_fornecedor_perfil: number
    cd_fornecedor_acessorio: number
    st_vidros: boolean
    st_ferragens: boolean
    st_perfis: boolean
    st_acessorios: boolean
    st_enviado_vidros: boolean
    dt_ultimo_envio_vidros: Date
    st_enviado_ferragens: boolean
    dt_ultimo_envio_ferragens: Date
    st_enviado_perfis: boolean
    dt_ultimo_envio_perfis: Date
    st_enviado_acessorios: boolean
    dt_ultimo_envio_acessorios: Date
    st_fabrica: boolean
    st_importado_vidros: boolean
    st_importado_perfis: boolean
    st_importado_ferragens: boolean
    st_importado_acessorios: boolean
    dt_importacao_vidros: Date
    dt_importacao_perfis: Date
    dt_importacao_ferragens: Date
    dt_importacao_acessorios: Date
}