<div *ngIf="havePermission" class="m-3">

    <dx-data-grid 
        id="data-grid-ferragens"
        [dataSource]="dataSource" 
        [columnAutoWidth]="true" 
        showBorders="true"
        [allowColumnReordering]="true" 
        [allowColumnResizing]="true"
        [remoteOperations]="true"
        (onRowUpdating)="onRowUpdating($event)"
        (onInitNewRow)="onInitNewRow($event)"
        (onEditingStart)="onEditingStart($event)"
        (onToolbarPreparing)="onToolbarPreparing($event)">

        <dxo-search-panel 
            [visible]="true" 
            [width]="240" 
            [placeholder]="'index.procurar' | translate">
        </dxo-search-panel>

        <dxo-editing
            mode="row"
            [allowAdding]="true"
            [allowUpdating]="cm_allowEditAndDelete"
            [allowDeleting]="cm_allowEditAndDelete">
        </dxo-editing>

        <dxo-scrolling mode="virtual"></dxo-scrolling>

        <dxi-column 
            dataField="id"
            dataType="text"
            sortOrder="desc"
            [allowEditing]="false"
            [caption]="'index.id' | translate">
        </dxi-column>

        <dxi-column 
            dataField="codigo"
            dataType="text"
            [editorOptions]="{ maxLength: 20 }"
            [caption]="'index.codigo' | translate">
            <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>

        <dxi-column 
            dataField="larg"
            dataType="number"
            [caption]="'index.larg' | translate">
            <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>

        <dxi-column 
            dataField="alt"
            dataType="number"
            [caption]="'index.alt' | translate">
            <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>

        <dxi-column 
            dataField="borda"
            dataType="number"
            [caption]="'index.borda' | translate">
        </dxi-column>

        <dxi-column 
            dataField="e_folga"
            dataType="number"
            [caption]="'index.e_folga' | translate">
            <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>

        <dxi-column 
            dataField="t_folga"
            dataType="number"
            [caption]="'index.t_folga' | translate">
            <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>

        <dxi-column 
            dataField="max_alt"
            dataType="number"
            [caption]="'index.max_alt' | translate">
        </dxi-column>

        <dxi-column 
            dataField="max_larg"
            dataType="number"
            [caption]="'index.max_larg' | translate">
        </dxi-column>

        <dxi-column 
            dataField="st_fecha"
            dataType="number"
            [caption]="'index.st_fecha' | translate">
        </dxi-column>

        <dxi-column 
            dataField="cd_aplica"
            dataType="number"
            [caption]="'index.cd_aplica' | translate">
            <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>

        <dxi-column 
            dataField="ds_comple"
            dataType="text"
            [editorOptions]="{ maxLength: 100 }"
            [caption]="'index.ds_comple' | translate">
            <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>

        <dxi-column 
            dataField="larg_corte"
            dataType="number"
            [caption]="'index.larg_corte' | translate">
            <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>

        <dxi-column 
            dataField="alt_corte"
            dataType="number"
            [caption]="'index.alt_corte' | translate">
            <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>

        <dxi-column 
            dataField="ds_codprod"
            [caption]="'index.ds_codprod' | translate">
        </dxi-column>

        <dxi-column 
            dataField="st_habilitada"
            dataType="boolean"
            [caption]="'index.st_habilitada' | translate">
        </dxi-column>

        <dxi-column 
            dataField="x_min"
            dataType="number"
            [caption]="'index.x_min' | translate">
        </dxi-column>

        <dxi-column 
            dataField="y_min"
            dataType="number"
            [caption]="'index.y_min' | translate">
        </dxi-column>

        <dxi-column 
            dataField="x_max"
            dataType="number"
            [caption]="'index.x_max' | translate">
        </dxi-column>

        <dxi-column 
            dataField="y_max"
            dataType="number"
            [caption]="'index.y_max' | translate">
        </dxi-column>

        <dxi-column 
            dataField="cd_fabrica"
            dataType="number"
            [caption]="'index.cd_fabrica' | translate">
        </dxi-column>

        <dxi-column 
            dataField="vl_valor"
            dataType="number"
            [caption]="'index.vl_valor' | translate">
        </dxi-column>

        <dxi-column 
            dataField="cota_codigo"
            dataType="boolean"
            [caption]="'index.cota_codigo' | translate">
        </dxi-column>

        <dxi-column 
            dataField="cota_codigo_x"
            dataType="number"
            [caption]="'index.cota_codigo_x' | translate">
        </dxi-column>

        <dxi-column 
            dataField="cota_codigo_y"
            dataType="number"
            [caption]="'index.cota_codigo_y' | translate">
        </dxi-column>

        <dxi-column 
            dataField="cota_lateral"
            dataType="boolean"
            [caption]="'index.cota_lateral' | translate">
        </dxi-column>

        <dxi-column 
            dataField="cota_lateral_x"
            dataType="number"
            [caption]="'index.cota_lateral_x' | translate">
        </dxi-column>

        <dxi-column 
            dataField="cota_lateral_y"
            dataType="number"
            [caption]="'index.cota_lateral_y' | translate">
        </dxi-column>

        <dxi-column 
            dataField="cota_altura"
            dataType="boolean"
            [caption]="'index.cota_altura' | translate">
        </dxi-column>

        <dxi-column 
            dataField="cota_altura_x"
            dataType="number"
            [caption]="'index.cota_altura_x' | translate">
        </dxi-column>

        <dxi-column 
            dataField="cota_altura_y"
            dataType="number"
            [caption]="'index.cota_altura_y' | translate">
        </dxi-column>

        <dxi-column 
            dataField="ds_furos"
            dataType="text"
            [editorOptions]="{ maxLength: 20 }"
            [caption]="'index.ds_furos' | translate">
        </dxi-column>

        <dxi-column 
            dataField="txt_dxf"
            [columnMinWidth]="300"
            [caption]="'index.txt_dxf' | translate"
            cellTemplate="cellTemplateDxf"
            editCellTemplate="editCellTemplateDxf">
            <dxi-validation-rule type="required" *ngIf="dxfIsRequired"></dxi-validation-rule >
                <div *dxTemplate="let data of 'cellTemplateDxf'">
                    <dx-button
                        stylingMode="contained"
                        type="normal"
                        icon="download"
                        (onClick)="CM_DownloadDxf(data.data)">
                    </dx-button>
                </div>
                <div *dxTemplate="let data of 'editCellTemplateDxf'">
                    <dx-file-uploader
                        accept=".dxf"
                        [multiple]="false"
                        [allowedFileExtensions]="['.dxf']"
                        (onValueChanged)="uploadDxf($event, data)"
                        uploadMode="useForm">
                    </dx-file-uploader>
                </div>
        </dxi-column>

        <dxi-column 
            dataField="cd_empresa" 
            [caption]="'index.empresa' | translate"
            [groupIndex]="0" 
            calculateDisplayValue="ds_empresa"
            [allowEditing]="false">
            <dxo-lookup
                [dataSource]="dataSourceCompanies"
                valueExpr="cd_empresa"
                displayExpr="ds_empresa">
            </dxo-lookup>
        </dxi-column>

        <div *dxTemplate="let data of 'dx-title'">
            <div class="text-center">
                <p class="title mb-0">{{'index.ferragens' | translate}}</p>
            </div>
        </div>

    </dx-data-grid>

</div>
<denied-access *ngIf="!havePermission" [formCode]="formCode"></denied-access>