import { LanguagueService } from '../helpers/language.service';

export class Situacao {
  cd_situacao: number
  ds_situacao: string
}

export const loadDataSourceSituacoes = (language: LanguagueService, dataSource: Situacao[]) => {
  language.translateMessageLazy("aberto", (value) => {
    dataSource.push({
      cd_situacao: SITUACAO_ABERTO,
      ds_situacao: value
    });
  });

  language.translateMessageLazy("pago", (value) => {
    dataSource.push({
      cd_situacao: SITUACAO_PAGO,
      ds_situacao: value
    });
  });
}

export const SITUACAO_ABERTO: number = 0;
export const SITUACAO_PAGO: number = 1;