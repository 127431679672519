export class CabecalhoImpressao {
    nome_fantasia: string;
    ds_nome_cliente: string;
    ds_cnpj: string;
    ds_logo: string;
    ds_logo_vidracaria: string;
    ds_fone: string;
    ds_endereco: string;
    ds_cidade: string;
    ds_estado: string;
}