<app-loader *ngIf="loading"></app-loader>
<div *ngIf="havePermission" class="m-3">
  <dx-data-grid
    id="data-grid-sellers"
    [dataSource]="dataSource"
    [remoteOperations]="true"
    [columnHidingEnabled]="true"
    [allowColumnReordering]="true"
    [allowColumnResizing]="true"
    showBorders="true"
    (onEditorPreparing)="onEditorPreparing($event)"
    (onToolbarPreparing)="onToolbarPreparing($event)"
    (onRowUpdating)="onRowUpdating($event)"
    (onInitNewRow)="onInitNewRow($event)">

    <dxo-export
      [enabled]="true"
      [allowExportSelectedData]="false"
      [fileName]="'index.vendedores' | translate">
    </dxo-export>

    <dxo-search-panel
      [visible]="true"
      [width]="240"
      [placeholder]="'index.procurar' | translate">
    </dxo-search-panel>

    <dxo-editing
      mode="row"
      [allowAdding]="true"
      [allowUpdating]="true"
      [allowDeleting]="true">
    </dxo-editing>

    <dxo-scrolling mode="virtual"></dxo-scrolling>

    <dxi-column
      dataField="ds_nome"
      [caption]="'index.nome' | translate"
      [editorOptions]="{ maxLength: 40 }">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column
      dataField="dt_admissao"
      [caption]="'index.dataAdmissao' | translate"
      dataType="date">
    </dxi-column>

    <dxi-column dataField="cd_tipo" [caption]="'index.tipo' | translate">
      <dxo-lookup
        [dataSource]="dataSourceTipoVendedores"
        valueExpr="cd_codigo"
        displayExpr="ds_nome">
      </dxo-lookup>
    </dxi-column>

    <dxi-column
      dataField="vl_comissao"
      [caption]="'index.valorComissao' | translate"
      dataType="number"
      [customizeText]="customizeComissionText"
      [editorOptions]="{ max: '999999999', format: '#########.##', min: 0 }">
    </dxi-column>

    <dxi-column
      dataField="ds_obs"
      [caption]="'index.observacao' | translate"
      dataType="text"
      [editorOptions]="{ maxLength: 80 }">
    </dxi-column>

    <dxi-column
      dataField="ds_rg"
      [caption]="'index.rg' | translate"
      dataType="text"
      [editorOptions]="{ maxLength: 11 }">
    </dxi-column>

    <dxi-column
      dataField="dt_ultvenda"
      [caption]="'index.ultimaVenda' | translate"
      dataType="date">
    </dxi-column>

    <dxi-column
      dataField="st_ativo"
      [caption]="'index.ativo' | translate"
      dataType="boolean">
    </dxi-column>

    <dxi-column
      dataField="ds_endereco"
      [caption]="'index.endereco' | translate"
      dataType="text"
      [editorOptions]="{ maxLength: 50 }">
    </dxi-column>

    <dxi-column
      dataField="ds_numero"
      [caption]="'index.numero' | translate"
      dataType="text"
      [editorOptions]="{ maxLength: 10 }">
    </dxi-column>

    <dxi-column
      dataField="ds_bairro"
      [caption]="'index.bairro' | translate"
      dataType="text"
      [editorOptions]="{ maxLength: 20 }">
    </dxi-column>

    <dxi-column
      dataField="ds_cep"
      [caption]="'index.cep' | translate"
      dataType="text"
      [editorOptions]="{ maxLength: 10 }">
    </dxi-column>

    <dxi-column
      dataField="cd_cidade"
      [caption]="'index.cidade' | translate"
      calculateDisplayValue="ds_cidade"
      dataType="text">
      <dxo-lookup
        [dataSource]="cidades"
        valueExpr="cd_cidade"
        displayExpr="ds_cidade">
      </dxo-lookup>
    </dxi-column>

    <dxi-column
      dataField="ds_email"
      [caption]="'index.email' | translate"
      dataType="text"
      [editorOptions]="{ maxLength: 100 }">
      <dxi-validation-rule type="email"> </dxi-validation-rule>
    </dxi-column>

    <dxi-column
      dataField="ds_fone"
      [caption]="'index.telefone' | translate"
      dataType="text"
      editCellTemplate="editCellTemplate">
      <div *dxTemplate="let data of 'editCellTemplate'">
        <telefone-mascara #maskFone [data]="data" maxLength="30"></telefone-mascara>
      </div>
      <dxi-validation-rule
        type="custom"
        [validationCallback]="regex"
        message="">
      </dxi-validation-rule>
    </dxi-column>

    <dxi-column
      dataField="ds_celular"
      [caption]="'index.celular' | translate"
      dataType="text"
      editCellTemplate="editCellTemplate">
      <div *dxTemplate="let data of 'editCellTemplate'">
        <telefone-mascara #maskCelular [data]="data" maxLength="30"></telefone-mascara>
      </div>
      <dxi-validation-rule
        type="custom"
        [validationCallback]="regex"
        message="">
      </dxi-validation-rule>
    </dxi-column>

    <div *dxTemplate="let data of 'dx-title'">
      <div class="text-center">
        <p class="title mb-0">{{ "index.vendedores" | translate }}</p>
      </div>
    </div>
  </dx-data-grid>
</div>
<denied-access *ngIf="!havePermission" [formCode]="formCode"></denied-access>