<app-loader *ngIf="loading"></app-loader>

<section class = "container-fluid">

      <div class="col-12">
        <div class="card py-3 px-3">
          <div>
            <h3 class="logo">
              <a>Glass<span id="title">CAD</span></a>
             
            </h3>
          </div>

          <form (submit)="onSubmit($event)">
            <dx-form id="form"
                [formData]="loginForm"
                [showColonAfterLabel]="true"
                [showValidationSummary]="true">
                
                <dxi-item
                    dataField="username"
                    [label]="{ text: 'index.email' | translate }">
                    <dxi-validation-rule 
                        type="required">
                    </dxi-validation-rule>
                    <dxi-validation-rule 
                        type="email">
                    </dxi-validation-rule>
                </dxi-item>

                <dxi-item 
                    dataField="password"
                    [label]="{ text: 'index.senha' | translate }"
                    [editorOptions]="{ mode: 'password' }">
                    <dxi-validation-rule 
                      type="required">
                    </dxi-validation-rule> 
                </dxi-item>

                <dxi-item
                  itemType="button"
                  horizontalAlignment="center"
                  [buttonOptions]="buttonOptions">
               </dxi-item>
            </dx-form>
            
            <div class="text-center mb-2">
              <a class="forgotPassword-link" (click)="onForgotPassowordClick($event)">
                {{ "index.esqueci_minha_senha" | translate }}
              </a>
            </div>

            <div class="text-center" *ngIf="showEnvironment">
              <span class="environment-version">
                {{environmentName}}
              </span>
            </div>
          </form>
        </div>
      </div>

</section>