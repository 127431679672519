import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { baseUrl } from "src/app/config";

@Injectable({
    providedIn: "root"
})
export class EstadosService {    
    private url = baseUrl;
    constructor(private http: HttpClient) { }

    getEstados(form: FormData): Observable<any> {
        return this.http.post<any>(`${this.url}/Estados`, form);
    }

    insertEstado(form: FormData): Observable<any> {
        return this.http.post(`${this.url}/InsertEstado`, form);
    }

    updateEstado(form: FormData): Observable<any> {
        return this.http.post(`${this.url}/UpdateEstado`, form);
    }

    deleteEstado(form: FormData): Observable<any> {
        return this.http.post(`${this.url}/DeleteEstado`, form);
    }
    
}