import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common'
import { LanguagueService } from 'src/app/shared/helpers/language.service';
import { PedidoPreviewService } from '../pedido-preview/pedido-preview.service';

@Component({
  selector: 'app-notfound',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.scss']
})
export class NotfoundComponent implements OnInit {

  urlLogoReitech: string;
  constructor(
    private location: Location,
    private language: LanguagueService,
    private pedidoPreviewService: PedidoPreviewService
  ) {
    this.language.globalize();
  }

  ngOnInit() {
    this.urlLogoReitech = this.pedidoPreviewService.getUrlWs() + "/logo/glasscad_bw.png";
  }

  goBack() {
    this.location.back()
  }

}
