import { Component, OnInit, ViewChild, ChangeDetectorRef, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { sleep, calcMinWidthToDropDownMenu, isIntegerNumber } from 'src/app/shared/helpers/util';
import { map } from 'rxjs/operators';
import { EditorService } from './editor.service';
import { RequestCallbackService } from 'src/app/shared/helpers/request-callback.service';
import { BibliotecaService } from '../biblioteca/biblioteca.service';
import { Grupo } from 'src/app/shared/models/Biblioteca';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import * as $ from "jquery";
import "devextreme/integration/jquery";
import { CURRENCY_SYMBOL, CONFIG_EXIBIR_TODAS_FORMULAS, TIPO_ITEM_CONJUNTO_FERRAGEM, TIPO_ITEM_CONJUNTO_VIDRO, TIPO_ITEM_CONJUNTO_SHAPE, CONTEXT_MENU_ITEM_ADD_FERRAGEM, CONTEXT_MENU_ITEM_ESPELHAR_VERTICAL, CONTEXT_MENU_ITEM_ESPELHAR_HORIZONTAL, CONTEXT_MENU_ITEM_EDIT_ESQUADRO, CONTEXT_MENU_ITEM_EDIT_CANTOS, CONTEXT_MENU_ITEM_ADD_RECORTES, CONTEXT_MENU_ITEM_EDIT_RECORTES, CONTEXT_MENU_ITEM_DELETE_RECORTES, CONTEXT_MENU_ITEM_EDIT_FERRAGEM, CONTEXT_MENU_ITEM_DELETE_FERRAGEM, TIPO_ITEM_CONJUNTO_FURO, TIPO_ITEM_CONJUNTO_ARVORE, CONFIG_OCULTAR_CUSTOS, TIPO_ITEM_COTA_ESCRITA, CONTEXT_MENU_ITEM_EDIT_ESCRITA, CONTEXT_MENU_ITEM_DELETE_ESCRITA, CONTEXT_MENU_ITEM_ADD_ESCRITA, CONFIG_MOEDA_PADRAO } from 'src/app/shared/helpers/constants';
import { PerfisAcessoriosComponent } from '../perfis-acessorios/perfis-acessorios.component';
import { ResumoTecnicoComponent } from '../resumo-tecnico/resumo-tecnico.component';
import { Observable } from 'rxjs/internal/Observable';
import { LazyDataSource } from 'src/app/shared/models/LazyDataSource';
import { LazySelectService } from 'src/app/shared/helpers/lazy-select.service';
import { LanguagueService } from 'src/app/shared/helpers/language.service';
import { ToastService } from 'src/app/shared/helpers/toast.service';
import { FinanceiroService } from 'src/app/shared/services/financeiro.service';
import { Plano } from 'src/app/shared/models/Plano';
import { FILTRO_TODOS } from '../biblioteca/biblioteca.component';

const ID_GRUPO_PROPRIO = -2;

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})
export class EditorComponent implements OnInit {

  private ped_orca_obs: Observable<string>;
  private grupo_obs: Observable<string>;
  ped_orca: number;
  private codigoProjeto: number;
  loading: boolean = false;
  loadingManualValue: boolean = false;
  loadingPainted: boolean = false;
  loadingArvore: boolean = false;
  conjunto: any;
  dataSourceCoresFer: any = [];
  dataSourceCoresPer: any = [];
  dataSourceVidros: LazyDataSource;
  dialogBibliotecaVisible: boolean = false;
  dataSourceLibraryGroup: Grupo[] = [];
  private selectedLibraryCode: number = 0;
  popupDeleteVisible: boolean = false;
  popupPedidoVisible: boolean = false;
  dataSourceConjuntoPedido: any;
  tabs: any[] = [];
  tipoItemFerragem: number;

  // region tarefa #30053
  showSaveAndBackButton: boolean;
  private qtde_pecas_edit: number;
  private cd_produt_edit: number;
  private largura_edit: number;
  private altura_edit: number;
  titleDialogPedido: string;
  showSaveAndBackConfirmButton: boolean;
  // end region tarefa #30053
  currency_symbol: string = CURRENCY_SYMBOL;
  currency_format: string = this.currency_symbol.concat("#,##0.##");

  @ViewChild(PerfisAcessoriosComponent)
  perfisAcessoriosComponent: PerfisAcessoriosComponent

  @ViewChild(ResumoTecnicoComponent)
  resumoTecnicoComponent: ResumoTecnicoComponent

  // tarefa #31617
  descriptionTooltipVisible: boolean = false;

  //tarefa #31658
  private currentTimeStampEvent: number;

  //tarefa #31658
  private cssClassVlVenda: string;

  //tarefa #32482
  private displayAllFormulas: boolean;

  itemsArvore = [];

  popupItemFerragemVisible: boolean;
  popupDeleteItemFerragemVisible: boolean;
  popupItemFerragem: any;

  popupItemVidroVisible: boolean;
  popupDeleteItemVidroVisible: boolean;
  popupItemVidro: any;

  popupItemRecorteAlvenariaVisible: boolean;
  popupDeleteItemRecorteAlvenariaVisible: boolean;
  popupRecorteAlvenaria: any;

  popupItemEscritaVisible: boolean;
  popupDeleteItemEscritaVisible: boolean;
  popupEscrita: any;

  dataSourceFerragens: any[] = [];

  setContextMenuItems: any[] = [];
  private setContextMenuAllItems: any[] = [];
  private selectedGlassItemId: number;
  // tarefa #33716
  private selectedGlassId: number;

  popupItemEdicaoEsquadroVisible: boolean;
  popupItemEdicaoEsquadro: any;

  popupItemEdicaoCantosVisible: boolean;

  planoEhPremium: boolean;
  planoEhLight: boolean;
  ocultarCustos: boolean;

  constructor(
    private service: EditorService,
    private language: LanguagueService,
    private router: Router,
    private route: ActivatedRoute,
    private toast: ToastService,
    private requestCallbackService: RequestCallbackService,
    private bibliotecaService: BibliotecaService,
    private changeDetectorRefs: ChangeDetectorRef,
    private lazyService: LazySelectService,
    private financeiroService: FinanceiroService,
    private ngZone: NgZone
  ) {
    this.language.globalize();
    this.ped_orca_obs = this.route.paramMap.pipe(
      map(param => param.get("pedido"))
    );

    this.grupo_obs = this.route.paramMap.pipe(
      map(param => param.get("grupo"))
    );

    this.resetConjunto();
    this.setupDataSourceConjuntoPedido();
    this.getConfigDisplayFormulas();
    this.getConfigMoedaPadrao();
    this.tipoItemFerragem = TIPO_ITEM_CONJUNTO_FERRAGEM;
    this.getPlano();
    this.getConfigOcultarCustos();
  }

  private getPlano() {
    this.financeiroService.getPlano().subscribe(
      (res: ApiResponse<{ success: boolean, message: string }>) => {
        this.requestCallbackService.okResponse(res,
          () => {
            const codigoPlano = res.data.cd_plano;
            this.planoEhPremium = codigoPlano == Plano.Premium;
            this.planoEhLight = codigoPlano == Plano.Light;
          });
      },
      error => {
        this.requestCallbackService.failResponse(error);
      }
    );
  }

  private setupDataSourceConjuntoPedido() {
    this.dataSourceConjuntoPedido = {
      ds_vidro: "",
      qtd_peca: 1 // POR DEFAULT INICIALIZA COM VALOR 1
    }
  }

  private resetConjunto() {
    this.conjunto = {
      cor_vidro: 0,
      cor_ferragem: 0,
      cor_perfil: 0,
      vlm2vidro: 0,
      st_calculoManual: null, // tarefa #32402
      valorTotal: 0,
      urlImagem: "",
      larg: 0,
      alt: 0,
      valorTotalM2: 0,
      formulas: [],
      proprio: false,
      valorCustoTotal: 0,
      available: false,
      vlm2vidroManual: 0,
      valorTotalSemCalculo: 0,
      st_folgas:false,
      qtdeServs:0,
      qtdeFers:0,
    }
  }

  ngOnInit() {
    this.ped_orca_obs.subscribe(cd_pedido => {
      if (!cd_pedido || !isIntegerNumber(cd_pedido)) {
        this.redirectToPedidos();
      }
      this.ped_orca = parseInt(cd_pedido, 10);
    });

    this.grupo_obs.subscribe(cd_projeto => {
      if (!cd_projeto || !isIntegerNumber(cd_projeto)) {
        this.redirectToBiblioteca();
      }
      this.codigoProjeto = parseInt(cd_projeto, 10);
    });

    this.pedidoPendenteByEmpresa();

    // region tarefa #30053
    this.qtde_pecas_edit = parseInt(this.route.snapshot.paramMap.get('qtde_pecas'), 10);
    this.cd_produt_edit = parseInt(this.route.snapshot.paramMap.get('cd_produto'), 10);
    this.largura_edit = parseInt(this.route.snapshot.paramMap.get('largura'), 10);
    this.altura_edit = parseInt(this.route.snapshot.paramMap.get('altura'), 10);

    if (this.qtde_pecas_edit && this.cd_produt_edit && this.largura_edit && this.altura_edit) {
      this.showSaveAndBackButton = true;
    }
    // end region tarefa #30053

    if (this.codigoProjeto) {
      this.loadGCADPainted(this.showSaveAndBackButton);
      this.readTopCorfer();
      this.setupDataSourceVidros();
    }

    // inicializa as tabs..
    this.setupTabs();
  }

  showLoad() {
    sleep(100).then(() => {
      this.loading = true;
    });
  }

  hideLoad() {
    sleep(100).then(() => {
      this.loading = false;
    });
  }

  loadEvent(showLoad: boolean) {
    this.loadingManualValue = showLoad
  }

  showLoadManualValue() {
    this.loadingManualValue = true;
  }

  hideLoadManualValue() {
    this.loadingManualValue = false;
  }

  showLoadPainted() {
    this.loadingPainted = true;
  }

  hideLoadPainted() {
    this.loadingPainted = false;
    this.popupItemEdicaoEsquadroVisible = false;
    this.popupItemEdicaoCantosVisible = false;
    this.popupItemRecorteAlvenariaVisible = false;
    this.popupDeleteItemRecorteAlvenariaVisible = false;
  }

  showLoadArvore() {
    this.loadingArvore = true;
  }

  hideLoadArvore() {
    this.loadingArvore = false;
  }

  private setupTabs() {
    this.tabs = [];
    sleep(400).then(() => {
      this.tabs = [{
        "ID": 1,
        "Name": this.language.translateMessage("conjunto")
      }, {
        "ID": 2,
        "Name": this.language.translateMessage("perfis_acessorios")
      }, {
        "ID": 3,
        "Name": this.language.translateMessage("resumo_tecnico")
      }];
    });
  }

  private redirectToPedidos() {
    this.router.navigate(["/pagina/pedidos"]);
  }

  public redirectToBiblioteca() {
    this.router.navigate(["/pagina/biblioteca/" + this.ped_orca]);
  }

  redirectToPedido() {
    this.router.navigate(["/pagina/pedidos/itens/" + this.ped_orca]);
  }

  // tarefa #30053
  // isEditingOrder define se está editando um conjunto que já pertence a um pedido
  // por default valor false
  private buildRequestForm(isCalculating: boolean = false, isEditingOrder: boolean = false, p_obj_esp: any = null, p_lista_vidro_fora_esquadro: any = null,
    p_formas: any = null, p_escritas: any = null,
    p_tipoAtualizar: any = null, p_conteudoAtualizar: any = null): FormData {

    let p_tipoVidro = {
      tipo_vidro: isCalculating ? this.conjunto.cor_vidro : isEditingOrder ? this.cd_produt_edit : null,
      tipo_cor: isCalculating ? this.conjunto.cor_ferragem : null,
      tipo_corperfil: isCalculating ? this.conjunto.cor_perfil : null,
      St_Auto: null,
      p_quant: isEditingOrder ? this.qtde_pecas_edit : 0,
      valor_vidro: isCalculating && this.conjunto.st_calculoManual ? this.conjunto.vlm2vidroManual : this.conjunto.vlm2vidro,
      st_calculoManual: isCalculating ? this.conjunto.st_calculoManual : null,
      valor_total: isCalculating && this.conjunto.st_calculoManual ? this.conjunto.valorTotalSemCalculo : this.conjunto.valorTotal,
      st_folgas: false,
      larg: isCalculating ? this.conjunto.larg : isEditingOrder ? this.largura_edit : 0,
      alt: isCalculating ? this.conjunto.alt : isEditingOrder ? this.altura_edit : 0
    }

    let p_origem_gcad = {
      p_origem_gcad: this.codigoProjeto + ".gcad",
      p_operation: isEditingOrder ? 2 : 1,
      p_origem: isEditingOrder ? 2 : 1,
      p_gcad_temp: isCalculating ? this.conjunto.p_gcad : ""
    };

    this.getFormulas();
    let p_formulas = isCalculating ? this.conjunto.formulas : {};

    if (p_obj_esp == null) {
      p_obj_esp = {
        item_espelho_horizontal: false,
        item_espelho_vertical: false,
      };
    }

    if (p_lista_vidro_fora_esquadro == null) {
      p_lista_vidro_fora_esquadro = [];
    }

    if (p_formas == null) {
      p_formas = {};
    }
    if (p_escritas == null) {
      p_escritas = {};
    }

    let form = {
      p_tipoVidro: JSON.stringify(p_tipoVidro),
      p_origem_gcad: JSON.stringify(p_origem_gcad),
      p_formulas: JSON.stringify(p_formulas),
      p_obj_esp: p_obj_esp,
      p_lista_vidro_fora_esquadro: JSON.stringify(p_lista_vidro_fora_esquadro),
      p_formas: JSON.stringify(p_formas),
      p_escritas: JSON.stringify(p_escritas),
      p_grupo: isCalculating ? this.selectedLibraryCode : 0,
      p_descricao_conj: isCalculating ? this.conjunto.descricao : null,
      p_tipoAtualizar: p_tipoAtualizar,
      p_conteudoAtualizar: p_conteudoAtualizar
    };

    let objJson = JSON.stringify(form);
    const formData = new FormData();
    formData.append("obj", objJson);
    return formData;
  }

  private getListGCADPainted(formData, isSavingLibrary = false, isSavingChanges = false) {
    this.showLoadPainted();
    this.service.getListGCADPainted(formData).subscribe(
      (res: ApiResponse<{ success: boolean, message: string }>) => {
        this.hideLoadPainted();
        this.requestCallbackService.okResponse(res,
          () => {
            let response = res.data;
            if (response.erro == "0") {

              this.conjunto = response;
              this.conjunto.available = true;
              this.conjunto.vlm2vidroManual = this.conjunto.vlm2vidro;
              let listaItensPosicionados = JSON.parse(this.conjunto.listaItensPosicionados)
              this.conjunto.urlImagem = listaItensPosicionados.Imagem + '?' + (new Date()).getTime();
              this.conjunto.itens = listaItensPosicionados.itens;

              sleep(500).then(() => {
                this.setupSetContextMenuItens();
                this.setupCanvas();
              });

              sleep(500).then(() => {
                this.buildArvore();

                if (this.service.ferragensCache.length == 0) {
                  this.buscarFerragens();
                } else {
                  this.dataSourceFerragens = this.service.ferragensCache;
                }
              });

              this.defineCssClassVlVenda(this.conjunto.st_calculoManual);

              sleep(500).then(() => {
                this.buildFormulas();
              });

              if (isSavingLibrary) {
                this.saveGcadInLib();
              }

              if (isSavingChanges) {
                this.saveGcadInLib(true);
              }

            } else {
              const msg = this.language.translateMessage(response.erro);
              this.toast.errorToast(msg);
            }
          });
      }, error => {
        this.hideLoadPainted();
        this.requestCallbackService.failResponse(error, "falha_buscar_conjunto");
      }
    );
  }

  private loadGCADPainted(isEditingOrder: boolean = false) {
    let formData = this.buildRequestForm(false, isEditingOrder);
    this.getListGCADPainted(formData);
  }

  readTopCorfer() {
    let requestJson = JSON.stringify(this.getDefaultRequest());
    const formData = new FormData();
    formData.append("requestStr", requestJson);

    this.service.readTopCorfer(formData).subscribe(
      (res: ApiResponse<{ success: boolean, message: string }>) => {
        this.requestCallbackService.okResponse(res,
          () => {
            let cores: any[] = res.data.corfer;
            cores.forEach(element => {
              element.ds_etiqueta = element.ds_etiqueta.trim();
            });
            this.dataSourceCoresFer = cores.filter(a => a.st_ferragem);
            this.dataSourceCoresPer = cores.filter(a => a.st_perfil);
          });
      }, error => {
        this.requestCallbackService.failResponse(error, "falha_buscar_corfer");
      }
    );
  }

  private setupDataSourceVidros() {

    this.dataSourceVidros = this.lazyService.setupLazyDataSource(
      "cd_codigo",
      "ds_descricao",
      (loadOptions) => {
        if (loadOptions.filter) {
          loadOptions.filter[0] = this.translateGlassFilter(loadOptions.filter[0]);
        }
        if (loadOptions.searchValue) {
          //tarefa #29706
          let filter = [["ds_descricao", loadOptions.searchOperation, loadOptions.searchValue], "or", ["ds_codigo", loadOptions.searchOperation, loadOptions.searchValue]]
          loadOptions.filter = filter;
        }

        const formData = new FormData();
        formData.append("loadOptions", JSON.stringify(loadOptions));
        return this.loadVidros(formData);
      },
      (formData) => {
        return this.loadVidroByKey(formData);
      }
    );
  }


  private loadVidros(formData: FormData): Promise<any> {
    return this.requestCallbackService.load(
      this.service.getVidrosLazy(formData),
      (response) => {
        const vidros = response.data.vidros.data;
        vidros.forEach(vidro => {
          this.translateGlassProperties(vidro);
        });
        return response.data.vidros;
      },
      "falha_vidros"
    );
  }

  private loadVidroByKey(formData: FormData): Promise<any> {
    return this.requestCallbackService.load(
      this.service.getVidrosLazy(formData),
      (response) => {
        let vidro = response.data.vidros.data[0];
        return this.translateGlassProperties(vidro);
      },
      "falha_vidros",
      true
    );
  }

  // TRADUZ AS PROPRIEDADES PARA EXIBIÇÃO (FRONT-END)
  private translateGlassProperties(vidro): any {
    if (vidro) {
      vidro.cd_vidro = vidro.cd_codigo;
      vidro.ds_vidro = vidro.ds_descricao;
      vidro.vl_valor = vidro.vl_venda;
    }
    return vidro;
  }

  // TRADUZ AS PROPRIEDADES PARA APLICAR FILTRO (BACK-END)
  private translateGlassFilter(searchExpr) {
    switch (searchExpr) {
      case "ds_vidro": {
        return "ds_descricao";
      }
      case "cd_vidro": {
        return "cd_codigo";
      }
      default: {
        return searchExpr;
      }
    }
  }

  private getDefaultRequest() {
    return {
      take: 0,
      skip: 0,
      page: 1,
      pageSize: 0
    };
  }

  onVidroSelected(e) {

    if (e.selectedItem && this.conjunto.cor_vidro == e.selectedItem.cd_vidro) {
      this.dataSourceConjuntoPedido.ds_vidro = e.selectedItem.ds_vidro;
      return;
    }

    if (e.selectedItem) {
      //# 31240
      //this.conjunto.vlm2vidro = e.selectedItem.vl_valor;
      this.conjunto.cor_vidro = e.selectedItem.cd_vidro;
      this.dataSourceConjuntoPedido.ds_vidro = e.selectedItem.ds_vidro;
    } else {
      this.conjunto.vlm2vidro = 0;
      this.conjunto.cor_vidro = 0;
      this.dataSourceConjuntoPedido.ds_vidro = "";
    }

    // REGRA PARA RECALCULAR VALORES APÓS UM VIDRO SER SELECIONADO
    this.saveConjunto();
  }

  onFerragemSelected(e) {

    if (e.selectedItem && this.conjunto.cor_ferragem == e.selectedItem.cd_codigo) {
      return;
    }

    if (e.selectedItem) {
      this.conjunto.cor_ferragem = e.selectedItem.cd_codigo;
    } else {
      this.conjunto.cor_ferragem = 0;
    }

    // REGRA PARA RECALCULAR VALORES APÓS UMA FERRAGEM SER SELECIONADA
    this.saveConjunto();
  }

  onPerfilSelected(e) {

    if (e.selectedItem && this.conjunto.cor_perfil == e.selectedItem.cd_codigo) {
      return;
    }

    if (e.selectedItem) {
      this.conjunto.cor_perfil = e.selectedItem.cd_codigo;
    } else {
      this.conjunto.cor_perfil = 0;
    }

    // REGRA PARA RECALCULAR VALORES APÓS UMA FERRAGEM SER SELECIONADA
    this.saveConjunto();
  }

  saveConjunto(p_obj_esp: any = null, p_lista_vidro_fora_esquadro: any = null, p_formas: any = null, p_escritas: any = null,
    p_tipoAtualizar: any = null, p_conteudoAtualizar: any = null) {
    // REGRA PARA TORNAR OBRIGATÓRIO PREENCHIMENTO VALOR TOTAL QUANDO
    // O CHECKBOX ESTIVER MARCADO.
    let isCalcManual = this.conjunto.st_calculoManual
    if (isCalcManual && this.conjunto.valorTotalSemCalculo == 0) {
      this.toast.warningToast("informe_valor_total");
      return;
    }

    // parametro this.showSaveAndBackButton define se o conjunto está no modo edição
    // caso esteja a request deve ser construída de maneira diferente...
    let formData = this.buildRequestForm(true, this.showSaveAndBackButton, p_obj_esp, p_lista_vidro_fora_esquadro, p_formas, p_escritas,
      p_tipoAtualizar, p_conteudoAtualizar);
    this.getListGCADPainted(formData);
  }

  buildArvore() {
    try {
      const vidros = [];
      this.conjunto.listaVidros.forEach((vidro, index) => {

        const ferragens = [];
        if (vidro.ferragem) {
          vidro.ferragem.forEach((fer, index) => {
            ferragens.push({
              id: vidro.id == "" ? index : vidro.id + "_" + fer.id,
              codigo: fer.id,
              text: fer.nome,
              tipo: TIPO_ITEM_CONJUNTO_FERRAGEM,
              expanded: false
            });
          });
        };

        vidros.push({
          id: "-1_" + vidro.id == "" ? index : vidro.id,
          codigo: vidro.id,
          text: this.language.translateMessage("vidro") + " " + vidro.nome,
          expanded: false,
          tipo: TIPO_ITEM_CONJUNTO_VIDRO,
          items: ferragens
        });
      });

      const treeItem = {
        id: -1,
        text: this.language.translateMessage("no_principal_arvore"),
        expanded: false,
        tipo: TIPO_ITEM_CONJUNTO_ARVORE,
        items: vidros
      };

      this.itemsArvore = [treeItem];
    } catch (ex) {
      const msg = this.language.translateMessage("falha_gerar_arvore");
      this.toast.errorToast(msg, ex);
    }
  }

  handleAddItemArvore(arvoreItem) {
    switch (arvoreItem.tipo) {
      case TIPO_ITEM_CONJUNTO_ARVORE: {
        this.popupItemVidro = { isNew: true, ds_nome: this.language.translateMessage("novo_vidro"), width: 1000, height: 1000 };
        this.popupItemVidroVisible = true;
        // this.populateFerragemItem(arvoreItem.codigo);
        break;
      }
      case TIPO_ITEM_CONJUNTO_VIDRO: {
        this.popupItemFerragem = { isNew: true, id: arvoreItem.codigo };
        this.popupItemFerragemVisible = true;
        break;
      }
      default: {
        this.toast.warningToast("item_vidro_nao_mapeado");
      }
    }
  }

  handleEditItemArvore(arvoreItem) {
    switch (arvoreItem.tipo) {
      case TIPO_ITEM_CONJUNTO_FERRAGEM: {
        this.popupItemFerragemVisible = true;
        this.populateFerragemItem(arvoreItem.codigo);
        break;
      }
      case TIPO_ITEM_CONJUNTO_VIDRO: {
        this.popupItemVidroVisible = true;
        this.popupItemVidro = this.findItemOnConjunto(arvoreItem.codigo);
        this.popupItemVidro.isNew = false;
        this.popupItemVidro.ds_nome = arvoreItem.text;
        this.popupItemVidro.st_form_larg = this.popupItemVidro.Formula_W && this.popupItemVidro.Formula_W.trim() != "";
        this.popupItemVidro.st_form_alt = this.popupItemVidro.Formula_H && this.popupItemVidro.Formula_H.trim() != "";
        this.popupItemVidro.st_form_x = this.popupItemVidro.Formula_X && this.popupItemVidro.Formula_X.trim() != "";
        this.popupItemVidro.st_form_y = this.popupItemVidro.Formula_Y && this.popupItemVidro.Formula_Y.trim() != "";
        break;
      }
      default: {
        this.toast.warningToast("item_vidro_nao_mapeado");
      }
    }
  }

  onValueChangedFormulaLargVidro(e) {
    const value = e.value;
    this.popupItemVidro.st_form_larg = value && value.trim() != "";
  }

  onValueChangedFormulaAltVidro(e) {
    const value = e.value;
    this.popupItemVidro.st_form_alt = value && value.trim() != "";
  }

  onValueChangedFormulaXVidro(e) {
    const value = e.value;
    this.popupItemVidro.st_form_x = value && value.trim() != "";
  }

  onValueChangedFormulaYVidro(e) {
    const value = e.value;
    this.popupItemVidro.st_form_y = value && value.trim() != "";
  }

  onValueChangedFormulaXFerragem(e) {
    const value = e.value;
    this.popupItemFerragem.st_form_x = value && value.trim() != "";
  }

  onValueChangedFormulaYFerragem(e) {
    const value = e.value;
    this.popupItemFerragem.st_form_y = value && value.trim() != "";
  }

  private populateFerragemItem(idFerragem: number) {
    const itemFerragem = this.findItemOnConjunto(idFerragem);
    this.popupItemFerragem = itemFerragem;
    this.popupItemFerragem.st_form_x = this.popupItemFerragem.Formula_X && this.popupItemFerragem.Formula_X.trim() != "";
    this.popupItemFerragem.st_form_y = this.popupItemFerragem.Formula_Y && this.popupItemFerragem.Formula_Y.trim() != "";

    const ferragem = this.dataSourceFerragens.find(item => item.DsCodigo.trim() == itemFerragem.ds_text.trim());
    this.popupItemFerragem.cd_ferragem = ferragem ? ferragem._codigo : 0;
  }

  private findItemOnConjunto(id: any): any {
    const itens: any[] = this.conjunto.itens;
    return itens.find(item => item.id == id);
  }

  private findItemOnConjuntoByParent(id: number, id_parent: number): any {
    const itens: any[] = this.conjunto.itens;
    return itens.find(item => item.id == id && item.id_parent == id_parent);
  }

  salvarFerragemPopup() {
    if (!this.popupItemFerragem.cd_ferragem) {
      this.toast.warningToast("selecione_ferragem");
      return;
    }
    if (this.popupItemFerragem.isNew) {
      this.addFerragemWS();
    } else {
      this.setarValorFerragemWS();
    }
  }

  addFerragemWS() {
    const formData = new FormData();
    const objFerragemRequest = this.buildFerragemToRequest();
    this.showLoadArvore();
    var jsonDados = JSON.stringify(objFerragemRequest);
    formData.append("obj", JSON.stringify(objFerragemRequest));
    this.saveConjunto(null, null, null, null, 2, jsonDados);
    this.popupItemFerragemVisible = false;
    this.hideLoadArvore();
    /*this.service.adicionarFerragem(formData).subscribe(
      (res: ApiResponse<{ success: boolean, message: string }>) => {
        this.hideLoadArvore();
        this.requestCallbackService.okResponse(res,
          () => {
            this.popupItemFerragemVisible = false;
            this.toast.successToast("msg_ferragem_adicionada");
            this.saveConjunto();
          });
      }, (error) => {
        this.hideLoadArvore();
        this.requestCallbackService.failResponse(error);
      });
      */
  }

  setarValorFerragemWS() {
    const formData = new FormData();
    const objFerragemRequest = this.buildFerragemToRequest();
    this.showLoadArvore();
    var jsonDados = JSON.stringify(objFerragemRequest);
    this.saveConjunto(null, null, null, null, 4, jsonDados);
    this.popupItemFerragemVisible = false;
    this.hideLoadArvore();
    /*
        formData.append("obj", JSON.stringify(objFerragemRequest));
        this.service.atualizarFerragem(formData).subscribe(
          (res: ApiResponse<{ success: boolean, message: string }>) => {
            this.hideLoadArvore();
            this.requestCallbackService.okResponse(res,
              () => {
                this.popupItemFerragemVisible = false;
                this.toast.successToast("edit");
                this.saveConjunto();
              });
          }, (error) => {
            this.hideLoadArvore();
            this.requestCallbackService.failResponse(error);
          });
          */
  }

  private buildFerragemToRequest() {
    return {
      p_gcad: this.conjunto.p_gcad,
      p_codnome: this.popupItemFerragem.id,
      p_jsonFerr: {
        xfer: this.popupItemFerragem.x + "",
        yfer: this.popupItemFerragem.y + "",
        xferform: this.popupItemFerragem.Formula_X ? this.popupItemFerragem.Formula_X : "",
        yferform: this.popupItemFerragem.Formula_Y ? this.popupItemFerragem.Formula_Y : "",
        codigofer: this.popupItemFerragem.ds_text ? this.popupItemFerragem.ds_text.trim() : "",
        codigoFerragem: this.popupItemFerragem.cd_ferragem,
        efer: this.popupItemFerragem.Esquerda ? this.popupItemFerragem.Esquerda : false,
        tfer: this.popupItemFerragem.Topo ? this.popupItemFerragem.Topo : false,
        nomeFerragem: this.popupItemFerragem.nomeFerragem ? this.popupItemFerragem.nomeFerragem : false,
        legendaVertical: this.popupItemFerragem.legendaVertical ? this.popupItemFerragem.legendaVertical : false,
        legendaHorizontal: this.popupItemFerragem.legendaHorizontal ? this.popupItemFerragem.legendaHorizontal : false,
        st_orca: this.popupItemFerragem.st_orcar ? this.popupItemFerragem.st_orcar : false,
      }
    };
  }

  handleDeleteItemArvore(arvoreItem) {
    switch (arvoreItem.tipo) {
      case TIPO_ITEM_CONJUNTO_FERRAGEM: {
        this.popupDeleteItemFerragemVisible = true;
        this.populateDeleteFerragemItem(arvoreItem.codigo);
        break;
      }
      case TIPO_ITEM_CONJUNTO_VIDRO: {
        this.popupDeleteItemVidroVisible = true;
        this.popupItemVidro = this.findItemOnConjunto(arvoreItem.codigo);
        this.popupItemVidro.ds_nome = arvoreItem.text;
        break;
      }
      default: {
        this.toast.warningToast("item_vidro_nao_mapeado");
      }
    }
  }

  private populateDeleteFerragemItem(idFerragem: number) {
    const itemFerragem = this.findItemOnConjunto(idFerragem);
    this.popupItemFerragem = itemFerragem;
    this.popupItemFerragem.ds_text = itemFerragem.ds_text ? itemFerragem.ds_text.trim() : "";
  }

  fecharPopupDeletarFerragem() {
    this.popupDeleteItemFerragemVisible = false;
  }

  deletarFerragemWS() {
    const formData = new FormData();
    let obj = {
      p_gcad: this.conjunto.p_gcad,
      p_codnome: this.popupItemFerragem.id
    };
    formData.append("obj", JSON.stringify(obj));
    this.showLoadArvore();
    var jsonDados = JSON.stringify(obj);
    this.saveConjunto(null, null, null, null, 3, jsonDados);
    this.hideLoadArvore();
    this.fecharPopupDeletarFerragem();
    /*
    this.service.excluirFerragem(formData).subscribe(
      (res: ApiResponse<{ success: boolean, message: string }>) => {
        this.hideLoadArvore();
        this.requestCallbackService.okResponse(res,
          () => {
            this.fecharPopupDeletarFerragem();
            this.toast.successToast("deleted");
            this.saveConjunto();
          });
      }, (error) => {
        this.hideLoadArvore();
        this.requestCallbackService.failResponse(error);
      });
      */
  }

  onValueChangedFerragem(e) {
    const ferragemSelecionada = this.dataSourceFerragens.find(item => item._codigo == e.value);
    this.popupItemFerragem.ds_text = ferragemSelecionada ? ferragemSelecionada.DsCodigo.trim() : "";
    this.popupItemFerragem.x = ferragemSelecionada ? ferragemSelecionada.X : 0;
    this.popupItemFerragem.y = ferragemSelecionada ? ferragemSelecionada.Y : 0;
    this.popupItemFerragem.st_form_x = ferragemSelecionada ? ferragemSelecionada.st_form_x : false;
    this.popupItemFerragem.st_form_y = ferragemSelecionada ? ferragemSelecionada.st_form_y : false;
  }

  onShownPopupFerragem(e) {
    $('#selectBoxFerragem').focus();
  }
  onShownPopupEscrita(e) {
    $('#selectBoxEscrita').focus();
  }

  salvarVidroPopup() {
    this.setarValorVidroWS();
  }

  setarValorVidroWS() {
    const isNew = this.popupItemVidro.isNew;
    let obj = {
      p_id: isNew ? "" : this.popupItemVidro.id,
      is_new: isNew,
      p_larg: this.popupItemVidro.width,
      p_alt: this.popupItemVidro.height,
      p_x: this.popupItemVidro.x ? this.popupItemVidro.x : 0,
      p_y: this.popupItemVidro.y ? this.popupItemVidro.y : 0,
      p_formula_X: this.popupItemVidro.Formula_X ? this.popupItemVidro.Formula_X : "",
      p_formula_Y: this.popupItemVidro.Formula_Y ? this.popupItemVidro.Formula_Y : "",
      p_formula_Larg: this.popupItemVidro.Formula_W ? this.popupItemVidro.Formula_W : "",
      p_formula_Alt: this.popupItemVidro.Formula_H ? this.popupItemVidro.Formula_H : "",
      p_horizontal: this.popupItemVidro.Horizontal ? this.popupItemVidro.Horizontal : 0,
      p_vertical: this.popupItemVidro.Vertical ? this.popupItemVidro.Vertical : 0,
      p_gcad: this.conjunto.p_gcad,
    };
    const formData = new FormData();
    formData.append("obj", JSON.stringify(obj));
    this.showLoadArvore();
    this.service.atualizarVidro(formData).subscribe(
      (res: ApiResponse<{ success: boolean, message: string }>) => {
        this.hideLoadArvore();
        this.requestCallbackService.okResponse(res,
          () => {
            this.popupItemVidroVisible = false;
            this.toast.successToast("edit");
            this.saveConjunto();
          });
      }, (error) => {
        this.hideLoadArvore();
        this.requestCallbackService.failResponse(error);
      });
  }

  fecharPopupDeletarVidro() {
    this.popupDeleteItemVidroVisible = false;
  }

  deletarVidroWS() {
    const formData = new FormData();
    let obj = {
      p_id: this.popupItemVidro.id,
      p_gcad: this.conjunto.p_gcad
    };
    formData.append("obj", JSON.stringify(obj));
    this.showLoadArvore();
    this.service.excluirVidro(formData).subscribe(
      (res: ApiResponse<{ success: boolean, message: string }>) => {
        this.hideLoadArvore();
        this.requestCallbackService.okResponse(res,
          () => {
            this.fecharPopupDeletarVidro();
            this.toast.successToast("deleted");
            this.saveConjunto();
          });
      }, (error) => {
        this.hideLoadArvore();
        this.requestCallbackService.failResponse(error);
      });
  }

  buscarFerragens() {
    const formData = new FormData();
    let obj = {
      p_gcad: this.conjunto.p_gcad,
      return_value: true
    };
    formData.append("obj", JSON.stringify(obj));
    this.showLoadArvore();
    this.service.buscarFerragens(formData).subscribe(
      (res: ApiResponse<{ success: boolean, message: string }>) => {
        this.hideLoadArvore();
        this.requestCallbackService.okResponse(res,
          () => {
            const ferragens = res.data.listaFerragens;
            this.service.ferragensCache = ferragens;
            this.dataSourceFerragens = ferragens;
          });
      }, (error) => {
        this.hideLoadArvore();
        this.requestCallbackService.failResponse(error);
      });
  }

  buildFormulas() {

    // remove toda a arvore de formulas..
    $("#formulas").empty();

    // cria conforme response do server..
    this.conjunto.formulas.forEach((formula, index) => {

      // tarefa #32482
      if (!this.displayAllFormulas && !formula.Visivel) {
        return;
      }

      // tarefa #29994
      if (formula.Atributos.length == 0) {
        // NESSA CONDIÇÃO DEVE CONSTRUIR UM INPUT
        let label = formula.Descricao;
        let labelId = formula.Codigo + index;
        let formatedValue = +formula.Valor;
        let elementId = formula.Codigo.replace(" ", "_");
        let elementInputId = formula.Codigo.replace(" ", "_") + "numberbox";

        let basicElement = this.buildBasicElement(labelId, label, elementId);
        $(basicElement).appendTo("#formulas");

        let htmlEl = $("<div>")
          .dxNumberBox({
            value: formatedValue,
            mode: "number",
            format: "###0",
            //readOnly: !this.planoEhPremium,
            min: 0,
            elementAttr: {
              id: elementInputId
            },
            onChange: (e) => {
              this.recalculateSet(e);
            }
          });
        let container = this.buildTooltipStructure(formula, htmlEl, elementId);

        $("#" + elementId).append(container);
      } else {
        // NESSA CONDIÇÃO DEVE CONSTRUIR UM SELECT (COMBOBOX)

        let label = formula.Descricao;
        let labelId = "attributes" + index;
        let formatedValue = formula.Valor;
        let elementId = "attribute" + index;
        let basicElement = this.buildBasicElement(labelId, label, elementId, false);
        $(basicElement).appendTo("#formulas");

        // tarefa #32482
        let itens = formula.Atributos;
        if (!this.displayAllFormulas) {
          itens = formula.Atributos.filter((atributo) => { return atributo.Visivel });
        }

        $("#" + elementId).dxSelectBox({
          //items: formula.Atributos,
          items: itens,
          displayExpr: "Descricao",
          valueExpr: "Valor",
          //disabled: !this.planoEhPremium,
          value: formatedValue,
          onValueChanged: (e) => {
            // AO ALTERAR O VALOR DO SELECT JÁ ALTERA O VALOR DA PROPRIEDADE
            this.conjunto.formulas[index].Valor = e.value;
            this.recalculateSet(e);
          },
          itemTemplate: (itemData, itemIndex) => {
            let elementId = itemData.Codigo.replace(" ", "_"); + itemIndex;
            let htmlEl = $("<span>")
              .attr("id", elementId)
              .text(itemData.Descricao);
            let container = this.buildTooltipStructure(itemData, htmlEl, elementId);
            return $(container);
          },
          fieldTemplate: (selectedItem) => {
            if (!selectedItem) {
              let htmlEl = $("<span>")
                .dxTextBox();
              return $(htmlEl);
            }
            let elementId = selectedItem.Codigo.replace(" ", "_") + "selectbox";
            let htmlEl = $("<span>")
              .dxTextBox({
                value: selectedItem.Descricao,
                elementAttr: {
                  id: elementId
                },
              });
            let container = this.buildTooltipStructure(selectedItem, htmlEl, elementId);
            return $(container);
          },
        });
      }
    });
  }

  // region tarefa #29994
  private buildTooltipStructure(item: any, htmlElTarget: JQuery<HTMLElement>, IdHtmlElTarget: string): JQuery<HTMLElement> {
    let container = $("<div>");

    let tooltipEl = $("<div>").dxTooltip({
      target: "#" + IdHtmlElTarget,
      showEvent: "mouseenter",
      hideEvent: "mouseleave",
      position: "left",
      contentTemplate: (element) => {
        let content = this.buildTooltipContent(item);
        element.html(content);
      }
    });

    container.append(htmlElTarget, tooltipEl);
    return container;
  }


  private buildTooltipContent(item: any): string {
    let codigo = this.language.translateMessage("codigo");
    let formula = this.language.translateMessage("formula");
    let valor = this.language.translateMessage("valor");
    let tooltipContent = "<div>";
    let formulaValue = item.FormulaVisivel ? item.FormulaVisivel : item.Formula_Visivel;
    tooltipContent = tooltipContent.concat("<div> " + codigo + ": " + item.Codigo + "</div>");
    tooltipContent = tooltipContent.concat("<div> " + formula + ": " + formulaValue + " </div>");
    tooltipContent = tooltipContent.concat("<div> " + valor + ": " + item.Valor + "</div>");
    tooltipContent = tooltipContent.concat("</div>");
    return tooltipContent.toString();
  }


  private buildBasicElement(labelId: string, label: string, elementId: string, pullLeft: boolean = true) {
    let cssClass = pullLeft ? "pull-left" : "";
    return '<div class="form-group"> ' +
      '<label for="' + labelId + '" class="' + cssClass + ' ">' + label + '</label>' +
      '<div id="' + labelId + '">' +
      '<div id="' + elementId + '" >' +
      '</div>' +
      '</div>' +
      '</div>';
  }
  // end region tarefa #29994

  getFormulas() {
    this.conjunto.formulas.forEach((formula) => {
      // tarefa #29994, #31798

      if (formula.Atributos.length == 0) {
        //tarefa #32482
        if (this.displayAllFormulas || formula.Visivel) {
          // NESSA CONDIÇÃO RECUPERA O VALOR DE UM INPUT
          let elementId = formula.Codigo.replace(" ", "_");
          let value = $("#" + elementId).find("input").val();
          if (value) {
            formula.Valor = value;
          }
        }
      }
    });
  }

  openDialogBiblioteca() {
    this.dialogBibliotecaVisible = true;
    const obj = {
      skip: 0,
      like: "null",
      cd_grupo: "0",
      cd_cliente: "0",
      st_folgas: FILTRO_TODOS,
      order: "",
    };
    const json = JSON.stringify(obj);
    this.showLoad();
    this.bibliotecaService.getGruposBiblioteca(json).subscribe(
      (res: ApiResponse<{ success: boolean, message: string }>) => {
        this.hideLoad();
        this.requestCallbackService.okResponse(res,
          () => {
            const gruposProprios: any[] = res.data.listaGruposBibliotecaPropria;
            gruposProprios.forEach(element => {
              if (element.cd_pai == 0) {
                element.cd_pai = ID_GRUPO_PROPRIO;
              }
            });
            const grupoProprio = new Grupo();
            grupoProprio.id = ID_GRUPO_PROPRIO;
            grupoProprio.expanded = true;
            grupoProprio.ds_grupo = this.language.translateMessage("meus_grupos");
            gruposProprios.push(grupoProprio);
            this.dataSourceLibraryGroup = gruposProprios;
          });
      }, error => {
        this.hideLoad();
        this.requestCallbackService.failResponse(error, "falha_buscar_grupo_bibliotecas");
      }
    );
  }

  onHideDialogStatus() {
    this.selectedLibraryCode = 0;
  }

  selectBibliotecaItem(e) {
    const cd_grupo = e.itemData.id;
    this.selectedLibraryCode = cd_grupo;
  }

  saveGCad() {
    // chama a função saveGcadInLib com a flag isOwner true
    let formData = this.buildRequestForm(true);
    this.getListGCADPainted(formData, false, true);
  }

  saveConjuntoOnBiblioteca() {

    if (this.selectedLibraryCode == 0 || this.selectedLibraryCode == ID_GRUPO_PROPRIO) {
      this.toast.warningToast("selecione_grupo_biblioteca");
      return;
    }
    let formData = this.buildRequestForm(true);
    this.getListGCADPainted(formData, true);
  }

  // FLAG isOwner define qual operação deve ser feita
  // caso true significa que deve apenas atualizar o conjunto
  // caso false deve criar um novo a partir do atual.
  saveGcadInLib(isOwner = false) {

    // region tarefa #31590
    let pathOridemGcad: string = this.conjunto.p_origem_gcad;
    const defaultFolderPath: string = "tmp\\";
    const indexOfFolderPath: number = pathOridemGcad.indexOf(defaultFolderPath);
    const gcadNameFile: string = pathOridemGcad.substring(indexOfFolderPath, pathOridemGcad.length).replace(defaultFolderPath, "");
    // end region tarefa #31590

    const formData = new FormData();
    let obj = {
      numero_pedido: 0,
      p_origem: 1,
      gcadOrigem: isOwner ? this.codigoProjeto + ".gcad" : gcadNameFile,
      gcadTemp: this.conjunto.p_gcad,
      gcadOp: isOwner ? 2 : 1,
      p_qtde: 1
    };
    let json = JSON.stringify(obj);
    formData.append("obj", json);
    this.showLoad();
    this.service.saveGcadInLib(formData).subscribe(
      (res: ApiResponse<{ success: boolean, message: string }>) => {
        this.hideLoad();

        this.requestCallbackService.okResponse(res,
          () => {
            if (res.data.retorno[0].gcad) {
              if (isOwner) {
                this.toast.successToast("msg_sucesso_alter_conjunto");
              } else {
                this.toast.successToast("msg_sucesso_conjunto_bib");
              }

              this.dialogBibliotecaVisible = false;
              let gcad = res.data.retorno[0].gcad;
              // SIMULANDO RELOAD.
              // altera o id da url somente quando não for proprietario...
              if (!isOwner) {
                this.router.navigate(["/pagina/biblioteca/" + this.ped_orca + "/editor/" + gcad]);
              }
              // reseta os dados conjunto
              this.resetConjunto();
              // seta o id do conjunto
              if (!isOwner) {
                this.codigoProjeto = gcad;
              }
              // busca os dados do conjunto
              this.loadGCADPainted();
              // END SIMULANDO RELOAD.
            } else {
              if (isOwner) {
                const msg = this.language.translateMessage("falha_salvar_alter_conjunto");
                this.toast.errorToast(msg);
              } else {
                const msg = this.language.translateMessage("falha_salvar_conjunto_bib");
                this.toast.errorToast(msg);
              }
            }
          });
      },
      error => {
        this.hideLoad();
        if (isOwner) {
          this.requestCallbackService.failResponse(error, "falha_salvar_alter_conjunto");
        } else {
          this.requestCallbackService.failResponse(error, "falha_salvar_conjunto_bib");
        }
      }
    );
  }

  openDialogDeleteConjunto() {
    this.popupDeleteVisible = true;
  }

  closeDialogDelete() {
    this.popupDeleteVisible = false;
  }

  deleteConjunto() {
    let id_conj = JSON.stringify(this.codigoProjeto);
    const formData = new FormData();
    formData.append("id_conj", id_conj);
    this.showLoad();
    this.service.excluiConjuntoBib(formData).subscribe(
      (res: ApiResponse<{ success: boolean, message: string }>) => {
        this.hideLoad();
        this.requestCallbackService.okResponse(res,
          () => {
            this.toast.successToast("msg_conjunto_deletado");
            this.redirectToBiblioteca();
          });
      }, error => {
        this.hideLoad();
        this.requestCallbackService.failResponse(error, "msg_falha_delete_conjunto");
      }
    );
  }

  openDialogPedido(isEditingOrder: boolean = false) {
    if (this.dataSourceConjuntoPedido.ds_vidro) {
      // region tarefa #30053
      if (isEditingOrder) {
        this.titleDialogPedido = this.language.translateMessage("salvar_e_voltar");
        this.dataSourceConjuntoPedido.qtd_peca = this.qtde_pecas_edit;
      } else {
        this.dataSourceConjuntoPedido.qtd_peca = 1;
        this.titleDialogPedido = this.language.translateMessage("adicionar_ao_pedido");
      }
      this.dataSourceConjuntoPedido.st_ferragem = true;
      this.dataSourceConjuntoPedido.st_perfil = true;

      this.showSaveAndBackConfirmButton = isEditingOrder;
      // end region tarefa #30053
      this.popupPedidoVisible = true;
    } else {
      this.toast.warningToast("msg_selecione_tipo_vidro");
    }
  }

  saveConjuntoOnPedido(e) {

    e.preventDefault();

    const p_origem_gcad = {
      p_origem_gcad: "",
      p_operation: "1",
      p_qtde: this.dataSourceConjuntoPedido.qtd_peca,
      st_ferragem: this.dataSourceConjuntoPedido.st_ferragem,
      st_perfil: this.dataSourceConjuntoPedido.st_perfil,
    };

    const p_origem_gcadJson = JSON.stringify(p_origem_gcad);

    const formData = new FormData();
    formData.append("p_origem_gcad", p_origem_gcadJson);
    formData.append("p_gcad", this.conjunto.p_origem_gcad); //caminho
    formData.append("caminhoPedido", this.ped_orca.toString());
    this.showLoad();
    this.service.saveListGCadAsp(formData).subscribe(
      (res: ApiResponse<{ success: boolean, message: string }>) => {
        this.hideLoad();
        this.requestCallbackService.okResponse(res,
          () => {
            this.toast.successToast("msg_sucesso_add_conj_ped");
            this.redirectToPedido();
          });
      }, error => {
        this.hideLoad();
        this.requestCallbackService.failResponse(error, "msg_falha_add_conj_ped");
      }
    );
  }

  toggleVenda(e) {
    this.defineCssClassVlVenda(e.value);
    let calculoManual = e.value ? "true" : "false";
    // CHAMA ESSE CARA AO MARCAR / DESMARCAR CHECKBOX..
    this.saveCalcValorTotalConj(calculoManual);
  }

  private defineCssClassVlVenda(isCalculoManual: boolean) {
    this.cssClassVlVenda = isCalculoManual ? "bg-vl-venda-manual" : "";
  }

  onChangevalorTotal(event: any) {
    // REGRA PARA SALVAR VALOR SETADO MANUALMENTE
    if (this.checkIfItIsTheSameEvent(event)) {
      return;
    }
    this.saveCalcValorTotalConj();
  }

  saveCalcValorTotalConj(calculoManual: string = "true") {

    const formData = new FormData();
    formData.append("p_gcad", this.conjunto.p_gcad);
    formData.append("p_input_valor_total", this.conjunto.valorTotalSemCalculo);
    formData.append("p_St_CalculoManual", calculoManual);

    this.showLoadManualValue();
    this.service.saveCalcValorTotalConj(formData).subscribe(
      (res: ApiResponse<{ success: boolean, message: string }>) => {
        this.hideLoadManualValue();
        this.requestCallbackService.okResponse(res,
          () => { });
      }, error => {
        this.hideLoadManualValue();
        this.requestCallbackService.failResponse(error, "errorU");
      }
    );
  }

  // tarefa #29678
  // REGRA PARA RECALCULAR VALORES APÓS ALGUM EVENTO.
  recalculateSet(e: any) {
    //tarefa #31658
    if (this.checkIfItIsTheSameEvent(e)) {
      return;
    }
    this.saveConjunto();
  }

  // region tarefa #31658
  private checkIfItIsTheSameEvent(e: any): boolean {
    try {
      const newTimeStampEvent = e.event.timeStamp;
      if (this.currentTimeStampEvent && (this.timeStampDifferenceIsLessThan500(newTimeStampEvent))) {
        return true;
      }
      this.currentTimeStampEvent = newTimeStampEvent;
      return false;
    } catch (e) {
      return false;
    }
  }

  private timeStampDifferenceIsLessThan500(newTimeStampEvent: number): boolean {
    const diff = this.calculateTimestampDifference(newTimeStampEvent);
    return diff < 500;
  }

  private calculateTimestampDifference(newTimeStampEvent: number): number {
    return newTimeStampEvent - this.currentTimeStampEvent;
  }
  // end region tarefa #31658

  //tarefa #29706
  displayExprPrd(data) {
    if (data) {
      return data.ds_codigo + " - " + data.ds_descricao;
    }
    return "";
  }

  // region tarefa #30053
  saveAndBack(e) {
    e.preventDefault();

    let obj = {
      numero_pedido: this.ped_orca, // numero do pedido que é exibido na url
      p_origem: 2,
      gcadOrigem: this.codigoProjeto + ".gcad", // aqui vai o cd_reitop da outra tela
      gcadTemp: this.conjunto.p_gcad,
      gcadOp: 2,
      p_qtde: this.dataSourceConjuntoPedido.qtd_peca //qte setada no dialog.
    };

    const formData = new FormData();
    formData.append("obj", JSON.stringify(obj));
    this.showLoad();
    this.service.saveGcadInLib(formData).subscribe(
      (res: ApiResponse<{ success: boolean, message: string }>) => {
        this.hideLoad();
        this.requestCallbackService.okResponse(res,
          () => {
            if (res.data.retorno[0].gcad) {
              this.toast.successToast("edit");
              this.redirectToPedido();
            } else {
              const msg = this.language.translateMessage("errorU");
              this.toast.errorToast(msg);
            }
          });
      },
      (error) => {
        this.hideLoad();
        this.requestCallbackService.failResponse(error, "errorU");
      });
  }
  // end region tarefa #30053

  onSelectionTabChanged(e) {
    this.changeDetectorRefs.detectChanges();
    let tabItem = e.addedItems[0];
    switch (tabItem.ID) {
      case 2:
        // perfis e acessorios
        this.perfisAcessoriosComponent.loadDataGrid();
        break;
      case 3:
        // resumo técnico
        this.resumoTecnicoComponent.load();
        break;
    }
  }

  pedidoPendenteByEmpresa() {

    // region tarefa #34328
    if (this.ped_orca == 0) {
      return;
    }
    // endregion

    const formData = new FormData();
    formData.append("cd_orca", this.ped_orca.toString());
    this.service.pedidoPendenteByEmpresa(formData).subscribe(
      (res: ApiResponse<{ success: boolean, message: string }>) => {
        this.requestCallbackService.okResponse(res,
          () => {
            if (!res.data.pedido) {
              const msg = this.language.translateMessage("pedido_nao_encontrado");
              this.toast.errorToast(msg);
              this.redirectToPedidos();
            }
          }, () => {
            this.redirectToPedidos();
          });
      },
      error => {
        this.requestCallbackService.failResponse(error, "falha_buscar_pedido");
        this.redirectToPedidos();
      }
    );
  }

  saveGcadEvent() {
    //Alterou serviço: atualiza conjunto by Rafael 04 / 05 / 2020
    //this.saveGcadInLib(true); // tarefa #30811 (Persiste as alterações do conjunto)
  }

  // region tarefa #31617
  minWidthSelectBox(): number {
    return calcMinWidthToDropDownMenu(600);
  }

  toggleDescriptionTooltipVisible() {
    this.descriptionTooltipVisible = !this.descriptionTooltipVisible;
  }
  // end region tarefa #31617

  // region tarefa #32482
  private getConfigDisplayFormulas() {
    this.showLoad();
    this.financeiroService.getMasterConfig(CONFIG_EXIBIR_TODAS_FORMULAS).subscribe((res: ApiResponse<{ success: boolean, message: string }>) => {
      this.hideLoad();
      this.requestCallbackService.okResponse(res,
        () => {
          this.displayAllFormulas = res.data.config.valor == 1;
        }
      );
    }, error => {
      this.hideLoad();
      this.requestCallbackService.failResponse(error, "falha_get_config");
    });
  }
  // end region tarefa #32482

  private getConfigMoedaPadrao() {
    this.showLoad();
    this.financeiroService.getMasterConfig(CONFIG_MOEDA_PADRAO).subscribe((res: ApiResponse<{ success: boolean, message: string }>) => {
      this.hideLoad();
      this.requestCallbackService.okResponse(res,
        () => {
          this.currency_symbol = res.data.config.descricao;
          this.currency_format = this.currency_symbol.concat("#,##0.##");
        }
      );
    }, error => {
      this.hideLoad();
      this.requestCallbackService.failResponse(error, "falha_get_config");
    });
  }

  // region tarefa #33716
  setupCanvas() {
    const canvasElement = this.getCanvasElement();
    canvasElement.focus();
    const canvasContext = this.getCanvasContext(canvasElement);
    const img = this.buildSetImageElement();
    img.addEventListener("load", () => {
      canvasContext.drawImage(img, 0, 0);
    });
  }

  setMouseMoveEvent(e) {
    const canvasElement = this.getCanvasElement();
    canvasElement.focus();

    const canvasContext = this.getCanvasContext(canvasElement);
    this.clearRect(canvasElement, canvasContext);

    const w = canvasElement.width;
    canvasElement.width = 1;
    canvasElement.width = w;

    this.clearRect(canvasElement, canvasContext);
    canvasContext.setTransform(1, 0, 0, 1, 0, 0);

    let [x, y] = this.calculateXYCanvas(e);

    const img = this.buildSetImageElement();
    canvasContext.drawImage(img, 0, 0);

    this.findItemSetMouseMoveEvent(x, y, (foundItem) => {
      const w = foundItem.SizeW;
      const h = foundItem.SizeH;
      const ix = foundItem.LocationX;
      const iy = foundItem.LocationY;
      this.clearRect(canvasElement, canvasContext);
      const img = this.buildSetImageElement();
      canvasContext.drawImage(img, 0, 0);
      canvasContext.rect(ix, iy, w, h);
      canvasContext.strokeStyle = '#ff0000';
      canvasContext.stroke();
    });
  }

  private getCanvasElement(): HTMLCanvasElement {
    return <HTMLCanvasElement>document.getElementById("imageConj");
  }

  private getCanvasContext(canvasElement: HTMLCanvasElement): CanvasRenderingContext2D {
    return canvasElement.getContext("2d");
  }

  private clearRect(canvasElement: HTMLCanvasElement, canvasContext: CanvasRenderingContext2D) {
    canvasContext.clearRect(0, 0, canvasElement.width, canvasElement.height);
  }

  private buildSetImageElement(): HTMLImageElement {
    const srcImage = this.conjunto.urlImagem;
    const img = document.createElement('img');
    img.setAttribute("class", "img-fluid");
    img.setAttribute("src", srcImage);
    return img;
  }

  private setupSetContextMenuItens() {
    const items = [
      { text: this.language.translateMessage("adicionar_ferragem"), id: CONTEXT_MENU_ITEM_ADD_FERRAGEM },
      { text: this.language.translateMessage("editar_ferragem"), id: CONTEXT_MENU_ITEM_EDIT_FERRAGEM },
      { text: this.language.translateMessage("excluir_ferragem"), id: CONTEXT_MENU_ITEM_DELETE_FERRAGEM },
      { text: this.language.translateMessage("espelhar_vertical"), id: CONTEXT_MENU_ITEM_ESPELHAR_VERTICAL },
      { text: this.language.translateMessage("espelhar_horizontal"), id: CONTEXT_MENU_ITEM_ESPELHAR_HORIZONTAL },
      { text: this.language.translateMessage("editar_esquadro"), id: CONTEXT_MENU_ITEM_EDIT_ESQUADRO },
      { text: this.language.translateMessage("editar_cantos"), id: CONTEXT_MENU_ITEM_EDIT_CANTOS },
      { text: this.language.translateMessage("adicionar_recortes"), id: CONTEXT_MENU_ITEM_ADD_RECORTES },
      { text: this.language.translateMessage("editar_recortes"), id: CONTEXT_MENU_ITEM_EDIT_RECORTES },
      { text: this.language.translateMessage("excluir_recortes"), id: CONTEXT_MENU_ITEM_DELETE_RECORTES },
      { text: this.language.translateMessage("adicionar_escrita"), id: CONTEXT_MENU_ITEM_ADD_ESCRITA },
      { text: this.language.translateMessage("editar_escrita"), id: CONTEXT_MENU_ITEM_EDIT_ESCRITA },
      { text: this.language.translateMessage("excluir_escrita"), id: CONTEXT_MENU_ITEM_DELETE_ESCRITA }
    ];
    this.setContextMenuItems = items;
    this.setContextMenuAllItems = items;
  }

  calculateXYCanvas(e): number[] {
    const parentOffset = $("#imageConj").offset();
    let x = e.pageX - parentOffset.left;
    let y = e.pageY - parentOffset.top;

    const altura = $("#imageConj").height();
    const largura = $("#imageConj").width();

    if (largura != 550 && altura != 500) {
      x = Math.round(parseFloat(((550 * x) / largura).toFixed(1)));
      y = Math.round(parseFloat(((500 * y) / altura).toFixed(2)));
    }

    return [x, y];
  }

  findItemSetMouseMoveEvent(x: number, y: number, callback: (foundItem) => void) {
    if (!this.conjunto || !this.conjunto.itens) {
      return;
    }

    const itens: any[] = this.conjunto.itens;
    for (let index = 0; index < itens.length; index++) {
      const item = itens[index];
      const w = item.SizeW;
      const h = item.SizeH;
      const ix = item.LocationX;
      const iy = item.LocationY;
      if ((x > ix + 0 && x < ix + 0 + w) && (y > iy + 0 && y < iy + 0 + h)) {
        callback(item);
        break;
      }
    }
  }

  dblclickCanvasEvent(e) {
    let [x, y] = this.calculateXYCanvas(e);
    let tipoObj: number = 0;
    this.findItemSetMouseMoveEvent(x, y, (foundItem) => {
      tipoObj = foundItem.TipoObj;
      this.selectedGlassItemId = foundItem.id;
      this.selectedGlassId = foundItem.id_parent;
    });

    switch (tipoObj) {
      case TIPO_ITEM_CONJUNTO_FURO: {
        this.populateFerragemItem(this.selectedGlassItemId);
        sleep(100).then(() => {
          this.popupItemFerragemVisible = true;
        });
        break;
      }
      case TIPO_ITEM_COTA_ESCRITA: {
        this.popupEscrita = this.findItemOnConjuntoByParent(this.selectedGlassItemId, this.selectedGlassId);
        sleep(100).then(() => {
          this.popupItemEscritaVisible = true;

        });
        break;
      }
      case TIPO_ITEM_CONJUNTO_SHAPE: {
        this.popupRecorteAlvenaria = this.findItemOnConjuntoByParent(this.selectedGlassItemId, this.selectedGlassId);
        sleep(100).then(() => {
          this.popupItemRecorteAlvenariaVisible = true;
        });
        break;
      }
      case TIPO_ITEM_CONJUNTO_VIDRO: {
        this.popupItemVidro = this.findItemOnConjunto(this.selectedGlassItemId);
        this.popupItemVidro.isNew = false;
        this.popupItemVidro.ds_nome = this.language.translateMessage("vidro") + " " + this.popupItemVidro.ds_text;
        this.popupItemVidro.st_form_larg = this.popupItemVidro.Formula_W && this.popupItemVidro.Formula_W.trim() != "";
        this.popupItemVidro.st_form_alt = this.popupItemVidro.Formula_H && this.popupItemVidro.Formula_H.trim() != "";
        this.popupItemVidro.st_form_x = this.popupItemVidro.Formula_X && this.popupItemVidro.Formula_X.trim() != "";
        this.popupItemVidro.st_form_y = this.popupItemVidro.Formula_Y && this.popupItemVidro.Formula_Y.trim() != "";
        sleep(100).then(() => {
          this.popupItemVidroVisible = true;
        });
        break;
      }
    }
  }

  clickCanvasEvent(e) {
    let [x, y] = this.calculateXYCanvas(e);
    $(document).off('.keyEvent');
    this.findItemSetMouseMoveEvent(x, y, (foundItem) => {
      const tipoObj = foundItem.TipoObj;
      $(document).on("keydown.keyEvent", (e) => {
        let x = 0;
        let y = 0;
        var tamanho = 50;
        switch (e.which) {
          case 37:
            x -= tamanho;
            break;
          case 38:
            y -= tamanho;
            break;
          case 39:
            x += tamanho;
            break;
          case 40:
            y += tamanho;
            break;
        }
        if (!(x == 0 && y == 0)) {
          if (((typeof foundItem.Formula_X === "undefined" || foundItem.Formula_X == 0) &&
            (typeof foundItem.Formula_Y === "undefined" || foundItem.Formula_Y == 0) &&
            (typeof foundItem.Formula_W === "undefined" || foundItem.Formula_W == 0) &&
            (typeof foundItem.Formula_H === "undefined" || foundItem.Formula_H == 0)) ||
            tipoObj == TIPO_ITEM_COTA_ESCRITA) {
            this.moverObjConjunto(foundItem.id, tipoObj, x, y);
          } else {
            const msg: string = tipoObj != TIPO_ITEM_CONJUNTO_VIDRO ? "mover_ferragem_com_formula" : "mover_ferragem_com_vidro";
            this.ngZone.run(() => {
              this.toast.warningToast(msg);
            });
          }
        }
      });
    });
  }

  moverObjConjunto(posicao: number, tipo: number, x: number, y: number) {
    const formData = new FormData();
    formData.append("gcad", this.conjunto.p_origem_gcad);
    formData.append("posicao", posicao.toString());
    formData.append("tipo", tipo.toString());
    formData.append("x", x.toString());
    formData.append("y", y.toString());
    formData.append("p_origem", this.showSaveAndBackButton ? "2" : "1");
    this.service.moverObjConjunto(formData).subscribe(
      (res: ApiResponse<{ success: boolean, message: string }>) => {
        this.requestCallbackService.okResponse(res,
          () => {
            if (res.data.listaItensPosicionados) {
              const listaItensPosicionados = JSON.parse(res.data.listaItensPosicionados)
              this.conjunto.urlImagem = listaItensPosicionados.Imagem + '?' + (new Date()).getTime();
              this.conjunto.itens = listaItensPosicionados.itens;
              sleep(50).then(() => {
                this.setupCanvas();
              });
            }
          });
      },
      (error) => {
        this.requestCallbackService.failResponse(error);
      });
  }

  setContextmenuEvent(e) {
    let [x, y] = this.calculateXYCanvas(e);

    let tipoObj: number = 0;
    this.findItemSetMouseMoveEvent(x, y, (foundItem) => {
      tipoObj = foundItem.TipoObj;
      this.selectedGlassItemId = foundItem.id;
      this.selectedGlassId = foundItem.id_parent;
      let tempItem: any = {};
      tempItem.larguraPeca = foundItem.width;
      tempItem.alturaPeca = foundItem.height;
      tempItem.x1 = foundItem.X1;
      tempItem.y1 = foundItem.Y1;
      tempItem.x2 = foundItem.X2;
      tempItem.y2 = foundItem.Y2;
      tempItem.x3 = foundItem.X3;
      tempItem.y3 = foundItem.Y3;
      tempItem.x4 = foundItem.X4;
      tempItem.y4 = foundItem.Y4;
      tempItem.rf1 = foundItem.rf1;
      tempItem.rf2 = foundItem.rf2;
      tempItem.rf3 = foundItem.rf3;
      tempItem.rf4 = foundItem.rf4;
      tempItem.CantoTipo1 = foundItem.CantoTipo1;
      tempItem.CantoTipo2 = foundItem.CantoTipo2;
      tempItem.CantoTipo3 = foundItem.CantoTipo3;
      tempItem.CantoTipo4 = foundItem.CantoTipo4;


      tempItem.p1 = 0;
      tempItem.p2 = 0;
      tempItem.p3 = 0;
      tempItem.p4 = 0;
      tempItem.p5 = 0;
      tempItem.p6 = 0;
      tempItem.p7 = 0;
      tempItem.p8 = 0;

      this.popupItemEdicaoEsquadro = tempItem;
    });

    switch (tipoObj) {
      //case TIPO_ITEM_CONJUNTO_FERRAGEM:
      case TIPO_ITEM_CONJUNTO_FURO: {
        const itemsToRemove = [CONTEXT_MENU_ITEM_ADD_FERRAGEM, CONTEXT_MENU_ITEM_ESPELHAR_VERTICAL, CONTEXT_MENU_ITEM_ESPELHAR_HORIZONTAL, CONTEXT_MENU_ITEM_EDIT_ESQUADRO, CONTEXT_MENU_ITEM_EDIT_CANTOS, CONTEXT_MENU_ITEM_ADD_RECORTES, CONTEXT_MENU_ITEM_EDIT_RECORTES, CONTEXT_MENU_ITEM_DELETE_RECORTES, CONTEXT_MENU_ITEM_ADD_ESCRITA, CONTEXT_MENU_ITEM_EDIT_ESCRITA, CONTEXT_MENU_ITEM_DELETE_ESCRITA];
        this.setContextMenuItems = this.setContextMenuAllItems.filter((value) => { return !itemsToRemove.includes(value.id); });
        break;
      }
      case TIPO_ITEM_CONJUNTO_VIDRO: {
        const itemsToRemove = [CONTEXT_MENU_ITEM_EDIT_FERRAGEM, CONTEXT_MENU_ITEM_DELETE_FERRAGEM, CONTEXT_MENU_ITEM_EDIT_RECORTES, CONTEXT_MENU_ITEM_DELETE_RECORTES, CONTEXT_MENU_ITEM_EDIT_ESCRITA, CONTEXT_MENU_ITEM_DELETE_ESCRITA];
        this.setContextMenuItems = this.setContextMenuAllItems.filter((value) => { return !itemsToRemove.includes(value.id); });
        break;
      }
      case TIPO_ITEM_CONJUNTO_SHAPE: {
        const itemsToRemove = [CONTEXT_MENU_ITEM_ADD_FERRAGEM, CONTEXT_MENU_ITEM_EDIT_FERRAGEM, CONTEXT_MENU_ITEM_DELETE_FERRAGEM, CONTEXT_MENU_ITEM_ESPELHAR_VERTICAL, CONTEXT_MENU_ITEM_ESPELHAR_HORIZONTAL, CONTEXT_MENU_ITEM_EDIT_ESQUADRO, CONTEXT_MENU_ITEM_EDIT_CANTOS, CONTEXT_MENU_ITEM_ADD_RECORTES, CONTEXT_MENU_ITEM_ADD_ESCRITA, CONTEXT_MENU_ITEM_EDIT_ESCRITA, CONTEXT_MENU_ITEM_DELETE_ESCRITA];
        this.setContextMenuItems = this.setContextMenuAllItems.filter((value) => { return !itemsToRemove.includes(value.id); });
        break;
      }
      case TIPO_ITEM_COTA_ESCRITA: {
        const itemsToRemove = [CONTEXT_MENU_ITEM_ADD_FERRAGEM, CONTEXT_MENU_ITEM_EDIT_FERRAGEM, CONTEXT_MENU_ITEM_DELETE_FERRAGEM, CONTEXT_MENU_ITEM_ESPELHAR_VERTICAL, CONTEXT_MENU_ITEM_ESPELHAR_HORIZONTAL, CONTEXT_MENU_ITEM_EDIT_ESQUADRO, CONTEXT_MENU_ITEM_EDIT_CANTOS, CONTEXT_MENU_ITEM_ADD_RECORTES, CONTEXT_MENU_ITEM_EDIT_RECORTES, CONTEXT_MENU_ITEM_DELETE_RECORTES, CONTEXT_MENU_ITEM_ADD_ESCRITA];
        this.setContextMenuItems = this.setContextMenuAllItems.filter((value) => { return !itemsToRemove.includes(value.id); });
        break;
      }
      default: { // FORA DO DESENHO
        this.setContextMenuItems = [];
        break;
      }
    }
  }

  setContextMenuItemClick(e) {
    switch (e.itemData.id) {
      case CONTEXT_MENU_ITEM_ADD_FERRAGEM: {
        this.popupItemFerragem = { isNew: true, id: this.selectedGlassItemId, Topo: false, Esquerda: false };
        this.popupItemFerragemVisible = true;
        break;
      }
      case CONTEXT_MENU_ITEM_EDIT_FERRAGEM: {
        this.populateFerragemItem(this.selectedGlassItemId);
        this.popupItemFerragemVisible = true;
        break;
      }
      case CONTEXT_MENU_ITEM_DELETE_FERRAGEM: {
        this.populateDeleteFerragemItem(this.selectedGlassItemId);
        this.popupDeleteItemFerragemVisible = true;
        break;
      }
      case CONTEXT_MENU_ITEM_ESPELHAR_VERTICAL: {
        const obj_esp = {
          item_espelho_horizontal: false,
          item_espelho_vertical: true,
          item: this.selectedGlassItemId
        }
        this.saveConjunto(obj_esp);
        break;
      }
      case CONTEXT_MENU_ITEM_ESPELHAR_HORIZONTAL: {
        const obj_esp = {
          item_espelho_horizontal: true,
          item_espelho_vertical: false,
          item: this.selectedGlassItemId
        }
        this.saveConjunto(obj_esp);
        break;
      }
      case CONTEXT_MENU_ITEM_EDIT_ESQUADRO: {
        const tempItem = this.popupItemEdicaoEsquadro;
        const larguraPeca: number = tempItem.larguraPeca;
        const alturaAltura: number = tempItem.alturaPeca;
        const razao = this.CM_getRazao(larguraPeca, alturaAltura);
        tempItem.razao = razao;
        tempItem.largura = parseFloat(larguraPeca.toFixed(2)) / razao;
        tempItem.altura = parseFloat(alturaAltura.toFixed(2)) / razao;

        tempItem.p1 = tempItem.x1 / razao;
        tempItem.p2 = tempItem.y1 / razao;
        tempItem.p3 = tempItem.largura - (tempItem.x2 / razao);
        tempItem.p4 = tempItem.y2 / razao;
        tempItem.p5 = tempItem.largura - (tempItem.x3 / razao);
        tempItem.p6 = tempItem.altura - (tempItem.y3 / razao);
        tempItem.p7 = tempItem.x4 / razao;
        tempItem.p8 = tempItem.altura - (tempItem.y4 / razao);
        //tempItem.CantoTipo1 = temp

        this.popupItemEdicaoEsquadro = tempItem;
        this.popupItemEdicaoEsquadroVisible = true;
        break;
      }
      case CONTEXT_MENU_ITEM_EDIT_CANTOS: {
        const tempItem = this.popupItemEdicaoEsquadro;
        const larguraPeca: number = tempItem.larguraPeca;
        const alturaAltura: number = tempItem.alturaPeca;
        const razao = this.CM_getRazao(larguraPeca, alturaAltura);
        tempItem.razao = razao;
        tempItem.largura = parseFloat(larguraPeca.toFixed(2)) / razao;
        tempItem.altura = parseFloat(alturaAltura.toFixed(2)) / razao;

        tempItem.p1 = tempItem.x1 / razao;
        tempItem.p2 = tempItem.y1 / razao;
        tempItem.p3 = tempItem.largura - (tempItem.x2 / razao);
        tempItem.p4 = tempItem.y2 / razao;
        tempItem.p5 = tempItem.largura - (tempItem.x3 / razao);
        tempItem.p6 = tempItem.altura - (tempItem.y3 / razao);
        tempItem.p7 = tempItem.x4 / razao;
        tempItem.p8 = tempItem.altura - (tempItem.y4 / razao);

        this.popupItemEdicaoEsquadro = tempItem;
        this.popupItemEdicaoCantosVisible = true;
        break;
      }
      case CONTEXT_MENU_ITEM_ADD_RECORTES: {
        this.popupRecorteAlvenaria = { isNew: true, id: this.selectedGlassItemId, Topo: false, Esquerda: false, Raio: 10 };
        this.popupItemRecorteAlvenariaVisible = true;
        break;
      }
      case CONTEXT_MENU_ITEM_EDIT_RECORTES: {
        this.popupRecorteAlvenaria = this.findItemOnConjuntoByParent(this.selectedGlassItemId, this.selectedGlassId);
        this.popupItemRecorteAlvenariaVisible = true;
        break;
      }
      case CONTEXT_MENU_ITEM_DELETE_RECORTES: {
        this.popupRecorteAlvenaria = this.findItemOnConjuntoByParent(this.selectedGlassItemId, this.selectedGlassId);
        this.popupDeleteItemRecorteAlvenariaVisible = true;
        break;
      }
      case CONTEXT_MENU_ITEM_ADD_ESCRITA: {
        this.popupEscrita = { isNew: true, id: this.selectedGlassItemId, Topo: false, Esquerda: false, Raio: 10 };
        this.popupItemEscritaVisible = true;
        break;
      }
      case CONTEXT_MENU_ITEM_EDIT_ESCRITA: {
        this.popupEscrita = this.findItemOnConjuntoByParent(this.selectedGlassItemId, this.selectedGlassId);
        this.popupItemEscritaVisible = true;
        break;
      }
      case CONTEXT_MENU_ITEM_DELETE_ESCRITA: {
        this.popupEscrita = this.findItemOnConjuntoByParent(this.selectedGlassItemId, this.selectedGlassId);
        this.popupDeleteItemEscritaVisible = true;
        break;
      }
    }
  }

  CM_getRazao(valorAltura: number, valorLargura: number, valorMaximoAltura: number = 400, valorMaximoLargura: number = 230, razao: number = 1): number {
    while (valorAltura / razao > valorMaximoAltura || valorLargura / razao > valorMaximoLargura) {
      razao += 1;
    }
    return razao;
  }

  calcularEsquadro() {
    const p_lista_vidro_fora_esquadro = [{
      "id_vidro": this.selectedGlassItemId,
      "x1": this.popupItemEdicaoEsquadro.x1,
      "y1": this.popupItemEdicaoEsquadro.y1,
      "x2": this.popupItemEdicaoEsquadro.x2,
      "y2": this.popupItemEdicaoEsquadro.y2,
      "x3": this.popupItemEdicaoEsquadro.x3,
      "y3": this.popupItemEdicaoEsquadro.y3,
      "x4": this.popupItemEdicaoEsquadro.x4,
      "y4": this.popupItemEdicaoEsquadro.y4,
      "CantoTipo1": this.popupItemEdicaoEsquadro.CantoTipo1,
      "CantoTipo2": this.popupItemEdicaoEsquadro.CantoTipo2,
      "CantoTipo3": this.popupItemEdicaoEsquadro.CantoTipo3,
      "CantoTipo4": this.popupItemEdicaoEsquadro.CantoTipo4,
      "rf1": this.popupItemEdicaoEsquadro.rf1,
      "rf2": this.popupItemEdicaoEsquadro.rf2,
      "rf3": this.popupItemEdicaoEsquadro.rf3,
      "rf4": this.popupItemEdicaoEsquadro.rf4
    }];
    this.saveConjunto(null, p_lista_vidro_fora_esquadro);
  }

  animarEsquadro(e) {
    const oldPts: any = $("#shp").attr("points").split(/, *| +/);
    const tempItem = this.popupItemEdicaoEsquadro;
    const m_razao = tempItem.razao;
    const newPts = [
      tempItem.x1 / m_razao,
      tempItem.y1 / m_razao,
      tempItem.largura - (tempItem.x2 / m_razao),
      tempItem.y2 / m_razao,
      tempItem.largura - (tempItem.x3 / m_razao),
      tempItem.altura - (tempItem.y3 / m_razao),
      tempItem.x4 / m_razao,
      tempItem.altura - (tempItem.y4 / m_razao)
    ];
    $("svg").animate({ top: "+=0" }, {
      duration: 500,
      progress: (_xxx, p) => {
        $("#shp").attr("points", oldPts.map((oldPt, i) => {
          let oldPtn: number = parseInt(oldPt);
          return +oldPt + (newPts[i] - oldPtn) * p;
        }));
      }
    });
  }

  salvarRecorteAlvenariaPopup(excluir: boolean = false) {
    const item = this.popupRecorteAlvenaria;
    const p_formas = {
      id_vidro: item.isNew ? item.id : item.id_parent,
      id_shape: item.isNew ? -1 : item.id,
      check_topo: item.Topo,
      check_esq: item.Esquerda,
      text_x: item.Ponto1_X ? item.Ponto1_X : 0,
      text_y: item.Ponto1_Y ? item.Ponto1_Y : 0,
      text_larg: item.Ponto3_X ? item.Ponto3_X : 0,
      text_alt: item.Ponto3_Y ? item.Ponto3_Y : 0,
      text_raio: item.Raio ? item.Raio : 0,
      excluir: excluir,
    };
    this.saveConjunto(null, null, p_formas);
  }

  fecharPopupDeletarRecorteAlvenaria() {
    this.popupDeleteItemRecorteAlvenariaVisible = false;
  }

  salvarEscrita(excluir: boolean = false) {
    const item = this.popupEscrita;

    if (!item.ds_text) {
      this.toast.warningToast("informe_descricao_escrita");
      return;
    }

    const p_escritas = {
      id_escrita: item.isNew ? -1 : item.id,
      texto: item.ds_text,
      excluir: excluir,
    };
    this.saveConjunto(null, null, null, p_escritas);
    this.popupItemEscritaVisible = false;
  }

  deletarEscrita() {
    this.salvarEscrita(true);
    this.popupDeleteItemEscritaVisible = false;
  }

  fecharPopupDeletarEscrita() {
    this.popupDeleteItemEscritaVisible = false;
  }

  switchValueChangedFerragem(e) {
    this.popupItemFerragem.Esquerda = e.previousValue;
  }

  switchValueChangedRecorteAlvenaria(e) {
    this.popupRecorteAlvenaria.Esquerda = e.previousValue;
  }
  switchValueChangedVidroCanto(e, canto) {
    var resposta = e.value ? 1 : 0;
    switch (+canto) {
      case 1:
        this.popupItemEdicaoEsquadro.CantoTipo1 = resposta;
        break;
      case 2:
        this.popupItemEdicaoEsquadro.CantoTipo2 = resposta;
        break;
      case 3:
        this.popupItemEdicaoEsquadro.CantoTipo3 = resposta;
        break;
      case 4:
        this.popupItemEdicaoEsquadro.CantoTipo4 = resposta;
        break;
    }
  }

  // end region tarefa #33716

  private getConfigOcultarCustos() {
    this.financeiroService.getMasterConfig(CONFIG_OCULTAR_CUSTOS).subscribe((res: ApiResponse<{ success: boolean, message: string }>) => {
      this.requestCallbackService.okResponse(res,
        () => {
          const ocultar = res.data.config.valor == 1;
          this.ocultarCustos = ocultar;
        }
      );
    }, error => {
      this.requestCallbackService.failResponse(error, "falha_get_config");
    });
  }
}
