<div *ngIf="havePermission" class="m-3">

    <dx-data-grid 
        id="data-grid-estados"
        [dataSource]="dataSource" 
        [columnAutoWidth]="true" 
        showBorders="true"
        [allowColumnReordering]="true" 
        [allowColumnResizing]="true"
        [remoteOperations]="true"
        (onRowUpdating)="onRowUpdating($event)"
        (onToolbarPreparing)="onToolbarPreparing($event)">

        <dxo-search-panel 
            [visible]="true" 
            [width]="240" 
            [placeholder]="'index.procurar' | translate">
        </dxo-search-panel>

        <dxo-editing
            mode="row"
            [allowAdding]="true"
            [allowUpdating]="cm_allowEditAndDelete"
            [allowDeleting]="cm_allowEditAndDelete">
        </dxo-editing>

        <dxo-scrolling mode="virtual"></dxo-scrolling>

        <dxi-column 
            dataField="cd_codigo"
            dataType="text"
            sortOrder="desc"
            [allowEditing]="false"
            [caption]="'index.cd_codigo' | translate">
        </dxi-column>

        <dxi-column 
            dataField="ds_descricao"
            dataType="string"
            [editorOptions]="{ maxLength: 40 }"
            [caption]="'index.ds_descricao' | translate">
            <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>

        <dxi-column
            dataField="cd_pais"
            dataType="number"
            [caption]="'index.pais' | translate"
            calculateDisplayValue="ds_pais"
            editCellTemplate="editCellTemplateCountry">
            <dxi-validation-rule type="required"></dxi-validation-rule>
            <dxo-lookup
                [dataSource]="dataSourceCountries"
                valueExpr="cd_codigo"
                displayExpr="ds_nome">
            </dxo-lookup>
        </dxi-column>
    
        <div *dxTemplate="let data of 'editCellTemplateCountry'">
            <dx-select-box
                [dataSource]="dataSourceCountries"
                displayExpr="ds_nome"
                valueExpr="cd_codigo" 
                [value]="data.value"
                [searchEnabled]="true"
                (onValueChanged)="data.setValue($event.value)"
                [dropDownOptions]="{ minWidth: minWidthSelectBox }">
            </dx-select-box>
        </div>

        <dxi-column 
            dataField="cd_ibge"
            dataType="number"
            [caption]="'index.cd_ibge' | translate">
        </dxi-column>

        <dxi-column 
            dataField="ds_estado"
            dataType="string"
            [caption]="'index.ds_estado' | translate">
        </dxi-column>
        
        <dxi-column 
            dataField="cd_empresa" 
            [caption]="'index.empresa' | translate"
            [groupIndex]="0" 
            calculateDisplayValue="ds_empresa"
            [allowEditing]="false">
            <dxo-lookup
                [dataSource]="dataSourceCompanies"
                valueExpr="cd_empresa"
                displayExpr="ds_empresa">
            </dxo-lookup>
        </dxi-column>

        <div *dxTemplate="let data of 'dx-title'">
            <div class="text-center">
                <p class="title mb-0">{{'index.estados' | translate}}</p>
            </div>
        </div>

    </dx-data-grid>

</div>
<denied-access *ngIf="!havePermission" [formCode]="formCode"></denied-access>