import { Injectable } from '@angular/core';
import { baseUrl } from './../../config';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AcompanhamentoService {

  private url: string = baseUrl

  constructor(private http: HttpClient) { }

  load(formData: FormData): Observable<any> {
    return this.http.post(`${this.url}/getAcompanhamento`, formData)
  }
}
