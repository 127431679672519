import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { baseUrl } from "src/app/config";

@Injectable({
    providedIn: "root"
})
export class TiposService {    
    private url = baseUrl;
    constructor(private http: HttpClient) { }

    getTipos(form: FormData): Observable<any> {
        return this.http.post<any>(`${this.url}/Tipos`, form);
    }

    insertTipo(form: FormData): Observable<any> {
        return this.http.post(`${this.url}/InsertTipo`, form);
    }

    updateTipo(form: FormData): Observable<any> {
        return this.http.post(`${this.url}/UpdateTipo`, form);
    }

    deleteTipo(form: FormData): Observable<any> {
        return this.http.post(`${this.url}/DeleteTipo`, form);
    }
    
}