import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { baseUrl, urlWs } from "./../../config";
import { USER_NAME, USER_EMAIL } from 'src/app/shared/helpers/constants';
import { setItemStorage } from 'src/app/shared/helpers/util';
import { LanguagueService } from 'src/app/shared/helpers/language.service';
import { Observable } from "rxjs";
import { isUserProfileFactory, isUserProfileIndependentGlazing, isUserProfileProvider, isUserProfileReitech } from "src/app/shared/models/UserProfile";
import { Plano } from "src/app/shared/models/Plano";

@Injectable({
  providedIn: "root"
})
export class HeaderNewService {
  constructor(private http: HttpClient, private language: LanguagueService) { }
  url = baseUrl;


  getUrlWs(): string {
    return urlWs;
  }

  getFabricaLogo(): Observable<any> {
    return this.http.get(`${this.url}/getFabricaLogo`);
  }

  setConfig(formData): Observable<any> {
    return this.http.post(`${this.url}/setConfig`, formData);
  }

  getUserData(): Observable<any> {
    return this.http.get(`${this.url}/getUserData`);
  }

  getMenuItens(userData: any) {
    const username = userData.ds_apelido;
    const email = userData.ds_nome;
    const isProvider: boolean = isUserProfileProvider(userData.cd_perfil);
    const isFactory: boolean = isUserProfileFactory(userData.cd_perfil);
    const isIndependentGlazing: boolean = isUserProfileIndependentGlazing(userData.cd_perfil);
    const isReitech: boolean = isUserProfileReitech(userData.cd_perfil);
    const isAdm: boolean = userData.isAdm;
    const isPlanoPremium: boolean = userData.cd_plano == Plano.Premium;

    setItemStorage(USER_NAME, username);
    setItemStorage(USER_EMAIL, email);

    const menu: MenuItem[] = [];

    menu.push({
      ID: "1",
      name: username,
      icon: "fas fa-user"
    });

    if (isAdm) {
      this.language.translateMessageLazy("idiomas", (value) => {
        menu.push({
          ID: "1_1",
          categoryId: "1",
          name: value,
          icon: "fas fa-language"
        });
      });

      this.language.translateMessageLazy("pt", (value: string) => {
        menu.push({
          ID: "1_1_1",
          categoryId: "1_1",
          name: value,
          icon: "fas fa-flag",
          idioma: 1
        });
      });

      this.language.translateMessageLazy("eng", (value: string) => {
        menu.push({
          ID: "1_1_2",
          categoryId: "1_1",
          name: value,
          icon: "fas fa-flag-usa",
          idioma: 2
        });
      });

      this.language.translateMessageLazy("esp", (value: string) => {
        menu.push({
          ID: "1_1_3",
          categoryId: "1_1",
          name: value,
          icon: "fas fa-flag-checkered",
          idioma: 3
        });
      });
    }

    this.language.translateMessageLazy("editarPerfil", (value: string) => {
      menu.push({
        ID: "1_2",
        categoryId: "1",
        name: value,
        icon: "fas fa-user-edit",
        link: "pagina/perfil"
      });
    });

    this.language.translateMessageLazy("configuracoes", (value: string) => {
      menu.push({
        ID: "1_3",
        categoryId: "1",
        name: value,
        icon: "fas fa-cog",
        link: "pagina/configuracoes"
      });
    });

    // caso usuario tenha permissao adiciona link para acesso as configs
    // tarefa #29971
    if (isProvider) {
      this.language.translateMessageLazy("configs", (value: string) => {
        menu.push({
          ID: "1_4",
          categoryId: "1",
          name: value,
          icon: "fas fa-tools",
          link: "pagina/configs"
        });
      });
    }

    if (isReitech) {
      this.language.translateMessageLazy("funcoes", (value: string) => {
        menu.push({
          ID: "1_5",
          categoryId: "1",
          name: value,
          icon: "fas fa-code",
          link: "pagina/funcoes"
        });
      });
    }

    this.language.translateMessageLazy("sair", (value: string) => {
      menu.push({
        ID: "1_6",
        categoryId: "1",
        name: value,
        icon: "fas fa-power-off",
        logoff: true
      });
    });

    // caso usuario tenha permissao adiciona link para acesso ao painel no menu
    if (isProvider && !isIndependentGlazing) {
      this.language.translateMessageLazy("painel", (value: string) => {
        menu.push({
          ID: "2",
          name: value,
          icon: "fas fa-briefcase",
          link: "pagina/painel"
        });
      });
    }

    if (isPlanoPremium) {
      this.language.translateMessageLazy("agenda", (value: string) => {
        menu.push({
          ID: "4",
          name: value,
          icon: "fas fa-calendar",
          link: "pagina/agenda"
        });
      });
    }

    // caso usuario seja do tipo adm (que possui acesso ao painel) não deve ter acesso ao financeiro
    if (!isFactory && isPlanoPremium) {
      this.language.translateMessageLazy("financeiro", (value: string) => {
        menu.push({
          ID: "5",
          name: value,
          icon: "fas fa-cash-register"
        });
      });

      this.language.translateMessageLazy("contasR", (value: string) => {
        menu.push({
          ID: "5_1",
          categoryId: "5",
          name: value,
          icon: "fas fa-hand-holding-usd",
          link: "pagina/financeiro/receber"
        });
      });

      this.language.translateMessageLazy("contasP", (value: string) => {
        menu.push({
          ID: "5_2",
          categoryId: "5",
          name: value,
          icon: "fas fa-money-check-alt",
          link: "pagina/financeiro/pagar"
        });
      });
    }

    this.language.translateMessageLazy("cadastros", (value: string) => {
      menu.push({
        ID: "6",
        name: value,
        icon: "fas fa-address-card"
      });
    });

    this.language.translateMessageLazy("usuarios", (value: string) => {
      menu.push({
        ID: "6_1",
        categoryId: "6",
        name: value,
        icon: "fas fa-users",
        link: "pagina/usuarios"
      });
    });

    this.language.translateMessageLazy("clientesFornecedores", (value: string) => {
      menu.push({
        ID: "6_2",
        categoryId: "6",
        name: value,
        icon: "fas fa-user-tie",
        link: "pagina/clientes"
      });
    });

    // caso usuario seja do tipo adm (que possui acesso ao painel) não deve ter acesso aos vendedores
    if (!isFactory) {
      this.language.translateMessageLazy("vendedores", (value: string) => {
        menu.push({
          ID: "6_3",
          categoryId: "6",
          name: value,
          icon: "fas fa-user-tag",
          link: "pagina/vendedores"
        });
      });
    }

    // caso usuario seja do tipo adm (que possui acesso ao painel) não deve ter acesso as condições de pag
    if (!isFactory) {
      this.language.translateMessageLazy("condicaoPagamento", (value: string) => {
        menu.push({
          ID: "6_4",
          categoryId: "6",
          name: value,
          icon: "fas fa-file-invoice-dollar",
          link: "pagina/condicoes"
        });
      });
    }

    this.language.translateMessageLazy("equipes", (value: string) => {
      menu.push({
        ID: "6_5",
        categoryId: "6",
        name: value,
        icon: "fas fa-user-friends",
        link: "pagina/equipes"
      });
    });

    this.language.translateMessageLazy("permissoes", (value: string) => {
      menu.push({
        ID: "6_6",
        categoryId: "6",
        name: value,
        icon: "fas fa-user-lock",
        link: "pagina/permissoes"
      });
    });

    this.language.translateMessageLazy("produtos", (value: string) => {
      menu.push({
        ID: "6_7",
        categoryId: "6",
        name: value,
        icon: "fas fa-boxes",
        link: "pagina/produtos"
      });
    });

    this.language.translateMessageLazy("uploadGCAD", (value: string) => {
      menu.push({
        ID: "6_8",
        categoryId: "6",
        name: value,
        icon: "fas fa-file-upload",
        link: "pagina/upload"
      });
    });

    this.language.translateMessageLazy("funcionarios", (value: string) => {
      menu.push({
        ID: "6_9",
        categoryId: "6",
        name: value,
        icon: "fas fa-user-tie",
        link: "pagina/funcionarios"
      });
    });

    // region tarefa #34328
    this.language.translateMessageLazy("biblioteca", (value: string) => {
      menu.push({
        ID: "6_10",
        categoryId: "6",
        name: value,
        icon: "fas fa-book",
        link: "pagina/biblioteca/0"
      });
    });
    // endregion

    this.language.translateMessageLazy("fornecedores_gcad", (value: string) => {
      menu.push({
        ID: "6_11",
        categoryId: "6",
        name: value,
        icon: "fas fa-industry",
        link: "pagina/fornecedores/grupocad"
      });
    });

    if (isReitech) {
      this.language.translateMessageLazy("cadastros_secundarios", (value: string) => {
        menu.push({
          ID: "7",
          name: value,
          icon: "far fa-list-alt"
        });
      });

      this.language.translateMessageLazy("unimed", (value: string) => {
        menu.push({
          ID: "7_1",
          categoryId: "7",
          name: value,
          icon: "fas fa-clipboard-list",
          link: "pagina/unimed"
        });
      });

      this.language.translateMessageLazy("grupos", (value: string) => {
        menu.push({
          ID: "7_2",
          categoryId: "7",
          name: value,
          icon: "fas fa-clipboard-list",
          link: "pagina/grupos"
        });
      });

      this.language.translateMessageLazy("subgrupos", (value: string) => {
        menu.push({
          ID: "7_3",
          categoryId: "7",
          name: value,
          icon: "fas fa-clipboard-list",
          link: "pagina/subgrupos"
        });
      });

      this.language.translateMessageLazy("tipos", (value: string) => {
        menu.push({
          ID: "7_4",
          categoryId: "7",
          name: value,
          icon: "fas fa-clipboard-list",
          link: "pagina/tipos"
        });
      });

      this.language.translateMessageLazy("portadores", (value: string) => {
        menu.push({
          ID: "7_5",
          categoryId: "7",
          name: value,
          icon: "fas fa-clipboard-list",
          link: "pagina/portadores"
        });
      });

      this.language.translateMessageLazy("ferragens", (value: string) => {
        menu.push({
          ID: "7_6",
          categoryId: "7",
          name: value,
          icon: "fas fa-clipboard-list",
          link: "pagina/ferragens"
        });
      });

      this.language.translateMessageLazy("paises", (value: string) => {
        menu.push({
          ID: "7_7",
          categoryId: "7",
          name: value,
          icon: "fas fa-clipboard-list",
          link: "pagina/paises"
        });
      });

      this.language.translateMessageLazy("estados", (value: string) => {
        menu.push({
          ID: "7_8",
          categoryId: "7",
          name: value,
          icon: "fas fa-clipboard-list",
          link: "pagina/estados"
        });
      });

      this.language.translateMessageLazy("cidades", (value: string) => {
        menu.push({
          ID: "7_9",
          categoryId: "7",
          name: value,
          icon: "fas fa-clipboard-list",
          link: "pagina/cidades"
        });
      });

      this.language.translateMessageLazy("produtos_vidraceiros", (value: string) => {
        menu.push({
          ID: "7_10",
          categoryId: "7",
          name: value,
          icon: "fas fa-clipboard-list",
          link: "pagina/produtos/vidraceiros"
        });
      });

    }

    this.language.translateMessageLazy("vendas", (value: string) => {
      menu.push({
        ID: "8",
        name: value,
        icon: "fas fa-comments-dollar"
      });
    });

    this.language.translateMessageLazy("pedido", (value: string) => {
      menu.push({
        ID: "8_1",
        categoryId: "8",
        name: value,
        icon: "fas fa-file-invoice",
        link: "pagina/pedidos"
      });
    });

    this.language.translateMessageLazy("relPedidos", (value: string) => {
      menu.push({
        ID: "8_2",
        categoryId: "8",
        name: value,
        icon: "fas fa-file-invoice",
        link: "pagina/relpedidos"
      });
    });

    this.language.translateMessageLazy("vendasdiario", (value: string) => {
      menu.push({
        ID: "8_3",
        categoryId: "8",
        name: value,
        icon: "fas fa-shopping-cart",
        link: "pagina/vendasdiario"
      });
    });

    if (!isProvider) {
      this.language.translateMessageLazy("acompanhamento", (value: string) => {
        menu.push({
          ID: "8_4",
          categoryId: "8",
          name: value,
          icon: "fas fa-clipboard",
          link: "pagina/acompanhamento"
        });
      });
    }

    return menu;
  }
}

export class MenuItem {
  ID: string;
  name: string;
  categoryId?: string;
  icon?: string;
  link?: string;
  logoff?: boolean;
  pedidos?: boolean;
  idioma?: number;
}
