export enum UserProfile {
    INDEFINIDO = 0,
    REITECH = 1,
    FABRICA = 2,
    VIDRACARIA = 3,
    VIDRACARIA_INDEPENDENTE = 4
}

export const isUserProfileProvider = (p_CodigoPerfilUsuario: number): boolean => {
    return p_CodigoPerfilUsuario == UserProfile.REITECH ||
      p_CodigoPerfilUsuario == UserProfile.FABRICA ||
      p_CodigoPerfilUsuario == UserProfile.VIDRACARIA_INDEPENDENTE;
  }
  
  export const isUserProfileFactory = (p_CodigoPerfilUsuario: number): boolean => {
    return p_CodigoPerfilUsuario == UserProfile.REITECH ||
      p_CodigoPerfilUsuario == UserProfile.FABRICA;
  }
  
  export const isUserProfileOnlyFactory = (p_CodigoPerfilUsuario: number): boolean => {
    return p_CodigoPerfilUsuario == UserProfile.FABRICA;
  }

  export const isUserProfileIndependentGlazing = (p_CodigoPerfilUsuario: number): boolean => {
    return p_CodigoPerfilUsuario == UserProfile.VIDRACARIA_INDEPENDENTE;
  }
  
  export const isUserProfileReitech = (p_CodigoPerfilUsuario: number): boolean => {
    return p_CodigoPerfilUsuario == UserProfile.REITECH;
  }