import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { LanguagueService } from 'src/app/shared/helpers/language.service';
import { RequestCallbackService } from 'src/app/shared/helpers/request-callback.service';
import { ToastService } from 'src/app/shared/helpers/toast.service';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { PedidoItemService } from '../pedido-item/pedidos-item.service';
import { ProdutosService } from '../produtos/produtos.service';
import { PedidoOtimizacaoService } from './pedido-otimizacao.service';

@Component({
  selector: 'app-pedido-otimizacao',
  templateUrl: './pedido-otimizacao.component.html',
  styleUrls: ['./pedido-otimizacao.component.scss']
})
export class PedidoOtimizacaoComponent implements OnChanges {

  dataSourceItens: any[] = [];
  dataSourceOptimizedItens: any[] = [];
  dataSourceGlass: any[] = [];
  dataSourceGruposGcad: any[] = [];
  dataSourceOperacao: any[] = [];

  @Input()
  orderId: number;

  @Input()
  value: number;

  loading: boolean;
  showOptimize: boolean;

  @Output()
  notifyOptimizedEvent = new EventEmitter();

  constructor(
    private pedidoOtimizacaoService: PedidoOtimizacaoService,
    private requestCallbackService: RequestCallbackService,
    private language: LanguagueService,
    produtosService: ProdutosService,
    private toastService: ToastService,
    private pedidoItemService: PedidoItemService
  ) {
    this.language.globalize();
    this.dataSourceGruposGcad = produtosService.getGruposGcad();
  }

  ngOnChanges() {
    this.showOptimize = false;
    this.explodeItens();
  }

  explodeItens() {
    this.dataSourceItens = [];
    this.dataSourceOptimizedItens = [];
    this.dataSourceGlass = [];

    const formData = new FormData();
    formData.append("orderId", this.orderId.toString());

    this.showLoad();
    this.pedidoOtimizacaoService.explodeItens(formData).subscribe(
      (res: ApiResponse<{ success: boolean, message: string }>) => {
        this.hideLoad();
        this.requestCallbackService.okResponse(res,
          () => {

            this.setupDataSourceOperacao();
            const orcaproGrouped: any[] = res.data.orcaproGrouped;
            this.dataSourceItens = orcaproGrouped;

            this.dataSourceGlass = res.data.orcaproGlass;
          });
      }, error => {
        this.hideLoad();
        this.requestCallbackService.failResponse(error);
      });
  }

  onToolbarPreparingExplode(e) {
    const toolbarItens: any[] = e.toolbarOptions.items;

    const btnTextOtimizar = this.language.translateMessage("otimizar");
    const btnOptimize = {
      location: "after",
      widget: "dxButton",
      name: "Otimimizar",
      options: {
        icon: "fieldchooser",
        text: btnTextOtimizar,
        onClick: () => {

          // if (this.dataSourceOptimizedItens.length == 0) {
          //   this.toastService.warningToast("nao_ha_itens_para_otimizar");
          //   return;
          // }

          this.optimizeItens();
        },
        hint: btnTextOtimizar
      }
    };

    toolbarItens.splice(1, 0, btnOptimize);
  }

  optimizeItens() {

    const formData = new FormData();
    formData.append("orcaproGrouped", JSON.stringify(this.dataSourceItens));
    formData.append("orderId", this.orderId.toString());

    this.showLoad();
    this.pedidoOtimizacaoService.optimizeItens(formData).subscribe(
      (res: ApiResponse<{ success: boolean, message: string }>) => {
        this.hideLoad();
        this.requestCallbackService.okResponse(res,
          () => {
            const orcaproOptimized: any[] = res.data.orcaproOptimized;
            this.dataSourceOptimizedItens = orcaproOptimized;
            this.showOptimize = true;
          });
      }, error => {
        this.hideLoad();
        this.requestCallbackService.failResponse(error);
      });
  }

  onCellPreparedExplode(e) {
    if (
      e.rowType === "data"
      && e.row.data.st_otimizar
    ) {
      e.cellElement.addClass("backgroud-cell-optimize");
    }
  }

  onToolbarPreparingOptimized(e) {
    const toolbarItens: any[] = e.toolbarOptions.items;

    const btnTextVoltar = this.language.translateMessage("voltar");
    const btnBack = {
      location: "before",
      widget: "dxButton",
      name: "Voltar",
      options: {
        width: 135,
        icon: "back",
        text: btnTextVoltar,
        onClick: () => {
          this.showOptimize = false;
        },
        hint: btnTextVoltar
      }
    };

    const btnTextSalvar = this.language.translateMessage("salvar");
    const btnSalvar = {
      location: "after",
      widget: "dxButton",
      name: "Salvar",
      options: {
        width: 135,
        icon: "save",
        text: btnTextSalvar,
        onClick: () => {
          this.saveOptimization();
        },
        hint: btnTextSalvar
      }
    };

    toolbarItens.splice(1, 0, btnBack, btnSalvar);
  }

  onCellPreparedOptimized(e) {
    if (
      e.rowType === "data"
      && e.row.data.st_otimizado
    ) {
      e.cellElement.addClass("backgroud-cell-optimized");
    }
  }

  showLoad() {
    this.loading = true;
  }

  hideLoad() {
    this.loading = false;
  }

  saveOptimization() {

    const formData = new FormData();
    formData.append("orcaproGlass", JSON.stringify(this.dataSourceGlass));
    formData.append("orcaproOptimized", JSON.stringify(this.dataSourceOptimizedItens));

    this.showLoad();
    this.pedidoOtimizacaoService.saveOptimization(formData).subscribe(
      (res: ApiResponse<{ success: boolean, message: string }>) => {
        this.hideLoad();
        this.requestCallbackService.okResponse(res,
          () => {
            this.toastService.successToast("otimizacao_realizada");
            this.notifyOptimizedEvent.emit();
          });
      }, error => {
        this.hideLoad();
        this.requestCallbackService.failResponse(error);
      });
  }

  private setupDataSourceOperacao() {
    this.dataSourceOperacao = this.pedidoItemService.operacoes();
  }

  onValueChanged(event, data) {
    const cd_operacao = event.value;
    const dataGrid = data.component;
    const groupKey = data.key;
    dataGrid.beginUpdate();

    dataGrid.expandRow(groupKey).then(() => {
      const dataSource = dataGrid.getDataSource();
      const itensAgrupados: any[] = dataSource.items();

      const itensGrupo = itensAgrupados.find(itemAgrupado => itemAgrupado.key == groupKey);
      const itens = itensGrupo.items;
      itens.forEach(item => {
        const rowIndex = dataGrid.getRowIndexByKey(item);
        dataGrid.cellValue(rowIndex, "cd_tipo", cd_operacao);
      });
      dataGrid.saveEditData();
    });

    dataGrid.endUpdate();
  }
}
