import { Component, OnInit } from '@angular/core';
import { FinanceiroService } from 'src/app/shared/services/financeiro.service';
import { RequestCallbackService } from 'src/app/shared/helpers/request-callback.service';
import { traduzirStatus } from 'src/app/shared/helpers/util';
import { LoadOptions } from 'devextreme/data/load_options';
import { BaseLazyComponent } from 'src/app/shared/helpers/BaseLazyComponent';
import { LanguagueService } from 'src/app/shared/helpers/language.service';

@Component({
  selector: 'app-vendas-dia',
  templateUrl: './vendas-dia.component.html',
  styleUrls: ['./vendas-dia.component.scss']
})
export class VendasDiaComponent extends BaseLazyComponent implements OnInit {

  loading: boolean = false;

  constructor(
    private financeiro: FinanceiroService,
    private requestCallbackService: RequestCallbackService,
    private language: LanguagueService) {
    super("cd_orca");
    this.language.globalize();
  }

  ngOnInit() {

  }

  showLoad() {
    this.loading = true;
  }

  hideLoad() {
    this.loading = false;
  }

  load(loadOptions: LoadOptions): Promise<any> {
    const formData = this.createFormData([{ key: "loadOptions", value: loadOptions }]);
    return this.requestCallbackService.load(
      this.financeiro.getVendas(formData),
      (response) => {
        const vendas = response.data.vendas.data;
        vendas.forEach(pedido => {
          if (!pedido.key) {
            let orca = traduzirStatus(pedido.st_orca, this.language);
            orca = orca.concat(" (").concat(pedido.st_orca).concat(")");
            pedido.st_orca = orca;
          }
        });
        return response.data.vendas;
      },
      "falha_buscar_vendas",
    );
  }

  onToolbarPreparing(e) {

    let items: any[] = e.toolbarOptions.items;

    // busca a barra de pesquisa
    const searchItem = items[items.length - 1];
    // permite collapse menu
    searchItem.locateInMenu = "auto";

    items.splice(
      0, 0, {
      location: 'after',
      widget: 'dxButton',
      name: 'Imprimir',
      options: {
        icon: 'print',
        onClick: () => { window.print() }
      }
    },
      {
        location: "center",
        template: "dx-title"
      }
    );
  }
}
