import { Injectable } from '@angular/core';


import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { FinanceiroService } from './../services/financeiro.service';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate{

  constructor(private router: Router, private loginService: FinanceiroService) { }
  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.loginService.currentUserValue;
 
    if (currentUser) {
        return  true;
    }
    
    this.router.navigate(['/error'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
