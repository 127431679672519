import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { baseUrl, urlWs } from "src/app/config";
import { LanguagueService } from "src/app/shared/helpers/language.service";
import { RequestCallbackService } from "src/app/shared/helpers/request-callback.service";
import { ToastService } from "src/app/shared/helpers/toast.service";
import { saveDataAsFile } from "src/app/shared/helpers/util";
import { ApiResponse } from "src/app/shared/models/ApiResponse";
import { ResumoTecnicoService } from "../resumo-tecnico/resumo-tecnico.service";

@Injectable({
  providedIn: 'root'
})
export class PedidoPreviewService {

  private url = baseUrl;

  constructor(
    private http: HttpClient,
    private language: LanguagueService,
    private toast: ToastService,
    private requestCallbackService: RequestCallbackService,
    private resumoteTecnicoService: ResumoTecnicoService) { }

  orderPreview(form: FormData): Observable<Blob> {
    this.appendLanguageData(form);
    this.resumoteTecnicoService.setupLabels(form);
    return this.http.post(`${this.url}/OrderPreview`, form, { responseType: 'blob' });
  }

  orderFornec(form: FormData): Observable<Blob> {
    this.resumoteTecnicoService.setupLabels(form);
    return this.http.post(`${this.url}/OrderFornec`, form, { responseType: 'blob' });
  }

  confirmOrderImportBySupplier(form: FormData): Observable<any> {
    return this.http.post(`${this.url}/ConfirmOrderImportBySupplier`, form);
  }

  appendLanguageData(form: FormData) {
    form.append("language", this.language.currentLang());
    const headerLabel = {
      ds_docto: this.language.translateMessage("ds_pedido"),
      dt_entrega: this.language.translateMessage("previsao"),
      dt_data: this.language.translateMessage("emissao"),
      cd_cliente: this.language.translateMessage("cliente"),
      cd_vendedor: this.language.translateMessage("vendedor"),
      cd_cond: this.language.translateMessage("condicao"),
      st_orca: this.language.translateMessage("status"),
      ds_obs: this.language.translateMessage("observacao"),
      vl_venda_total: this.language.translateMessage("valorT"),
      dt_ultima_alteracao: this.language.translateMessage("ultima_alteracao")
    }
    const itemLabel = {
      product: this.language.translateMessage("produto"),
      width: this.language.translateMessage("largura"),
      height: this.language.translateMessage("altura"),
      quantity: this.language.translateMessage("quantidade"),
      unit: this.language.translateMessage("un_venda"),
      total: this.language.translateMessage("total")
    }
    form.append("headerLabel", JSON.stringify(headerLabel));
    form.append("itemLabel", JSON.stringify(itemLabel));
  }

  processResponsePdf(response: Blob): boolean {
    // caso ocorra tudo bem, gera o pdf
    if (response.type == "application/pdf") {
      this.toast.successToast("relatorio_gerado_sucesso");
      try {
        // tenta forçar a abertura do pdf em um nova aba
        let fileURL = URL.createObjectURL(response);
        window.open(fileURL);
        return true;
      } catch (error) {
        // caso não seja possível (antigo EDGE) força o download
        let fileName = this.language.translateMessage("pedidoA").concat("_").concat(new Date().getTime().toString()).concat(".pdf");
        saveDataAsFile(response, fileName, response.type);
        return false;
      }
    } else {
      // caso contrário deserializa a resposta e trata o erro
      let textFileAsBlob = new Blob([response], { type: "json" });
      const reader = new FileReader();
      reader.addEventListener('loadend', (e: any) => {
        const serializedResponse = e.srcElement.result;
        let apiResponse: ApiResponse<{ success: boolean, message: string }> = JSON.parse(serializedResponse);
        this.requestCallbackService.okResponse(apiResponse);
      });
      reader.readAsText(textFileAsBlob);
      return false;
    }
  }

  getUrlWs(): string {
    return urlWs;
  }
}
