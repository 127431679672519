import { Component, OnInit, ViewChild } from '@angular/core';
import { PainelService } from '../painel/painel.service';
import { RequestCallbackService } from 'src/app/shared/helpers/request-callback.service';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { WITHOUT_PERMISSION_KEY, ES_PY_LANGUAGE, EN_US_LANGUAGE, PT_BR_LANGUAGE } from 'src/app/shared/helpers/constants';
import { ConfigService } from './config.service';
import { LanguagueService } from 'src/app/shared/helpers/language.service';
import { BaseLazyComponent } from 'src/app/shared/helpers/BaseLazyComponent';
import { LoadOptions } from 'devextreme/data/load_options';
import { DxDataGridComponent } from 'devextreme-angular';

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.scss']
})
// tarefa #29971
export class ConfigComponent extends BaseLazyComponent implements OnInit {

  loading: boolean;
  responseStatus: number;
  dataSourceConfigs: any = [];
  languages: any = [];
  languageHasBeenChanged: boolean;

  @ViewChild(DxDataGridComponent, { static: false })
  dataGridConfigs: DxDataGridComponent;

  constructor(
    private painelService: PainelService,
    private requestCallbackService: RequestCallbackService,
    private configService: ConfigService,
    private language: LanguagueService
  ) {
    super(["campo", "cd_empresa"]);
    this.language.globalize();
  }

  ngOnInit() {
    this.checkPermission();
  }

  private checkPermission() {
    this.showLoad();
    this.painelService.isUserProfileReitech().subscribe(
      (res: ApiResponse<{ success: boolean, message: string }>) => {
        this.hideLoad();
        this.requestCallbackService.okResponse(res,
          () => {
            if (res.key == WITHOUT_PERMISSION_KEY) {
              this.responseStatus = 2;
            } else {
              this.responseStatus = 1;
              this.setupDataSourceLanguages();
            }
          });
      },
      error => {
        this.hideLoad();
        this.responseStatus = 3;
        this.requestCallbackService.failResponse(error);
      }
    );
  }

  load(loadOptions: LoadOptions): Promise<any> {
    const formData = this.createFormData([{ key: "loadOptions", value: loadOptions }]);
    return this.requestCallbackService.load(
      this.configService.getConfigs(formData),
      (response) => {
        return response.data.configs;
      },
      "",
    );
  }

  update(key: any, values: any): Promise<void> {
    const formData = this.createFormData([{ key: "key", value: key }, { key: "values", value: values }]);
    return this.requestCallbackService.update(this.configService.updateConfig(formData), this.dataGridConfigs, () => {
      if (this.languageHasBeenChanged && values.campo == "idioma") {
        // ALTERA A FLAG PARA O ESTADO ORIGINAL...
        this.languageHasBeenChanged = false;
        this.language.setLanguage(values.descricao);
      }
    });
  }

  onRowUpdating(options) {
    options.newData = Object.assign(options.oldData, options.newData);
  }

  private setupDataSourceLanguages() {
    this.languages = [
      {
        ds_codigo: PT_BR_LANGUAGE,
        ds_nome: this.language.translateMessage("pt")
      },
      {
        ds_codigo: EN_US_LANGUAGE,
        ds_nome: this.language.translateMessage("eng")
      },
      {
        ds_codigo: ES_PY_LANGUAGE,
        ds_nome: this.language.translateMessage("esp")
      }
    ];
  }

  private showLoad() {
    this.loading = false;
  }

  private hideLoad() {
    this.loading = true;
  }

  onToolbarPreparing(e) {

    let items: any[] = e.toolbarOptions.items;

    items.forEach((item) => {
      if (item.name === "revertButton") {
        // recupera o botão 'Desfazer alterações'
        item.options.onClick = () => {
          // chama o comportamento default do botão..
          this.dataGridConfigs.instance.cancelEditData();

          // altera o valor da flag para false..
          this.languageHasBeenChanged = false;
        }
      }
    });

    // busca a barra de pesquisa
    const searchItem = items[items.length - 1];
    // permite collapse menu
    searchItem.locateInMenu = "auto";

    items.unshift({
      location: "center",
      template: "dx-title"
    });
  }

  onLanguageSelected(e, itemData, columnName) {
    const currentValue = this.dataGridConfigs.instance.cellValue(itemData.rowIndex, columnName);
    const selectedValue = e.selectedItem.ds_codigo;
    // notifica o grid apenas se realmente houve alteração, caso contrário entra em looping..
    if (currentValue != selectedValue) {
      // altera o valor da flag para true..
      this.languageHasBeenChanged = true;
      this.dataGridConfigs.instance.cellValue(itemData.rowIndex, columnName, selectedValue);
    }
  }
}
