import { Injectable } from "@angular/core";
import { baseUrl } from "./../../config";
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { LazySelectService } from "src/app/shared/helpers/lazy-select.service";
import { RequestCallbackService } from "src/app/shared/helpers/request-callback.service";
import { LazyDataSource } from "src/app/shared/models/LazyDataSource";

@Injectable({
  providedIn: "root"
})
export class UsuariosService {

  private url = baseUrl;

  constructor(
    private http: HttpClient,
    private requestCallbackService: RequestCallbackService,
    private lazyService: LazySelectService) { }

  insert(form: FormData): Observable<any> {
    return this.http.post(`${this.url}/insertUsuario`, form);
  }

  update(form: FormData): Observable<any> {
    return this.http.post(`${this.url}/updateUser`, form);
  }

  delete(form: FormData): Observable<any> {
    return this.http.post(`${this.url}/deleteUser`, form);
  }

  getEmpresas(form: FormData): Observable<any> {
    return this.http.post(`${this.url}/getEmpresas`, form);
  }

  getVendedoresFabrica(form: FormData): Observable<any> {
    return this.http.post(`${this.url}/readVendedoresFabrica`, form);
  }

  setupDataSourceCompanies(): LazyDataSource {
    return this.lazyService.setupLazyDataSource(
      "cd_empresa",
      "ds_empresa",
      (loadOptions) => {
        const formData = new FormData();
        formData.append("loadOptions", JSON.stringify(loadOptions));
        return this.loadCompanies(formData);
      },
      (formData) => {
        return this.loadCompanyByKey(formData);
      }
    );
  }

  loadCompanies(formData: FormData): Promise<any> {
    return this.requestCallbackService.load(
      this.getEmpresas(formData),
      (response) => {
        return response.data.empresas;
      },
      "falha_buscar_empresas"
    );
  }

  private loadCompanyByKey(formData: FormData): Promise<any> {
    return this.requestCallbackService.load(
      this.getEmpresas(formData),
      (response) => {
        return response.data.empresas.data[0];
      },
      "falha_buscar_empresas",
      true
    );
  }
}
