<app-loader *ngIf="loading"></app-loader>

<div class="m-3">
  <dx-data-grid
    id="data-grid-fornecedores_gcad"
    [dataSource]="dataSource"
    showBorders="true"
    [remoteOperations]="true"
    [columnHidingEnabled]="true"
    [allowColumnReordering]="true"
    [allowColumnResizing]="true"
    (onToolbarPreparing)="onToolbarPreparing($event)"
  >
    <dxo-export
      [enabled]="true"
      [allowExportSelectedData]="false"
      [fileName]="'index.fornecedores_gcad' | translate"
    >
    </dxo-export>

    <dxo-search-panel
      [visible]="true"
      [width]="240"
      [placeholder]="'index.procurar' | translate"
    >
    </dxo-search-panel>

    <dxo-scrolling mode="virtual"></dxo-scrolling>

    <dxi-column
      dataField="ds_nome"
      [caption]="'index.nome' | translate"
      dataType="text"
    >
    </dxi-column>

    <dxi-column
      dataField="cd_ramo"
      [groupIndex]="0"
      [caption]="'index.ramo' | translate"
    >
      <dxo-lookup
        [dataSource]="dataSourceRamos"
        valueExpr="cd_codigo"
        displayExpr="ds_nome">
      </dxo-lookup>
    </dxi-column>

    <dxi-column
      dataField="ds_fantasia"
      [caption]="'index.nomeFantasia' | translate"
      dataType="text"
    >
    </dxi-column>

    <dxi-column
      dataField="ds_cnpj"
      [caption]="'index.cpfCnpj' | translate"
      dataType="text"
    >
    </dxi-column>

    <dxi-column
      dataField="ds_email"
      [caption]="'index.email' | translate"
      dataType="text"
    >
    </dxi-column>

    <dxi-column
      dataField="ds_fone1"
      [caption]="'index.telefone' | translate"
      dataType="phone"
    >
    </dxi-column>

    <dxi-column
      dataField="cd_pais"
      [caption]="'index.pais' | translate">
        <dxo-lookup
            [dataSource]="dataSourceCountries"
            valueExpr="cd_codigo"
            displayExpr="ds_nome">
        </dxo-lookup>
    </dxi-column>

    <dxi-column
      dataField="ds_cidade1"
      [caption]="'index.cidade' | translate"
      calculateDisplayValue="ds_cidade1"
      dataType="text">
      <dxo-lookup
        [dataSource]="dataSourceCities"
        valueExpr="cd_cidade"
        displayExpr="ds_cidade">
      </dxo-lookup>
    </dxi-column>

    <dxi-column
      dataField="ds_endereco1"
      [caption]="'index.endereco' | translate"
      dataType="text"
    >
    </dxi-column>

    <dxi-column
      dataField="ds_numero1"
      [caption]="'index.numero' | translate"
      dataType="text"
    >
    </dxi-column>

    <dxi-column
      dataField="ds_bairro1"
      [caption]="'index.bairro' | translate"
      dataType="text"
    >
    </dxi-column>

    <div *dxTemplate="let data of 'dx-title'">
      <div class="text-center">
        <p class="title mb-0">{{ "index.fornecedores_gcad" | translate }}</p>
      </div>
    </div>
  </dx-data-grid>
</div>
