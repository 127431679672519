import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LanguagueService } from 'src/app/shared/helpers/language.service';
import { RequestCallbackService } from 'src/app/shared/helpers/request-callback.service';
import { ToastService } from 'src/app/shared/helpers/toast.service';
import { sleep } from 'src/app/shared/helpers/util';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { PedidoPreviewService } from './pedido-preview.service';

@Component({
  selector: 'app-pedido-preview',
  templateUrl: './pedido-preview.component.html',
  styleUrls: ['./pedido-preview.component.scss']
})
export class PedidoPreviewComponent implements OnInit {

  loading: boolean;
  statusRequest: string;
  hash: string;
  urlLogoReitech: string;
  relatorioParaFornecedor: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private language: LanguagueService,
    private requestCallbackService: RequestCallbackService,
    private pedidoPreviewService: PedidoPreviewService,
    private toastService: ToastService) {
    this.language.globalize();
  }

  ngOnInit() {
    const hash = this.route.snapshot.queryParamMap.get('hash');
    const fornec = this.route.snapshot.queryParamMap.get('fornec');
    this.relatorioParaFornecedor = fornec == 'true';

    this.hash = hash;
    if (hash) {
      sleep(500).then(() => {
        this.preview();
      });
    } else {
      this.router.navigate(["/404"]);
    }
    this.urlLogoReitech = this.pedidoPreviewService.getUrlWs() + "/logo/glasscad_bw.png";
  }

  showLoad() {
    this.loading = true;
  }

  hideLoad() {
    this.loading = false;
  }

  preview() {
    this.language.translateMessageLazy("gerando_relatorio", (value) => {
      this.statusRequest = value;
    });

    const request = this.gerarRequisicaoConformeTipoRelatorio(this.relatorioParaFornecedor);

    this.showLoad();
    request.toPromise().then((response: Blob) => {
      this.hideLoad();
      const sucesso = this.pedidoPreviewService.processResponsePdf(response);
      if (sucesso) {
        this.statusRequest = this.language.translateMessage("relatorio_gerado_sucesso");
      } else {
        this.statusRequest = this.language.translateMessage("falha_gerar_relatorio");
      }
    }).catch(result => {
      this.statusRequest = this.language.translateMessage("falha_gerar_relatorio");
      this.hideLoad();
      this.requestCallbackService.failResponse(result, "falha_gerar_relatorio");
    });
  }

  private gerarRequisicaoConformeTipoRelatorio(p_relatorioParaFornecedor: boolean): Observable<Blob> {
    const formData = new FormData();
    if (p_relatorioParaFornecedor) {
      formData.append("orderhash", this.hash);
      return this.pedidoPreviewService.orderFornec(formData);
    } else {
      formData.append("cd_tipo", "0");
      formData.append("orderhash", this.hash);
      return this.pedidoPreviewService.orderPreview(formData);
    }
  }

  confirmOrderImportBySupplier(){
    this.showLoad();
    const formData = new FormData();
    formData.append("orderhash", this.hash);
    this.pedidoPreviewService.confirmOrderImportBySupplier(formData).subscribe(
      (res: ApiResponse<{ success: boolean, message: string }>) => {
        this.hideLoad();
        this.requestCallbackService.okResponse(res,
          () => {
            this.toastService.successToast("confirmar_importacao_pedido_sucesso");
          });
      }, error => {
        this.hideLoad();
        this.requestCallbackService.failResponse(error);
      });
  }
}
