import { Injectable } from "@angular/core";
import { baseUrl } from "./../../config";
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
@Injectable({
  providedIn: "root"
})
export class CondicoesService {

  private url = baseUrl;

  constructor(private http: HttpClient) { }

  insert(form: FormData): Observable<any> {
    return this.http.post(`${this.url}/insertCondicoes`, form);
  }

  update(form: FormData): Observable<any> {
    return this.http.post(`${this.url}/updateCondicoes`, form);
  }

  delete(form: FormData): Observable<any> {
    return this.http.post(`${this.url}/deleteCondicoes`, form);
  }

  getData(form: FormData): Observable<any> {
    return this.http.post<any>(`${this.url}/getCondicoes`, form);
  }
}
