import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { getItemStorage } from './util';
import { LANGUAGE, PT_BR_LANGUAGE, ES_ES_LANGUAGE, EN_US_LANGUAGE, ES_PY_LANGUAGE } from './constants';
import Globalize from "globalize";
import supplemental from "devextreme-cldr-data/supplemental.json";
import ptCldrData from "devextreme-cldr-data/pt.json";
import esCldrData from "devextreme-cldr-data/es.json";
import enCldrData from "devextreme-cldr-data/en.json";
import { locale, loadMessages } from "devextreme/localization";
import ptMessages from "devextreme/localization/messages/pt.json";
import esMessages from "devextreme/localization/messages/es.json";
import enMessages from "devextreme/localization/messages/en.json";

@Injectable({
    providedIn: 'root'
})
export class LanguagueService {

    private prefixResource: string = "index.";

    constructor(private translate: TranslateService) {
    }

    globalize() {
        if (this.translate.currentLang == "pt") {
            Globalize.load(supplemental, ptCldrData);
            loadMessages(ptMessages);
            locale(PT_BR_LANGUAGE);
        } else if (this.translate.currentLang == "es") {
            Globalize.load(supplemental, esCldrData);
            loadMessages(esMessages);
            locale(ES_ES_LANGUAGE);
        } else if (this.translate.currentLang == "eng") {
            Globalize.load(supplemental, enCldrData);
            loadMessages(enMessages);
            locale(EN_US_LANGUAGE);
        }
    };

    loadLanguage(idioma: string = "") {

        this.translate.setDefaultLang("pt");

        if (idioma == null || idioma == "") {
            idioma = getItemStorage(LANGUAGE);
            if (idioma == null) {
                idioma = navigator.language;
            }
        }
        let languagePrefix = idioma.slice(0, 2);
        switch (languagePrefix) {
            case "pt": {
                this.translate.use("pt");
                break;
            }
            case "en": {
                this.translate.use("eng");
                break;
            }
            case "es": {
                this.translate.use("es");
                break;
            }
            default: {
                this.translate.use("pt");
                break;
            }
        }
    }

    translateMessage(resourceKey: string): string {
        let msg: string = this.translate.instant(this.prefixResource.concat(resourceKey));
        return msg.replace(this.prefixResource, "");
    }

    translateMessageLazy(resourceKey: string, callback: (message: string) => void) {
        this.translate.get(this.prefixResource.concat(resourceKey)).subscribe((value: string) => {
            callback(value);
        });
    }

    //region tarefa #29971
    setLanguage = (lng: string) => {
        if (lng == PT_BR_LANGUAGE || lng == "") {
            this.translate.use("pt");
        } else if (lng == EN_US_LANGUAGE) {
            this.translate.use("eng");
        } else if (lng == ES_ES_LANGUAGE || lng == ES_PY_LANGUAGE) {
            this.translate.use("es");
        }
        localStorage.setItem("lng", lng);
        parent.document.location.reload();
    }
    //end region tarefa #29971

    currentLang(): string {
        return this.translate.currentLang;
    }
}
