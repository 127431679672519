import { Component, OnInit, ViewChild } from "@angular/core";
import { VendedoresService } from "./vendedores.service";
import { FinanceiroService } from "src/app/shared/services/financeiro.service";
import { ClientesService } from "../clientes/clientes.service";
import { sleep } from "src/app/shared/helpers/util";
import { RequestCallbackService } from "src/app/shared/helpers/request-callback.service";
import { VENDEDOR_TODOS, VENDEDOR_INTERNO, VENDEDOR_EXTERNO, VENDEDOR_ATIVO, VENDEDOR_INATIVO, CONFIG_IDIOMA } from 'src/app/shared/helpers/constants';
import { DxDataGridComponent } from 'devextreme-angular';
import { BaseLazyComponent } from 'src/app/shared/helpers/BaseLazyComponent';
import { LoadOptions } from 'devextreme/data/load_options';
import { LanguagueService } from 'src/app/shared/helpers/language.service';
import { validateRegex } from 'src/app/components/telefone-mascara/TelefoneMascaraUtils';

@Component({
  selector: "app-vendedores",
  templateUrl: "./vendedores.component.html",
  styleUrls: ["./vendedores.component.scss"]
})
export class VendedoresComponent extends BaseLazyComponent implements OnInit {

  loading: boolean = false;
  havePermission: boolean = true;
  formCode: number;
  cidades: any = [];
  dataSourceTipoVendedores = [];

  @ViewChild(DxDataGridComponent, { static: false })
  dataGridVendedores: DxDataGridComponent;

  constructor(
    private service: VendedoresService,
    private language: LanguagueService,
    private financeiroService: FinanceiroService,
    private clienteService: ClientesService,
    private requestCallbackService: RequestCallbackService
  ) {
    super("cd_codigo");
    this.language.globalize();
  }

  ngOnInit() {
    this.setupDataSourceCidades();
    this.setupTipoVendedor();
    //this.getIdiomaConfig();
  }

  showLoad() {
    this.loading = true;
  }

  hideLoad() {
    this.loading = false;
  }

  load(loadOptions: LoadOptions): Promise<any> {
    const formData = this.createFormData([{ key: "loadOptions", value: loadOptions }]);
    return this.requestCallbackService.load(
      this.service.read(formData),
      (response) => {
        if (this.requestCallbackService.isResponseWithoutPermission(response)) {
          this.formCode = response.data.form;
          this.havePermission = false;
        } else {
          const vendedores: any[] = response.data.vendedores.data;
          vendedores.forEach(vendedor => {
            vendedor.st_ativo = vendedor.st_ativo == VENDEDOR_ATIVO;
          });
        }
        return response.data.vendedores;
      },
      "falha_buscar_vendedores",
    );
  }

  setupDataSourceCidades() {
    this.cidades = this.clienteService.getDataSourceCidade();
  }

  insert(values: any): Promise<void> {
    this.defineStAtivo(values);
    const formData = this.createFormData([{ key: "values", value: values }]);
    return this.requestCallbackService.insert(this.service.insert(formData), this.dataGridVendedores);
  }

  update(key: any, values: any): Promise<void> {
    this.defineStAtivo(values);
    const formData = this.createFormData([{ key: "key", value: key }, { key: "values", value: values }]);
    return this.requestCallbackService.update(this.service.update(formData), this.dataGridVendedores);
  }

  delete(key: any): Promise<void> {
    const formData = this.createFormData([{ key: "key", value: key }]);
    return this.requestCallbackService.delete(this.service.delete(formData), this.dataGridVendedores);
  }

  onRowUpdating(options) {
    options.newData = Object.assign(options.oldData, options.newData);
  }

  private defineStAtivo(vendedor: any) {
    vendedor.st_ativo = vendedor.st_ativo == true ? VENDEDOR_ATIVO : VENDEDOR_INATIVO;
  }

  getIdiomaConfig() {
    this.requestCallbackService.doRequestWithoutLoader(
      this.financeiroService.getConfig(CONFIG_IDIOMA),
      (response) => {
        const idioma = response.data.config.descricao;
        if (idioma == "pt-BR") {
          this.dataGridVendedores.instance.columnOption("ds_rg", "visible", false);
        }
      },
      "falha_config_idioma"
    );
  }

  private setupTipoVendedor() {
    sleep(200).then(() => {
      this.dataSourceTipoVendedores = [
        {
          cd_codigo: VENDEDOR_TODOS,
          ds_nome: this.language.translateMessage("todos")
        },
        {
          cd_codigo: VENDEDOR_INTERNO,
          ds_nome: this.language.translateMessage("interno")
        },
        {
          cd_codigo: VENDEDOR_EXTERNO,
          ds_nome: this.language.translateMessage("externo")
        },
      ];
    });
  }

  onEditorPreparing(e: any) {
    if (e.parentType == "dataRow") {
      e.editorOptions.inputAttr = { autocomplete: "none" };
    }
  }

  customizeComissionText(cellInfo) {
    if (cellInfo.value) {
      return `${cellInfo.value}%`;
    }
    return "0%";
  }

  customizeMetaText(cellInfo) {
    if (cellInfo.value) {
      return `${cellInfo.value},00`;
    }
    return "0,00";
  }

  onToolbarPreparing(e) {

    let items: any[] = e.toolbarOptions.items;

    // busca a barra de pesquisa
    const searchItem = items[items.length - 1];
    // permite collapse menu
    searchItem.locateInMenu = "auto";

    items.splice(
      1,
      0,
      {
        location: "after",
        widget: "dxButton",
        name: "Imprimir",
        options: {
          icon: "print",
          onClick: () => {
            window.print();
          }
        }
      },
      {
        location: "center",
        template: "dx-title"
      }
    );
  }

  onInitNewRow(e) {
    e.data.st_ativo = true;
  }

  // tarefa #29487
  regex(e): boolean {
    return validateRegex(e);
  }

}
