import { Injectable } from '@angular/core';
import { baseUrl } from 'src/app/config';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class EditorService {
    
    ferragensCache: any[] = [];

    private url = baseUrl;
    constructor(private http: HttpClient) { }

    getListGCADPainted(form: FormData): Observable<any> {
        return this.http.post<any>(`${this.url}/GetListGCADPainted`, form);
    }

    readTopCorfer(form: FormData): Observable<any> {
        return this.http.post<any>(`${this.url}/Read_Top_corfer`, form);
    }

    saveGcadInLib(form: FormData): Observable<any> {
        return this.http.post<any>(`${this.url}/saveGcadInLib`, form);
    }

    excluiConjuntoBib(form: FormData): Observable<any> {
        return this.http.post<any>(`${this.url}/excluiConjuntoBib`, form);
    }

    saveListGCadAsp(form: FormData): Observable<any> {
        return this.http.post<any>(`${this.url}/saveListGCadAsp`, form);
    }

    getVidrosLazy(form: FormData): Observable<any> {
        return this.http.post<any>(`${this.url}/GetVidrosLazy`, form)
    }

    getResumoTecnico(form: FormData): Observable<any> {
        return this.http.post<any>(`${this.url}/GetResumoTecnico`, form);
    }

    getServicos(form: FormData): Observable<any> {
        return this.http.post<any>(`${this.url}/GetServicos`, form);
    }

    setServicos(form: FormData): Observable<any> {
        return this.http.post<any>(`${this.url}/SetServicos`, form);
    }

    getProdutos(form: FormData): Observable<any> {
        return this.http.post<any>(`${this.url}/getProdutos`, form);
    }

    deleteServico(form: FormData): Observable<any> {
        return this.http.post<any>(`${this.url}/deleteServicos`, form);
    }

    saveCalcValorTotalConj(form: FormData): Observable<any> {
        return this.http.post<any>(`${this.url}/saveCalcValorTotalConj`, form);
    }

    calculateFormula(form: FormData): Observable<any> {
        return this.http.post<any>(`${this.url}/calculateFormula`, form);
    }

    getInfoCabecalho(): Observable<any> {
        return this.http.get<any>(`${this.url}/GetInfoCabecalho`);
    }

    pedidoPendenteByEmpresa(form: FormData): Observable<any> {
        return this.http.post<any>(`${this.url}/pedidoPendenteByEmpresa`, form);
    }
    
    adicionarFerragem(form: FormData): Observable<any> {
        return this.http.post<any>(`${this.url}/AddFerragem`, form);
    }

    atualizarFerragem(form: FormData): Observable<any> {
        return this.http.post<any>(`${this.url}/SetPropFerragem`, form);
    }

    excluirFerragem(form: FormData): Observable<any> {
        return this.http.post<any>(`${this.url}/ExcluirFerragem`, form);
    }

    atualizarVidro(form: FormData): Observable<any> {
        return this.http.post<any>(`${this.url}/SetPropVidro`, form);
    }

    excluirVidro(form: FormData): Observable<any> {
        return this.http.post<any>(`${this.url}/ExcluirVidro`, form);
    }

    buscarFerragens(form: FormData): Observable<any> {
        return this.http.post<any>(`${this.url}/ListaFerragens`, form);
    }

    moverObjConjunto(form: FormData): Observable<any> {
        return this.http.post<any>(`${this.url}/moveObjConjunto`, form);
    }
    
}