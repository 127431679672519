<!-- partial:index.partial.html -->
    <div class="body">
        <div class="noise"></div>
        <div class="overlay"></div>
        <div class="terminal">
        <h1> {{ "index.erro" | translate }} <span class="errorcode">404</span></h1>
        <p class="output">{{ "index.msg_pagina_indisponivel" | translate }}.</p>
        <p class="output">{{ "index.por_favor_tente" | translate }} <a (click)="goBack()" href="#1">{{ "index.retornar" | translate }}</a> {{ "index.ou" | translate }} <a routerLink="/pagina" href="#2">{{ "index.retornar_pagina_inicial" | translate }}</a>.</p>
        <p class="output">{{ "index.boa_sorte" | translate }}.</p>
        </div>

        <div id="panel-img-reitech">
            <img id="img-reitech" [src]="urlLogoReitech" />
          </div>
    </div>
<!-- partial -->