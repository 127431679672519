import { Component, OnInit, ViewChild } from '@angular/core';
import { EquipeService } from './equipe.service';
import { RequestCallbackService } from 'src/app/shared/helpers/request-callback.service';
import { LoadOptions } from 'devextreme/data/load_options';
import { BaseLazyComponent } from 'src/app/shared/helpers/BaseLazyComponent';
import { DxDataGridComponent } from 'devextreme-angular';
import { LazyDataSource } from 'src/app/shared/models/LazyDataSource';
import { LazySelectService } from 'src/app/shared/helpers/lazy-select.service';
import { LanguagueService } from 'src/app/shared/helpers/language.service';

@Component({
  selector: "app-equipes",
  templateUrl: "./equipes.component.html",
  styleUrls: ["./equipes.component.scss"]
})
export class EquipesComponent extends BaseLazyComponent implements OnInit {
  constructor(
    private service: EquipeService,
    private language: LanguagueService,
    private requestCallbackService: RequestCallbackService,
    private lazyService: LazySelectService) {
    super("id");
    this.language.globalize();
  }

  havePermission: boolean = true;
  formCode: number;
  dataSourceEmployees: LazyDataSource;

  @ViewChild(DxDataGridComponent, { static: false })
  dataGridTeams: DxDataGridComponent;

  ngOnInit() {
    this.setupDataSourceEmployees();
  }

  load(loadOptions: LoadOptions): Promise<any> {
    const formData = this.createFormData([{ key: "loadOptions", value: loadOptions }]);
    return this.requestCallbackService.load(
      this.service.getEquipe(formData),
      (response) => {
        if (this.requestCallbackService.isResponseWithoutPermission(response)) {
          this.formCode = response.data.form;
          this.havePermission = false;
        }
        return response.data.equipes;
      },
      "falha_buscar_equipes",
    );
  }

  insert(values: any): Promise<void> {
    values.ds_cor = values.ds_cor.substr(1);
    const formData = this.createFormData([{ key: "values", value: values }]);
    return this.requestCallbackService.insert(this.service.post(formData), this.dataGridTeams);
  }

  update(key: any, values: any): Promise<void> {
    values.ds_cor = values.ds_cor.substr(1);
    const formData = this.createFormData([{ key: "key", value: key }, { key: "values", value: values }]);
    return this.requestCallbackService.update(this.service.put(formData), this.dataGridTeams);
  }

  delete(key: any): Promise<void> {
    const formData = this.createFormData([{ key: "key", value: key }]);
    return this.requestCallbackService.delete(this.service.delete(formData), this.dataGridTeams);
  }

  onRowUpdating(options) {
    options.newData = Object.assign(options.oldData, options.newData);
  }

  private setupDataSourceEmployees() {
    this.dataSourceEmployees = this.lazyService.setupLazyDataSource(
      "id",
      "ds_nome",
      (loadOptions) => {
        const formData = new FormData();
        formData.append("loadOptions", JSON.stringify(loadOptions));
        if (loadOptions.skip == undefined) {
          return;
        }
        return this.loadEmployees(formData);
      },
      (formData) => {
        return this.loadEmployeeByKey(formData);
      }
    );
  }

  loadEmployees(formData: FormData): Promise<any> {
    return this.requestCallbackService.load(
      this.service.getEmployeesForTeams(formData),
      (response) => {
        return response.data.employees;
      },
      "falha_buscar_funcionarios"
    );
  }

  loadEmployeeByKey(formData: FormData): Promise<any> {
    return this.requestCallbackService.load(
      this.service.getEmployeesForTeams(formData),
      (response) => {
        return response.data.employees.data[0];
      },
      "falha_buscar_funcionarios",
      true
    );
  }

  onToolbarPreparing(e) {
    let items: any[] = e.toolbarOptions.items;

    // busca a barra de pesquisa
    const searchItem = items[items.length - 1];
    // permite collapse menu
    searchItem.locateInMenu = "auto";

    items.unshift({
      location: "center",
      template: "dx-title"
    });
  }
}
