

import { Injectable } from "@angular/core";
import { baseUrl } from "./../../config";
import { HttpClient } from "@angular/common/http";

@Injectable({
    providedIn: "root"
})

export class TelefoneMascaraService {
    url = baseUrl;
    constructor(private http: HttpClient) { }

    getCountriesWithMask() {
        return this.http.get<any>(`${this.url}/getCountriesWithMask`);
    }
}