import { Component, OnInit, ViewChild } from "@angular/core";
import { ProdutosService } from "./produtos.service";
import DataSource from "devextreme/data/data_source";
import { RequestCallbackService } from "src/app/shared/helpers/request-callback.service";
import { calcMinWidthToDropDownMenu, sleep } from "src/app/shared/helpers/util";
import { CONFIG_OCULTAR_CUSTOS, WITHOUT_PERMISSION_KEY } from 'src/app/shared/helpers/constants';
import * as $ from "jquery";
import { BaseLazyComponent } from 'src/app/shared/helpers/BaseLazyComponent';
import { DxDataGridComponent } from 'devextreme-angular';
import { LoadOptions } from 'devextreme/data/load_options';
import { LanguagueService } from 'src/app/shared/helpers/language.service';
import { ToastService } from 'src/app/shared/helpers/toast.service';
import { HeaderNewService } from 'src/app/components/header-new/header_new.service';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { isUserProfileOnlyFactory } from "src/app/shared/models/UserProfile";
import { FinanceiroService } from "src/app/shared/services/financeiro.service";
import { Plano } from "src/app/shared/models/Plano";

export class Grupo {
  cd_codigo: number;
  ds_grupo: string;
}

export class Subgrupo {
  cd_codigo: number;
  ds_subgrupo: string;
}

export class Unimed {
  cd_unimed: number;
  ds_unimed: string;
}

export class Tipo {
  cd_tipo: number;
  ds_codigo: string;
}

interface Espiao {
  id: number;
  hora: string; // Ajustado para string para lidar com o formato DateTime
  operacao: string;
  ds_usuario: string;
  cd_empresa: number;
}

@Component({
  selector: "app-produtos",
  templateUrl: "./produtos.component.html",
  styleUrls: ["./produtos.component.scss"]
})
export class ProdutosComponent extends BaseLazyComponent implements OnInit {
  width: any;
  historico: Espiao[] = []; // Altere o tipo para Espiao[]
  percentageForm: any = {};
  dataSourceProdutos: DataSource;
  grupos: Grupo[] = [];
  subgrupos: Subgrupo[] = [];
  tipos: Tipo[] = [];
  gruposS = [];
  unimeds: Unimed[];
  loading: boolean = false;
  havePermission: boolean = true;
  formCode: number;
  popupVisible: boolean = false;
  aplicar: string;
  recarregar: string;
  dataSourceIsGeralHeaderFilter = [];
  userProfileIsOnlyFactory: boolean;
  ocultarCustos: boolean;
  historicoLoading: boolean = false;
  popupTitle: string = "Histórico de Alterações";
  popupHistoricoVisible: boolean = false; // Visibilidade do popup de histórico


  @ViewChild(DxDataGridComponent, { static: false })
  dataGridProducts: DxDataGridComponent;

  constructor(
    private service: ProdutosService,
    private toast: ToastService,
    private language: LanguagueService,
    private requestCallbackService: RequestCallbackService,
    private headerService: HeaderNewService,
    private financeiroService: FinanceiroService) {
    super("cd_codigo");
    this.language.globalize();
  }

  ngOnInit() {
    this.setupTranslatedStrings();
    this.checkPermissionProducts();
    // tarefa #33462
    this.getUserData();
    this.getConfigOcultarCustos();
  }

  showLoad = () => {
    this.loading = true;
  }

  hideLoad = () => {
    this.loading = false;
  }

  abrirPopupHistorico(produto: any) {
    this.historicoLoading = true;
    this.popupHistoricoVisible = true;

    this.service.GetHist(produto.cd_codigo).subscribe(
      (historico: Espiao[]) => {
        this.historico = historico.map(item => ({
          ...item, // Copia as outras propriedades do objeto
          operacao: item.operacao.replace(/\n/g, '<br>') // Substitui \n por <br> na operação
        }));
        this.historicoLoading = false;
      },
      error => {
        this.requestCallbackService.failResponse(error, "falha_buscar_historico");
        this.historicoLoading = false;
        console.error(error);
      }
    );
  }

  private formatarDataHora(dataHoraString: string): string {
    const dataHora = new Date(dataHoraString);
    return dataHora.toLocaleString(); // Formata para o formato local
  }

  private getConfigOcultarCustos() {
    this.financeiroService.getMasterConfig(CONFIG_OCULTAR_CUSTOS).subscribe((res: ApiResponse<{ success: boolean, message: string }>) => {
      this.requestCallbackService.okResponse(res,
        () => {
          const ocultar = res.data.config.valor == 1;
          this.ocultarCustos = ocultar;
          this.dataGridProducts.instance.columnOption("vl_custo", "visible", !ocultar);
        }
      );
    }, error => {
      this.requestCallbackService.failResponse(error, "falha_get_config");
    });
  }

  onInitNewRow(e) {
    if (this.ocultarCustos) {
      e.data.vl_custo = 1;
    }
  }

  getUserData() {
    this.headerService.getUserData().subscribe((res: ApiResponse<{ success: boolean, message: string }>) => {
      this.requestCallbackService.okResponse(res,
        () => {
          const data = res.data;
          this.userProfileIsOnlyFactory = isUserProfileOnlyFactory(data.cd_perfil);
          if (data.cd_plano == Plano.Light)
            this.dataGridProducts.instance.columnOption("vl_venda", "allowEditing", false);
        }
      );
    }, error => {
      this.requestCallbackService.failResponse(error, "falha_buscar_info_usuario");
    });
  }

  load(loadOptions: LoadOptions): Promise<any> {
    const formData = this.createFormData([{ key: "loadOptions", value: loadOptions }]);
    return this.requestCallbackService.load(
      this.service.loadProdutos(formData),
      (response) => {
        return response.data.produtos;
      },
      "falha_buscar_produtos",
    );
  }

  insert(values: any): Promise<void> {
    const formData = this.createFormData([{ key: "values", value: values }]);
    return this.requestCallbackService.insert(this.service.insertProduto(formData), this.dataGridProducts);
  }

  update(key: any, values: any): Promise<void> {
    const formData = this.createFormData([{ key: "key", value: key }, { key: "values", value: values }]);
    return this.requestCallbackService.update(this.service.updateProduto(formData), this.dataGridProducts);
  }

  delete(key: any): Promise<void> {
    const formData = this.createFormData([{ key: "key", value: key }]);
    return this.requestCallbackService.delete(this.service.deleteProduto(formData), this.dataGridProducts);
  }

  getGrupos() {
    this.requestCallbackService.doRequestWithoutLoader(
      this.service.getGrupos(),
      (response) => {
        this.grupos = response.data.grupos;
      },
      "falha_buscar_grupos"
    );
  }

  getSubgrupos() {
    this.requestCallbackService.doRequestWithoutLoader(
      this.service.getSubGrupos(),
      (response) => {
        this.subgrupos = response.data.subgrupos;
      },
      "falha_buscar_sub_grupos"
    );
  }

  // tarefa #29629
  setupIsGeralHeaderFilter() {
    this.dataSourceIsGeralHeaderFilter = [{
      text: this.language.translateMessage("privado"),
      value: false
    }, {
      text: this.language.translateMessage("global"),
      value: true
    }]
  }

  checkPermissionProducts() {
    this.requestCallbackService.doRequest(
      this.service.checkPermissionProducts(),
      (response) => {
        if (response.key == WITHOUT_PERMISSION_KEY) {
          this.formCode = response.data.form;
          this.havePermission = false;
        } else {
          this.dataSourceProdutos = this.dataSource;
          this.getGrupos();
          this.getUnimed();
          this.getSubgrupos();
          this.getTipos();
          this.setupIsGeralHeaderFilter();
          this.setupGruposS();
        }
      },
      this.showLoad,
      this.hideLoad,
      "falha_check_permissao_produtos"
    );
  }

  getTipos() {
    this.requestCallbackService.doRequestWithoutLoader(
      this.service.getTipos(),
      (response) => {
        this.tipos = response.data.tiposProdutos;
      },
      "falha_buscar_tipo_produto"
    );
  }

  // tarefa #29641
  private setupGruposS() {
    sleep(200).then(() => {
      this.gruposS = this.service.getGruposGcad();
    });
  }

  getUnimed() {
    this.requestCallbackService.doRequestWithoutLoader(
      this.service.getUnimed(),
      (response) => {
        this.unimeds = response.data.unimeds;
      },
      "falha_buscar_unimed"
    );
  }

  ajustar() {
    if (this.width >= 0 && this.width != null) {
      let filter = this.dataGridProducts.instance.getCombinedFilter(true);

      if (filter == null) {
        filter = [];
      }

      this.requestCallbackService.doRequest(
        this.service.ajustar(JSON.stringify(filter), this.width.toString()),
        (response) => {
          this.popupVisible = false;
          this.width = null;
          this.percentageForm = {};

          const qtdProducts = response.message;
          const products: string = this.language.translateMessage("produtos");
          const msgVlVenda: string = this.language.translateMessage("vlVendaAtualizado");
          const finalMsg = msgVlVenda.concat(" ").concat("<br>").concat(qtdProducts).concat(" ").concat(products);
          this.toast.successToast(finalMsg);
          this.dataSourceProdutos.reload();
        },
        this.showLoad,
        this.hideLoad,
        "vlVendaFalha"
      );
    }
  }

  inject(e) {
    e.preventDefault();
    this.width = this.percentageForm.width;

    if (this.width >= 0) {
      this.ajustar();
    }
  }

  limpar() {
    this.dataGridProducts.instance.clearFilter("row");
  }

  showInfo() {
    this.popupVisible = true;
  }

  setupTranslatedStrings() {
    this.language.translateMessageLazy("aplicar", message => this.aplicar = message);
    this.language.translateMessageLazy("recarregar", message => this.recarregar = message);
  }

  onToolbarPreparing = (e) => {
    const toolBarItems: any[] = e.toolbarOptions.items;
    toolBarItems.splice(
      3,
      0,
      {
        location: "after",
        widget: "dxButton",
        name: "Aplicar %",
        locateInMenu: "auto",
        visible: !this.userProfileIsOnlyFactory, // tarefa #33462
        options: {
          icon: "percent",
          onClick: this.showInfo.bind(this),
          hint: this.aplicar
        }
      },
      {
        location: "after",
        widget: "dxButton",
        locateInMenu: "auto",
        name: "limpar",
        options: {
          icon: "refresh",
          onClick: this.limpar.bind(this),
          hint: this.recarregar
        }
      },
      {
        location: "after",
        widget: "dxButton",
        name: "Imprimir",
        options: {
          icon: "print",
          onClick: () => {
            window.print();
          }
        }
      },
      {
        location: "center",
        template: "dx-title"
      }
    );
  }

  // region tarefa 28245
  onEditorPreparing(e) {
    if (
      e.dataField != "vl_venda"
      && e.dataField != "is_geral"
      && e.dataField != "vl_estoque_empenhado"
      && e.dataField != "vl_estoque"
      && e.dataField != "vl_estoque_previsto"
      && e.dataField != "vl_perc_venda"
    ) {
      if (e.row != null && e.row.data != null)
        e.editorOptions.disabled = e.row.data.is_geral;
    }

    // tarefa #29629
    if (e.parentType === "filterRow" && e.dataType === "boolean") {
      e.editorOptions.itemTemplate = "isGeralTemplate";
    }

    //tarefa #31616
    if (e.parentType == "filterRow" && e.editorName == 'dxSelectBox') {
      e.editorOptions.onOpened = (e) => { e.component._popup.option('width', this.minWidthSelectBox()); };
    }
  }

  onCellPrepared(e) {
    if (
      e.rowType === "data" &&
      e.column.dataField != null &&
      e.column.dataField != "vl_venda" &&
      e.column.dataField != "is_geral" &&
      e.column.dataField != "vl_estoque"
    ) {
      if (e.row.data.is_geral) {
        e.cellElement.addClass("backgroud-cell");
      }
    }
  }
  // end region 28245

  countColumnCustomizeText = (cellInfo) => {
    return this.language.translateMessage("produtos") + ": " + cellInfo.value;
  }

  onRowUpdating(options) {
    options.newData = Object.assign(options.oldData, options.newData);
  }

  //
  allowDeleting = (e): boolean => {
    // permite o delete apenas se o produto nao for do tipo is_geral
    return !(e.row.data.is_geral) && !this.userProfileIsOnlyFactory; // tarefa #33462
  }

  contentReady() {
    let headerFilterGeral = $("#header_filter_geral").parent();
    $(headerFilterGeral).prev().hide();
    $(headerFilterGeral).hide();

    let rowFilterGeral = $(".dx-datagrid-filter-row td:first-child").find(".dx-editor-container");
    $(rowFilterGeral).hide();
  }

  // region tarefa #31616
  minWidthSelectBox(): number {
    return calcMinWidthToDropDownMenu(350);
  }
  // end region tarefa #31616
}
