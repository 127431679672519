import { Component, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { LoadOptions } from 'devextreme/data/load_options';
import { BaseLazyComponent } from 'src/app/shared/helpers/BaseLazyComponent';
import { LanguagueService } from 'src/app/shared/helpers/language.service';
import { RequestCallbackService } from 'src/app/shared/helpers/request-callback.service';
import { ToastService } from 'src/app/shared/helpers/toast.service';
import { saveDataAsFile } from 'src/app/shared/helpers/util';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { LazyDataSource } from 'src/app/shared/models/LazyDataSource';
import { UsuariosService } from '../usuarios/usuarios.service';
import { FerragensService } from './ferragens.service';

@Component({
  selector: 'app-ferragens',
  templateUrl: './ferragens.component.html',
  styleUrls: ['./ferragens.component.scss']
})
export class FerragensComponent extends BaseLazyComponent implements OnInit {

  havePermission: boolean = true;
  formCode: number;
  dataSourceCompanies: LazyDataSource;
  dxfIsRequired: boolean;

  @ViewChild(DxDataGridComponent, { static: false })
  dataGridFerragens: DxDataGridComponent;

  constructor(
    private language: LanguagueService,
    private requestCallbackService: RequestCallbackService,
    private ferragensService: FerragensService,
    private serviceUser: UsuariosService,
    private toast: ToastService
  ) {
    super("id");
    this.language.globalize();
  }

  ngOnInit() {
    this.setupDataSourceCompanies();
  }

  private setupDataSourceCompanies() {
    this.dataSourceCompanies = this.serviceUser.setupDataSourceCompanies();
  }

  load(loadOptions: LoadOptions): Promise<any> {
    const formData = this.createFormData([{ key: "loadOptions", value: loadOptions }]);
    return this.requestCallbackService.load(
      this.ferragensService.getFerragens(formData),
      (response) => {
        if (this.requestCallbackService.isResponseWithoutPermission(response)) {
          this.formCode = response.data.form;
          this.havePermission = false;
        }
        return response.data.ferragens;
      },
      "falha_buscar_ferragens",
    );
  }

  CM_DownloadDxf(data) {
    const id = data.id;
    const formData = this.createFormData([{ key: "id", value: id }]);
    this.ferragensService.getDXFById(formData).subscribe(
      (res: ApiResponse<{ success: boolean, message: string }>) => {
        this.requestCallbackService.okResponse(res,
          () => {
            const fileName = res.data.fileName;
            const dxf = res.data.dxf;
            saveDataAsFile(dxf, fileName);
            this.toast.successToast("dxf_baixado_sucesso");
          });
      },
      error => {
        this.requestCallbackService.failResponse(error, "falha_baixar_dxf");
      }
    );
  }

  insert(values: any): Promise<void> {
    const formData = this.createFormData([{ key: "values", value: values }]);
    return this.requestCallbackService.insert(this.ferragensService.insertFerragem(formData), this.dataGridFerragens);
  }

  update(key: any, values: any): Promise<void> {
    const formData = this.createFormData([{ key: "key", value: key }, { key: "values", value: values }]);
    return this.requestCallbackService.update(this.ferragensService.updateFerragem(formData), this.dataGridFerragens);
  }

  delete(key: any): Promise<void> {
    const formData = this.createFormData([{ key: "key", value: key }]);
    return this.requestCallbackService.delete(this.ferragensService.deleteFerragem(formData), this.dataGridFerragens);
  }

  onRowUpdating(options) {
    options.newData = Object.assign(options.oldData, options.newData);
  }

  cm_allowEditAndDelete = (e): boolean => {
    return e.row.data.st_edita;
  }

  uploadDxf(event: any, data: any) {
    try {
      const file: File = event.value[0];
      const reader = new FileReader();
      reader.onload = () => {
        const txt_dxf = reader.result
        data.setValue(txt_dxf);
      };
      reader.readAsText(file);
    } catch (e) {
      this.toast.warningToast("arquivo_invalido");
    }
  }

  onInitNewRow(e) {
    this.dxfIsRequired = true;
  }

  onEditingStart() {
    this.dxfIsRequired = false;
  }

  onToolbarPreparing(e) {

    const items: any[] = e.toolbarOptions.items;
    const searchItem = items[items.length - 1];
    searchItem.locateInMenu = "auto";

    items.unshift({
      location: "center",
      template: "dx-title"
    });
  }
}
