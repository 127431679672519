<!-- IMG BACKGROUND -->
<img id="bg" [src]="urlBgImage">
<div id="container">
  <div id="menu">
    <!-- <app-header></app-header> -->
    <app-header-new></app-header-new>
  </div>
  <div id="content">
    <div class="alert-panel" *ngIf="licenseExpiring">
      <div class="alert alert-danger" role="alert">
        {{ "index.erro_contatar_fornecedor" | translate }}
      </div>   
    </div>
    <router-outlet (activate)='onActivate()'></router-outlet>
  </div>
</div>