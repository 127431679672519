import { baseUrl } from "./../../config";
import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: "root"
})
export class SentryNotifyWSService {
    constructor(private http: HttpClient) { }

    sendEventToWS(form: FormData) {
        return this.http.post(`${baseUrl}/notiFySentry`, form);
    }
}