<div *ngIf="technicalSummary ">
    <div class="d-none d-print-block">
        <!-- INICIO Cabecalho DADOS DA EMPRESA -->
        <div class="border p-2 mb-2 ml-3 mr-3">
            <div class="container">
                <div class="row">
                    <div class="col-sm-2 align-self-center">
                        <img class="img_cabecalho_imp pull-left" [src]="cabecalhoImpressao.ds_logo_vidracaria" />
                    </div>
                    <div class="col-sm-8">
                        <div class="text-center dados_cabecalho">
                            <p>{{ cabecalhoImpressao.nome_fantasia }}</p>
                            <p>
                            {{ cabecalhoImpressao.ds_nome_cliente }} /
                            {{ cabecalhoImpressao.ds_cnpj }}
                            </p>
                            <p>
                            {{ cabecalhoImpressao.ds_endereco }} -
                            {{ cabecalhoImpressao.ds_cidade }} -
                            {{ cabecalhoImpressao.ds_estado }}
                            </p>
                            <p>{{ cabecalhoImpressao.ds_fone }}</p>
                        </div>
                    </div>
                    <div class="col-sm-2 align-self-center">
                        <img class="img_cabecalho_imp pull-right" [src]="cabecalhoImpressao.ds_logo" /> 
                    </div>
                </div>
            </div>
        </div>
        <!-- END Cabecalho DADOS DA EMPRESA -->
    </div>

    <h5 class="d-none d-print-block text-center mb-4"> {{'index.resumo_tecnico' | translate }} </h5>
    <div class="row no-gutters">
        <div class="col-md-3 align-self-center">
            <img [src]="technicalSummary.imagem" class="ml-2 img-fluid">
        </div>
        <div class="col-md-9 align-self-center">
            <div class="container">
                <div class="row no-gutters">
                    <div class="col-10">
                        <div class="row m-3">
                            <span class="font-weight-bold">{{ "index.grupo" | translate }}: </span>
                            <span class="ml-2">{{ technicalSummary.grupo }}</span>
                        </div>
                        <div class="row m-3">
                            <span class="font-weight-bold">{{ "index.descricao" | translate }}:</span> 
                            <span class="ml-2">{{ technicalSummary.descricao }}</span>
                        </div>
                        <div class="row m-3"> 
                            <span class="font-weight-bold">{{ "index.vao" | translate }}:</span> 
                            <span class="ml-2">{{ technicalSummary.vao }}</span>
                        </div>
                        <div class="row m-3">
                            <span class="font-weight-bold">{{ "index.vidro" | translate }}:</span> 
                            <span class="ml-2">{{ technicalSummary.vidro }}</span>
                        </div>
                        <div class="row m-3">
                            <span class="font-weight-bold">{{ "index.ferragem" | translate }}:</span> 
                            <span class="ml-2">{{ technicalSummary.ferragem }}</span>
                        </div>
                        <div class="row m-3">
                            <span class="font-weight-bold">{{ "index.total_calculado" | translate }}:</span> 
                            <span class="ml-2">{{ technicalSummary.valor_total_comb }}</span>
                        </div>
                        <div class="row m-3" *ngIf="conjunto.st_calculoManual">
                            <span class="font-weight-bold">{{ "index.total_negociado" | translate }}:</span>
                            <span class="ml-2">{{ conjunto.valorTotal }}</span>
                        </div>
                    </div>
                    <div class="col-2">
                        <dx-button class="no-print" icon="print" (onClick)="printTechnicalSummary()">
                        </dx-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr/>
    <div>
        <h6>{{ "index.vidros" | translate }}</h6>
        <dx-data-grid
            [dataSource]="technicalSummary.vidros"
            [allowColumnReordering]="true" 
            [allowColumnResizing]="true"
            [showBorders]="true">

            <dxi-column 
                dataField="Descricao" 
                [caption]="'index.descricao' | translate">
            </dxi-column>

            <dxi-column 
                dataField="M2" 
                [caption]="'index.m2' | translate">
            </dxi-column>

            <dxi-column 
                dataField="M2_Calc" 
                [caption]="'index.m2_calc' | translate">
            </dxi-column>

            <dxi-column 
                dataField="tamanho" 
                [caption]="'index.quantidade' | translate">
            </dxi-column>

            <dxi-column 
                dataField="vl_custo" 
                [(visible)]="!ocultarCustos"
                [caption]="'index.custo' | translate">
            </dxi-column>

            <dxi-column 
                dataField="vl_venda" 
                [caption]="'index.venda' | translate">
            </dxi-column>

            <dxi-column 
                dataField="custoTotal" 
                [(visible)]="!ocultarCustos"
                [caption]="'index.custo_total' | translate">
            </dxi-column>

            <dxi-column 
                dataField="vendaTotal" 
                [caption]="'index.valor_total' | translate">
            </dxi-column>

            <dxo-summary>
                <dxi-total-item column="custoTotal" summaryType="sum" valueFormat="##0.##"> </dxi-total-item>
                <dxi-total-item column="vendaTotal" summaryType="sum" valueFormat="##0.##"> </dxi-total-item>
            </dxo-summary>

        </dx-data-grid>
    </div>

    <div class="mt-4">
        <h6>{{ "index.ferragens" | translate }}</h6>
        <dx-data-grid
            [dataSource]="technicalSummary.ferragens"
            [allowColumnReordering]="true" 
            [allowColumnResizing]="true"
            [showBorders]="true">

            <dxi-column 
                dataField="DsCodigo" 
                [caption]="'index.descricao' | translate">
            </dxi-column>

            <dxi-column 
                dataField="qtdeTotal" 
                [caption]="'index.pecas' | translate">
            </dxi-column>

            <dxi-column 
                dataField="vl_custo" 
                [(visible)]="!ocultarCustos"
                [caption]="'index.custo' | translate">
            </dxi-column>

            <dxi-column 
                dataField="vl_venda" 
                [caption]="'index.venda' | translate">
            </dxi-column>

            <dxi-column 
                dataField="totalCusto" 
                [(visible)]="!ocultarCustos"
                [caption]="'index.custo_total' | translate">
            </dxi-column>

            <dxi-column 
                dataField="totalVenda" 
                [caption]="'index.valor_total' | translate">
            </dxi-column>

            <dxo-summary>
                <dxi-total-item column="totalCusto" summaryType="sum" valueFormat="##0.##"> </dxi-total-item>
                <dxi-total-item column="totalVenda" summaryType="sum" valueFormat="##0.##"> </dxi-total-item>
            </dxo-summary>

        </dx-data-grid>
    </div>

    <div class="mt-4">
        <h6>{{ "index.perfis" | translate }}</h6>
        <dx-data-grid
            [dataSource]="technicalSummary.perfis"
            [allowColumnReordering]="true" 
            [allowColumnResizing]="true"
            [showBorders]="true">

            <dxi-column 
                dataField="CodProd" 
                [caption]="'index.descricao' | translate">
            </dxi-column>

            <dxi-column 
                dataField="Qtde_Pc" 
                [caption]="'index.quantidade' | translate">
            </dxi-column>

            <dxi-column 
                dataField="Qtde_calculada" 
                [caption]="'index.tam_cobranca' | translate">
            </dxi-column>

            <dxi-column 
                dataField="ValorCusto" 
                [(visible)]="!ocultarCustos"
                [caption]="'index.custo' | translate">
            </dxi-column>

            <dxi-column 
                dataField="Valor" 
                [caption]="'index.venda' | translate">
            </dxi-column>

            <dxi-column 
                dataField="CustoTotal" 
                [(visible)]="!ocultarCustos"
                [caption]="'index.custo_total' | translate">
            </dxi-column>

            <dxi-column 
                dataField="ValorTotal" 
                [caption]="'index.valor_total' | translate">
            </dxi-column>

            <dxo-summary>
                <dxi-total-item column="CustoTotal" summaryType="sum" valueFormat="##0.##"> </dxi-total-item>
                <dxi-total-item column="ValorTotal" summaryType="sum" valueFormat="##0.##"> </dxi-total-item>
            </dxo-summary>

        </dx-data-grid>
    </div>

    <div class="mt-4">
        <h6>{{ "index.acessorios_gerais" | translate }}</h6>
        <dx-data-grid
            [dataSource]="technicalSummary.acessorios"
            [allowColumnReordering]="true" 
            [allowColumnResizing]="true"
            [showBorders]="true">

            <dxi-column 
                dataField="CodProd" 
                [caption]="'index.descricao' | translate">
            </dxi-column>

            <dxi-column 
                dataField="Qtde_Pc" 
                [caption]="'index.quantidade' | translate">
            </dxi-column>

            <dxi-column 
                dataField="Qtde_calculada" 
                [caption]="'index.tam_cobranca' | translate">
            </dxi-column>

            <dxi-column 
                dataField="ValorCusto" 
                [(visible)]="!ocultarCustos"
                [caption]="'index.custo' | translate">
            </dxi-column>

            <dxi-column 
                dataField="Valor" 
                [caption]="'index.venda' | translate">
            </dxi-column>

            <dxi-column 
                dataField="CustoTotal" 
                [(visible)]="!ocultarCustos"
                [caption]="'index.custo_total' | translate">
            </dxi-column>

            <dxi-column 
                dataField="ValorTotal" 
                [caption]="'index.valor_total' | translate">
            </dxi-column>

            <dxo-summary>
                <dxi-total-item column="CustoTotal" summaryType="sum" valueFormat="##0.##"> </dxi-total-item>
                <dxi-total-item column="ValorTotal" summaryType="sum" valueFormat="##0.##"> </dxi-total-item>
            </dxo-summary>

        </dx-data-grid>
        
    </div>

    <div class="mt-4">
        <h6>{{ "index.indefinido" | translate }}</h6>
        <dx-data-grid
            [dataSource]="technicalSummary.indefinidos"
            [allowColumnReordering]="true" 
            [allowColumnResizing]="true"
            [showBorders]="true">

            <dxi-column 
                dataField="CodProd" 
                [caption]="'index.descricao' | translate">
            </dxi-column>

            <dxi-column 
                dataField="Qtde_Pc" 
                [caption]="'index.quantidade' | translate">
            </dxi-column>

            <dxi-column 
                dataField="Qtde_calculada" 
                [caption]="'index.tam_cobranca' | translate">
            </dxi-column>

            <dxi-column 
                dataField="ValorCusto" 
                [(visible)]="!ocultarCustos"
                [caption]="'index.custo' | translate">
            </dxi-column>

            <dxi-column 
                dataField="Valor" 
                [caption]="'index.venda' | translate">
            </dxi-column>

            <dxi-column 
                dataField="CustoTotal" 
                [(visible)]="!ocultarCustos"
                [caption]="'index.custo_total' | translate">
            </dxi-column>

            <dxi-column 
                dataField="ValorTotal" 
                [caption]="'index.valor_total' | translate">
            </dxi-column>

            <dxo-summary>
                <dxi-total-item column="CustoTotal" summaryType="sum" valueFormat="##0.##"> </dxi-total-item>
                <dxi-total-item column="ValorTotal" summaryType="sum" valueFormat="##0.##"> </dxi-total-item>
            </dxo-summary>

        </dx-data-grid>
        
    </div>
</div>
