<!-- INICIO Cabecalho PEDIDO -->
<div class="border p-2 cabecalhoPedido">
    <form (submit)="savePedidoCabecalho($event)">

        <div *ngIf="!isNewRegister" class="d-print-none pull-right">
            <dx-menu 
                [items]="dataSourceMenuOthers" 
                displayExpr="name" 
                orientation="horizontal" 
                submenuDirection="auto"
                [hideSubmenuOnMouseLeave]="false" 
                (onItemClick)="itemClickMenuOthers($event)">
                <div *dxTemplate="let data of 'rootOther'">
                    <dx-button [icon]="data.icon" [hint]="data.name">
                    </dx-button>
                </div>
            </dx-menu>
        </div>

        <dx-button 
            class="btn-back d-print-none" 
            icon="fas fa-file-invoice" 
            [text]="'index.pedido' | translate"
            (onClick)="redirectToPedidos()">
        </dx-button>

        <div *ngIf="!isNewRegister" class="d-print-none pull-right">
            <dx-menu 
                [items]="dataSourceMenuPrint" 
                displayExpr="name" 
                orientation="horizontal" 
                submenuDirection="auto"
                [hideSubmenuOnMouseLeave]="false" 
                (onItemClick)="itemClick($event)">
                <div *dxTemplate="let data of 'root'">
                    <dx-button [icon]="data.icon" [hint]="data.name">
                    </dx-button>
                </div>
            </dx-menu>
        </div>

        <!-- Exibe botao delete qunado nao for novo registro -->
        <dx-button 
            *ngIf="!isNewRegister" 
            class="d-print-none pull-right" 
            [hint]="'index.deletar' | translate"
            icon="trash" 
            (onClick)="showDialogDeletePedido()">
        </dx-button>

        <dx-button 
            class="d-print-none pull-right mr-2" 
            icon="save" 
            useSubmitBehavior="true"
            validationGroup="validationOrder" 
            [hint]="'index.salvar' | translate">
        </dx-button>

        <dx-form 
            id="form" 
            [formData]="dataSourcePedidoCabecalho" 
            labelLocation="left" 
            validationGroup="validationOrder"
            (onInitialized)="saveFormInstance($event)">
            <dxi-item itemType="group">
                <dxi-item itemType="group" [colCount]="3">
                    <dxi-item 
                        dataField="ds_docto" 
                        [label]="{ text: 'index.ds_pedido' | translate }"
                        [editorOptions]="{ readOnly: disableAllFields, hoverStateEnabled: false, maxLength: 15 }">
                        <!-- #31102 ds_docto somente leitura -->
                    </dxi-item>
                    <dxi-item 
                        dataField="dt_entrega" 
                        editorType="dxDateBox"
                        [label]="{ text: 'index.previsao' | translate }"
                        [editorOptions]="{ readOnly: disableAllFields, hoverStateEnabled: !disableAllFields, onFocusIn: onFocusInDtEntrega }">
                        <dxi-validation-rule type="required"> </dxi-validation-rule>
                    </dxi-item>
                    <dxi-item 
                        dataField="dt_data" 
                        editorType="dxDateBox" 
                        [label]="{ text: 'index.emissao' | translate }"
                        [editorOptions]="{ readOnly: true, hoverStateEnabled: false }">
                    </dxi-item>
                </dxi-item>
                <dxi-item itemType="group" [colCount]="2">
                    
                    <dxi-item 
                        *ngIf="lookUpToShow == -1"
                        dataField="cd_empresa" 
                        [label]="{ text: 'index.cliente' | translate }" >
                        <dxi-validation-rule type="required"> </dxi-validation-rule>
                    </dxi-item>

                    <dxi-item 
                        *ngIf="lookUpToShow == 1"
                        dataField="cd_empresa" 
                        [label]="{ text: 'index.cliente' | translate }" 
                        editorType="dxSelectBox" 
                        [editorOptions]="{
                            dataSource: dataSourceVidraceiros,
                            displayExpr: 'ds_fantasia',
                            valueExpr: 'cd_empresa',
                            searchMode: 'contains',
                            searchEnabled:'true',
                            readOnly: disableAllFields,
                            hoverStateEnabled: !disableAllFields
                        }">
                        <dxi-validation-rule type="required"> </dxi-validation-rule>
                    </dxi-item>

                    <dxi-item 
                        *ngIf="lookUpToShow == 2"
                        dataField="cd_cliente" 
                        [label]="{ text: 'index.cliente' | translate }"
                        editorType="dxSelectBox" 
                        [editorOptions]="{
                            dataSource: dataSourceClient,
                            displayExpr: 'ds_nome',
                            valueExpr: 'cd_codigo',
                            searchMode: 'contains',
                            searchEnabled:'true',
                            readOnly: disableAllFields,
                            hoverStateEnabled: !disableAllFields,
                            buttons: commandButtons,
                            onInput: inputHandler,
                            onClosed: closedHandler,
                            noDataText: noDataCustom,
                            onEnterKey: onEnterKeyClient,
                            onOpened: onOpenedHandler,
                            onValueChanged: onValueChangedClient,
                            elementAttr: {id: 'dxSelectBoxClient'}
                        }">
                        <dxi-validation-rule type="required"> </dxi-validation-rule>
                    </dxi-item>

                    <dxi-item 
                        dataField="cd_vendedor" 
                        cssClass="drop-down-vendedor"
                        [label]="{ text: 'index.vendedor' | translate }" 
                        editorType="dxSelectBox" 
                        [editorOptions]="{
                            dataSource: dataSourceSellers,
                            displayExpr: 'ds_nome',
                            valueExpr: 'cd_codigo',
                            searchMode: 'contains',
                            searchEnabled:'true',
                            readOnly: disableAllFields,
                            hoverStateEnabled: !disableAllFields
                        }">
                    </dxi-item>
                    <dxi-item 
                        dataField="cd_cond" 
                        [label]="{ text: 'index.condicao' | translate }"
                        editorType="dxSelectBox" 
                        [editorOptions]="{
                            dataSource: dataSourceCondicoes,
                            displayExpr: 'ds_descricao',
                            valueExpr: 'cd_codigo',
                            searchMode: 'contains',
                            searchEnabled:'true',
                            readOnly: disableAllFields,
                            hoverStateEnabled: !disableAllFields
                        }">
                    </dxi-item>
                    <dxi-item *ngIf="!isNewRegister" 
                        dataField="st_orca" 
                        [label]="{ text: 'index.status' | translate }"
                        [editorOptions]="{ readOnly: true, hoverStateEnabled: false }">
                    </dxi-item>
                </dxi-item>
                <dxi-item itemType="group" [colCount]="3">
                    <dxi-item 
                        dataField="ds_obs" 
                        [label]="{ text: 'index.observacao' | translate }"
                        [editorOptions]="{ readOnly: disableAllFields, hoverStateEnabled: !disableAllFields, maxLength: 100 }">
                    </dxi-item>
                    <dxi-item *ngIf="!isNewRegister" 
                        dataField="vl_venda_total"
                        [label]="{ text: 'index.valorT' | translate }" 
                        [editorOptions]="{ readOnly: true, hoverStateEnabled: false }">
                    </dxi-item>
                    <dxi-item *ngIf="!isNewRegister"
                        cssClass="d-print-none cost-field" 
                        dataField="vl_custo"
                        [label]="{ text: 'index.custo' | translate }"
                        [editorOptions]="{ buttons: buttonsCost, mode: fieldCostMode }">
                    </dxi-item>
                </dxi-item>
            </dxi-item>
        </dx-form>
    </form>
</div>
<!-- FIM Cabecalho PEDIDO -->


<dx-popup
    [width]="'auto'"
    [height]="'auto'"
    [showTitle]="true"
    [title]="'index.deletar_pedido' | translate" 
    [dragEnabled]="false"
    [closeOnOutsideClick]="true"
    [(visible)]="popupDeletePedidoVisible">

    <div *dxTemplate="let data of 'content'">
        <div class="option">
            <span>{{ "index.msg_delete_pedido" | translate }} {{dataSourcePedidoCabecalho.ds_docto}} ?</span> 
        </div>
        <br>
        <div class="row">
            <div class="col d-flex text-center">
                <dx-button
                    class="button-info"
                    [text]="'index.sim' | translate"
                    (onClick)="deletePedido()">
                </dx-button>
            </div>
            <div class="col d-flex text-center">
                <dx-button
                    class="button-info"
                    [text]="'index.cancelar' | translate"
                    (onClick)="closeDialogDeletePedido($event)">
                </dx-button>
            </div>
        </div>
    </div>
</dx-popup>

<!-- region tarefa #30164 -->
<dx-popup
    [width]="'auto'"
    [height]="'auto'"
    [showTitle]="true"
    [title]="'index.novo_cliente' | translate"
    [dragEnabled]="false"
    [closeOnOutsideClick]="true"
    [(visible)]="popupNewClientVisible">

    <div *dxTemplate="let data of 'content'">
        <dx-scroll-view width="100%" height="100%">
            <form (submit)="handleNewClient($event)">
                <dx-form
                    [formData]="newClient"
                    [readOnly]="false"
                    [showColonAfterLabel]="true"
                    [showValidationSummary]="true">

                    <dxi-item 
                        itemType="button"
                        horizontalAlignment="right"
                        [buttonOptions]="btnSaveAndReturnOptions">
                    </dxi-item>

                    <dxi-item itemType="group" [colCount]="2">
                        <dxi-item 
                            dataField="ds_nome" 
                            [label]="{ text: 'index.nome' | translate }"
                            [editorOptions]="{ maxLength: 60 }">
                            <dxi-validation-rule 
                                type="required">
                            </dxi-validation-rule>
                        </dxi-item>
                        <dxi-item 
                            dataField="ds_email" 
                            [label]="{ text: 'index.email' | translate }"
                            [editorOptions]="{ maxLength: 100 }">
                            <dxi-validation-rule 
                            type="email">
                            </dxi-validation-rule>
                        </dxi-item>
                    </dxi-item>
                    <dxi-item itemType="group" [colCount]="2">
                        <dxi-item 
                            dataField="ds_fone1" 
                            [label]="{ text: 'index.telefone' | translate }"
                            [editorOptions]="{ maxLength: 30 }">
                        </dxi-item>
                        <dxi-item 
                            dataField="ds_tipo" 
                            [label]="{ text: 'index.tipo' | translate }"
                            editorType="dxSelectBox" 
                            [editorOptions]="{
                            items: tipoPessoa,
                            displayExpr: 'ds_tipo',
                            valueExpr: 'id',
                            searchMode: 'contains',
                            searchEnabled:'true'
                            }">
                        </dxi-item>
                    </dxi-item>
                    <dxi-item itemType="group" [colCount]="2">
                        <dxi-item 
                            dataField="ds_cpf" 
                            [label]="{ text: 'index.cpfCnpj' | translate }"
                            [editorOptions]="{ maxLength: 14 }">
                        </dxi-item>
                        <dxi-item 
                            dataField="ds_endereco1" 
                            [label]="{ text: 'index.endereco' | translate }"
                            [editorOptions]="{ maxLength: 50 }">
                        </dxi-item>
                    </dxi-item>
                    <dxi-item itemType="group" [colCount]="2">
                        <dxi-item 
                            dataField="ds_numero1" 
                            [label]="{ text: 'index.numero' | translate }"
                            [editorOptions]="{ maxLength: 10 }">
                        </dxi-item>
                        <dxi-item 
                            dataField="ds_bairro1" 
                            [label]="{ text: 'index.bairro' | translate }"
                            [editorOptions]="{ maxLength: 30 }">
                        </dxi-item>
                    </dxi-item>
                    <dxi-item itemType="group" [colCount]="1">
                        <dxi-item 
                            dataField="cd_cidade" 
                            [label]="{ text: 'index.cidade' | translate }"
                            editorType="dxSelectBox" 
                            [editorOptions]="{
                            dataSource: cidades,
                            displayExpr: 'ds_cidade',
                            valueExpr: 'cd_cidade',
                            searchMode: 'contains',
                            searchEnabled:'true'
                            }">
                        </dxi-item>
                    </dxi-item>
                </dx-form>
            </form>
        </dx-scroll-view>
    </div>
</dx-popup>
<!-- end region tarefa #30164 -->

<dx-popup
    [showTitle]="true"
    [title]="'index.perfis' | translate"
    [(visible)]="visibilityPopupGraphBar"
    >
    <div *dxTemplate="let data of 'content'">
        <dx-scroll-view width="100%" height="100%">
            <dx-data-grid 
                [dataSource]="orcaproPerfis"
                [masterDetail]="{ enabled: true, template: 'detailBar', autoExpandAll: false }"
                [showBorders]="true"
                [columnAutoWidth]="true">

                <dxi-column dataField="ds_produto" [caption]="'index.produto' | translate"></dxi-column>

                <dxi-column dataField="larg" [caption]="'index.largura' | translate"></dxi-column>

                <dxi-column dataField="qtde_pecas" [caption]="'index.quantidade' | translate"></dxi-column>

                <dxi-column dataField="cd_tipo" [caption]="'index.operacao' | translate">
                    <dxo-lookup [dataSource]="dataSourceOperacao" valueExpr="cd_tipo" displayExpr="ds_descricao">
                    </dxo-lookup>
                </dxi-column>

                <div *dxTemplate="let bar of 'detailBar'">

                    <div *ngFor="let itemBar of bar.data.barras">
                        <dx-chart
                            [rotated]="true"
                            [dataSource]="itemBar">

                            <dxo-size
                                [height]="40">
                            </dxo-size>
                            
                            <!-- [showInLegend]="false" -->
                            <dxo-common-series-settings
                                argumentField="cd_barra"
                                valueField="vl_tamanho"
                                type="stackedBar">
                            </dxo-common-series-settings>

                            <dxo-series-template
                                nameField="cd_codigo"
                                [customizeSeries]="customizeSeries">
                            </dxo-series-template>

                            <dxo-export [enabled]="true"></dxo-export>

                            <dxo-legend
                                position="inside"
                                horizontalAlignment="right"
                                verticalAlignment="center"
                                markerSize="5"
                                paddingLeftRight="1"
                                paddingTopBottom="1">
                                <dxo-margin
                                    [top]="6"
                                    [left]="0"
                                    [right]="0">
                                </dxo-margin>
                            </dxo-legend>

                            <dxo-tooltip
                                [enabled]="false"
                                location="edge">
                            </dxo-tooltip>

                        </dx-chart>
                    </div>
                </div>
            </dx-data-grid>    
        </dx-scroll-view>
    </div>
</dx-popup>


<dx-popup
    [showTitle]="true"
    [title]="'index.enviar_por_email' | translate"
    [(visible)]="visibilityPopupEmail"
    [width]="'auto'"
    minWidth="450px"
    [height]="'auto'">
    <div *dxTemplate="let data of 'content'">
        <dx-scroll-view width="100%" height="100%">
            <app-pedido-email 
                [cd_orca]="ped_orca"
                [cd_cliente]="lookUpToShow == 2 ? dataSourcePedidoCabecalho.cd_cliente : dataSourcePedidoCabecalho.cd_empresa"
                (notifySentEmaildEvent)="sentEmaildEvent()">
            </app-pedido-email>
        </dx-scroll-view>
    </div>
</dx-popup>