import { Injectable } from "@angular/core";

import { baseUrl } from "./../../config";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root"
})
export class uploadgcadservices {
  constructor(private http: HttpClient) {}

  url = baseUrl;

  update(form) {
    return this.http.post(`${this.url}/SaveGcadFile`, form);
  }
}
