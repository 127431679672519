<div class="m-3">
  <div class="row my-3 no-print" id="row_status">
    <div class="box col-lg-3 pb-2 bg-status">
      <dx-select-box
        id="useFilterApplyButton"
        [items]="statuses"
        [(value)]="currentFilter"
        valueExpr="key"
        displayExpr="name"
        (onValueChanged)="selectStatus($event)">
      </dx-select-box>
    </div>
  </div>

  <dx-data-grid
    id="data-grid-follow"
    [columnHidingEnabled]="false"
    [dataSource]="dataSource"
    [rowAlternationEnabled]="true"
    [showRowLines]="true"
    [showBorders]="true"
    [columnAutoWidth]="true"
    [allowColumnReordering]="true"
    [allowColumnResizing]="true"
    [remoteOperations]="true"
    (onToolbarPreparing)="onToolbarPreparing($event)">

    <dxo-export
      [enabled]="true"
      [fileName]="'index.acompanhamento' | translate"
      [allowExportSelectedData]="false">
    </dxo-export>

    <dxo-filter-row
      [visible]="true">
    </dxo-filter-row>

    <dxo-scrolling mode="virtual"></dxo-scrolling>

    <dxi-column
      dataField="dt_atual"
      dataType="date"
      [caption]="'index.datat' | translate">
    </dxi-column>

    <dxi-column
      dataField="ds_docto"
      [caption]="'index.pedidoA' | translate">
    </dxi-column>

    <dxi-column
      dataField="ds_setor"
      [caption]="'index.setor' | translate">
    </dxi-column>

    <dxi-column
      dataField="vl_larg"
      [caption]="'index.largura' | translate">
    </dxi-column>

    <dxi-column
      dataField="vl_alt"
      [caption]="'index.altura' | translate">
    </dxi-column>

    <dxi-column
      dataField="produto"
      [caption]="'index.produto' | translate"
    ></dxi-column>

    <dxi-column
      dataField="obs"
      [caption]="'index.observacoes' | translate">
    </dxi-column>

    <dxi-column
      dataField="cd_orca"
      [caption]="'index.orca' | translate">
    </dxi-column>

    <dxi-column
    dataField="romaneio"
    [caption]="'index.romaneio' | translate"
  ></dxi-column>

  <dxi-column
  dataField="id_etiqueta"
  [caption]="'index.codigo' | translate">
</dxi-column>

    <dxi-column
      dataField="cd_item"
      [caption]="'index.item' | translate">
    </dxi-column>

    <dxi-column
      dataField="dt_prev"
      dataType="date"
      [caption]="'index.datap' | translate">
    </dxi-column>


    <dxi-column
      dataField="ds_status"
      [allowFiltering]="false"
      [caption]="'index.status' | translate">
    </dxi-column>



    <dxi-column
      dataField="m2_calc"
      [caption]="'index.m2' | translate">
    </dxi-column>

    <dxi-column
      dataField="ds_cnpj_fornece"
      [caption]="'index.fornecedor' | translate">
    </dxi-column>

    <div *dxTemplate="let data of 'dx-title'">
      <div class="text-center">
        <p class="title mb-0">{{ "index.acompanhamento" | translate }}</p>
      </div>
    </div>
  </dx-data-grid>
</div>
