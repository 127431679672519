<app-loader *ngIf="loading"></app-loader>

<div class="m-3">
  <dx-data-grid
      id="order-table"
      [dataSource]="dataSource"
      [allowColumnReordering]="true"
      [showBorders]="true"
      [remoteOperations]="true"
      [allowColumnResizing]="true"
      (onInitialized)="onDataGridInitialized()"
      (onToolbarPreparing)="onToolbarPreparing($event)"
      rowTemplate="rowTemplateName"
    >
      <!-- <dxo-filter-row [visible]="true"></dxo-filter-row> -->
      <dxo-header-filter [visible]="true"></dxo-header-filter>
      <dxo-search-panel
        [visible]="true"
        [width]="240"
        [placeholder]="'index.procurar' | translate">
      </dxo-search-panel>
      
      <dxo-scrolling mode="virtual"></dxo-scrolling>
      
      <dxo-grouping [autoExpandAll]="false"></dxo-grouping>

      <dxi-column
        dataField="ds_docto"
        [caption]="'index.pedidoA' | translate"
      >
      </dxi-column>

      <dxi-column
        dataField="st_orca"
        [caption]="'index.status' | translate"
      >
      </dxi-column>

      <dxi-column
        dataField="dt_data"
        dataType="date"
        [caption]="'index.emissao' | translate"
      >
      </dxi-column>

      <dxi-column
        dataField="dt_entrega"
        dataType="date"
        [caption]="'index.previsao' | translate"
      >
      </dxi-column>

      <dxi-column
        dataField="cliente"
        [caption]="'index.cliente' | translate"
      >
      </dxi-column>

      <dxi-column
        dataField="vl_total"
        [visible]="false"
        [caption]="'index.valor' | translate"
      >
      </dxi-column>

      <dxi-column
        dataField="ds_obs"
        [caption]="'index.observacoes' | translate"
      >
      </dxi-column>

      <dxo-summary>
        <dxi-total-item
          column="ds_obs"
          summaryType="count"
          [customizeText]="countColumnCustomizeText"
        >
        </dxi-total-item>
      </dxo-summary>

      <div *dxTemplate="let data of 'dx-title'">
        <div class="text-center">
          <p class="title mb-0">{{ "index.listadepedidos" | translate }}</p>
        </div>
      </div>

      <tbody *dxTemplate="let item of 'rowTemplateName'">
        <td colspan="7">
          <div [class]="item.data.cssClass" (click)="onOrderClick(item)">
            <div class="border">
              <div class="row no-gutters">
                <div class="col-lg-6">

                  <div class="container">
                    <div class="row no-gutters">
                      <div class="col-6">
                        <div class="dx-field">
                          <div class="dx-field-label"><strong>&nbsp; {{ "index.pedidoA" | translate }} :</strong></div>
                          <div class="dx-field-value">
                            <dx-text-box
                              [value]="item.data.ds_docto"
                              [hint]="item.data.ds_docto"
                              [readOnly]="true">
                            </dx-text-box>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="dx-field">
                          <div class="dx-field-label"><strong>&nbsp; {{ "index.status" | translate }} :</strong></div>
                          <div class="dx-field-value">
                            <dx-text-box
                              [value]="item.data.st_orca"
                              [hint]="item.data.st_orca"
                              [readOnly]="true">
                            </dx-text-box>
                          </div>
                        </div>   
                      </div>
                    </div>
                  </div>
                  <div class="container">
                    <div class="row no-gutters">
                      <div class="col-6">
                        <div class="dx-field">
                          <div class="dx-field-label"><strong>&nbsp; {{ "index.emissao" | translate }} :</strong></div>
                          <div class="dx-field-value">
                            <dx-date-box
                              [value]="item.data.dt_data"
                              [hint]="item.data.dt_data"
                              [readOnly]="true">
                            </dx-date-box>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="dx-field">
                          <div class="dx-field-label"><strong>&nbsp; {{ "index.previsao" | translate }} :</strong></div>
                          <div class="dx-field-value">
                            <dx-date-box
                              [value]="item.data.dt_entrega"
                              [hint]="item.data.dt_entrega"
                              [readOnly]="true">
                            </dx-date-box>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="container">
                    <div class="row no-gutters">
                      <div class="col-6">
                        <div class="dx-field">
                          <div class="dx-field-label"><strong>&nbsp; {{ "index.cliente" | translate }} :</strong></div>
                          <div class="dx-field-value">
                            <dx-text-box
                              [value]="item.data.cliente"
                              [hint]="item.data.cliente"
                              [readOnly]="true">
                            </dx-text-box>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="dx-field">
                          <div class="dx-field-label"><strong>&nbsp; {{ "index.valor" | translate }} :</strong></div>
                          <div class="dx-field-value">
                            <dx-text-box
                              [value]="item.data.vl_total"
                              [hint]="item.data.vl_total"
                              [readOnly]="true">
                            </dx-text-box>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="container">
                    <div class="row no-gutters">
                      <div class="col-6">
                        <div class="dx-field">
                          <div class="dx-field-label"><strong>&nbsp; {{ "index.observacoes" | translate }} :</strong></div>
                          <div class="dx-field-value">
                            <dx-text-box
                              [value]="item.data.ds_obs"
                              [hint]="item.data.ds_obs"
                              [readOnly]="true">
                            </dx-text-box>
                          </div>
                        </div>
                      </div>
                      <!-- // tarefa #33483-->
                      <div class="col-6" *ngIf="item.data.st_fabrica">
                        <div class="dx-field">
                          <div class="dx-field-label"><strong>&nbsp; {{ "index.fornecedor" | translate }} :</strong></div>
                          <div class="dx-field-value">
                            <dx-check-box 
                              [value]="item.data.st_gerado_fornecedor"
                              [hint]="item.data.st_gerado_fornecedor"
                              [readOnly]="true">
                            </dx-check-box>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 align-self-center">
                  <ul class="progress-indicator">
                      <li class="{{ item.data.pendente }}">
                        <span class="bubble"></span>
                        <span class="badge">{{ item.data.qtd_itens_pendentes }}</span>
                        {{ "index.Pendente" | translate }}
                      </li>
                      <li class="{{ item.data.completed }}">
                        <span class="bubble"></span>
                        <span class="badge">{{ item.data.qtd_itens_tecnico }}</span>
                        {{ "index.Tecnico" | translate }}
                      </li>
                      <li class="{{ item.data.fabrica }}">
                        <span class="bubble"></span>
                        <span class="badge">{{ item.data.qtd_itens_fabrica }}</span>
                        {{ "index.Fabrica" | translate }}
                      </li>
                      <li class="{{ item.data.fabricando }}">
                        <span class="bubble"></span>
                        <span class="badge">{{ item.data.qtd_itens_fabricando }}</span>
                        {{ "index.Fabricando" | translate }}
                      </li>
                      <li class="{{ item.data.recebido }}">
                        <span class="bubble"></span>
                        <span class="badge">{{ item.data.qtd_itens_recebido }}</span>
                        {{ "index.Recebido" | translate }}
                      </li>
                      <li class="{{ item.data.entregue }}">
                        <span class="bubble"></span>
                        <span class="badge">{{ item.data.qtd_itens_entregue }}</span>
                        {{ "index.entregue" | translate }}
                      </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tbody>
  </dx-data-grid>
</div>