import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { FinanceiroService } from 'src/app/shared/services/financeiro.service';
import { Portador } from 'src/app/shared/models/Portador';
import { RequestCallbackService } from 'src/app/shared/helpers/request-callback.service';
import { Situacao, loadDataSourceSituacoes } from 'src/app/shared/models/Situacao';
import { DxDataGridComponent } from 'devextreme-angular';
import { BaseLazyComponent } from 'src/app/shared/helpers/BaseLazyComponent';
import { LoadOptions } from 'devextreme/data/load_options';
import { LazyDataSource } from 'src/app/shared/models/LazyDataSource';
import { LanguagueService } from 'src/app/shared/helpers/language.service';

@Component({
  selector: 'app-contas-pagar',
  templateUrl: './contas-pagar.component.html',
  styleUrls: ['./contas-pagar.component.scss']
})
export class ContasPagarComponent extends BaseLazyComponent implements OnInit, AfterViewInit {

  dataSourceSituacao: Situacao[] = [];
  dataSourceProviders: LazyDataSource;
  portadores: Portador[] = [];
  loading: boolean = false;
  havePermission: boolean = true;
  formCode: number;

  @ViewChild(DxDataGridComponent, { static: false })
  dataGridContasPagar: DxDataGridComponent;

  constructor(
    private service: FinanceiroService,
    private language: LanguagueService,
    private requestCallbackService: RequestCallbackService) {
    super("cd_codigo");
    this.language.globalize();
  }

  ngOnInit() {
    this.setupDataSourceProviders();
    this.getPortadores()
  }

  showLoad = () => {
    this.loading = true;
  }

  hideLoad = () => {
    this.loading = false;
  }

  ngAfterViewInit(): void {
    loadDataSourceSituacoes(this.language, this.dataSourceSituacao);
  }

  load(loadOptions: LoadOptions): Promise<any> {
    const formData = this.createFormData([{ key: "loadOptions", value: loadOptions }]);
    return this.requestCallbackService.load(
      this.service.getPagar(formData),
      (response) => {
        if (this.requestCallbackService.isResponseWithoutPermission(response)) {
          this.formCode = response.data.form;
          this.havePermission = false;
        }
        return response.data.pagar;
      },
      "falha_buscar_contas_pagar",
    );
  }

  insert(values: any): Promise<void> {
    const formData = this.createFormData([{ key: "values", value: values }]);
    return this.requestCallbackService.insert(this.service.postPagar(formData), this.dataGridContasPagar);
  }

  update(key: any, values: any): Promise<void> {
    const formData = this.createFormData([{ key: "key", value: key }, { key: "values", value: values }]);
    return this.requestCallbackService.update(this.service.updateCP(formData), this.dataGridContasPagar);
  }

  onRowUpdating(options) {
    options.newData = Object.assign(options.oldData, options.newData);
  }

  delete(key: any): Promise<void> {
    const formData = this.createFormData([{ key: "key", value: key }]);
    return this.requestCallbackService.delete(this.service.deletarCP(formData), this.dataGridContasPagar);
  }

  getPortadores() {
    this.requestCallbackService.doRequest(
      this.service.getPortadores(),
      (response) => {
        const portadores: any[] = response.data.portadores;
        this.portadores = portadores;
      },
      this.showLoad,
      this.hideLoad,
      "falha_buscar_portadores"
    );
  }

  setupDataSourceProviders() {
    this.dataSourceProviders = this.service.getDataSourceClients(true);
  }

  onToolbarPreparing(e) {
    let items: any[] = e.toolbarOptions.items;

    // busca a barra de pesquisa
    const searchItem = items[items.length - 1];
    // permite collapse menu
    searchItem.locateInMenu = "auto";

    items.unshift({
      location: "center",
      template: "dx-title"
    });
  }
}
