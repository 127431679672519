import { Component, OnInit, ViewChild } from "@angular/core";
import { relpedidosService } from "./relpedidos.service";
import { Router, ActivatedRoute } from "@angular/router";
import { traduzirStatus } from "src/app/shared/helpers/util";
import { RequestCallbackService } from 'src/app/shared/helpers/request-callback.service';
import { STATUS_PENDENTE_INT, STATUS_TECNICO_INT, STATUS_FABRICA_INT, STATUS_ENTREGUE_INT, STATUS_RECEBIDO_INT, STATUS_FABRICANDO_INT, STATUS_RECUSADO } from 'src/app/shared/helpers/constants';
import { LoadOptions } from 'devextreme/data/load_options';
import { BaseLazyComponent } from 'src/app/shared/helpers/BaseLazyComponent';
import { LanguagueService } from 'src/app/shared/helpers/language.service';
import { DxDataGridComponent } from 'devextreme-angular/';

@Component({
  selector: "app-relpedidos",
  templateUrl: "./relpedidos.component.html",
  styleUrls: ["./relpedidos.component.scss"]
})
export class RelpedidosComponent extends BaseLazyComponent implements OnInit {

  @ViewChild(DxDataGridComponent, { static: false })
  dataGridOrders: DxDataGridComponent;
  loading: boolean = false;
  newOrderTitle: string;
  st_orca_filter: string;

  constructor(
    private relService: relpedidosService,
    private router: Router,
    private route: ActivatedRoute,
    private requestCallbackService: RequestCallbackService,
    private language: LanguagueService) {
    super("cd_orca");
    this.language.globalize();
  }

  ngOnInit() {
    this.setupTranslatedStrings();
    this.st_orca_filter = this.route.snapshot.paramMap.get('st_orca');
  }

  showLoad() {
    this.loading = true;
  }

  hideLoad() {
    this.loading = false;
  }

  onOrderClick(item) {
    let status = item.data.status;
    const cd_orca_pedido = item.data.cd_orca;
    this.router.navigate(["/pagina/pedidos/itens/" + cd_orca_pedido], {
      state: { status: status }
    });
  }

  load(loadOptions: LoadOptions): Promise<any> {
    if (loadOptions.filter) {
      //this.relService.prepareDateFilters(loadOptions.filter, ["dt_data", "dt_entrega"]);
    }
    const formData = this.createFormData([{ key: "loadOptions", value: loadOptions }]);
    return this.requestCallbackService.load(
      this.relService.getPedidos(formData),
      (response) => {
        let pedidos: any[] = response.data.pedidos.data;

        pedidos.forEach(pedido => {

          // tarefa #30049
          // segue o fluxo caso seja os dados para exibição na grade (que possuem cd_orca)
          // caso sejam filtros interromple o fluxo (não possuem cd_orca)
          if (pedido.cd_orca == null) {
            return;
          }

          let orca = traduzirStatus(pedido.st_orca, this.language);

          // tarefa #30505
          orca = orca.concat(" (").concat(pedido.st_orca).concat(")");

          let higherStatus: number = this.getHigherStatusAndCalculatesQuantities(pedido);

          // formatando css da progresses bar
          this.defineBubbleForOrder(pedido, higherStatus);

          pedido.st_orca = orca;
        });
        return response.data.pedidos;
      },
      "falha_buscar_pedidos",
    );
  }

  private getHigherStatusAndCalculatesQuantities(pedido: any): number {
    let higherStatus: number = 0;
    pedido.status.forEach(status => {

      let orderStatus: number = status.status;
      switch (orderStatus) {
        case STATUS_PENDENTE_INT: {
          pedido.qtd_itens_pendentes = status.qtde;
          break;
        }
        case STATUS_TECNICO_INT: {
          pedido.qtd_itens_tecnico = status.qtde;
          break;
        }
        case STATUS_FABRICA_INT: {
          pedido.qtd_itens_fabrica = status.qtde;
          break;
        }
        case STATUS_FABRICANDO_INT: {
          pedido.qtd_itens_fabricando = status.qtde;
          break;
        }
        case STATUS_RECEBIDO_INT: {
          pedido.qtd_itens_recebido = status.qtde;
          break;
        }
        case STATUS_ENTREGUE_INT: {
          pedido.qtd_itens_entregue = status.qtde;
          break;
        }
      }

      if (higherStatus < orderStatus) {
        higherStatus = status.status;
      }
    });
    return higherStatus;
  }

  private defineBubbleForOrder(pedido: any, status: number) {
    this.resetCssClassBuble(pedido);

    // tarefa #32237
    if (pedido.st_orca == STATUS_RECUSADO) {
      return;
    }

    switch (status) {
      case STATUS_PENDENTE_INT: {
        this.defineCssBublePendente(pedido);
        break;
      }
      case STATUS_TECNICO_INT: {
        this.defineCssBubleTecnico(pedido);
        break;
      }
      case STATUS_FABRICA_INT: {
        this.defineCssBubleFabrica(pedido);
        break;
      }
      case STATUS_FABRICANDO_INT: {
        this.defineCssBubleFabricando(pedido);
        break;
      }
      case STATUS_RECEBIDO_INT: {
        this.defineCssBubleRecebido(pedido);
        break;
      }
      case STATUS_ENTREGUE_INT: {
        this.defineCssBubleEntregue(pedido);
        break;
      }
    }
  }

  private resetCssClassBuble(pedido: any) {
    const cssClassIncomplete = "incomplete";
    pedido.pendente = cssClassIncomplete;
    pedido.completed = cssClassIncomplete;
    pedido.fabrica = cssClassIncomplete;
    pedido.fabricando = cssClassIncomplete;
    pedido.recebido = cssClassIncomplete;
    pedido.entregue = cssClassIncomplete;
  }

  private defineCssBublePendente(pedido: any) {
    pedido.pendente = "pendente";
    pedido.cssClass = "border-pendente";
  }

  private defineCssBubleTecnico(pedido: any) {
    this.defineCssBublePendente(pedido);
    pedido.completed = "completed";
    pedido.cssClass = "border-tecnico";
  }

  private defineCssBubleFabrica(pedido: any) {
    this.defineCssBubleTecnico(pedido);
    pedido.fabrica = "fabrica";
    pedido.cssClass = "border-fabrica";
  }

  private defineCssBubleFabricando(pedido: any) {
    this.defineCssBubleFabrica(pedido);
    pedido.fabricando = "fabricando";
    pedido.cssClass = "border-fabricando";
  }

  private defineCssBubleRecebido(pedido: any) {
    this.defineCssBubleFabricando(pedido);
    pedido.recebido = "recebido";
    pedido.cssClass = "border-recebido";
  }

  private defineCssBubleEntregue(pedido: any) {
    this.defineCssBubleRecebido(pedido);
    pedido.entregue = "entregue";
    pedido.cssClass = "border-entregue";
  }

  onDataGridInitialized() {
    //tarefa #32237
    if (this.st_orca_filter == STATUS_RECUSADO) {
      //this.dataGridOrders.instance.filter(["st_orca", "=", STATUS_RECUSADO]);
      this.dataGridOrders.instance.columnOption("st_orca", "filterValues", [STATUS_RECUSADO]);
    }
  }

  // tarefa #30049
  onToolbarPreparing(e) {

    let items: any[] = e.toolbarOptions.items;

    // busca a barra de pesquisa
    const searchItem = items[items.length - 1];
    // permite collapse menu
    searchItem.locateInMenu = "auto";

    items.splice(
      0,
      0,
      {
        location: "after",
        locateInMenu: "auto",
        widget: "dxButton",
        name: "Add",
        options: {
          icon: "plus",
          text: this.newOrderTitle,
          onClick: () => {
            this.redirectToNewOrder();
          }
        }
      },
      {
        location: "center",
        template: "dx-title"
      }
    );
  }

  redirectToNewOrder() {
    this.router.navigateByUrl("pagina/pedidos/itens", {
      state: { novo: true }
    });
  }

  // tarefa #30049
  setupTranslatedStrings() {
    this.language.translateMessageLazy("novo_pedido", value => this.newOrderTitle = value)
  }

  countColumnCustomizeText = (cellInfo) => {
    const value = cellInfo.value ? cellInfo.value : 0;
    return this.language.translateMessage("pedidos") + ": " + value;
  }
  // end tarefa #30049

}
