import { Component, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { LoadOptions } from 'devextreme/data/load_options';
import { BaseLazyComponent } from 'src/app/shared/helpers/BaseLazyComponent';
import { LanguagueService } from 'src/app/shared/helpers/language.service';
import { RequestCallbackService } from 'src/app/shared/helpers/request-callback.service';
import { calcMinWidthToDropDownMenu } from 'src/app/shared/helpers/util';
import { LazyDataSource } from 'src/app/shared/models/LazyDataSource';
import { ClientesService } from '../clientes/clientes.service';
import { UsuariosService } from '../usuarios/usuarios.service';
import { EstadosService } from './estados.service';

@Component({
  selector: 'app-estados',
  templateUrl: './estados.component.html',
  styleUrls: ['./estados.component.scss']
})
export class EstadosComponent extends BaseLazyComponent implements OnInit {

  havePermission: boolean = true;
  formCode: number;
  dataSourceCompanies: LazyDataSource;
  dataSourceCountries: LazyDataSource;

  @ViewChild(DxDataGridComponent, { static: false })
  dataGridEstados: DxDataGridComponent;
  
  constructor(
    private language: LanguagueService,
    private requestCallbackService: RequestCallbackService,
    private estadosService: EstadosService,
    private serviceUser: UsuariosService,
    private clientesService: ClientesService,
  ) {
    super("cd_codigo");
    this.language.globalize();
  }

  ngOnInit() {
    this.setupDataSourceCompanies();
    this.setupDataSourceCountrie();
  }

  private setupDataSourceCompanies() {
    this.dataSourceCompanies = this.serviceUser.setupDataSourceCompanies();
  }

  private setupDataSourceCountrie() {
    this.dataSourceCountries = this.clientesService.setupDataSourceCountries();
  }

  load(loadOptions: LoadOptions): Promise<any> {
    const formData = this.createFormData([{ key: "loadOptions", value: loadOptions }]);
    return this.requestCallbackService.load(
      this.estadosService.getEstados(formData),
      (response) => {
        if (this.requestCallbackService.isResponseWithoutPermission(response)) {
          this.formCode = response.data.form;
          this.havePermission = false;
        }
        return response.data.estados;
      },
      "falha_buscar_estados",
    );
  }

  insert(values: any): Promise<void> {
    const formData = this.createFormData([{ key: "values", value: values }]);
    return this.requestCallbackService.insert(this.estadosService.insertEstado(formData), this.dataGridEstados);
  }

  update(key: any, values: any): Promise<void> {
    const formData = this.createFormData([{ key: "key", value: key }, { key: "values", value: values }]);
    return this.requestCallbackService.update(this.estadosService.updateEstado(formData), this.dataGridEstados);
  }

  delete(key: any): Promise<void> {
    const formData = this.createFormData([{ key: "key", value: key }]);
    return this.requestCallbackService.delete(this.estadosService.deleteEstado(formData), this.dataGridEstados);
  }

  onRowUpdating(options) {
    options.newData = Object.assign(options.oldData, options.newData);
  }

  cm_allowEditAndDelete = (e): boolean => {
    return e.row.data.st_edita;
  }

  minWidthSelectBox(): number {
    return calcMinWidthToDropDownMenu(400);
  }

  onToolbarPreparing(e) {

    const items: any[] = e.toolbarOptions.items;
    const searchItem = items[items.length - 1];
    searchItem.locateInMenu = "auto";

    items.unshift({
      location: "center",
      template: "dx-title"
    });
  }

}
