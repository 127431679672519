import { Injectable } from '@angular/core';
import { baseUrl } from 'src/app/config';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LanguagueService } from 'src/app/shared/helpers/language.service';
import { OPERACAO_FABRICAR, OPERACAO_ESTOQUE } from 'src/app/shared/helpers/constants';
import { PedidoPreviewService } from '../pedido-preview/pedido-preview.service';
import { ResumoTecnicoService } from '../resumo-tecnico/resumo-tecnico.service';
import { map } from 'rxjs/operators'; // Importe o operador map
import { DtoServico, DtoFerrragem, DtoVidro } from '../../shared/models/DtoElementos';
import { DtoOrcapro } from '../../shared/models/DtoOrcapro';
import { OtimPerfList } from '../../shared/models/OtimPerfList';
import { Produto } from '../../shared/models/Produto';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';

@Injectable({
    providedIn: 'root'
})
export class PedidoItemService {

    private url = baseUrl;

    constructor(
        private http: HttpClient,
        private language: LanguagueService,
        public pedidoPreviewService: PedidoPreviewService,
        private resumoteTecnicoService: ResumoTecnicoService) { }

        readPedidoItem(form: FormData): Observable<ApiResponse<DtoOrcapro[]>> {
          return this.http.post<any>(`${this.url}/Read_ProdOrc`, form)
              .pipe(
                  map((response: any) => {
                      const data = response.data;
                      const orcapros: DtoOrcapro[] = data.orcapro.map(item => {
                          return {
                              ...item,
                              produto: item.produto as Produto, // Converte produto para DtoProd
                              otimPerfList: item.otimPerfList as OtimPerfList, // Converte otimPerfList para OtimPerfList
                              ficha_tecnica_perfil: item.ficha_tecnica_perfil as DtoServico[], // Converte ficha_tecnica_perfil para DtoServico[]
                              ficha_tecnica_ferragem: item.ficha_tecnica_ferragem as DtoFerrragem[], // Converte ficha_tecnica_ferragem para DtoFerrragem[]
                              ficha_tecnica_vidro: item.ficha_tecnica_vidro as DtoVidro[] // Converte ficha_tecnica_vidro para DtoVidro[]
                          };
                      });
                      return { ...response, data: { orcapro: orcapros } };
                  })
              );
      }

    cadastraPedidoCabecalho(form: FormData): Observable<any> {
        return this.http.post<any>(`${this.url}/CadastraPedido`, form)
    }

    insertPedidoItem(form: FormData): Observable<any> {
        return this.http.post<any>(`${this.url}/Insert_ProdOrc`, form)
    }

    updatePedidoItem(form: FormData): Observable<any> {
        return this.http.post<any>(`${this.url}/Update_ProdOrc`, form)
    }

    deletePedidoItem(form: FormData): Observable<any> {
        return this.http.post<any>(`${this.url}/Delete_ProdOrc`, form)
    }

    getEnderecos(form: FormData): Observable<any> {
        return this.http.post<any>(`${this.url}/getDropEnderecos`, form)
    }

    getPedido(form): Observable<any> {
        return this.http.post<any>(`${this.url}/getPedido`, form)
    }

    getInfoCabecalho(): Observable<any> {
        return this.http.get<any>(`${this.url}/GetInfoCabecalho`)
    }

    updateOrcaPro(form: FormData): Observable<any> {
        return this.http.post<any>(`${this.url}/Update_Orcapro`, form)
    }

    getProducts(formData: FormData): Observable<any> {
        return this.http.post<any>(`${this.url}/GetProducts`, formData);
    }

    getProductsWithFilter(formData: FormData): Observable<any> {
        return this.http.post<any>(`${this.url}/GetProductsWithFilter`, formData);
    }

    deletePedido(form: FormData): Observable<any> {
        return this.http.post<any>(`${this.url}/excluirPedido`, form)
    }

    checkPermission(form: FormData): Observable<any> {
        return this.http.post<any>(`${this.url}/checkPermission`, form)
    }

    getOrderReport(formData: FormData): Observable<Blob> {
        this.pedidoPreviewService.appendLanguageData(formData);
        return this.http.post(`${this.url}/orderReport`, formData, { responseType: 'blob' });
    }

    processResponsePdf(response: Blob) {
        this.pedidoPreviewService.processResponsePdf(response);
    }

    restoreOrderPending(formData: FormData): Observable<any> {
        return this.http.post(`${this.url}/RestoreOrderPending`, formData);
    }

    loadChartOptimization(formData: FormData): Observable<any> {
        return this.http.post(`${this.url}/LoadChartOptimization`, formData);
    }

    getFornList(): Observable<any> {
        return this.http.get(`${this.url}/getFornList`);
    }

    previewFerragens(form: FormData): Observable<Blob> {
        this.resumoteTecnicoService.setupLabels(form);
        return this.http.post(`${this.url}/previewFerragens`, form, { responseType: 'blob' });
    }

    previewPerfis(form: FormData): Observable<Blob> {
        this.resumoteTecnicoService.setupLabels(form);
        return this.http.post(`${this.url}/previewPerfis`, form, { responseType: 'blob' });
    }

    previewAcessorios(form: FormData): Observable<Blob> {
        this.resumoteTecnicoService.setupLabels(form);
        return this.http.post(`${this.url}/previewAcessorios`, form, { responseType: 'blob' });
    }

    previewVidros(form: FormData): Observable<Blob> {
        this.resumoteTecnicoService.setupLabels(form);
        return this.http.post(`${this.url}/previewVidros`, form, { responseType: 'blob' });
    }

    technicalDrawing(form: FormData): Observable<Blob> {
        this.resumoteTecnicoService.setupLabels(form);
        return this.http.post(`${this.url}/technicalDrawing`, form, { responseType: 'blob' });
    }

    sendMailToForn(formData: FormData): Observable<any> {
        return this.http.post(`${this.url}/sendMailToForn`, formData);
    }

    gruposCadPedido(formData: FormData): Observable<any> {
        return this.http.post(`${this.url}/GruposCadPedido`, formData);
    }

    isIndependentGlazing(): Observable<any> {
        return this.http.get(`${this.url}/IsIndependentGlazing`);
    }

    operacoes() {
        return [
            {
                cd_tipo: OPERACAO_FABRICAR,
                ds_descricao: this.language.translateMessage("fabricar")
            },
            {
                cd_tipo: OPERACAO_ESTOQUE,
                ds_descricao: this.language.translateMessage("estoque")
            }
        ];
    }
}
