import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { baseUrl } from "src/app/config";
import { cleanLocalStorage, addDataToLocalStorage, getItemStorage } from "../helpers/util";
import { ApiResponse } from '../models/ApiResponse';
import { RequestCallbackService } from '../helpers/request-callback.service';
import { HASHCAD } from '../helpers/constants';
import { LazyDataSource } from '../models/LazyDataSource';
import { LazySelectService } from '../helpers/lazy-select.service';
import { ClientesVidraceirosService } from 'src/app/containers/clientes-vidraceiros/clientes-vidraceiros.service';

@Injectable({
  providedIn: "root"
})
export class FinanceiroService {

  private url = baseUrl;
  private currentUserSubject: BehaviorSubject<string>;

  constructor(
    private http: HttpClient,
    private requestCallbackService: RequestCallbackService,
    private clienteVidraceiroService: ClientesVidraceirosService,
    private lazyService: LazySelectService) {

    this.currentUserSubject = new BehaviorSubject<string>(
      getItemStorage(HASHCAD)
    );
  }

  postReceber(form: FormData): Observable<any> {
    return this.http.post<any>(
      `${this.url}/InsertContasReceber`,
      form
    );
  }

  postPagar(form: FormData): Observable<any> {
    return this.http.post<any>(`${this.url}/InsertContasPagar`, form);
  }

  getReceber(form: FormData): Observable<any> {
    return this.http.post<any>(`${this.url}/GetContasReceber`, form);
  }

  getPagar(form: FormData): Observable<any> {
    return this.http.post<any>(`${this.url}/GetContasPagar`, form);
  }

  private getClientes(form: FormData): Observable<any> {
    return this.http.post<any>(`${this.url}/GetClientesAll`, form);
  }

  private getVendedores(form: FormData): Observable<any> {
    return this.http.post<any>(`${this.url}/getVendedores`, form);
  }

  getFornecedores(form: FormData): Observable<any> {
    return this.http.post<any>(`${this.url}/getFornContaPagar`, form);
  }

  getConfig(name: string, searchByMaster: boolean = false): Observable<any> {
    return this.http.get<any>(
      `${this.url}/CM_GetConfigJson?p_nomeCampo=${name}&searchByMaster=${searchByMaster}`
    );
  }

  getMasterConfig(name: string): Observable<any> {
    return this.getConfig(name, true);
  }

  getPortadores(): Observable<any> {
    return this.http.get<any>(`${this.url}/GetPortadores`);
  }

  updateCP(form: FormData): Observable<any> {
    return this.http.post<any>(`${this.url}/UpdateContasPagar`, form);
  }

  updateCR(form: FormData): Observable<any> {
    return this.http.post<any>(`${this.url}/UpdateContasReceber`, form);
  }

  deletar(form: FormData): Observable<any> {
    return this.http.post<any>(`${this.url}/DeleteContasReceber`, form);
  }

  deletarCP(form: FormData): Observable<any> {
    return this.http.post<any>(`${this.url}/DeleteContasPagar`, form);
  }

  getVendas(form: FormData): Observable<any> {
    return this.http.post<any>(`${this.url}/getVendas`, form);
  }

  getPlano(): Observable<any> {
    return this.http.get<any>(`${this.url}/Plano`);
  }

  login(form: FormData): Observable<any> {
    return this.http
      .post<any>(
        `${this.url}/efetuarLoginAngular`, form
      )
      .pipe(
        map((response: ApiResponse<{ success: boolean, message: string }>) => {
          let res = false;
          this.requestCallbackService.okResponse(response,
            () => {
              let user = response.data.user;
              addDataToLocalStorage(user);
              this.currentUserSubject.next(user.hash);
              res = user;
            });
          return res;
        })
      );
  }

  public get currentUserValue(): string {
    this.currentUserSubject = new BehaviorSubject<string>(
      getItemStorage(HASHCAD)
    );
    return this.currentUserSubject.value;
  }

  logout(): Observable<any> {
    return this.http.get<any>(`${this.url}/closeAllSessionUserInBd`).pipe(
      map((response: ApiResponse<{ success: boolean, message: string }>) => {
        this.requestCallbackService.okResponse(response,
          () => {
            cleanLocalStorage();
            this.currentUserSubject.next(null);
          });
        return response;
      }));
  }

  getDataSourceClients(isProviders: boolean = false): LazyDataSource {
    return this.lazyService.setupLazyDataSource(
      "cd_codigo",
      "ds_nome",
      (loadOptions) => {
        const formData = new FormData();
        formData.append("loadOptions", JSON.stringify(loadOptions));
        return this.loadClients(formData, isProviders);
      },
      (formData) => {
        return this.loadClientByKey(formData, isProviders);
      }
    );
  }

  getDataSourceVidraceiros(): LazyDataSource {
    return this.lazyService.setupLazyDataSource(
      "cd_empresa",
      "ds_fantasia",
      (loadOptions) => {
        const formData = new FormData();
        formData.append("loadOptions", JSON.stringify(loadOptions));
        return this.loadVidraceiros(formData);
      },
      (formData) => {
        return this.loadVidraceiroByKey(formData);
      }
    );
  }

  private loadVidraceiros(formData: FormData): Promise<any> {
    return this.requestCallbackService.load(
      this.clienteVidraceiroService.getVidraceiros(formData),
      (response) => {
        return response.data.empresas;
      },
      "falha_buscar_clientes"
    );
  }

  loadVidraceiroByKey(formData: FormData): Promise<any> {
    return this.requestCallbackService.load(
      this.clienteVidraceiroService.getVidraceiros(formData),
      (response) => {
        return response.data.empresas.data[0];
      },
      "falha_buscar_clientes",
      true
    );
  }

  private getEndpointRequestForClient(isProviders: boolean, formData: FormData): Observable<any> {
    if (isProviders) {
      return this.getFornecedores(formData);
    }
    return this.getClientes(formData);
  }

  private loadClients(formData: FormData, isProviders: boolean = false): Promise<any> {
    return this.requestCallbackService.load(
      this.getEndpointRequestForClient(isProviders, formData),
      (response) => {
        return response.data.clientes;
      },
      "falha_buscar_clientes"
    );
  }

  loadClientByKey(formData: FormData, isProviders: boolean = false): Promise<any> {
    return this.requestCallbackService.load(
      this.getEndpointRequestForClient(isProviders, formData),
      (response) => {
        return response.data.clientes.data[0];
      },
      "falha_buscar_clientes",
      true
    );
  }

  buildFormDataToFindByKey(key: any): FormData {
    const loadOptions = {
      filter: ["cd_codigo", key.toString()]
    }
    const formData = new FormData();
    formData.append("loadOptions", JSON.stringify(loadOptions));
    return formData;
  }

  getDataSourceSellers(): LazyDataSource {
    return this.lazyService.setupLazyDataSource(
      "cd_codigo",
      "ds_nome",
      (loadOptions) => {
        const formData = new FormData();
        formData.append("loadOptions", JSON.stringify(loadOptions));
        return this.loadSellers(formData);
      },
      (formData) => {
        return this.loadSellerByKey(formData);
      }
    );
  }

  private loadSellers(formData: FormData): Promise<any> {
    return this.requestCallbackService.load(
      this.getVendedores(formData),
      (response) => {
        return response.data.vendedores;
      },
      "falha_buscar_vendedores"
    );
  }

  loadSellerByKey(formData: FormData): Promise<any> {
    return this.requestCallbackService.load(
      this.getVendedores(formData),
      (response) => {
        return response.data.vendedores.data[0];
      },
      "falha_buscar_vendedores",
      true
    );
  }

  getCondicoes(formData: FormData): Observable<any> {
    return this.http.post<any>(`${this.url}/getDropCondicao`, formData);
  }

  getDataSourceConditions(): LazyDataSource {
    return this.lazyService.setupLazyDataSource(
      "cd_codigo",
      "ds_descricao",
      (loadOptions) => {
        const formData = new FormData();
        formData.append("loadOptions", JSON.stringify(loadOptions));
        return this.loadConditions(formData);
      },
      (formData) => {
        return this.loadConditionByKey(formData);
      }
    );
  }

  private loadConditions(formData: FormData): Promise<any> {
    return this.requestCallbackService.load(
      this.getCondicoes(formData),
      (response) => {
        return response.data.condicoes;
      },
      "falha_buscar_condicoes"
    );
  }

  loadConditionByKey(formData: FormData): Promise<any> {
    return this.requestCallbackService.load(
      this.getCondicoes(formData),
      (response) => {
        return response.data.condicoes.data[0];
      },
      "falha_buscar_condicoes",
      true
    );
  }
}
