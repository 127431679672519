import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from './../../config';
import { Observable } from 'rxjs';

// region tarefa #28142
@Injectable({
    providedIn: 'root'
})
export class HomeService {

    constructor(private http: HttpClient) { }
    url = baseUrl

    getPedidosChart(): Observable<any> {
        return this.http.get(`${this.url}/getPedidosChart`)
    }

    getHistorico(): Observable<any> {
        return this.http.get(`${this.url}/getHistorico`)
    }

    getNoticias(): Observable<any> {
        return this.http.get(`${this.url}/getNoticias`)
    }

    // tarefa #30159
    getCalendarTasks(): Observable<any> {
        return this.http.get(`${this.url}/getCalendarTasks`);
    }

    // tarefa #32237
    getRejectedOrders(): Observable<any> {
        return this.http.get(`${this.url}/GetRejectedOrders`);
    }

}
// end region tarefa #28142
