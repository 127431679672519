<app-loader *ngIf="loading"></app-loader>

<section class = "container-fluid">

    <div class="card w-75 card-custom">
      <div class="card-header bg-transparent">
        <h5>{{ "index.relatorio_pedido" | translate }}</h5>
      </div>
        <div class="card-body">
          <p class="card-text">{{statusRequest}}</p>
          <div class="text-center">
            <dx-button
                icon="fas fa-sync-alt" 
                class="button-info"
                type="default"
                [text]="'index.gerar_novamente' | translate"
                (onClick)="preview()">
            </dx-button>
          </div>
        </div>
        <div *ngIf="relatorioParaFornecedor" class="card-footer">
          <small class="text-muted">            
            <dx-button
              icon="check" 
              class="button-info"
              type="success"
              [text]="'index.confirmar_importacao_pedido' | translate"
              (onClick)="confirmOrderImportBySupplier()">
            </dx-button>
          </small>
        </div>
    </div>

    <div id="panel-img-reitech">
      <img id="img-reitech" [src]="urlLogoReitech" />
    </div>

</section>
