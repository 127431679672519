import { Injectable } from '@angular/core';
import { baseUrl } from 'src/app/config';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class PerfilService {

    url = baseUrl;
    
    constructor(private http: HttpClient) {

    }

    getPerfil() {
        return this.http.get<any>(`${this.url}/getPerfilData`)
    }

    updatePerfil(formData) {
        return this.http.post(`${this.url}/updatePerfilData`, formData);
    }

}
