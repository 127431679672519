<app-loader *ngIf="loading"></app-loader>
<form (submit)="onFormSubmit($event)">
    <dx-form id="form-email"
        [formData]="formEmailDTO"
        [showColonAfterLabel]="true"
        [showValidationSummary]="true"
        labelLocation="left">
        
        <dxi-item dataField="ds_email_destinatario" [label]="{ text: 'index.destinatario' | translate }">
            <dxi-validation-rule 
                type="required">
            </dxi-validation-rule>
            <dxi-validation-rule 
                type="email">
            </dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="ds_assunto" [label]="{ text: 'index.assunto' | translate }">
            <dxi-validation-rule 
                type="required">
            </dxi-validation-rule>
        </dxi-item>

        <dxi-item itemType="group" [colCount]="2">
            <dxi-item 
                dataField="st_pedido" 
                editorType="dxCheckBox"
                [label]="{ text: 'index.ds_pedido' | translate }">
                <dxi-validation-rule type="required" *ngIf="!(formEmailDTO.st_pedido || formEmailDTO.st_projeto)">
                </dxi-validation-rule>
            </dxi-item>
            <dxi-item 
                dataField="st_projeto" 
                editorType="dxCheckBox"
                [label]="{ text: 'index.ds_projeto' | translate }">
                <dxi-validation-rule type="required" *ngIf="!(formEmailDTO.st_pedido || formEmailDTO.st_projeto)">
                </dxi-validation-rule>
            </dxi-item>
        </dxi-item>

        <dxi-item dataField="ds_mensagem" [label]="{ text: 'index.mensagem' | translate }" editorType="dxTextArea" [editorOptions]="{ height: 240 }">
            <dxi-validation-rule 
                type="required">
            </dxi-validation-rule>
        </dxi-item> 

        <dxi-item 
            itemType="button"
            horizontalAlignment="left"
            [buttonOptions]="buttonOptions">
        </dxi-item>
    </dx-form>
</form>