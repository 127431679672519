import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { forkJoin } from 'rxjs';
import { HeaderNewService } from 'src/app/components/header-new/header_new.service';
import { LanguagueService } from 'src/app/shared/helpers/language.service';
import { RequestCallbackService } from 'src/app/shared/helpers/request-callback.service';
import { ToastService } from 'src/app/shared/helpers/toast.service';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { PedidoEmailService } from './pedido-email.service';

@Component({
  selector: 'app-pedido-email',
  templateUrl: './pedido-email.component.html',
  styleUrls: ['./pedido-email.component.scss']
})
export class PedidoEmailComponent implements OnInit {

  formEmailDTO: any = {};
  buttonOptions: any = {};
  orderClient: any = {};
  currentUser: any = {};
  loading: boolean;

  @Input()
  cd_cliente: number;

  @Input()
  cd_orca: number;

  @Output()
  notifySentEmaildEvent = new EventEmitter();

  constructor(private pedidoEmailService: PedidoEmailService,
    private requestCallbackService: RequestCallbackService,
    private headerService: HeaderNewService,
    private toastService: ToastService,
    private language: LanguagueService) {
    this.language.globalize();
  }

  ngOnInit() {
    this.getFormInfo();
    this.language.translateMessageLazy("enviar", (value) => {
      this.buttonOptions = {
        text: value,
        useSubmitBehavior: true,
        type: "success",
      }
    });
  }

  showLoad() {
    this.loading = true;
  }

  hideLoad() {
    this.loading = false;
  }

  getFormInfo() {
    const formData = new FormData();
    formData.append("clientId", this.cd_cliente.toString());
    let clienteByIDRequest = this.pedidoEmailService.clienteByID(formData);
    let getUserDataRequest = this.headerService.getUserData();

    this.showLoad();
    forkJoin([clienteByIDRequest, getUserDataRequest]).subscribe((responses: ApiResponse<{ success: boolean, message: string }>[]) => {
      this.hideLoad();

      const clienteByIdResponse = responses[0];
      const getUserDataResponse = responses[1];

      this.requestCallbackService.okResponse(clienteByIdResponse,
        () => {
          this.orderClient = clienteByIdResponse.data.cliente;
        });

      this.requestCallbackService.okResponse(getUserDataResponse,
        () => {
          this.currentUser = getUserDataResponse.data;
        });

      if (this.orderClient != null && this.orderClient.ds_email) {
        this.formEmailDTO.ds_email_destinatario = this.orderClient.ds_email;
      }

      const ds_nome = this.orderClient != null ? this.orderClient.ds_nome : "";

      const msg_prezado = this.language.translateMessage("prezado");
      const msg_segueSeuPedido = this.language.translateMessage("segue_seu_pedido");
      const msg_atenciosamente = this.language.translateMessage("atenciosamente");
      const mensagem = `${msg_prezado} ${ds_nome}, \n${msg_segueSeuPedido}: \n\n[link] \n\n${msg_atenciosamente},\n${this.currentUser.ds_nome} \n${this.currentUser.ds_empresa}`;
      this.formEmailDTO.ds_mensagem = mensagem;
    }, error => {
      this.requestCallbackService.failResponse(error);
      this.hideLoad();
    });
  }

  onFormSubmit(e) {
    e.preventDefault();

    const formData = new FormData();
    const obj = {
      email: this.currentUser.ds_nome,
      destinatario: this.formEmailDTO.ds_email_destinatario,
      assunto: this.formEmailDTO.ds_assunto,
      mensagem: this.formEmailDTO.ds_mensagem,
      st_pedido: this.formEmailDTO.st_pedido ? this.formEmailDTO.st_pedido : false,
      st_projeto: this.formEmailDTO.st_projeto ? this.formEmailDTO.st_projeto : false,
      cd_pedido: this.cd_orca,
      ds_pedido: this.language.translateMessage("ds_pedido"),
      ds_projeto: this.language.translateMessage("ds_projeto"),
    };
    formData.append("obj", JSON.stringify(obj));

    this.showLoad();
    this.pedidoEmailService.sendMailTo(formData).subscribe(
      (res: ApiResponse<{ success: boolean, message: string }>) => {
        this.hideLoad();
        this.requestCallbackService.okResponse(res,
          () => {
            this.toastService.successToast("email_enviado_sucesso");
            this.notifySentEmaildEvent.emit();
          });
      },
      error => {
        this.hideLoad();
        this.requestCallbackService.failResponse(error);
      }
    );
  }
}
