import { Component, OnInit } from '@angular/core';
import { LanguagueService } from 'src/app/shared/helpers/language.service';
import { RequestCallbackService } from 'src/app/shared/helpers/request-callback.service';
import { ToastService } from 'src/app/shared/helpers/toast.service';
import { isEnvironmentDebugOrTest } from 'src/app/shared/helpers/util';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { environment } from 'src/environments/environment';
import { RecuperarSenhaService } from './recuperar-senha.service';

@Component({
  selector: 'app-recuperar-senha',
  templateUrl: './recuperar-senha.component.html',
  styleUrls: ['./recuperar-senha.component.scss']
})
export class RecuperarSenhaComponent implements OnInit {

  loading: boolean = false;
  showEnvironment: boolean = false;
  environmentName: string = "";
  recoveryForm: any = {};
  buttonOptions = {};
  successRecoverySend: boolean;

  constructor(
    private language: LanguagueService,
    private requestCallbackService: RequestCallbackService,
    private toastService: ToastService,
    private service: RecuperarSenhaService
  ) {
    this.language.globalize();
    this.setupEnvironment()
  }

  private setupEnvironment() {
    this.showEnvironment = isEnvironmentDebugOrTest();
    this.environmentName = environment.environmentName;
  }

  ngOnInit() {
    this.setupButtonOptions();
  }

  private setupButtonOptions() {
    this.language.translateMessageLazy("recuperar", (value) => {
      this.buttonOptions = {
        text: value,
        useSubmitBehavior: true
      }
    });
  }

  onSubmit(event) {
    event.preventDefault();
    const recoveryEmail = this.recoveryForm.email;
    this.sendPasswordRecoveryEmail(recoveryEmail);
  }

  sendPasswordRecoveryEmail(email: string) {
    const formData = new FormData();
    const data = {
      ds_email: email,
      ds_assunto: this.language.translateMessage("recuperar_senha"),
      ds_mensagem: this.language.translateMessage("msg_email_recuperar_senha")
    };
    formData.append("data", JSON.stringify(data));

    this.loading = true;
    this.service.sendPasswordRecoveryEmail(formData)
      .subscribe((response: ApiResponse<{ success: boolean, message: string }>) => {
        this.loading = false;
        this.requestCallbackService.okResponse(response,
          () => {
            this.successRecoverySend = true;
            this.toastService.successToast("email_enviado_sucesso")
          }
        );

      }, error => {
        this.loading = false;
        this.requestCallbackService.failResponse(error);
      });
  }

}
