import { urlWs } from "../../config";
import { environment } from '../../../environments/environment';
import { PATH_NO_IMAGE, HASHCAD, USER_NAME, USER_EMAIL, STATUS_PENDENTE, STATUS_TECNICO, STATUS_FABRICANDO, STATUS_FABRICA, STATUS_RECEBIDO, STATUS_ENTREGUE, STATUS_RECUSADO } from './constants';
import { RequestCallbackService } from './request-callback.service';
import * as $ from "jquery";
import { LanguagueService } from './language.service';

export const cleanLocalStorage = () => {
  localStorage.removeItem(HASHCAD);
  localStorage.removeItem(USER_EMAIL);
  localStorage.removeItem(USER_NAME);
};

export const addDataToLocalStorage = (user: any) => {
  setItemStorage(HASHCAD, user.hash);
  setItemStorage(USER_EMAIL, user.ds_nome);
  setItemStorage(USER_NAME, user.ds_apelido);
};

export const getItemStorage = (key: string): string => {
  return localStorage.getItem(key);
}

export const setItemStorage = (key: string, value: string) => {
  localStorage.setItem(key, value);
}

export const getDatePtBr = (dbDate: string): string => {
  try {
    let date = /\/Date\((\d*)\)\//.exec(dbDate);
    return new Date(+date[1]).toLocaleString();
  } catch (e) {
    return dbDate;
  }
};

export const getSimpleDate = (dbDate: string): string => {
  try {
    let date = getDatePtBr(dbDate);
    return date.substring(0, 10);
  } catch (e) {
    return dbDate;
  }
};

export const getDate = (date): Date | any => {
  if (date) {
    return new Date(parseInt(date.substr(6)));
  }
  return date;
};

export const saveDataAsFile = (blobParts: any, fileName: string, docType: string = "text/plain") => {
  let textFileAsBlob = new Blob([blobParts], { type: docType });
  let fileNameToSaveAs = fileName;
  let downloadLink = document.createElement("a");
  downloadLink.download = fileNameToSaveAs;
  downloadLink.innerHTML = "My Hidden Link";
  window.URL = window.URL || (window as any).webkitURL;
  downloadLink.href = window.URL.createObjectURL(textFileAsBlob);
  downloadLink.onclick = destroyClickedElement;
  downloadLink.style.display = "none";
  document.body.appendChild(downloadLink);
  downloadLink.click();
};

const destroyClickedElement = event => {
  document.body.removeChild(event.target);
};

export const getCompletePathNoImage = () => {
  return urlWs + "/" + PATH_NO_IMAGE + '?' + (new Date()).getTime();
}

//region tarefa #29704
export const sleep = (time: number) => {
  return new Promise(resolve => setTimeout(resolve, time));
}

export const applyBlinkingEffectById = (idElement: string, time: number = 1500) => {
  let element = $("#" + idElement);
  applyBlinkingEffect(element, time);
}

export const applyBlinkingEffectByClass = (classElement: string, time: number = 1500) => {
  let element = $("." + classElement);
  applyBlinkingEffect(element, time);
}

export const applyBlinkingEffect = (element: any, time: number = 1500) => {
  $(element).addClass("blinking-effect");
  sleep(time).then(() => {
    $(element).removeClass("blinking-effect");
  });
}
// end region

//region tarefa #29842
export const isEnvironmentDebugOrTest = (): boolean => {
  return environment.environmentName == "DEBUG" || environment.environmentName == "TEST";
}
// end region #29842

//region tarefa #30164
export const getTiposDePessoa = (language: LanguagueService) => {
  return [
    {
      id: "Física",
      ds_tipo: language.translateMessage("pessoa_fisica")
    },
    {
      id: "Jurídica",
      ds_tipo: language.translateMessage("pessoa_jurifica")
    }
  ];
};
// region tarefa #30164

//region tarefa #29487
export const matchRegex = (regex: any, value: any): boolean => {
  let regExp: RegExp = new RegExp(regex); // insere '/' no inicio e no fim '/' 
  return regExp.test(value);
}
// end region tarefa #29487

// region tarefa #30571
// ALERTA DE GAMBI
// endpoint criado para 'acordar' o server, pois na grande maioria das vezes a primeira requisição fica 'presa' (muito tempo para processar).
export const getServerDate = (requestCallbackService: RequestCallbackService) => {
  requestCallbackService.getServerDate().subscribe((res) => {
    //console.log(res);
  }, error => {
    //console.log(error);
  });
}
// end region tarefa #30571

// region tarefa #30615
export const traduzirStatus = (status: string, language: LanguagueService): string => {
  switch (status) {
    case STATUS_PENDENTE:
      return language.translateMessage("Pendente");
    case STATUS_TECNICO:
      return language.translateMessage("Tecnico");
    case STATUS_FABRICANDO:
      return language.translateMessage("Fabricando");
    case STATUS_FABRICA:
      return language.translateMessage("Fabrica");
    case STATUS_RECEBIDO:
      return language.translateMessage("Recebido");
    case STATUS_ENTREGUE:
      return language.translateMessage("entregue");
    case STATUS_RECUSADO:
      return language.translateMessage("recusado");
    default:
      return language.translateMessage("Outros");
  }
  // end region tarefa #30615
}

//region tarefa #31365
export const isEnvironmentTestOrProduction = (): boolean => {
  return environment.environmentName == "TEST" || environment.environmentName == "PRODUCTION";
}
// end region #31365


export const calcMinWidthToDropDownMenu = (maxWidth: number): number => {
  const width = window.innerWidth / 1.5;
  if (width > maxWidth) {
    return maxWidth;
  }
  return width;
}

export const isIntegerNumber = (value: string): boolean => {
  const number = parseInt(value, 10);
  if (Number.isNaN(number) || !Number.isInteger(number)) {
    return false;
  }
  return true;
}