import { Component, OnInit, ViewChild } from "@angular/core";
import { DxSchedulerComponent, DxTreeViewComponent } from "devextreme-angular";
import { AgendaService } from "./agenda.service";
import { map } from "rxjs/operators";
import { Agenda } from "src/app/shared/models/Agenda";
import { FinanceiroService } from "src/app/shared/services/financeiro.service";
import { Equipe } from "src/app/shared/models/equipe";
import DataSource from "devextreme/data/data_source";
import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { RequestCallbackService } from "src/app/shared/helpers/request-callback.service";
import { getDate } from "src/app/shared/helpers/util";
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { WITHOUT_PERMISSION_KEY } from 'src/app/shared/helpers/constants';
import { LoadOptions } from 'devextreme/data/load_options';
import { LazyDataSource } from 'src/app/shared/models/LazyDataSource';
import { LanguagueService } from 'src/app/shared/helpers/language.service';
import { ToastService } from 'src/app/shared/helpers/toast.service';

export class EquipeDEV {
  id: number;
  text: string;
  color: string;
}
@Component({
  selector: "app-agenda",
  templateUrl: "./agenda.component.html",
  styleUrls: ["./agenda.component.scss"]
})
export class AgendaComponent implements OnInit {
  @ViewChild("agenda", { static: true }) agenda: DxSchedulerComponent;
  @ViewChild(DxTreeViewComponent, { static: false }) treeView;

  dataSourceClients: LazyDataSource;
  equipesDev: EquipeDEV[] = [];
  appointmentsData: Agenda[];
  currentDate = Date.now();
  equipes: Equipe[];
  dataSource: DataSource;
  dataSource2: DataSource;
  treeBoxValue: EquipeDEV[] = [];
  dataObs: Observable<string>;
  equipeObs: Observable<string>;
  equipe: number;
  result: number[];
  view: string = "month";
  loading: boolean = false;
  havePermission: boolean = true;
  formCode: number;

  constructor(
    private service: AgendaService,
    private financeiro: FinanceiroService,
    private language: LanguagueService,
    private route: ActivatedRoute,
    private requestCallbackService: RequestCallbackService,
    private toast: ToastService
  ) {
    this.language.globalize();
    this.equipeObs = this.route.paramMap.pipe(
      map(param => param.get("equipe"))
    );
    this.dataObs = this.route.paramMap.pipe(map(param => param.get("data")));
  }

  ngOnInit() {
    this.getEquipe();
    this.setupDataSourceClients();
    this.getAgenda();

    this.equipeObs.subscribe(res => {
      if (res != null) {
        this.equipe = parseInt(res);
        this.result = [];
        this.result.push(this.equipe);
      }
    });
  }

  showLoad() {
    this.loading = true;
  }

  hideLoad() {
    this.loading = false;
  }

  add(event) {
    let agenda = event.appointmentData;

    let form = {
      id: 0,
      title: agenda.ds_titulo,
      start: agenda.dt_inicio,
      end: agenda.dt_fim,
      recurrenceRule: agenda.ds_ocorrencia,
      recurrenceException: "",
      isAllDay: agenda.st_dia_todo,
      description: agenda.ds_descricao,
      clienteId: agenda.cd_cliente,
      equipeId: agenda.cd_equipe
    };
    let json = JSON.stringify(form);
    const formData = new FormData();
    formData.append("form", json);

    this.service.post(formData).subscribe((res: ApiResponse<{ success: boolean, message: string }>) => {

      this.requestCallbackService.okResponse(res,
        () => {
          if (res.key == WITHOUT_PERMISSION_KEY) {
            this.toast.warningToast("perm");
            this.toast.warningPermission(res);
          } else {
            this.toast.successToast("added");
          }
        });
      this.getAgenda();
    }, error => {
      this.requestCallbackService.failResponse(error, "errorA");
      this.getAgenda();
    });
  }

  update(event) {
    let registro = event.newData;
    let form = {
      id: registro.id,
      title: registro.ds_titulo,
      start: registro.dt_inicio,
      end: registro.dt_fim,
      recurrenceRule: registro.ds_ocorrencia,
      recurrenceException: "",
      isAllDay: registro.st_dia_todo,
      description: registro.ds_descricao,
      clienteId: registro.cd_cliente,
      equipeId: registro.cd_equipe
    };
    let json = JSON.stringify(form);
    const formData = new FormData();
    formData.append("form", json);
    this.service.update(formData).subscribe((res: ApiResponse<{ success: boolean, message: string }>) => {

      this.requestCallbackService.okResponse(res,
        () => {
          if (res.key == WITHOUT_PERMISSION_KEY) {
            this.toast.warningToast("perm");
            this.toast.warningPermission(res);
          } else {
            this.toast.successToast("edit");
          }
        });
      this.getAgenda();
    }, error => {
      this.requestCallbackService.failResponse(error, "errorU");
      this.getAgenda();
    });
  }

  delete(event) {
    let registro = event.appointmentData;
    let form = {
      id: registro.id,
      title: registro.ds_titulo,
      start: registro.dt_inicio,
      end: registro.dt_fim,
      recurrenceRule: registro.ds_ocorrencia,
      recurrenceException: "",
      isAllDay: registro.st_dia_todo,
      description: registro.ds_descricao,
      clienteId: registro.cd_cliente,
      equipeId: registro.cd_equipe
    };
    let json = JSON.stringify(form);
    const formData = new FormData();
    formData.append("form", json);
    this.service.delete(formData).subscribe((res: ApiResponse<{ success: boolean, message: string }>) => {

      this.requestCallbackService.okResponse(res,
        () => {
          if (res.key == WITHOUT_PERMISSION_KEY) {
            this.toast.warningToast("perm");
            this.toast.warningPermission(res);
          } else {
            this.toast.successToast("deleted");
          }
        });
      this.getAgenda();
    }, error => {
      this.requestCallbackService.failResponse(error, "errorD");
      this.getAgenda();
    });
  }

  setupDataSourceClients() {
    this.dataSourceClients = this.financeiro.getDataSourceClients();
  }

  getEquipe() {
    const sortByDsNome = [{ "selector": "ds_nome", "desc": false }];
    const loadOptions: LoadOptions = { sort: sortByDsNome };
    const formData = new FormData();
    formData.append("loadOptions", JSON.stringify(loadOptions));
    formData.append("integrantes", "false");
    this.service.getEquipe(formData).subscribe(
      (res: ApiResponse<{ success: boolean, message: string }>) => {

        this.requestCallbackService.okResponse(res,
          () => {
            const equipes = res.data.equipes.data;
            equipes.forEach(e => {
              let equipe = new EquipeDEV();
              equipe.id = e.id;
              equipe.text = e.ds_nome;
              equipe.color = e.ds_cor;
              this.equipesDev.push(equipe);
            });
            this.equipes = equipes;

            this.dataSource2 = new DataSource({
              store: this.equipesDev
            });
          }
        );
      },
      error => {
        this.requestCallbackService.failResponse(error, "falha_buscar_equipes");
      }
    );
  }

  getAgenda() {
    this.showLoad();
    this.service.getData().subscribe(
      (res: ApiResponse<{ success: boolean, message: string }>) => {
        this.hideLoad();
        this.requestCallbackService.okResponse(res,
          () => {
            if (res.key == WITHOUT_PERMISSION_KEY) {
              this.formCode = res.data.form;
              this.havePermission = false;
            } else {

              const appointmentsData = res.data.agenda;
              appointmentsData.forEach(a => {
                a.dt_inicio = new Date(a.dt_inicio);
                a.dt_fim = new Date(a.dt_fim);
              });
              this.appointmentsData = appointmentsData;

              this.dataObs.subscribe(res => {
                if (res != null) {
                  this.currentDate = Date.parse(res + "T00:00:00");
                  this.view = "day";
                }
              });
              this.dataSource = new DataSource({
                store: this.appointmentsData
              });
            }
          });
      },
      error => {
        this.hideLoad();
        this.requestCallbackService.failResponse(error, "falha_buscar_agenda");
      }
    );
  }

  syncTreeViewSelection() {
    let component = this.treeView && this.treeView.instance;

    if (!component) return;

    if (!this.treeBoxValue) {
      component.unselectAll();
      // region tarefa #29626
      let idsEquipes = [];
      this.equipesDev.forEach(element => {
        idsEquipes.push(element.id);
      });
      this.build(idsEquipes);
      // end region tarefa #29626
    }

    if (this.treeBoxValue) {
      this.treeBoxValue.forEach(
        function (value) {
          component.selectItem(value);
        }.bind(this)
      );
    }
  }

  treeView_itemSelectionChanged(e) {
    const nodes = e.component.getNodes();
    this.treeBoxValue = this.getSelectedItemsKeys(nodes);
  }

  getSelectedItemsKeys(items) {
    let result = [],
      that = this;

    items.forEach(function (item) {
      if (item.selected) {
        result.push(item.key);
      }
      if (item.items.length) {
        result = result.concat(that.getSelectedItemsKeys(item.items));
      }
    });
    that.build(result);
    this.result = result;

    return result;
  }

  build(key) {
    let data = [];

    if (key.length >= 1) {
      this.appointmentsData.forEach(e => {
        key.forEach(element => {
          if (e.cd_equipe == element) {
            data.push(e);
          }
        });
      });

      this.dataSource = new DataSource({
        store: data
      });
      this.findById(key);
    } else if (key.lentgh < 1) {
      this.appointmentsData.forEach(e => {
        if (e.cd_equipe == key) {
          data.push(e);
        }
      });
      this.dataSource = new DataSource({
        store: data
      });
    }
  }

  findById(key) {
    let teams = [];

    this.equipesDev.forEach(e => {
      key.forEach(element => {
        if (e.id === element) {
          teams.push(e);
        }
      });
    });
    this.dataSource2 = new DataSource({
      store: teams
    });
  }
}
