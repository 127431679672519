<div *ngIf="havePermission" class="m-3">
  <dx-data-grid
    id="data-grid-teams"
    [dataSource]="dataSource"
    [remoteOperations]="true"
    showBorders="true"
    [columnHidingEnabled]="true"
    [allowColumnReordering]="true"
    [allowColumnResizing]="true"
    (onRowUpdating)="onRowUpdating($event)"
    (onToolbarPreparing)="onToolbarPreparing($event)">

    <dxo-search-panel
      [visible]="true"
      [width]="240"
      [placeholder]="'index.procurar' | translate">
    </dxo-search-panel>

    <dxo-export
      [enabled]="true"
      [allowExportSelectedData]="false"
      [fileName]="'index.equipes' | translate">
    </dxo-export>

    <dxo-editing
      mode="form"
      [allowAdding]="true"
      [allowUpdating]="true"
      [allowDeleting]="true">
    </dxo-editing>

    <dxo-scrolling mode="virtual"></dxo-scrolling>

    <dxi-column
      dataField="ds_nome"
      [caption]="'index.equipe' | translate"
      [editorOptions]="{ maxLength: 20 }">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column
      dataField="integrantes"
      [caption]="'index.integrantes' | translate"
      [allowSorting]="false"
      cellTemplate="cellTemplateIntegrantes"
      editCellTemplate="editCellTemplateIntegrantes">

      <dxi-validation-rule type="required"></dxi-validation-rule>

      <div *dxTemplate="let data of 'cellTemplateIntegrantes'">
        <dx-tag-box
            *ngIf="data.value && data.value.length"
            [items]="data.value"
            displayExpr="ds_nome"
            valueExpr="id"
            [readOnly]="true"
            [value]="data.value">
        </dx-tag-box>
      </div>

      <div *dxTemplate="let data of 'editCellTemplateIntegrantes'">
          <dx-tag-box
              [dataSource]="dataSourceEmployees"
              displayExpr="ds_nome"
              [hideSelectedItems]="true"
              [searchEnabled]="true"
              (onValueChanged)="data.setValue($event.value)"
              [value]="data.value">
          </dx-tag-box>
      </div>

    </dxi-column>

    <dxi-column
      dataField="vl_qtde_integrantes"
      [caption]="'index.qtdIntegrantes' | translate"
      dataType="number"
      [editorOptions]="{ max: 99 }"
      [allowEditing]="false">
    </dxi-column>

    <dxi-column
      dataField="ds_cor"
      [caption]="'index.cor' | translate"
      cellTemplate="cellColorTemplate"
      editCellTemplate="editCellColorTemplate"
      [width]="70">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <div *dxTemplate="let data of 'cellColorTemplate'">
      <dx-color-box
        [value]="data.value"
        [readOnly]="true"
        (onValueChanged)="data.setValue($event.value)">
      </dx-color-box>
    </div>
    
    <div *dxTemplate="let data of 'editCellColorTemplate'">
      <dx-color-box
        [value]="data.value"
        (onValueChanged)="data.setValue($event.value)">
      </dx-color-box>
    </div>

    <div *dxTemplate="let data of 'dx-title'">
      <div class="text-center">
        <p class="title mb-0">{{ "index.equipes" | translate }}</p>
      </div>
    </div>
  </dx-data-grid>
</div>
<denied-access *ngIf="!havePermission" [formCode]="formCode"></denied-access>
