import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { baseUrl } from "src/app/config";
import { LazySelectService } from "src/app/shared/helpers/lazy-select.service";
import { RequestCallbackService } from "src/app/shared/helpers/request-callback.service";

@Injectable({
    providedIn: "root"
})
export class CidadesService {
    private url = baseUrl;
    constructor(
        private http: HttpClient,
        private lazyService: LazySelectService,
        private requestCallbackService: RequestCallbackService
    ) { }

    getCidades(form: FormData): Observable<any> {
        return this.http.post<any>(`${this.url}/Cidades`, form);
    }

    insertCidade(form: FormData): Observable<any> {
        return this.http.post(`${this.url}/InsertCidade`, form);
    }

    updateCidade(form: FormData): Observable<any> {
        return this.http.post(`${this.url}/UpdateCidade`, form);
    }

    deleteCidade(form: FormData): Observable<any> {
        return this.http.post(`${this.url}/DeleteCidade`, form);
    }

    getEstados(form: FormData): Observable<any> {
        return this.http.post(`${this.url}/getEstados`, form);
    }

    setupDataSourceStates() {
        return this.lazyService.setupLazyDataSource(
            "cd_codigo",
            "ds_nome",
            (loadOptions) => {
                const formData = new FormData();
                formData.append("loadOptions", JSON.stringify(loadOptions));
                return this.loadStates(formData);
            },
            (formData) => {
                return this.loadStateByKey(formData);
            }
        );
    }

    private loadStates(formData: FormData): Promise<any> {
        return this.requestCallbackService.load(
            this.getEstados(formData),
            (response) => {
                return response.data.estados;
            },
            "falha_buscar_estados"
        );
    }

    private loadStateByKey(formData: FormData): Promise<any> {
        return this.requestCallbackService.load(
            this.getEstados(formData),
            (response) => {
                return response.data.estados.data[0];
            },
            "falha_buscar_estados",
            true
        );
    }
}