<div *ngIf="havePermission" class="m-3">

    <dx-data-grid 
        id="data-grid-portadores"
        [dataSource]="dataSource" 
        [columnAutoWidth]="true" 
        showBorders="true"
        [allowColumnReordering]="true" 
        [allowColumnResizing]="true"
        [remoteOperations]="true"
        (onRowUpdating)="onRowUpdating($event)"
        (onToolbarPreparing)="onToolbarPreparing($event)">

        <dxo-search-panel 
            [visible]="true" 
            [width]="240" 
            [placeholder]="'index.procurar' | translate">
        </dxo-search-panel>

        <dxo-editing
            mode="row"
            [allowAdding]="true"
            [allowUpdating]="cm_allowEditAndDelete"
            [allowDeleting]="cm_allowEditAndDelete">
        </dxo-editing>

        <dxo-scrolling mode="virtual"></dxo-scrolling>

        <dxi-column 
            dataField="cd_portador"
            dataType="text"
            sortOrder="desc"
            [allowEditing]="false"
            [caption]="'index.cd_portador' | translate">
        </dxi-column>

        <dxi-column 
            dataField="ds_portador"
            dataType="string"
            [editorOptions]="{ maxLength: 50 }"
            [caption]="'index.ds_portador' | translate">
            <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>

        <dxi-column 
            dataField="ds_obs"
            dataType="string"
            [editorOptions]="{ maxLength: 50 }"
            [caption]="'index.ds_obs' | translate">
        </dxi-column>

        <dxi-column 
            dataField="vl_total_cr"
            dataType="number"
            [caption]="'index.vl_total_cr' | translate">
        </dxi-column>

        <dxi-column 
            dataField="vl_total_db"
            dataType="number"
            [caption]="'index.vl_total_db' | translate">
        </dxi-column>

        <dxi-column 
            dataField="cd_credito"
            dataType="string"
            [editorOptions]="{ maxLength: 7 }"
            [caption]="'index.cd_credito' | translate">
        </dxi-column>

        <dxi-column 
            dataField="cd_banco"
            dataType="number"
            [allowEditing]="false"
            [caption]="'index.cd_banco' | translate">
        </dxi-column>

        <dxi-column 
            dataField="cd_tipo_baixa"
            dataType="number"
            [caption]="'index.cd_tipo_baixa' | translate">
        </dxi-column>

        <dxi-column 
            dataField="st_contab"
            dataType="boolean"
            [caption]="'index.st_contab' | translate">
        </dxi-column>

        <dxi-column 
            dataField="st_notas"
            dataType="boolean"
            [caption]="'index.st_notas' | translate">
        </dxi-column>

        <dxi-column 
            dataField="st_mensagem"
            dataType="boolean"
            [caption]="'index.st_mensagem' | translate">
        </dxi-column>

        <dxi-column 
            dataField="cd_grupo"
            dataType="number"
            [caption]="'index.cd_grupo' | translate">
        </dxi-column>

        <dxi-column 
            dataField="dt_saldo_inicial"
            dataType="date"
            [caption]="'index.dt_saldo_inicial' | translate">
        </dxi-column>

        <dxi-column 
            dataField="vl_saldo_inicial"
            dataType="number"
            [caption]="'index.vl_saldo_inicial' | translate">
        </dxi-column>

        <dxi-column 
            dataField="cd_empresa" 
            [caption]="'index.empresa' | translate"
            [groupIndex]="0" 
            calculateDisplayValue="ds_empresa"
            [allowEditing]="false">
            <dxo-lookup
                [dataSource]="dataSourceCompanies"
                valueExpr="cd_empresa"
                displayExpr="ds_empresa">
            </dxo-lookup>
        </dxi-column>

        <div *dxTemplate="let data of 'dx-title'">
            <div class="text-center">
                <p class="title mb-0">{{'index.portadores' | translate}}</p>
            </div>
        </div>

    </dx-data-grid>

</div>
<denied-access *ngIf="!havePermission" [formCode]="formCode"></denied-access>