import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from 'src/app/config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AgendaService {

  private url = baseUrl;
  constructor(private http: HttpClient) { }

  getData(): Observable<any> {
    return this.http.get<any>(`${this.url}/getAgenda`);
  }

  getEquipe(form: FormData): Observable<any> {
    return this.http.post<any>(`${this.url}/getEquipes`, form)
  }

  post(form: FormData): Observable<any> {
    return this.http.post<any>(`${this.url}/insertAgenda`, form)
  }

  update(form: FormData): Observable<any> {
    return this.http.post<any>(`${this.url}/updateAgenda`, form)
  }

  delete(form: FormData): Observable<any> {
    return this.http.post<any>(`${this.url}/deleteAgenda`, form)
  }
}
