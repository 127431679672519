<app-loader *ngIf="loading"></app-loader>

<ng-container *ngIf="!showOptimize">
  <dx-data-grid
      id="gridContainer" 
      [dataSource]="dataSourceItens"
      [showBorders]="true"
      [masterDetail]="{ enabled: true, template: 'detail', autoExpandAll: true }"
      (onToolbarPreparing)="onToolbarPreparingExplode($event)"
      [columnAutoWidth]="true">
      
      <dxo-paging [enabled]="false"></dxo-paging>
      <dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>
      
      <dxi-column dataField="imagemThumb" [caption]="'index.foto' | translate" cellTemplate="fotoTemplate"
          editCellTemplate="fotoTemplate" [allowEditing]="false">
      </dxi-column>
      <div *dxTemplate="let data of 'fotoTemplate'">
        <img class="img-prod border" [src]="data.value" />
      </div>
      
      <dxi-column dataField="ds_descricao" [caption]="'index.produto' | translate"></dxi-column>

      <dxi-column dataField="obs" [caption]="'index.observacao' | translate"></dxi-column>

      <dxi-column dataField="larg" [caption]="'index.largura' | translate"></dxi-column>

      <dxi-column dataField="alt" [caption]="'index.altura' | translate"></dxi-column>

      <dxi-column dataField="qtde_pecas" [caption]="'index.quantidade' | translate"></dxi-column>

      <dxi-column dataField="vl_unit" [caption]="'index.un_venda' | translate"></dxi-column>

      <dxi-column dataField="vl_total" [caption]="'index.total' | translate"></dxi-column>

      <div *dxTemplate="let item of 'detail'">
        <!-- <div class="master-detail-caption">{{item.data.obs + " Itens: "}}</div> -->
        <ng-container *ngIf="item.data.itens.length > 0">
          <dx-data-grid 
            [dataSource]="item.data.itens"
            [showBorders]="true"
            (onCellPrepared)="onCellPreparedExplode($event)">

            <dxo-editing 
              mode="row"
              [allowUpdating]="true">
            </dxo-editing>

            <dxo-paging [enabled]="false"></dxo-paging>
            <dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>

            <dxi-column dataField="ds_descricao" [caption]="'index.produto' | translate" sortOrder="asc" [allowEditing]="false"></dxi-column>

            <dxi-column dataField="obs" [caption]="'index.observacao' | translate" [allowEditing]="false"></dxi-column>

            <dxi-column dataField="larg" [caption]="'index.largura' | translate" [allowEditing]="false"></dxi-column>

            <dxi-column dataField="qtde_pecas" [caption]="'index.quantidade' | translate" [allowEditing]="false"></dxi-column>

            <dxi-column dataField="vl_unit" [caption]="'index.un_venda' | translate" [allowEditing]="false"></dxi-column>

            <dxi-column dataField="vl_total" [caption]="'index.total' | translate" [allowEditing]="false"></dxi-column>

            <dxi-column dataField="cd_tipo" [caption]="'index.operacao' | translate">
              <dxo-lookup [dataSource]="dataSourceOperacao" valueExpr="cd_tipo" displayExpr="ds_descricao">
              </dxo-lookup>
            </dxi-column>

            <dxi-column
              [groupIndex]="0"
              dataField="cd_gabarito"
              [caption]="'index.grupoc' | translate"
              groupCellTemplate="groupCellTemplateNome">

              <dxo-lookup
                [dataSource]="dataSourceGruposGcad"
                displayExpr="ds_nome"
                valueExpr="cd_codigo">
              </dxo-lookup>

              <div *dxTemplate="let data of 'groupCellTemplateNome'">
                <div class="row">
                  <div class="col-3 d-flex align-items-center">
                    <span>{{ data.displayValue }}</span>
                  </div>
                  <div class="col-9 d-flex justify-content-end">
                    <div class="mr-90">
                      <dx-select-box
                        [dataSource]="dataSourceOperacao"
                        displayExpr="ds_descricao"
                        valueExpr="cd_tipo"
                        (onValueChanged)="onValueChanged($event, data)">
                      </dx-select-box>
                    </div>
                  </div>
                </div>
              </div>
            </dxi-column>

          </dx-data-grid>    
        </ng-container>

        <ng-container *ngIf="item.data.itens.length == 0">
          <div class="text-center"> {{ "index.sem_itens" | translate }} </div>
        </ng-container>
      </div>

  </dx-data-grid>
</ng-container>

<ng-container *ngIf="showOptimize">

  <dx-scroll-view width="100%" height="100%">
    <dx-data-grid
      [dataSource]="dataSourceGlass"
      [showBorders]="true"
      (onToolbarPreparing)="onToolbarPreparingOptimized($event)" 
      [columnAutoWidth]="true">    
      
      <dxi-column dataField="imagemThumb" [caption]="'index.foto' | translate" cellTemplate="fotoTemplate"
          editCellTemplate="fotoTemplate" [allowEditing]="false">
      </dxi-column>
      <div *dxTemplate="let data of 'fotoTemplate'">
        <img class="img-prod border" [src]="data.value" />
      </div>
      
      <dxi-column dataField="ds_descricao" [caption]="'index.produto' | translate"></dxi-column>

      <dxi-column dataField="obs" [caption]="'index.observacao' | translate"></dxi-column>

      <dxi-column dataField="larg" [caption]="'index.largura' | translate"></dxi-column>

      <dxi-column dataField="alt" [caption]="'index.altura' | translate"></dxi-column>

      <dxi-column dataField="qtde_pecas" [caption]="'index.quantidade' | translate"></dxi-column>

      <dxi-column dataField="vl_unit" [caption]="'index.un_venda' | translate"></dxi-column>

      <dxi-column dataField="vl_total" [caption]="'index.total' | translate"></dxi-column>

    </dx-data-grid>
    

    <dx-data-grid 
      [dataSource]="dataSourceOptimizedItens"
      [showBorders]="true"
      [masterDetail]="{ enabled: true, template: 'detail', autoExpandAll: false }"
      (onCellPrepared)="onCellPreparedOptimized($event)"
      [columnAutoWidth]="true">    
      
      <dxi-column dataField="ds_descricao" [caption]="'index.produto' | translate" sortOrder="asc"></dxi-column>

      <dxi-column dataField="obs" [caption]="'index.observacao' | translate"></dxi-column>

      <dxi-column dataField="larg" [caption]="'index.largura' | translate"></dxi-column>

      <dxi-column dataField="alt" [caption]="'index.altura' | translate"></dxi-column>

      <dxi-column dataField="qtde_pecas" [caption]="'index.quantidade' | translate"></dxi-column>

      <dxi-column dataField="vl_unit" [caption]="'index.un_venda' | translate"></dxi-column>

      <dxi-column dataField="vl_total" [caption]="'index.total' | translate"></dxi-column>

      <dxi-column dataField="cd_tipo" [caption]="'index.operacao' | translate">
        <dxo-lookup [dataSource]="dataSourceOperacao" valueExpr="cd_tipo" displayExpr="ds_descricao">
        </dxo-lookup>
      </dxi-column>

      <dxi-column
        [groupIndex]="0"
        dataField="cd_gabarito"
        [caption]="'index.grupoc' | translate"
        groupCellTemplate="groupCellTemplateNome">
                
        <dxo-lookup
          [dataSource]="dataSourceGruposGcad"
          displayExpr="ds_nome"
          valueExpr="cd_codigo">
        </dxo-lookup>

        <div *dxTemplate="let data of 'groupCellTemplateNome'">
          <span>{{ data.displayValue }}</span>
        </div>
      </dxi-column>

      <div *dxTemplate="let item of 'detail'">
        <ng-container *ngIf="item.data.otimPerfList != null">
          <dx-data-grid 
            [dataSource]="item.data.otimPerfList.ListaPerfil"
            [masterDetail]="{ enabled: true, template: 'detailBar', autoExpandAll: false }"
            [showBorders]="true"
            [columnAutoWidth]="true">

            <dxi-column dataField="Size" [caption]="'index.tamanho' | translate"></dxi-column>

            <dxi-column dataField="Used" [caption]="'index.usado' | translate"></dxi-column>

            <div *dxTemplate="let bar of 'detailBar'">
              <dx-data-grid 
                [dataSource]="bar.data.SubPartes"
                [showBorders]="true"
                [columnAutoWidth]="true">
        
                <dxi-column dataField="Product" [caption]="'index.codigo' | translate"></dxi-column>

                <dxi-column dataField="Size" [caption]="'index.tamanho' | translate"></dxi-column>

                <dxo-summary>
                  <dxi-total-item
                      column="Size"
                      summaryType="count">
                  </dxi-total-item>
                </dxo-summary>

              </dx-data-grid>
            </div>
          </dx-data-grid>    
        </ng-container>

        <ng-container *ngIf="item.data.otimPerfList == null">
          <div class="text-center"> {{ "index.sem_itens" | translate }} </div>
        </ng-container>
      </div>

    </dx-data-grid>
  </dx-scroll-view>
</ng-container>