<app-loader *ngIf="loading"></app-loader>

<div *ngIf="havePermission" class="m-3">
        <!-- Propriedade para gera o count das tarefas caso nao haja espaco para exibir a descricao tarefa #29363 -->
        <!-- [adaptivityEnabled]="true" -->
    <dx-scheduler
        #agenda
        id="agenda"
        [dataSource]="dataSource"         
        [remoteFiltering] ="true"
        [views]='["month", "week", "day"]'
        [(currentView)]="view"
        [currentDate]="currentDate"
        [startDayHour]="7"
        [endDayHour]="19"
        textExpr="ds_titulo"
        [groups]="['cd_equipe']"
        startDateExpr="dt_inicio"
        endDateExpr="dt_fim"
        allDayExpr="st_dia_todo"
        descriptionExpr="ds_descricao"
        recurrenceRuleExpr="ds_ocorrencia"
        (onAppointmentAdding)="add($event)"
        (onAppointmentUpdating)="update($event)"
        (onAppointmentDeleting)="delete($event)">

        <dxi-view
            type="month"
            groupOrientation="vertical"
            cellDuration="60">
        </dxi-view>
        <dxi-view
            type="week"
            groupOrientation="vertical"
            cellDuration="30">
        </dxi-view>
        <dxi-view
            type="day"
            groupOrientation="vertical"
            cellDuration="30">
        </dxi-view>
            <dxi-resource 
                [dataSource]="dataSourceClients"
                valueExpr="cd_codigo"
                displayExpr="ds_nome" 
                fieldExpr="cd_cliente"
                [allowMultiple]="false"
                [label]="'index.cliente' | translate">
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-resource>
            
            <dxi-resource 
                fieldExpr="cd_equipe"
                [allowMultiple]="false"
                [dataSource]="dataSource2"
                [label]="'index.equipe' | translate">
            </dxi-resource>

    </dx-scheduler>
    <div class="row mt-3" id="row_equipes">
        <div class="col-lg-3 pb-2 bg-equipes">
            <span class="mb-3">{{ 'index.equipes' | translate}} </span>
            <dx-drop-down-box
                [(value)]="treeBoxValue"
                valueExpr="id"
                displayExpr="text"
                [showClearButton]="true"
                [dataSource]="equipesDev"
                (onValueChanged)="syncTreeViewSelection()">

                <div *dxTemplate="let data of 'content'">
                    <dx-tree-view
                        [dataSource]="equipesDev"
                        dataStructure="plain"
                        keyExpr="id"
                        selectionMode="multiple"
                        showCheckBoxesMode="normal"
                        [selectNodesRecursive]="false"
                        displayExpr="text"
                        [selectByClick]="true"
                        (onContentReady)="syncTreeViewSelection($event)"
                        (onItemSelectionChanged)="treeView_itemSelectionChanged($event)">
                    </dx-tree-view>
                </div>
            </dx-drop-down-box>
        </div>
    </div>
</div>
<denied-access *ngIf="!havePermission" [formCode]="formCode"></denied-access>

