<app-loader *ngIf="loading"></app-loader>

<div class="m-3" *ngIf="!loading">
  <dx-data-grid
    id="order-table"
    [dataSource]="dataSource"
    [columnHidingEnabled]="true"
    showBorders="true"
    [allowColumnReordering]="true"
    [allowColumnResizing]="true"
    [remoteOperations]="true"
    (onToolbarPreparing)="onToolbarPreparing($event)"
  >
    <dxo-export
      [enabled]="true"
      [fileName]="'index.pedido' | translate"
      [allowExportSelectedData]="false"
    ></dxo-export>

    <dxo-search-panel
      [visible]="true"
      [width]="240"
      [placeholder]="'index.procurar' | translate"
    >
    </dxo-search-panel>

    <dxo-scrolling mode="virtual"></dxo-scrolling>

    <dxo-grouping [autoExpandAll]="false"></dxo-grouping>

    <dxi-column dataField="ds_docto" [caption]="'index.cd_pedido' | translate">
    </dxi-column>

    <dxi-column dataField="ds_nome" [caption]="'index.cliente' | translate">
    </dxi-column>

    <dxi-column
      dataField="nome_vendedor"
      [caption]="'index.vendedor' | translate"
    >
    </dxi-column>

    <dxi-column dataField="dt_data" [caption]="'index.data' | translate" dataType="date">
    </dxi-column>

    <dxi-column dataField="dt_prev" [caption]="'index.dataPrev' | translate" dataType="date">
    </dxi-column>

    <dxi-column
      dataField="st_orca"
      cellTemplate="statusTemplate"
      [caption]="'index.status' | translate"
    >
    </dxi-column>

    <div *dxTemplate="let data of 'statusTemplate'">
      <span>{{ getStatusDescription(data.value)}}</span>
    </div>

    <dxi-column dataField="vl_venda_total" [caption]="'index.valor' | translate">
    </dxi-column>
    <dxi-column dataField="vl_m2" [caption]="'index.m2' | translate">
    </dxi-column>

    <dxi-column dataField="st_gerado_fabrica" dataType="boolean" [caption]="'index.gerado_pelo_fornecedor' | translate">
    </dxi-column>

    <dxi-column dataField="ds_obs" [caption]="'index.observacoes' | translate">
    </dxi-column>

    <div *dxTemplate="let data of 'dx-title'">
      <div class="text-center">
        <p class="title mb-0">{{ "index.pedido" | translate }}</p>
      </div>
    </div>
  </dx-data-grid>
</div>
