import { Component, OnInit } from '@angular/core';
import { LoadOptions } from 'devextreme/data/load_options';
import { BaseLazyComponent } from 'src/app/shared/helpers/BaseLazyComponent';
import { LanguagueService } from 'src/app/shared/helpers/language.service';
import { RequestCallbackService } from 'src/app/shared/helpers/request-callback.service';
import { sleep } from 'src/app/shared/helpers/util';
import { LazyDataSource } from 'src/app/shared/models/LazyDataSource';
import { ClientesService } from '../clientes/clientes.service';
import { ProdutosService } from '../produtos/produtos.service';
import { FornecedoresGrupoCadService } from './fornecedores-grupo-cad.service';

@Component({
  selector: 'app-fornecedores-grupo-cad',
  templateUrl: './fornecedores-grupo-cad.component.html',
  styleUrls: ['./fornecedores-grupo-cad.component.scss']
})
export class FornecedoresGrupoCadComponent extends BaseLazyComponent implements OnInit {

  loading: boolean = false;
  dataSourceCountries: LazyDataSource;
  dataSourceCities: LazyDataSource;
  dataSourceRamos: any[];

  constructor(
    private requestCallbackService: RequestCallbackService,
    private language: LanguagueService,
    private service: FornecedoresGrupoCadService,
    private clientesService: ClientesService,
    private produtosService: ProdutosService
  ) {
    super("cd_codigo");
    this.language.globalize();
  }

  ngOnInit() {
    this.setupDataSourceRamos();
    this.setupDataSourceCidades();
    this.setupDataSourceCountries();
  }

  setupDataSourceRamos() {
    sleep(200).then(() => {
      this.dataSourceRamos = this.produtosService.getGruposGcad()
    });
  }

  setupDataSourceCidades() {
    this.dataSourceCities = this.clientesService.getDataSourceCidade();
  }

  setupDataSourceCountries() {
    this.dataSourceCountries = this.clientesService.setupDataSourceCountries();
  }

  load(loadOptions: LoadOptions): Promise<any> {
    const formData = this.createFormData([{ key: "loadOptions", value: loadOptions }]);
    return this.requestCallbackService.load(
      this.service.getFornecedores(formData),
      (response) => {
        return response.data.fornecedores;
      },
      "falha_buscar_fornecedores",
    );
  }

  onToolbarPreparing(e) {

    let items: any[] = e.toolbarOptions.items;

    // busca a barra de pesquisa
    const searchItem = items[items.length - 1];
    // permite collapse menu
    searchItem.locateInMenu = "auto";

    items.splice(
      1,
      0,
      {
        location: "after",
        widget: "dxButton",
        name: "Imprimir",
        options: {
          icon: "print",
          onClick: () => {
            window.print();
          }
        }
      },
      {
        location: "center",
        template: "dx-title"
      }
    );
  }
}
