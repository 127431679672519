import { ErrorHandler, Injectable, Injector, Inject } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import * as Sentry from "@sentry/browser";
import { isEnvironmentTestOrProduction, getItemStorage } from '../helpers/util';
import { SentryNotifyWSService } from './SentryNotifyWS.service';
import { SENTRY_DSN, HASHCAD, USER_EMAIL, USER_NAME } from '../helpers/constants';
import { ApiResponse } from '../models/ApiResponse';
import { ToastService } from '../helpers/toast.service';
import { LanguagueService } from '../helpers/language.service';

// tarefa #31365
Sentry.init({
    dsn: SENTRY_DSN,
    // TryCatch has to be configured to disable XMLHttpRequest wrapping, as we are going to handle
    // http module exceptions manually in Angular's ErrorHandler and we don't want it to capture the same error twice.
    // Please note that TryCatch configuration requires at least @sentry/browser v5.16.0.
    integrations: [new Sentry.Integrations.TryCatch({
        XMLHttpRequest: false,
    })],
    release: environment.appVersion,
    environment: environment.environmentName
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {

    constructor(
        private language: LanguagueService,
        private notifyWSService: SentryNotifyWSService,
        @Inject(Injector) private injector: Injector) {
    }

    // Need to get ToastrService from injector rather than constructor injection to avoid cyclic dependency error
    private get toastrService(): ToastService {
        return this.injector.get(ToastService);
    }

    extractError(error) {
        // Try to unwrap zone.js error.
        // https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
        if (error && error.ngOriginalError) {
            error = error.ngOriginalError;
        }

        // We can handle messages and Error objects directly.
        if (typeof error === "string" || error instanceof Error) {
            return error;
        }

        // If it's http module error, extract as much information from it as we can.
        if (error instanceof HttpErrorResponse) {
            // The `error` property of http exception can be either an `Error` object, which we can use directly...
            if (error.error instanceof Error) {
                return error.error;
            }

            // ... or an`ErrorEvent`, which can provide us with the message but no stack...
            if (error.error instanceof ErrorEvent) {
                return error.error.message;
            }

            // ...or the request body itself, which we can use as a message instead.
            if (typeof error.error === "string") {
                return `Server returned code ${error.status} with body "${error.error}"`;
            }

            // If we don't have any detailed information, fallback to the request message itself.
            return error.message;
        }

        // Skip if there's no error, and let user decide what to do with it.
        return null;
    }

    handleError(error) {
        let extractedError = this.extractError(error) || "Handled unknown error";

        // ignorar este erro....
        if (extractedError instanceof Error && extractedError.name == "ObjectUnsubscribedError") {
            return;
        }
        const msg = this.language.translateMessage("erro_desconhecido");
        this.toastrService.errorToast(msg);

        // tarefa #31466
        // Capture handled exception and send it to Sentry.
        if (isEnvironmentTestOrProduction()) {
            Sentry.setTag("hash", getItemStorage(HASHCAD));
            Sentry.setTag("ds_nome", getItemStorage(USER_EMAIL));
            let eventId: string = Sentry.captureException(extractedError);
            this.trySendFeedbackToWs(eventId, extractedError);
            this.openFeedbackDialog(eventId, this.language.currentLang(), getItemStorage(USER_NAME), getItemStorage(USER_EMAIL));
        }

        // When in development mode, log the error to console for immediate feedback.
        if (!environment.production) {
            console.error(extractedError);
        }
    }

    openFeedbackDialog(eventId: string, lang: string, userName: string, userEmail: string) {
        let user = {
            name: userName,
            email: userEmail
        }

        // não existe tradução pt, nessa condição deve-se fornecer todas as string para personalizar o form..
        // https://docs.sentry.io/enriching-error-data/user-feedback/?platform=browser
        if (lang == "pt") {
            let title = "Parece que estamos tendo problemas.";
            let subtitle = "Nossa equipe foi notificada.";
            let subtitle2 = "Se você quiser ajudar, conte-nos o que aconteceu abaixo.";
            let labelName = "Nome";
            let labelEmail = "Email";
            let labelComments = "O que aconteceu?";
            let labelClose = "Fechar";
            let labelSubmit = "Enviar";
            let errorGeneric = "Ocorreu um erro desconhecido ao enviar seu relato. Por favor, tente novamente.";
            let errorFormEntry = "Alguns campos são inválidos. Corrija os erros e tente novamente.";
            let successMessage = "Seu feedback foi enviado. Obrigado!";
            Sentry.showReportDialog({
                eventId, user, lang, title, subtitle, subtitle2, labelName, labelEmail, labelComments,
                labelClose, labelSubmit, errorGeneric, errorFormEntry, successMessage
            });
        } else {
            Sentry.showReportDialog({ eventId, user, lang });
        }
    }

    trySendFeedbackToWs(eventId: string, extractedError: string | Error) {
        const formData = new FormData();
        formData.append("extractedError", JSON.stringify(extractedError));
        formData.append("dsn", SENTRY_DSN);
        formData.append("eventId", eventId);
        this.notifyWSService.sendEventToWS(formData).subscribe(
            (res: ApiResponse<{ success: boolean, message: string }>) => {
            }, error => {
            })
    };
}
