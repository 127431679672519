import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { baseUrl } from "src/app/config";
import { ResumoTecnicoService } from "../resumo-tecnico/resumo-tecnico.service";

@Injectable({
    providedIn: 'root'
})
export class PedidoFormService {

    private url = baseUrl;

    constructor(
        private http: HttpClient,
        private resumoteTecnicoService: ResumoTecnicoService) { }

    technicalSummary(form: FormData): Observable<Blob> {
        this.resumoteTecnicoService.setupLabels(form);
        return this.http.post(`${this.url}/OrderTechnicalSummary`, form, { responseType: 'blob' });
    }
}