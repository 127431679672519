<app-loader *ngIf="!content"></app-loader>
<div *ngIf="content" class="m-3">

    <div *ngIf="responseStatus == 1">

        <div id="chart" class="panel">
            <dx-chart [dataSource]="historicoChart" id="historicoChart">
                <dxi-series *ngFor="let energy of labelChart" [valueField]="energy.value" [name]="energy.name">
                </dxi-series>
                <dxo-common-series-settings #seriesSettings argumentField="data" [type]="line">
                </dxo-common-series-settings>
                <dxo-margin [bottom]="20"></dxo-margin>
                <dxo-argument-axis [valueMarginsEnabled]="false" discreteAxisDivisionMode="crossLabels">
                    <dxo-grid [visible]="true"></dxo-grid>
                </dxo-argument-axis>
                <dxo-legend verticalAlignment="bottom" horizontalAlignment="center" itemTextPosition="bottom">
                </dxo-legend>
                <dxo-title [text]="'index.painelAcessosPedidos' | translate" horizontalAlignment="center">
                </dxo-title>
                <dxo-tooltip [enabled]="true"></dxo-tooltip>
            </dx-chart>
        </div>
        
        <div *ngIf="st_reitech" class="panel wrapper">
            <dx-data-grid 
                id="datagridLogin"
                [dataSource]="dataSourceLogin" [columnAutoWidth]="true"
                showBorders="true" [allowColumnReordering]="true" [allowColumnResizing]="true"
                (onToolbarPreparing)="onToolbarPreparing($event)" [remoteOperations]="true">

                <dxo-search-panel [visible]="true" [width]="240" [placeholder]="'index.procurar' | translate">
                </dxo-search-panel>

                <dxo-scrolling mode="virtual"></dxo-scrolling>

                <dxi-column dataField="cd_codigo" [caption]="'index.codigo' | translate">
                </dxi-column>

                <dxi-column dataField="ds_nome" [caption]="'index.nome' | translate" sortOrder="asc">
                </dxi-column>

                <dxi-column dataField="ds_email" [caption]="'index.email' | translate">
                </dxi-column>

                <dxi-column dataField="ds_empresa_pai" [caption]="'index.empresaPai' | translate">
                </dxi-column>

                <dxi-column 
                    dataField="tipo_empresa" 
                    [caption]="'index.tipoEmpresa' | translate">
                    <dxo-lookup
                        [dataSource]="dataSourceCompaniesType"
                        valueExpr="cd_codigo"
                        displayExpr="ds_nome">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column dataField="cd_reitech" [caption]="'index.codigoReitech' | translate">
                </dxi-column>

                <dxi-column dataField="ult_acesso" dataType="datetime" [caption]="'index.ultimoAcesso' | translate">
                </dxi-column>

                <dxi-column dataField="dt_vencto" dataType="date" [caption]="'index.dt_expiracao' | translate">
                </dxi-column>

                <div *dxTemplate="let data of 'dx-title'">
                    <div class="text-center">
                        <p class="title mb-0">Logins</p>
                    </div>
                </div>
            </dx-data-grid>
        </div>

        <div class="panel wrapper">
            <dx-data-grid 
                id="datagridPedidos"
                [dataSource]="dataSource" [columnAutoWidth]="true" [columnHidingEnabled]="true"
                showBorders="true" [allowColumnReordering]="true" [allowColumnResizing]="true"
                [remoteOperations]="true"
                (onToolbarPreparing)="onToolbarPreparing($event)">

                <dxo-search-panel [visible]="true" [width]="240" [placeholder]="'index.procurar' | translate">
                </dxo-search-panel>

                <dxo-scrolling mode="virtual"></dxo-scrolling>

                <dxi-column 
                    dataField="cd_orca" 
                    [caption]="'index.pedidoInterno' | translate"
                    [groupIndex]="0"
                    sortOrder="desc">
                </dxi-column>
                
                <dxi-column dataField="cd_material" [caption]="'index.ramo' | translate">
                    <dxo-lookup
                        [dataSource]="dataSourceRamos"
                        valueExpr="cd_codigo"
                        displayExpr="ds_nome">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column dataField="ds_docto" [caption]="'index.pedidoCliente' | translate">
                </dxi-column>

                <dxi-column dataField="dt_data" dataType="date" [caption]="'index.emissao' | translate">
                </dxi-column>

                <dxi-column dataField="dt_entrega" dataType="date" [caption]="'index.previsao' | translate">
                </dxi-column>

                <dxi-column dataField="ds_empresa" [caption]="'index.vidracaria' | translate">
                </dxi-column>

                <dxi-column dataField="ds_nome" [caption]="'index.clienteFinal' | translate">
                </dxi-column>

                <dxi-column
                    dataField="st_orca"
                    cellTemplate="statusTemplate"
                    [caption]="'index.status' | translate">
                </dxi-column>

                <div *dxTemplate="let data of 'statusTemplate'">
                    <span>{{ getStatusDescription(data.value)}}</span>
                </div>

                <dxi-column
                    dataField="st_importado"
                    cellTemplate="statusImportacaoTemplate"
                    [caption]="'index.status_importacao' | translate">
                </dxi-column>

                <div *dxTemplate="let data of 'statusImportacaoTemplate'">
                    <span>{{ data.value ? ("index.importado" | translate) : ('index.nao_importado' | translate) }}</span>
                </div>

                <dxi-column type="buttons">
                    <dxi-button [hint]="'index.importar' | translate" icon="export" [onClick]="handleImport">
                    </dxi-button>
                </dxi-column>

                <div *dxTemplate="let data of 'dx-title'">
                    <div class="text-center">
                        <p class="title mb-0">{{'index.painelDeControle' | translate}}</p>
                    </div>
                </div>

            </dx-data-grid>
        </div>
    </div>

    <div *ngIf="responseStatus == 2">
        <denied-access></denied-access>
    </div>

    <div *ngIf="responseStatus == 3">
        <div class="mt-4 box">
            <div>
                <h5 class="text-center">{{'index.loginM' | translate}}</h5>
                <p class="text-center">{{'index.loginErroServidor' | translate}}</p>
            </div>
        </div>
    </div>

</div>