<dx-data-grid
    #dataGridServices
    (onToolbarPreparing)="onToolbarPreparingServices($event)"
    [dataSource]="dataSourceServices"
    (onRowUpdating)="onRowUpdatingService($event)"
    (onInitNewRow)="onInitNewRowService($event)"
    [showColumnHeaders]="false"
    [showBorders]="true"
    (onInitialized)="saveGridServicesInstance($event)"
    rowTemplate="rowTemplateName">

    <dxo-editing
        [allowUpdating]="true"
        [allowDeleting]="true"
        [allowAdding]="!planoEhLight"
        mode="batch"> 
        <!-- popup -->
    </dxo-editing>

    <dxo-paging [pageSize]="10"></dxo-paging>
    <dxo-pager [showPageSizeSelector]="true" [showInfo]="true"> </dxo-pager>

    <dxi-column
        dataField="CodProdImg"
        [caption]="'index.foto' | translate"
        cellTemplate="imagemTemplate"
        editCellTemplate="imagemTemplate"
        [width]="150">
    </dxi-column>

    <div *dxTemplate="let data of 'imagemTemplate'">
        <img [src]="data.value" id="img_prd_service">
    </div>

    <dxi-column dataField="CodProd" [caption]="'index.produto' | translate">
        <dxo-lookup
        [dataSource]="dataSourceProdService"
        displayExpr="ds_codigo" >
        </dxo-lookup>
        <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column> 

    <dxi-column 
        dataField="Qtde_Pc"
        dataType="string"
        [caption]="'index.qtd_itens' | translate">
        <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column 
        dataField="Formula_Qtde_Pc"
        dataType="string"
        [formItem]="{visible: false}"
        [caption]="'index.formula' | translate">
    </dxi-column>

    <dxi-column 
        dataField="Valor" 
        [caption]="'index.valor' | translate">
        <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column 
        dataField="ValorTotal" 
        [caption]="'index.total' | translate">
    </dxi-column>

    <dxi-column 
        dataField="Qtde"
        dataType="string"
        [caption]="'index.tam_corte' | translate">
    </dxi-column>

    <dxi-column 
        dataField="Formula"
        [formItem]="{visible: false}"
        [caption]="'index.tam_corte' | translate">
    </dxi-column>

    <dxi-column 
        dataField="Qtde_calculada"
        dataType="string"
        [caption]="'index.formula_calc' | translate">
    </dxi-column>

    <dxi-column 
        dataField="Formula_calculada"
        [formItem]="{visible: false}"
        [caption]="'index.tam_cobranca' | translate">
    </dxi-column>

    <dxi-column 
        dataField="st_larg"
        [formItem]="{visible: false}">
    </dxi-column>

    <tbody class="dx-row" *dxTemplate="let item of 'rowTemplateName'">
        <tr class="notes-row" [id]="item.data.Key">
        <td colspan="11">
            <div class="container-services">
            <div>
                <div class="row no-gutters">
                <div class="col-md-12 col-lg-2 text-center align-self-center">
                    <img [alt]="item.data.CodProd" [src]="item.data.CodProdImg" class="img-prod border" />
                </div>

                <div class="col-md-11 col-lg-9">
                    <div class="container content">
                    <div class="row">
                        <div class="col-sm-12 col-md-6 col-lg-4">
                            <div class="form-group">
                                <label [for]="item.data.CodProd">{{"index.produto" | translate}}</label>
                                <div [id]="item.data.CodProd">
                                <dx-select-box [dataSource]="dataSourceProdService" 
                                    [readOnly]="planoEhLight"
                                    displayExpr="ds_codigo"
                                    [value]="item.data.CodProd" 
                                    (onInitialized)="onContentReadyCollumnService($event, item, 'CodProd')" 
                                    (onValueChanged)="valueChangedHandler($event, item, 'CodProd')"
                                    searchMode="contains" searchEnabled="true" [dropDownOptions]="{ minWidth: minWidthSelectBox }">
                                    <dx-validator validationGroup="rowTemplate">
                                        <dxi-validation-rule type="required"></dxi-validation-rule>
                                    </dx-validator>
                                </dx-select-box>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-4">
                            <div class="form-group">
                                <label [for]="item.data.Qtde_Pc">{{"index.qtd_itens" | translate}}</label>
                                <div [id]="item.data.Qtde_Pc">
                                <dx-text-box [value]="item.data.Qtde_Pc" 
                                    (onContentReady)="onContentReadyCollumnService($event, item, 'Qtde_Pc')" 
                                    (onValueChanged)="valueChangedHandler($event, item, 'Qtde_Pc')"
                                    (onFocusIn)="onFocusInFieldService($event, item, item.data.Formula_Qtde_Pc, 'Formula_Qtde_Pc')"
                                    (onFocusOut)="onFocusOutFieldService($event, item, 'Formula_Qtde_Pc', 'Qtde_Pc')">
                                    <dx-validator validationGroup="rowTemplate">
                                        <dxi-validation-rule type="required"></dxi-validation-rule>
                                    </dx-validator>
                                </dx-text-box>
                                </div>
                            </div>
                            <dx-text-box [value]="item.data.Formula_Qtde_Pc"
                                (onValueChanged)="valueChangedHandler($event, item, 'Formula_Qtde_Pc')" [visible]="false">
                            </dx-text-box>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-2">
                            <div class="form-group">
                                <label [for]="item.data.Valor">{{"index.valor" | translate}}</label>
                                <div [id]="item.data.Valor">
                                    <dx-number-box [value]="item.data.Valor" 
                                        [readOnly]="planoEhLight"
                                        (onContentReady)="onContentReadyCollumnService($event, item, 'Valor')"
                                        (onValueChanged)="valueChanged($event, item, 'Valor', true)">
                                        <dx-validator validationGroup="rowTemplate"> 
                                            <dxi-validation-rule type="required"></dxi-validation-rule>
                                        </dx-validator>  
                                    </dx-number-box>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-2">
                            <div class="form-group">
                                <label [for]="item.data.ValorTotal">{{"index.total" | translate}}</label>
                                <div [id]="item.data.ValorTotal">
                                <dx-number-box [value]="item.data.ValorTotal" [disabled]="true">
                                </dx-number-box>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12 col-md-6 col-lg-6">
                            <div class="form-group">
                                <label [for]="item.data.Qtde">{{"index.tam_corte" | translate}}</label>
                                <div [id]="item.data.Qtde">
                                <dx-text-box [value]="item.data.Qtde"
                                    [disabled]="!item.data.st_larg"
                                    (onContentReady)="onContentReadyCollumnService($event, item, 'Qtde')"
                                    (onValueChanged)="valueChangedHandler($event, item, 'Qtde')"
                                    (onFocusIn)="onFocusInFieldService($event, item, item.data.Formula, 'Formula')"
                                    (onFocusOut)="onFocusOutFieldService($event, item, 'Formula', 'Qtde')">
                                </dx-text-box>
                                </div>
                            </div>
                            <dx-text-box [value]="item.data.Formula"
                                (onValueChanged)="valueChangedHandler($event, item, 'Formula')" [visible]="false">
                            </dx-text-box>
                        </div>
                        
                        <div class="col-sm-12 col-md-6 col-lg-6">
                            <div class="form-group">
                                <label [for]="item.data.Qtde_calculada">{{"index.tam_cobranca" | translate}}</label>
                                <div [id]="item.data.Qtde_calculada">
                                <dx-text-box [value]="item.data.Qtde_calculada"
                                    [disabled]="!item.data.st_larg" 
                                    (onContentReady)="onContentReadyCollumnService($event, item, 'Qtde_calculada')"
                                    (onValueChanged)="valueChangedHandler($event, item, 'Qtde_calculada')"
                                    (onFocusIn)="onFocusInFieldService($event, item, item.data.Formula_calculada, 'Formula_calculada')"
                                    (onFocusOut)="onFocusOutFieldService($event, item, 'Formula_calculada', 'Qtde_calculada')">
                                </dx-text-box>
                                </div>
                            </div>
                            <dx-text-box [value]="item.data.Formula_Qtde_Pc" 
                                (onValueChanged)="valueChangedHandler($event, item, 'Formula_calculada')" [visible]="false">
                            </dx-text-box>
                        </div>
                    
                    </div> 
                    </div>
                </div>

                <div class="col-md-1 col-lg-1 text-center align-self-center no-print p-0">
                    <span class="action-buttons">
                        <i class="dx-icon dx-icon-trash"  (click)="handleDelete(item)"></i>
                    </span>
                </div>
                </div>
            </div>
            </div>
        </td>
        </tr>
    </tbody>
</dx-data-grid>