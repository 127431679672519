<div id="container" class="m-3">
    <app-loader *ngIf="!content"></app-loader>
    <div *ngIf="content">
        <div class="row text-center">
            <div class="col-xs-12 col-sm-12 col-md-6">
                <div class="border rounded mt-2">

                    <div *ngIf="historicos.length == 0" id="no-historico-data">
                        <div class="title">{{'index.historico' | translate}}</div>
                        <div class="vertical-center">
                            <span>{{'index.dadosHistorico' | translate}}</span>
                        </div>
                    </div>

                    <div *ngIf="historicos.length > 0" id="list-historico">
                        <div class="title">{{'index.historico' | translate}}</div>
                        <div class="list-container m-3">
                            <dx-list [items]="historicos" id="dx-list-historico">
                                <div *dxTemplate="let item of 'item'" [ngClass]="getClassName(item.operacao)">
                                    <div>
                                        <div>{{item.ds_usuario}} - {{item.data_hora}}</div>
                                        <div class="price">{{item.operacao}}</div>
                                    </div>
                                </div>
                            </dx-list>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6">
                <div class="border rounded mt-2">
                    <div *ngIf="showOrderChart">
                        <div *ngIf="pedidosChart.length == 0" id="pie-chart-no-data">
                            <div class="title">{{'index.graficoPedidos' | translate}}</div>
                            <div class="vertical-center">
                                <span>{{'index.dadosPedidos' | translate}}</span>
                            </div>
                        </div>
    
                        <dx-pie-chart id="pie-chart" *ngIf="pedidosChart.length > 0"
                            [title]="'index.graficoPedidos' | translate" palette="soft" [dataSource]="pedidosChart"
                            resolveLabelOverlapping="shift" [customizePoint]="customizePoint">
                            <dxo-legend orientation="horizontal" itemTextPosition="right" horizontalAlignment="center"
                                verticalAlignment="bottom" [columnCount]="6"></dxo-legend>
                            <dxi-series argumentField="ds_status" valueField="qtde">
                                <dxo-label [visible]="true" [customizeText]="customizeLabel">
                                </dxo-label>
                            </dxi-series>
                        </dx-pie-chart>
                    </div>

                    <div *ngIf="!showOrderChart">

                        <div *ngIf="tasks.length == 0" id="no-task-data">
                            <div class="title">{{'index.tarefas' | translate}}</div>
                            <div class="vertical-center">
                                <span>{{'index.sem_tarefas' | translate}}</span>
                            </div>
                        </div>
    
                        <div *ngIf="tasks.length > 0" id="list-tasks">
                            <div class="title">{{'index.tarefas' | translate}}</div>
                            <div class="list-container m-3">
                                <dx-list [items]="tasks" id="dx-list-tasks">
                                    <div *dxTemplate="let item of 'item'" [style.color]="item.ds_cor" ngClass="action">
                                        <div>
                                             <!-- <div>{{item.ds_nome_funcionario}}</div> -->
                                            <div>{{'index.equipe' | translate}}: {{item.ds_nome_equipe}}</div>
                                            <div>{{'index.assunto' | translate}}: {{item.ds_titulo}}</div>
                                            <div>{{'index.descricao' | translate}}: {{item.ds_descricao}}</div>
                                            <div *ngIf="item.isToday">{{'index.inicio' | translate}}: {{'index.hoje' | translate}}</div>
                                            <div *ngIf="item.isTomorrow">{{'index.inicio' | translate}}: {{'index.amanha' | translate}}</div>
                                            <div *ngIf="!item.isToday && !item.isTomorrow">{{'index.inicio' | translate}}: {{item.dt_inicio}}</div>
                                            <!-- <div>{{'index.inicio' | translate}}: {{item.dt_inicio}}</div>
                                            <div>{{'index.fim' | translate}}: {{item.dt_fim}}</div>
                                            <div *ngIf="item.st_dia_todo">{{'index.dia_todo' | translate}}</div>  -->
                                        </div>
                                    </div>
                                </dx-list>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row text-center">
            <div class="col-xs-12 col-sm-12 col-md-12">
                <div class="border rounded mt-4">

                    <div id="no-noticias" *ngIf="urlNoticias == null">
                        <div class="title">{{'index.noticias' | translate}}</div>
                        <div class="vertical-center">
                            <span>{{'index.dadosNoticias' | translate}}</span>
                        </div>
                    </div>

                    <div id="noticias" *ngIf="urlNoticias != null">
                        <div class="title">{{'index.noticias' | translate}}</div>
                        <iframe id="iframe-noticias" [src]="urlNoticias" class="w-100"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>