import { Component, OnInit, AfterViewInit, ViewChild } from "@angular/core";
import { FinanceiroService } from "./../../shared/services/financeiro.service";
import { Portador } from "src/app/shared/models/Portador";
import { RequestCallbackService } from "src/app/shared/helpers/request-callback.service";
import { Situacao, loadDataSourceSituacoes } from 'src/app/shared/models/Situacao';
import { BaseLazyComponent } from 'src/app/shared/helpers/BaseLazyComponent';
import { LoadOptions } from 'devextreme/data/load_options';
import { DxDataGridComponent } from 'devextreme-angular';
import { LazyDataSource } from 'src/app/shared/models/LazyDataSource';
import { LanguagueService } from 'src/app/shared/helpers/language.service';

@Component({
  selector: "app-contas-receber",
  templateUrl: "./contas-receber.component.html",
  styleUrls: ["./contas-receber.component.scss"]
})
export class ContasReceberComponent extends BaseLazyComponent implements OnInit, AfterViewInit {

  dataSourceSituacao: Situacao[] = [];
  dataSourceClient: LazyDataSource;
  portadores: Portador[] = [];
  loading: boolean = false;
  havePermission: boolean = true;
  formCode: number;

  @ViewChild(DxDataGridComponent, { static: false })
  dataGridContasReceber: DxDataGridComponent;

  constructor(
    private service: FinanceiroService,
    private language: LanguagueService,
    private requestCallbackService: RequestCallbackService
  ) {
    super("cd_codigo");
    this.language.globalize();
  }

  ngOnInit() {
    this.setupDataSourceClients();
    this.getPortadores();
  }

  ngAfterViewInit(): void {
    loadDataSourceSituacoes(this.language, this.dataSourceSituacao);
  }

  showLoad = () => {
    this.loading = true;
  }

  hideLoad = () => {
    this.loading = false;
  }

  load(loadOptions: LoadOptions): Promise<any> {
    const formData = this.createFormData([{ key: "loadOptions", value: loadOptions }]);
    return this.requestCallbackService.load(
      this.service.getReceber(formData),
      (response) => {
        if (this.requestCallbackService.isResponseWithoutPermission(response)) {
          this.formCode = response.data.form;
          this.havePermission = false;
        } else {
          const contasReceber: any[] = response.data.receber.data;
        }
        return response.data.receber;
      },
      "falha_buscar_contas_receber",
    );
  }

  insert(values: any): Promise<void> {
    const formData = this.createFormData([{ key: "values", value: values }]);
    return this.requestCallbackService.insert(this.service.postReceber(formData), this.dataGridContasReceber);
  }

  update(key: any, values: any): Promise<void> {
    const formData = this.createFormData([{ key: "key", value: key }, { key: "values", value: values }]);
    return this.requestCallbackService.update(this.service.updateCR(formData), this.dataGridContasReceber);
  }

  delete(key: any): Promise<void> {
    const formData = this.createFormData([{ key: "key", value: key }]);
    return this.requestCallbackService.delete(this.service.deletar(formData), this.dataGridContasReceber);
  }

  onRowUpdating(options) {
    options.newData = Object.assign(options.oldData, options.newData);
  }

  private setupDataSourceClients() {
    this.dataSourceClient = this.service.getDataSourceClients();
  }

  getPortadores() {
    this.requestCallbackService.doRequest(
      this.service.getPortadores(),
      (response) => {
        const portadores: any[] = response.data.portadores;
        this.portadores = portadores;
      },
      this.showLoad,
      this.hideLoad,
      "falha_buscar_portadores",
    );
  }

  onToolbarPreparing(e) {
    let items: any[] = e.toolbarOptions.items;

    // busca a barra de pesquisa
    const searchItem = items[items.length - 1];
    // permite collapse menu
    searchItem.locateInMenu = "auto";

    items.unshift({
      location: "center",
      template: "dx-title"
    });
  }
}
