import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { RequestCallbackService } from 'src/app/shared/helpers/request-callback.service';
import { sleep, getCompletePathNoImage, calcMinWidthToDropDownMenu } from 'src/app/shared/helpers/util';
import { EditorService } from '../editor/editor.service';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import CustomStore from 'devextreme/data/custom_store';
import validationEngine from "devextreme/ui/validation_engine";
import * as $ from "jquery";
import { LoadOptions } from 'devextreme/data/load_options';
import { LazyDataSource } from 'src/app/shared/models/LazyDataSource';
import { LazySelectService } from 'src/app/shared/helpers/lazy-select.service';
import { LanguagueService } from 'src/app/shared/helpers/language.service';
import { ToastService } from 'src/app/shared/helpers/toast.service';
import { FinanceiroService } from 'src/app/shared/services/financeiro.service';
import { Plano } from 'src/app/shared/models/Plano';

@Component({
  selector: 'app-perfis-acessorios',
  templateUrl: './perfis-acessorios.component.html',
  styleUrls: ['./perfis-acessorios.component.scss']
})
export class PerfisAcessoriosComponent implements OnInit {

  dataSourceServices: DataSource;
  dataSourceProdService: LazyDataSource;
  private dataGridServices: any;
  private changedColumns = [];

  private rowsToUpdate: any[] = [];
  private deferred: JQuery.Deferred<any, any, any>;
  private serviceHasBeenChanged: boolean;
  planoEhLight: boolean;

  @Input()
  conjunto: any;

  @Output() notifySaveGcadEvent = new EventEmitter();

  constructor(
    private requestCallbackService: RequestCallbackService,
    private language: LanguagueService,
    private toast: ToastService,
    private service: EditorService,
    private lazyService: LazySelectService,
    private financeiroService: FinanceiroService
  ) {
    this.language.globalize();
  }

  ngOnInit() {
    this.setupServiceDataSource();
    this.setupDataSourceProdutos();
    this.getPlano();
  }

  private setupServiceDataSource() {

    let store = new CustomStore({
      key: "Id",
      load: (loadOptions: LoadOptions) => {
        const formData = this.setupLoadRequest(loadOptions);
        return this.handleLoadService(formData);
      },
      insert: (values) => {
        return this.processMultipleService(values);
      },
      update: (key, values) => {
        return this.processMultipleService(values);
      },
      remove: (key) => {
        return this.processMultipleService({ Id: key, Deleted: true });
      }
    });
    this.dataSourceServices = new DataSource({ store });
  }

  setupLoadRequest(loadOptions: LoadOptions): FormData {
    const formData = new FormData();

    let conjunto = {
      p_gcad: this.conjunto.p_gcad,
      opt: {
        take: 100,
        skip: 0,
        page: 1,
        pageSize: 0
      }
    }
    let objJson = JSON.stringify(conjunto);
    formData.append("obj", objJson);
    formData.append("loadOptions", JSON.stringify(loadOptions));
    return formData;
  }

  handleLoadService(formData: FormData): Promise<void | any[]> {
    return this.service.getServicos(formData).toPromise().then(
      (result: ApiResponse<{ success: boolean, message: string }>) => {
        let response = [];
        this.requestCallbackService.okResponse(result,
          () => {
            let servicos = result.data.data;
            servicos.forEach(element => {
              element.changeValue = true;
            });
            response = servicos;

            if (this.serviceHasBeenChanged) {
              this.notifySaveGcadEvent.emit();
              this.serviceHasBeenChanged = false;
            }
          });
        return response;
      }, error => {
        this.requestCallbackService.failResponse(error, "falha_perfis_acessorios");
      });
  }

  private getPlano() {
    this.financeiroService.getPlano().subscribe(
      (res: ApiResponse<{ success: boolean, message: string }>) => {
        this.requestCallbackService.okResponse(res,
          () => {
            const codigoPlano = res.data.cd_plano;
            this.planoEhLight = codigoPlano == Plano.Light;
          });
      },
      error => {
        this.requestCallbackService.failResponse(error);
      }
    );
  }

  private processMultipleService(values: any): JQuery.Promise<any, any, any> {

    let service = values;
    service.Id = service.Id != null ? service.Id : null;
    service.CodProdImg = service.CodProdImg ? service.CodProdImg : "";
    service.Qtde = service.Qtde ? service.Qtde : 0;
    service.Formula = service.Formula ? service.Formula : "";
    service.Grupo = service.Grupo ? service.Grupo : "";
    service.Formula_calculada = service.Formula_calculada ? service.Formula_calculada : "";
    service.Qtde_calculada = service.Qtde_calculada ? service.Qtde_calculada : 0;
    service.ValorCusto = service.ValorCusto ? service.ValorCusto : 0;
    service.Valor = service.Valor ? service.Valor : 0;
    service.CustoTotal = service.CustoTotal ? service.CustoTotal : 0;
    service.ValorTotal = service.ValorTotal ? service.ValorTotal : 0;
    service.Formula_Qtde_Pc = service.Formula_Qtde_Pc ? service.Formula_Qtde_Pc : "";
    service.Qtde_Pc = service.Qtde_Pc ? service.Qtde_Pc : 0;
    this.rowsToUpdate.push(service);

    if (!this.deferred) {
      this.deferred = $.Deferred();

      sleep(1000).then(() => {
        this.handleServiceRequest(this.successProcess, this.rejectProcess);
      });
    }
    return this.deferred.promise();
  }

  private handleServiceRequest(successProcess: () => void, rejectProcess: () => void) {
    let conjunto = {
      p_gcad: this.conjunto.p_gcad,
      parametros: this.rowsToUpdate
    }
    let objJson = JSON.stringify(conjunto);
    const formData = new FormData();
    formData.append("obj", objJson);

    this.service.setServicos(formData).toPromise().then(
      (res: ApiResponse<{ success: boolean, message: string }>) => {
        this.requestCallbackService.okResponse(res,
          () => {
            successProcess();
            this.toast.successToast("salvar_sucesso");
            this.serviceHasBeenChanged = true;
          }, () => {
            rejectProcess();
          });
      }, error => {
        rejectProcess();
        this.requestCallbackService.failResponse(error, "salvar_falha");
      });
  }

  private rejectProcess = () => {
    this.deferred.reject();
    this.cleanProcess();
  }

  private successProcess = () => {
    this.deferred.resolve();
    this.cleanProcess();
  }

  private cleanProcess() {
    this.deferred = null;
    this.rowsToUpdate = [];
  }


  onRowUpdatingService(options) {
    options.newData = Object.assign(options.oldData, options.newData);
  }

  onInitNewRowService(e) {
    // tarefa #29677
    e.data.CodProdImg = getCompletePathNoImage();
    e.data.changeValue = true;

    if (this.dataSourceServices.items().length == 0) {
      this.dataGridServices.refresh();
    }
  }

  // tarefa #29677
  handleDelete(e) {
    // notifica o grid para deletar a linha
    this.dataGridServices.deleteRow(e.rowIndex);

    // executa a ação caso não seja um novo registro (nova linha)
    if (!e.isNewRow) {
      // recupera a 'Key' do registro
      const elementId = e.data.Key;
      // busca o elemento html pelo Id e adiciona a classe de modificação do registro (efeito visual para o usuário)
      $("#" + elementId).addClass("cell_edit");
    }
  }

  // tarefa #29677
  saveGridServicesInstance(e) {
    this.dataGridServices = e.component;
  }

  private setupDataSourceProdutos() {
    // filtro para o grupo #31805
    const filterGroup = [["cd_grupo_cad", "=", "1"], "or", ["cd_grupo_cad", "=", "3"], "or", ["cd_grupo_cad", "=", "4"]];
    this.dataSourceProdService = this.lazyService.setupLazyDataSource(
      "ds_codigo",
      "ds_descricao",
      (loadOptions) => {
        loadOptions.filter = filterGroup;
        if (loadOptions.searchValue) {
          // tarefa #29706
          const filter = [["ds_descricao", loadOptions.searchOperation, loadOptions.searchValue], "or", ["ds_codigo", loadOptions.searchOperation, loadOptions.searchValue]]
          loadOptions.filter = [loadOptions.filter, "and", filter];
        }
        const formData = new FormData();
        formData.append("loadOptions", JSON.stringify(loadOptions));
        return this.loadProducts(formData);
      },
      (formData, key) => {
        const filterDsCodigo = ["ds_codigo", key.toString()];
        const combinedFilter = [filterGroup, "and", filterDsCodigo];
        const loadOptions = {
          filter: combinedFilter
        }
        formData = new FormData();
        formData.append("loadOptions", JSON.stringify(loadOptions));
        return this.loadProductByKey(formData);
      }
    );
  }

  private loadProducts(formData: FormData): Promise<any> {
    return this.requestCallbackService.load(
      this.service.getProdutos(formData),
      (response) => {
        return response.data.produtos;
      },
      "falha_produtos"
    );
  }

  private loadProductByKey(formData: FormData): Promise<any> {
    return this.requestCallbackService.load(
      this.service.getProdutos(formData),
      (response) => {
        return response.data.produtos.data[0];
      },
      "falha_produtos",
      true
    );
  }

  private handleChangedCollumnServices(itemData, columnName) {
    // Ao realizar uma alteração nas colunas do grid de serviços
    // grava as informações da coluna em questão no array 'changedColumns'
    this.changedColumns.push({
      dataIndex: itemData.dataIndex,
      columnName: columnName
    });
  }

  onContentReadyCollumnService(e, itemData, columnName) {
    // ao inicializar a coluna do grid de serviços
    // verifica se ela sofreu alteração percorrendo o array 'changedColumns'
    this.changedColumns.forEach(column => {
      if (itemData.dataIndex == column.dataIndex && columnName == column.columnName) {
        // caso identifique que houve alteração
        // adiciona a classe 'cell_edit' ao elemento para destacá-la e manter o comportamento (nesse caso manual)
        // do modo 'batch' do datagrid.
        e.element.addClass("cell_edit");
        return;
      }
    });
  }

  // tarefa #29677
  private calculateFormula(obj: any, callback: (value: any) => void) {
    this.service.calculateFormula(obj).subscribe(
      (res: ApiResponse<{ success: boolean, message: string }>) => {
        this.requestCallbackService.okResponse(res,
          () => {
            callback(res.data.value);
          });
      }, error => {
        this.requestCallbackService.failResponse(error, "falha_calcular_formula");
        callback("0");
      }
    );
  }

  valueChangedHandler(e, itemData, columnName) {

    this.handleChangedCollumnServices(itemData, columnName);

    let value = e.value;
    if (columnName == "CodProd" && e.value) {
      value = e.value.ds_codigo;
    }

    // checka a flag para saber se deve notificar o grid sobre a alteração no valor.
    if (itemData.data.changeValue) {
      // caso sim altera o valor e notifica o grid.
      this.dataGridServices.cellValue(itemData.rowIndex, columnName, value);

      if (columnName == "CodProd" && e.value) {
        this.dataGridServices.cellValue(itemData.rowIndex, "CodProdImg", e.value.ds_imagem_server);
        const st_larg = e.value.st_larg;
        this.dataGridServices.cellValue(itemData.rowIndex, "st_larg", st_larg);
        if (!st_larg) {
          this.dataGridServices.cellValue(itemData.rowIndex, "Qtde", 0);
          this.dataGridServices.cellValue(itemData.rowIndex, "Formula", 0);
          this.dataGridServices.cellValue(itemData.rowIndex, "Qtde_calculada", 0);
          this.dataGridServices.cellValue(itemData.rowIndex, "Formula_calculada", 0);
        }
      }
    } else {
      // caso não altera apenas o valor.
      e.component.option("value", value);
    }
  }

  valueChanged(e, itemData, columnName) {
    this.handleChangedCollumnServices(itemData, columnName);
    this.dataGridServices.cellValue(itemData.rowIndex, columnName, e.value);
  }

  // evento disparado quando o campo recebe o foco.
  onFocusInFieldService(e, itemData, formula: string) {
    // altera a flag para não informar o grid que houve alteração no valor do campo.
    itemData.data.changeValue = false;

    // altera o valor do campo exibindo a formula.
    e.component.option("value", formula);
  }

  // evento disparado quando o campo perde o foco.
  onFocusOutFieldService(e, itemData, dataFieldFormula: string, dataFieldValue: string) {

    // altera a flag para informar o grid que houve alteração no valor do campo.
    itemData.data.changeValue = true;

    // recupera o valor da fórmula que está no campo
    let currentFormulaValue = e.component.option("value");

    this.dataGridServices.beginUpdate();
    this.dataGridServices.cellValue(itemData.rowIndex, dataFieldFormula, currentFormulaValue);

    //caso exista uma fórmula setada faz a requisição para calcular o valor
    if (currentFormulaValue) {
      let form = {
        "formula": currentFormulaValue,
        "valor": this.dataGridServices.cellValue(itemData.rowIndex, dataFieldValue),
        "campo": dataFieldFormula,
        "objeto": itemData.data,
        "p_origem_gcad": this.conjunto.p_origem_gcad,
        "p_gcad": this.conjunto.p_gcad
      }
      const formData = new FormData();
      formData.append("obj", JSON.stringify(form));
      this.calculateFormula(formData, (responseValue) => {
        // seta o valor calculado no campo
        this.dataGridServices.cellValue(itemData.rowIndex, dataFieldValue, responseValue);
        this.dataGridServices.endUpdate();
      });
    } else {
      // caso não exista uma fórmula setada seta valor zero.
      //e.component.option("value", 0);
      this.dataGridServices.cellValue(itemData.rowIndex, dataFieldValue, 0);
      this.dataGridServices.endUpdate();
    }
  }

  onToolbarPreparingServices(e) {
    let toolbarItems: any[] = e.toolbarOptions.items;

    toolbarItems.forEach((item) => {
      if (item.name === "saveButton") {
        item.options.onClick = () => {
          let result = validationEngine.validateGroup("rowTemplate");
          if (result.isValid) {

            // limpa o array de alterações de colunas do grid de serviços para que seja construído da forma padrão
            this.changedColumns = [];

            e.component.saveEditData();
          }
        };
      }

      if (item.name === "revertButton") {
        // recupera o botão 'Desfazer alterações'
        item.options.onClick = () => {

          // limpa o array de alterações de colunas do grid de serviços para que seja construído da forma padrão
          this.changedColumns = [];

          // chama o comportamento default do botão..
          e.component.cancelEditData();
        }
      }

    });
  }

  loadDataGrid() {
    this.dataSourceServices.load();
    this.dataGridServices.refresh();
  }

  minWidthSelectBox(): number {
    return calcMinWidthToDropDownMenu(250);
  }
}
