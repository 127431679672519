import { Component, OnInit, ViewChild } from '@angular/core';
import { setItemStorage } from 'src/app/shared/helpers/util';
import { PerfilService } from './perfil.service';
import { RequestCallbackService } from 'src/app/shared/helpers/request-callback.service';
import { DxFormComponent } from 'devextreme-angular';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { iconInvisible, iconVisible, USER_NAME } from 'src/app/shared/helpers/constants';
import * as $ from "jquery";
import { LanguagueService } from 'src/app/shared/helpers/language.service';
import { ToastService } from 'src/app/shared/helpers/toast.service';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})
export class PerfilComponent implements OnInit {

  @ViewChild(DxFormComponent, { static: false }) form: DxFormComponent
  loading: boolean = false;
  dataSourceForm: any = {};

  fieldPasswordMode: string;
  iconPassword: string;
  buttonPasswordEye: any = [];
  fieldConfirmPasswordMode: string;
  iconConfirmPassword: string;
  buttonConfirmPasswordEye: any = [];

  constructor(
    private language: LanguagueService,
    private perfilService: PerfilService,
    private requestCallbacService: RequestCallbackService,
    private toast: ToastService) {
      this.language.globalize();
  }

  ngOnInit() {
    this.getPerfil();
  }

  showLoad() {
    this.loading = true;
  }

  hideLoad() {
    this.loading = false;
  }

  passwordComparison = () => {
    let ds_senha = this.form.instance.option("formData").ds_senha;
    return ds_senha;
  };

  private getPerfil() {
    this.showLoad();
    this.perfilService.getPerfil().subscribe((res: ApiResponse<{ success: boolean, message: string }>) => {
      this.hideLoad();
      this.requestCallbacService.okResponse(res,
        () => {
          let user = res.data.user;
          user.email_note = this.language.translateMessage("aviso_edit_email");
          user.ds_senha = ""; // tarefa #30215

          // tarefa #30234
          this.setupBtnPasswordEye();
          this.setupBtnPasswordEye(false);

          this.dataSourceForm = user;
        }
      );
    }, error => {
      this.hideLoad();
      this.requestCallbacService.failResponse(error, "falha_get_user_perfil_data");
    });
  }

  Submit(e) {

    // tarefa #30215
    e.preventDefault();

    let nome = this.dataSourceForm.ds_apelido.trim();
    let senha = this.dataSourceForm.ds_senha;

    let user = {
      ds_apelido: nome,
      ds_senha: senha
    }
    let userJson = JSON.stringify(user);

    const formData = new FormData();
    formData.append("user", userJson);
    this.updatePerfil(formData);
  }

  private updatePerfil(formData) {
    this.showLoad();
    this.perfilService.updatePerfil(formData).subscribe((res: ApiResponse<{ success: boolean, message: string }>) => {
      this.hideLoad();

      this.requestCallbacService.okResponse(res,
        () => {

          // region tarefa #30234
          this.dataSourceForm.ds_senha = "";
          this.dataSourceForm.ds_confirma_senha = "";

          this.setupBtnPasswordEye();
          this.setupBtnPasswordEye(false);
          // end region tarefa #30234

          let user = res.data.user;
          this.toast.successToast("edit");

          // altera o nome do usuario no drawer com jquery
          let spanUserName = $(".fa-user").siblings();
          spanUserName.text(user.ds_apelido);
          setItemStorage(USER_NAME, user.ds_apelido);
        });
    }, error => {
      this.hideLoad();
      this.requestCallbacService.failResponse(error, "falha_update_user_perfil_data");
    });
  }


  // region tarefa #30234
  setupBtnPasswordEye(isBtnPassword: boolean = true) {

    this.fieldPasswordMode = "password"
    this.iconPassword = iconInvisible;
    this.fieldConfirmPasswordMode = "password";
    this.iconConfirmPassword = iconInvisible;

    let builtButton = [
      {
        name: isBtnPassword ? "passwordEye" : "confirmPasswordEye",
        location: "after",
        options: {
          icon: isBtnPassword ? this.iconPassword : this.iconConfirmPassword,
          stylingMode: "underlined",
          onClick: () => {

            if (isBtnPassword) {
              this.fieldPasswordMode =
                this.fieldPasswordMode === "text" ? "password" : "text";
              this.iconPassword =
                this.fieldPasswordMode === "text"
                  ? iconVisible
                  : iconInvisible;
              this.buttonPasswordEye[0].options.icon = this.iconPassword;
            } else {
              this.fieldConfirmPasswordMode =
                this.fieldConfirmPasswordMode === "text"
                  ? "password"
                  : "text";
              this.iconConfirmPassword =
                this.fieldConfirmPasswordMode === "text"
                  ? iconVisible
                  : iconInvisible;
              this.buttonConfirmPasswordEye[0].options.icon = this.iconConfirmPassword;
            }
          }
        }
      }
    ];

    if (isBtnPassword) {
      this.buttonPasswordEye = builtButton;
    } else {
      this.buttonConfirmPasswordEye = builtButton;
    }
  }
  // end region tarefa #30234

}
