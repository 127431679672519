<app-loader *ngIf="loading"></app-loader>
<div *ngIf="havePermission" class="m-3">
  <dx-data-grid
    id="data-grid-contas-pagar"
    [dataSource]="dataSource"
    showBorders="true"
    ShowGroupPanel="false"
    [allowColumnReordering]="true"
    [allowColumnResizing]="true"
    [columnHidingEnabled]="true"
    [remoteOperations]="true"
    (onRowUpdating)="onRowUpdating($event)"
    (onToolbarPreparing)="onToolbarPreparing($event)"
  >
    <dxo-search-panel
      [visible]="true"
      [width]="240"
      [placeholder]="'index.procurar' | translate"
    ></dxo-search-panel>

    <dxo-export
      [enabled]="true"
      [allowExportSelectedData]="false"
      [fileName]="'index.contasP' | translate"
    >
    </dxo-export>
    
    <dxo-editing
      mode="form"
      [allowAdding]="true"
      [allowUpdating]="true"
      [allowDeleting]="true"
    >
    </dxo-editing>

    <dxo-scrolling mode="virtual"></dxo-scrolling>

    <dxi-column 
      dataField="cd_cliente" 
      [caption]="'index.credor' | translate"
      calculateDisplayValue="ds_nome">
      <dxi-validation-rule type="required"></dxi-validation-rule>
      <dxo-lookup
        [dataSource]="dataSourceProviders"
        valueExpr="cd_codigo"
        displayExpr="ds_nome">
      </dxo-lookup>
    </dxi-column>

    <dxi-column
      dataField="vl_valor"
      [caption]="'index.valor' | translate"
      dataType="number"
      [editorOptions]="{ format: '###################.##', min: 0 }"
    >
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column
      dataField="cd_portador"
      [caption]="'index.portador' | translate"
    >
      <dxo-lookup
        [dataSource]="portadores"
        valueExpr="cd_portador"
        displayExpr="ds_portador"
      >
      </dxo-lookup>
    </dxi-column>

    <dxi-column
      dataField="docto"
      [caption]="'index.documento' | translate"
      [editorOptions]="{ maxLength: 15 }"
    >
    </dxi-column>

    <dxi-column
      dataField="dt_vencimento"
      [caption]="'index.dataV' | translate"
      dataType="date"
    >
    </dxi-column>

    <dxi-column
      dataField="dt_pagto"
      [caption]="'index.dataP' | translate"
      dataType="date"
    >
    </dxi-column>

    <dxi-column
      dataField="vl_pago"
      [caption]="'index.valorP' | translate"
      dataType="number"
      [editorOptions]="{ format: '###################.##', min: 0 }"
    >
    </dxi-column>

    <dxi-column
      dataField="obs"
      [caption]="'index.observacoes' | translate"
      [editorOptions]="{ maxLength: 70 }"
    >
    </dxi-column>

    <dxi-column dataField="situacao" [caption]="'index.situacao' | translate">
      <dxo-lookup
        [dataSource]="dataSourceSituacao"
        valueExpr="cd_situacao"
        displayExpr="ds_situacao"
      >
      </dxo-lookup>
    </dxi-column>

    <div *dxTemplate="let data of 'dx-title'">
      <div class="text-center">
        <p class="title mb-0">{{ "index.contasP" | translate }}</p>
      </div>
    </div>

    <dxo-summary>
      <dxi-total-item column="vl_valor" summaryType="sum"> </dxi-total-item>
      <dxi-total-item column="vl_pago" summaryType="sum"> </dxi-total-item>
    </dxo-summary>
  </dx-data-grid>
</div>
<denied-access *ngIf="!havePermission" [formCode]="formCode"></denied-access>

