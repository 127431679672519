import { OnInit } from "@angular/core";
import { Grupo } from "src/app/shared/models/Biblioteca";
import { BibliotecaService } from "./biblioteca.service";
import { map, first } from "rxjs/operators";
import DataSource from "devextreme/data/data_source";
import { isIntegerNumber, sleep } from "src/app/shared/helpers/util";
import { Component } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { RequestCallbackService } from 'src/app/shared/helpers/request-callback.service';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { Observable } from 'rxjs';
import * as $ from "jquery";
import { LanguagueService } from 'src/app/shared/helpers/language.service';
import { ToastService } from 'src/app/shared/helpers/toast.service';

export const FILTRO_TODOS = -1;
const FILTRO_MEDIDA_VAO = 1;
const FILTRO_MEDIDA_EXATA = 0;

const ORDENACAO_DESCRICAO = "ds_bib";
@Component({
  selector: "app-biblioteca",
  templateUrl: "./biblioteca.component.html",
  styleUrls: ["./biblioteca.component.scss"],
  providers: [BibliotecaService]
})
export class BibliotecaComponent implements OnInit {
  ped_orca: string;
  ped_orca_obs: Observable<string>;
  dataSourceConjuntos: DataSource = new DataSource([]);
  gruposFabrica: Grupo[] = [];
  gruposProprios: Grupo[] = [];
  loading: boolean = false;
  itemPopupGroup: any;
  titlePopUpSaveGroup: string;
  isAddingGroup: boolean = false;
  visibilityPopupDeleteGroup: boolean = false;
  visibilityPopupSaveGroup: boolean = false;
  visibilityPopupDeleteConjunto: boolean = false;
  private idConjuntoToDelete: string;
  filters: any[] = [];
  ordering: any[] = [];
  currentFilter: number = FILTRO_TODOS;
  currentOrder: string = ORDENACAO_DESCRICAO;

  constructor(
    private service: BibliotecaService,
    private language: LanguagueService,
    private router: Router,
    private route: ActivatedRoute,
    private toast: ToastService,
    private requestCallbackService: RequestCallbackService
  ) {
    this.language.globalize();
    this.ped_orca_obs = this.route.paramMap.pipe(
      map(param => param.get("pedido"))
    );
    this.pesquisar = this.pesquisar.bind(this);
  }

  ngOnInit() {
    this.ped_orca_obs.subscribe(cd_pedido => {
      if (!cd_pedido || !isIntegerNumber(cd_pedido)) {
        this.router.navigate(["/pagina/pedidos"]);
      }
      this.ped_orca = cd_pedido;
      this.getData();
      this.setupFiltersArray();
    });
  }

  getData(cd_grupo: string = "0") {
    const formData = new FormData();

    const obj = {
      skip: 0,
      like: "null",
      cd_grupo: cd_grupo,
      cd_cliente: "0",
      st_folgas: this.currentFilter,
      order: this.currentOrder,
    };
    let json = JSON.stringify(obj);
    formData.append("obj", json);
    this.showLoad();
    this.service.getGruposBiblioteca(json).subscribe(
      (res: ApiResponse<{ success: boolean, message: string }>) => {
        this.hideLoad();

        this.requestCallbackService.okResponse(res,
          () => {
            if (cd_grupo == "0") {
              this.gruposFabrica = this.buildGroupsFabrica(res);
              this.gruposProprios = this.buildGroupsProprios(res);
            }

            let conjuntos = res.data.listaConjuntosBiblioteca;
            this.dataSourceConjuntos = new DataSource(conjuntos);
            this.dataSourceConjuntos.paginate(false); // FORCA A EXIBIÇÃO DE TODOS OS CONJUNTOS..

            this.scrollToTop();
          });
      },
      error => {
        this.hideLoad();
        this.requestCallbackService.failResponse(error, "falha_buscar_biblioteca");
      }
    );
  }

  private buildGroupsFabrica(res: ApiResponse<{ success: boolean, message: string }>): Grupo[] {
    const ID_GRUPO_FABRICA = -1;
    const gruposFabrica: any[] = res.data.listaGruposBiblioteca;
    gruposFabrica.forEach(group => {
      if (group.cd_pai == 0 || group.cd_pai == group.id) {
        group.cd_pai = ID_GRUPO_FABRICA;
      }
    });
    const grupoFabrica = new Grupo();
    grupoFabrica.id = ID_GRUPO_FABRICA;
    grupoFabrica.expanded = true;
    grupoFabrica.ds_grupo = this.language.translateMessage("Fabrica");
    gruposFabrica.push(grupoFabrica);
    return gruposFabrica;
  }

  private buildGroupsProprios(res: ApiResponse<{ success: boolean, message: string }>): Grupo[] {
    const ID_GRUPO_PROPRIO = -2;
    const gruposProprios: any[] = res.data.listaGruposBibliotecaPropria;
    gruposProprios.forEach(element => {
      if (element.cd_pai == 0) {
        element.cd_pai = ID_GRUPO_PROPRIO;
      }
    });
    const grupoProprio = new Grupo();
    grupoProprio.id = ID_GRUPO_PROPRIO;
    grupoProprio.expanded = true;
    grupoProprio.ds_grupo = this.language.translateMessage("meus_grupos");
    gruposProprios.push(grupoProprio);
    return gruposProprios;
  }

  setupFiltersArray() {
    sleep(400).then(() => {
      this.filters = [
        { key: FILTRO_TODOS, name: this.language.translateMessage("todos") },
        { key: FILTRO_MEDIDA_VAO, name: this.language.translateMessage("medida_vao") },
        { key: FILTRO_MEDIDA_EXATA, name: this.language.translateMessage("medida_exata") },
      ];
      this.ordering = [
        { key: ORDENACAO_DESCRICAO, name: this.language.translateMessage("descricao") },
      ]
    })
  }

  onGrupoClick(group) {
    const cd_group_selected = group.id;
    this.getData(cd_group_selected.toString());
  }

  private scrollToTop() {
    const scrollView = $("#tileView").dxScrollView('instance');
    if (scrollView) {
      const topDistance = scrollView.scrollTop() * -1;
      scrollView.scrollBy(topDistance);
    }
  }

  onConjuntoClick(data) {
    this.router.navigate(["/pagina/biblioteca/" + this.ped_orca + "/editor/" + data.Nome]);
  }

  pesquisar(e) {
    // REGRA PARA EFETUAR A BUSCA DO CONJUNTO PELO CÓDIGO OU PELA DESCRIÇÃO..
    const searchValue = e.value ? e.value.trim() : e.value;
    this.dataSourceConjuntos.filter([
      ["Descricao", "contains", searchValue],
      "or",
      ["Nome", "contains", searchValue]
    ]);
    this.dataSourceConjuntos.load().done(() => {
      this.scrollToTop();
    });
  }

  showLoad() {
    this.loading = true;
  }

  hideLoad() {
    this.loading = false;
  }

  countColumnCustomizeText(cellInfo) {
    return this.language.translateMessage("total") + ": " + cellInfo.value;
  }

  handleAddGroup(item) {
    this.visibilityPopupSaveGroup = true;
    this.itemPopupGroup = {
      id: item.id,
      ds_grupo: ""
    }
    this.titlePopUpSaveGroup = this.language.translateMessage("criar_grupo");
    this.isAddingGroup = true;
  }

  handleEditGroup(item) {
    this.visibilityPopupSaveGroup = true;
    this.itemPopupGroup = {
      id: item.id,
      ds_grupo: item.ds_grupo.trim(),
      cd_pai: item.cd_pai,
    }
    this.titlePopUpSaveGroup = this.language.translateMessage("editar_grupo");
    this.isAddingGroup = false;
  }

  handleDeleteGroup(item) {
    this.visibilityPopupDeleteGroup = true;
    this.itemPopupGroup = {
      id: item.id,
      ds_grupo: item.ds_grupo.trim()
    }
  }

  handleSaveGrupo(e) {
    e.preventDefault();
    if (!this.itemPopupGroup.ds_grupo) {
      return;
    }

    if (this.isAddingGroup) {
      this.insertGrupos(this.itemPopupGroup)
    } else {
      this.updateGrupos(this.itemPopupGroup)
    }
  }

  closePopupDeleteGroup() {
    this.visibilityPopupDeleteGroup = false;
  }

  insertGrupos(item) {
    const formData = new FormData();
    const obj = {
      sort: null,
      group: null,
      filter: null,
      id: null,
      ds_grupo: item.ds_grupo,
      st_assistente: null,
      cd_pai: item.id,
    };
    let json = JSON.stringify(obj);
    formData.append("obj", json);
    this.showLoad();
    this.service.insertGrupos(formData).subscribe(
      (res: ApiResponse<{ success: boolean, message: string }>) => {
        this.hideLoad();
        this.requestCallbackService.okResponse(res,
          () => {
            this.toast.successToast("added");
            this.visibilityPopupSaveGroup = false;
            this.getData();
          });
      },
      error => {
        this.hideLoad();
        this.requestCallbackService.failResponse(error, "errorA");
      }
    );
  }

  updateGrupos(item) {
    const formData = new FormData();
    const obj = {
      sort: null,
      group: null,
      filter: null,
      id: item.id,
      ds_grupo: item.ds_grupo,
      st_assistente: null,
      cd_pai: item.cd_pai
    };
    let json = JSON.stringify(obj);
    formData.append("obj", json);
    this.showLoad();
    this.service.updateGrupos(formData).subscribe(
      (res: ApiResponse<{ success: boolean, message: string }>) => {
        this.hideLoad();
        this.requestCallbackService.okResponse(res,
          () => {
            this.toast.successToast("edit");
            this.visibilityPopupSaveGroup = false;
            this.getData();
          });
      },
      error => {
        this.hideLoad();
        this.requestCallbackService.failResponse(error, "errorU");
      }
    );
  }

  deleteGrupos(item) {
    const formData = new FormData();
    const obj = {
      sort: null,
      group: null,
      filter: null,
      id: item.id,
      ds_grupo: item.ds_grupo,
      st_assistente: null
    };
    let json = JSON.stringify(obj);
    formData.append("obj", json);
    this.showLoad();
    this.service.deleteGrupos(formData).subscribe(
      (res: ApiResponse<{ success: boolean, message: string }>) => {
        this.hideLoad();
        this.requestCallbackService.okResponse(res,
          () => {
            this.toast.successToast("deleted");
            this.visibilityPopupDeleteGroup = false;
            this.getData();
          });
      },
      error => {
        this.hideLoad();
        this.requestCallbackService.failResponse(error, "errorD");
      }
    );
  }

  redirectToPedido() {
    this.router.navigate(["/pagina/pedidos/itens/" + this.ped_orca]);
  }

  openModalFullScreenConjunto(element: any) {

    // Get the modal
    let idModal = "myModal";
    let idImg = "img01";
    let idClose = "close";
    let idCaption = "caption";

    let modal = document.getElementById(idModal);

    let modalImg = <HTMLImageElement>document.getElementById(idImg); //
    let captionText = document.getElementById(idCaption);
    modal.style.display = "block";
    modalImg.src = element.ImagemDetalhe;
    captionText.innerHTML = element.Descricao;

    // Get the <span> element that closes the modal
    let span = <HTMLElement>document.getElementById(idClose);

    // When the user clicks on <span> (x), close the modal
    span.onclick = () => {
      modal.style.display = "none";
    };

    // When the user clicks anywhere outside of the modal, close it
    window.onclick = (event) => {
      if (event.target == modal) {
        modal.style.display = "none";
      }
    }
  }

  deleteConjunto() {
    this.visibilityPopupDeleteConjunto = false;
    const id_conj = this.idConjuntoToDelete;
    const formData = new FormData();
    formData.append("id_conj", id_conj);
    this.showLoad();
    this.service.excluiConjuntoBib(formData).pipe(first())
      .subscribe(
        (res: ApiResponse<{ success: boolean, message: string }>) => {
          this.hideLoad();
          this.requestCallbackService.okResponse(res,
            () => {
              this.toast.successToast("msg_conjunto_deletado");
              this.getData();
            });
        }, error => {
          this.hideLoad();
          this.requestCallbackService.failResponse(error, "msg_falha_delete_conjunto");
        }
      );
  }

  openPopupDeleteConjunto(id: string) {
    this.visibilityPopupDeleteConjunto = true;
    this.idConjuntoToDelete = id;
  }

  closePopupDeleteConjunto() {
    this.visibilityPopupDeleteConjunto = false;
  }
}
