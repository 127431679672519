import { Injectable } from '@angular/core';
import { LanguagueService } from './language.service';
import { ToastrService, GlobalConfig, ActiveToast } from 'ngx-toastr';
import { CustomToast } from 'src/app/components/custom-toast/custom.toast';

@Injectable({
    providedIn: 'root'
})
export class ToastService {

    constructor(private toastr: ToastrService, private language: LanguagueService) {
    }

    successToast = (resourceMessageKey: string) => {
        this.toastr.success(
            this.language.translateMessage(resourceMessageKey),
            "",
            {
                closeButton: true,
                enableHtml: true
            }
        );
    };

    errorToast = (msg: string, internalError: any = "") => {
        const opt: GlobalConfig = Object.assign({}, this.toastr.toastrConfig);
        opt.toastComponent = CustomToast;
        opt.toastClass = 'customtoast';
        // gambi para transmitir a mensagem de erro para o componente customizado
        opt.easing = internalError;
        opt.disableTimeOut = true;
        opt.tapToDismiss = false;
        opt.closeButton = true;
        opt.enableHtml = true;
        this.toastr.show(msg, "", opt);
    };

    warningPermission = (error: any) => {
        let formCode = error.form ? error.form : error.data.form;
        let msg = this.language.translateMessage("formulario") + " : " + formCode;
        this.toastr.warning(
            msg,
            "",
            {
                closeButton: true
            }
        );
    };

    warningToast = (resourceMessageKey: string) => {
        this.toastr.warning(
            this.language.translateMessage(resourceMessageKey),
            "",
            {
                closeButton: true,
            }
        );
    };

    //region tarefa #32237
    customWarning(resourceMessageKey: string): ActiveToast<any>{
        return this.toastr.warning(
            this.language.translateMessage(resourceMessageKey),
            "",
            {
                closeButton: true,
                tapToDismiss: false,
                enableHtml: true,
                disableTimeOut: true
            }
        );
    }

    clear(toastId?: number){
        this.toastr.clear(toastId)
    }
    //end region tarefa #32237
}