<app-loader *ngIf="loading"></app-loader>
<div class="m-3">
    <div class="form border">
        
        <form (submit)="Submit($event)">
            <dx-button id="button" icon="save" useSubmitBehavior="true" validationGroup="validationProfile"> </dx-button>
            <dx-form id="form"
                [formData]="dataSourceForm"
                [showColonAfterLabel]="true"
                [showValidationSummary]="true"
                validationGroup="validationProfile">
                
                <dxi-item itemType="group" [caption]="'index.editarPerfil' | translate">
                    <dxi-item
                        dataField="ds_apelido"
                        [label]="{ text: 'index.nome' | translate }"
                        [editorOptions]="{ autocomplete: 'none', maxLength: '20' }">
                        <dxi-validation-rule 
                            type="required">
                        </dxi-validation-rule>  
                    </dxi-item>

                    <dxi-item
                        dataField="ds_nome"
                        [label]="{ text: 'index.email' | translate }">
                        <div *dxTemplate="let data of 'ds_nome'">
                            <dx-text-box [value]="dataSourceForm.ds_nome" [disabled]="true" *ngIf="dataSourceForm.ds_nome"></dx-text-box>
                            <span id="aviso_email">{{ "index.aviso_edit_email" | translate }}</span>
                        </div>
                    </dxi-item>

                    <dxi-item
                        dataField="ds_empresa"
                        [label]="{ text: 'index.empresa' | translate }"
                        [editorOptions]="{ disabled: true }">
                    </dxi-item>

                    <dxi-item 
                        dataField="ds_senha"
                        [label]="{ text: 'index.senha' | translate }" 
                        editorType="dxTextBox"
                        [editorOptions]="{ mode: fieldPasswordMode, autocomplete: 'none', maxLength: '32', buttons: buttonPasswordEye}">
                    </dxi-item>

                    <dxi-item
                        dataField="ds_confirma_senha"
                        [label]="{ text: 'index.confirmeSenha' | translate }" 
                        editorType="dxTextBox"
                        [editorOptions]="{ mode: fieldConfirmPasswordMode, maxLength: '32', buttons: buttonConfirmPasswordEye}">
                        <dxi-validation-rule
                            type="compare"
                            [comparisonTarget]="passwordComparison"
                            [message]="'index.senhasNaoConferem' | translate"> 
                        </dxi-validation-rule>
                    </dxi-item>

                </dxi-item>
            </dx-form>
        </form>
    </div>
</div>