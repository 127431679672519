import { Component, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { LoadOptions } from 'devextreme/data/load_options';
import { BaseLazyComponent } from 'src/app/shared/helpers/BaseLazyComponent';
import { LanguagueService } from 'src/app/shared/helpers/language.service';
import { RequestCallbackService } from 'src/app/shared/helpers/request-callback.service';
import { LazyDataSource } from 'src/app/shared/models/LazyDataSource';
import { UsuariosService } from '../usuarios/usuarios.service';
import { TiposService } from './tipos.service';

@Component({
  selector: 'app-tipos',
  templateUrl: './tipos.component.html',
  styleUrls: ['./tipos.component.scss']
})
export class TiposComponent extends BaseLazyComponent implements OnInit {

  havePermission: boolean = true;
  formCode: number;
  dataSourceCompanies: LazyDataSource;

  @ViewChild(DxDataGridComponent, { static: false })
  dataGridTipos: DxDataGridComponent;
  
  constructor(
    private language: LanguagueService,
    private requestCallbackService: RequestCallbackService,
    private tiposService: TiposService,
    private serviceUser: UsuariosService
  ) {
    super("cd_codigo");
    this.language.globalize();
  }

  ngOnInit() {
    this.setupDataSourceCompanies();
  }

  private setupDataSourceCompanies() {
    this.dataSourceCompanies = this.serviceUser.setupDataSourceCompanies();
  }

  load(loadOptions: LoadOptions): Promise<any> {
    const formData = this.createFormData([{ key: "loadOptions", value: loadOptions }]);
    return this.requestCallbackService.load(
      this.tiposService.getTipos(formData),
      (response) => {
        if (this.requestCallbackService.isResponseWithoutPermission(response)) {
          this.formCode = response.data.form;
          this.havePermission = false;
        }
        return response.data.tipos;
      },
      "falha_buscar_tipo_produto",
    );
  }

  insert(values: any): Promise<void> {
    const formData = this.createFormData([{ key: "values", value: values }]);
    return this.requestCallbackService.insert(this.tiposService.insertTipo(formData), this.dataGridTipos);
  }

  update(key: any, values: any): Promise<void> {
    const formData = this.createFormData([{ key: "key", value: key }, { key: "values", value: values }]);
    return this.requestCallbackService.update(this.tiposService.updateTipo(formData), this.dataGridTipos);
  }

  delete(key: any): Promise<void> {
    const formData = this.createFormData([{ key: "key", value: key }]);
    return this.requestCallbackService.delete(this.tiposService.deleteTipo(formData), this.dataGridTipos);
  }

  onRowUpdating(options) {
    options.newData = Object.assign(options.oldData, options.newData);
  }

  cm_allowEditAndDelete = (e): boolean => {
    return e.row.data.st_edita;
  }

  onToolbarPreparing(e) {

    const items: any[] = e.toolbarOptions.items;
    const searchItem = items[items.length - 1];
    searchItem.locateInMenu = "auto";

    items.unshift({
      location: "center",
      template: "dx-title"
    });
  }

}
