import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { LoadOptions } from 'devextreme/data/load_options';
import { Param } from '../models/Param';

@Injectable({
    providedIn: 'root'
})
export class BaseLazyComponent {

    private loadOptions: LoadOptions;
    dataSource: DataSource;

    constructor(key: string | string[]) {
        if (new.target === BaseLazyComponent) {
            throw new TypeError("Cannot construct Abstract instances directly");
        }
        this.createCustomDataSource(key);
    }

    private createCustomDataSource(key: string | string[]) {
        const store = new CustomStore({
            key: key,
            load: (loadOptions: LoadOptions) => {
                this.loadOptions = loadOptions;
                this.sortBy(key, true);
                return this.load(loadOptions);
            },
            insert: (values) => {
                return this.insert(values);
            },
            update: (key, values) => {
                return this.update(key, values);
            },
            remove: (key) => {
                return this.delete(key);
            }
        });
        this.dataSource = new DataSource({ store });
    }

    createFormData(params: Param[]): FormData {
        const formData = new FormData();
        params.forEach(param => {
            if (!(typeof param.value === "string")) {
                param.value = JSON.stringify(param.value)
            }
            formData.append(param.key, param.value);
        });
        return formData;
    }

    sortBy(column: string | string[], desc: boolean) {
        if (!this.loadOptions.sort && !Array.isArray(column)) {
            const sortBy = [{ "selector": column, "desc": desc }];
            this.loadOptions.sort = sortBy;
        }
    }

    load(loadOptions: LoadOptions): Promise<any> {
        throw new Error('You have to implement the method doSomething!');
    };

    insert(values: any): Promise<any> {
        throw new Error('You have to implement the method doSomething!');
    };

    update(key: string, values: any): Promise<any> {
        throw new Error('You have to implement the method doSomething!');
    };

    delete(key: string): Promise<any> {
        throw new Error('You have to implement the method doSomething!');
    };

}