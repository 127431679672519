import { Component, OnInit } from "@angular/core";
import { PedidosService } from "./../../shared/services/pedidos.service";
import { Pedido } from "src/app/shared/models/Pedido";
import { RequestCallbackService } from "src/app/shared/helpers/request-callback.service";
import { traduzirStatus } from "src/app/shared/helpers/util";
import { LoadOptions } from 'devextreme/data/load_options';
import { BaseLazyComponent } from 'src/app/shared/helpers/BaseLazyComponent';
import { LanguagueService } from 'src/app/shared/helpers/language.service';

@Component({
  selector: "app-pedidos",
  templateUrl: "./pedidos.component.html",
  styleUrls: ["./pedidos.component.scss"]
})
export class PedidosComponent extends BaseLazyComponent implements OnInit {

  loading: boolean = false;

  constructor(
    private service: PedidosService,
    private requestCallbackService: RequestCallbackService,
    private language: LanguagueService) {
    super("cd_orca");
    this.language.globalize();
  }

  ngOnInit() {

  }

  showLoad() {
    this.loading = true;
  }

  hideLoad() {
    this.loading = false;
  }

  load(loadOptions: LoadOptions): Promise<any> {
    const formData = this.createFormData([{ key: "loadOptions", value: loadOptions }]);
    return this.requestCallbackService.load(
      this.service.getPedidos(formData),
      (response) => {
        return response.data.pedidos;
      },
      "falha_buscar_pedidos",
    );
  }

  onToolbarPreparing(e) {

    let items: any[] = e.toolbarOptions.items;

    // busca a barra de pesquisa
    const searchItem = items[items.length - 1];
    // permite collapse menu
    searchItem.locateInMenu = "auto";

    items.splice(
      0,
      0,
      {
        location: "after",
        widget: "dxButton",
        name: "Imprimir",
        options: {
          icon: "print",
          onClick: () => {
            window.print();
          }
        }
      },
      {
        location: "center",
        template: "dx-title"
      }
    );
  }

  getStatusDescription(acronymStatus: string): string {
    return traduzirStatus(acronymStatus, this.language);
  }
}
