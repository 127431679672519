import { Component, OnInit, ViewChild } from "@angular/core";
import { PermissaoService } from "./../permissoes/permissao.service";
import { User } from "src/app/shared/models/User";
import { UsuariosService } from "./usuarios.service";
import { RequestCallbackService } from "src/app/shared/helpers/request-callback.service";
import { calcMinWidthToDropDownMenu } from "src/app/shared/helpers/util";
import { WITHOUT_PERMISSION_KEY, iconInvisible, iconVisible } from 'src/app/shared/helpers/constants';
import { PainelService } from '../painel/painel.service';
import { BaseLazyComponent } from 'src/app/shared/helpers/BaseLazyComponent';
import { LoadOptions } from 'devextreme/data/load_options';
import { DxDataGridComponent } from 'devextreme-angular';
import { LazySelectService } from 'src/app/shared/helpers/lazy-select.service';
import { LazyDataSource } from 'src/app/shared/models/LazyDataSource';
import { LanguagueService } from 'src/app/shared/helpers/language.service';
@Component({
  selector: "app-usuarios",
  templateUrl: "./usuarios.component.html",
  styleUrls: ["./usuarios.component.scss"]
})
export class UsuariosComponent extends BaseLazyComponent implements OnInit {

  loading: boolean = false;
  dataSourceCompanies: LazyDataSource
  dataSourceSellers: LazyDataSource
  passwordIsRequired: boolean = false;
  havePermission: boolean = true;
  formCode: number;
  passwordButton: any;
  passwordMode: string;
  eyeIcon: string;

  @ViewChild(DxDataGridComponent, { static: false })
  dataGridUser: DxDataGridComponent;

  constructor(
    private service: PermissaoService,
    private serviceUser: UsuariosService,
    private painelService: PainelService,
    private requestCallbackService: RequestCallbackService,
    private lazyService: LazySelectService,
    private language: LanguagueService) {
    super("cd_codigo");
    this.language.globalize();
    this.passwordMode = "password";
    this.eyeIcon = iconInvisible;
  }

  ngOnInit() {
    this.setupDataSourceCompanies();
    this.setupDataSourceSellers();
    this.isMasterUser();
  }

  showLoad = () => {
    this.loading = true;
  }

  hideLoad = () => {
    this.loading = false;
  }

  private isMasterUser() {
    this.requestCallbackService.doRequest(
      this.painelService.isUserProfileReitech(),
      (response) => {
        // caso não seja um usuário do tipo fabrica e deve ocultar algumas colunas..
        if (response.key == WITHOUT_PERMISSION_KEY || !response.data.st_fabrica) {
          this.dataGridUser.instance.columnOption("cd_empresa", "visible", false);
          this.dataGridUser.instance.columnOption("cd_empresa", "validationRules", false);
          this.dataGridUser.instance.columnOption("vl_max_sessoes", "visible", false);
          this.dataGridUser.instance.columnOption("ds_empresa", "visible", false);
          this.dataGridUser.instance.columnOption("ds_empresa", "groupIndex", -1);
          this.dataGridUser.instance.columnOption("cd_vendedor", "visible", false);
        }
      },
      this.showLoad,
      this.hideLoad,
    );
  }

  private setupDataSourceCompanies() {
    this.dataSourceCompanies = this.serviceUser.setupDataSourceCompanies();
  }

  private setupDataSourceSellers() {
    this.dataSourceSellers = this.lazyService.setupLazyDataSource(
      "cd_codigo",
      "ds_nome",
      (loadOptions) => {
        const formData = this.createFormData([{ key: "loadOptions", value: loadOptions }]);
        return this.loadSellers(formData);
      },
      (formData) => {
        return this.loadSellersByKey(formData);
      }
    );
  }

  loadSellers(formData: FormData): Promise<any> {
    return this.requestCallbackService.load(
      this.serviceUser.getVendedoresFabrica(formData),
      (response) => {
        return response.data.vendedores;
      },
      "falha_buscar_vendedores"
    );
  }

  private loadSellersByKey(formData: FormData): Promise<any> {
    return this.requestCallbackService.load(
      this.serviceUser.getVendedoresFabrica(formData),
      (response) => {
        return response.data.vendedores.data[0];
      },
      "falha_buscar_vendedores",
      true
    );
  }

  load(loadOptions: LoadOptions): Promise<any> {
    const formData = this.createFormData([{ key: "loadOptions", value: loadOptions }]);
    return this.requestCallbackService.load(
      this.service.getUsuarios(formData),
      (response) => {
        if (this.requestCallbackService.isResponseWithoutPermission(response)) {
          this.formCode = response.data.form;
          this.havePermission = false;
        } else {
          const usuarios: User[] = response.data.usuarios.data;
          usuarios.forEach(user => {
            this.setupPasswordMode(user);
          });
        }
        return response.data.usuarios;
      },
      "falha_buscar_usuarios",
    );
  }

  insert(values: any): Promise<void> {
    const formData = this.createFormData([{ key: "values", value: values }]);
    return this.requestCallbackService.insert(this.serviceUser.insert(formData), this.dataGridUser);
  }

  update(key: any, values: any): Promise<void> {
    const formData = this.createFormData([{ key: "key", value: key }, { key: "values", value: values }]);
    return this.requestCallbackService.update(this.serviceUser.update(formData), this.dataGridUser);
  }

  delete(key: any): Promise<void> {
    const formData = this.createFormData([{ key: "key", value: key }]);
    return this.requestCallbackService.delete(this.serviceUser.delete(formData), this.dataGridUser);
  }

  // tarefa #29622
  passwordValueChanged(e, data) {
    if (data.setValue) {
      data.setValue(e.value);
    }
  }

  onEditorPreparing(e: any) {
    if (e.parentType == "dataRow") {
      e.editorOptions.inputAttr = { autocomplete: "none" };
    }
  }

  onInitNewRow(e) {
    this.passwordIsRequired = true;
    e.data.vl_max_sessoes = 1;
    const user = e.data;
    this.setupPasswordMode(user);
  }

  onEditingStart() {
    this.passwordIsRequired = false;
  }

  onToolbarPreparing(e) {
    let items: any[] = e.toolbarOptions.items;

    // busca a barra de pesquisa
    const searchItem = items[items.length - 1];
    // permite collapse menu
    searchItem.locateInMenu = "auto";

    items.unshift({
      location: "center",
      template: "dx-title"
    });
  }

  setupPasswordMode(user: any) {
    this.passwordButton = {
      icon: this.eyeIcon,
      type: "default",
      onClick: () => {
        this.passwordMode = this.passwordMode === "text" ? "password" : "text";
        this.eyeIcon = this.passwordMode === "text" ? iconVisible : iconInvisible;
        if (this.passwordIsRequired) {
          // TODO TRATAMENTO DIFERENTE PAR NOVO REGISTRO..
        }
        this.setupPasswordMode(user);
      }
    };
  }

  onRowUpdating(options) {
    options.newData = Object.assign(options.oldData, options.newData);
  }

  minWidthSelectBox(): number {
    return calcMinWidthToDropDownMenu(400);
  }
}
