import { Component, OnInit } from '@angular/core';
import { AcompanhamentoService } from './acompanhamento.service';
import { Etiquetas } from 'src/app/shared/models/Etiquetas';
import { RequestCallbackService } from 'src/app/shared/helpers/request-callback.service';
import { sleep } from 'src/app/shared/helpers/util';
import { LoadOptions } from 'devextreme/data/load_options';
import { STATUS_ETIQUETA_ENTREGUE, STATUS_ETIQUETA_CANCELADA, STATUS_ETIQUETA_EXCLUIDA, STATUS_ETIQUETA_DEVOLVIDA, STATUS_ETIQUETA_CREDITO, STATUS_ETIQUETA_PENDENTE } from 'src/app/shared/helpers/constants';
import { BaseLazyComponent } from 'src/app/shared/helpers/BaseLazyComponent';
import { LanguagueService } from 'src/app/shared/helpers/language.service';

const ETIQUETAS_PENDENTE = 21;
const ETIQUETAS_ENTREGUE = -1;
const ETIQUETAS_TODAS = 0;

@Component({
  selector: 'app-acompanhamento',
  templateUrl: './acompanhamento.component.html',
  styleUrls: ['./acompanhamento.component.scss']
})
export class AcompanhamentoComponent extends BaseLazyComponent implements OnInit {

  statuses: any[] = [];
  loading: boolean = false;
  acompanhamento: Etiquetas[] = [];
  currentFilter: number;

  constructor(
    private service: AcompanhamentoService,
    private language: LanguagueService,
    private requestCallbackService: RequestCallbackService
  ) {
    super("cd_codigo");
    this.language.globalize();
  }

  ngOnInit() {
    this.setupStatusArray();
  }

  setupStatusArray() {
    sleep(400).then(() => {
      this.statuses = [
        { key: ETIQUETAS_PENDENTE, name: this.language.translateMessage("Pendente") },
        { key: ETIQUETAS_ENTREGUE, name: this.language.translateMessage("entregue") },
        { key: ETIQUETAS_TODAS, name: this.language.translateMessage("todos") }
      ];
      this.currentFilter = ETIQUETAS_TODAS;
    })
  }

  load(loadOptions: LoadOptions): Promise<any> {
    const formData = this.createFormData([{ key: "loadOptions", value: loadOptions }]);
    return this.requestCallbackService.load(
      this.service.load(formData),
      (response) => {
        let follow: any[] = response.data.acompanhamento.data;
        follow.forEach(item => {
          const status: number = item.cd_status;
          switch (status) {
            case STATUS_ETIQUETA_ENTREGUE:
              item.ds_status = this.language.translateMessage("entregue");
              break;
            case STATUS_ETIQUETA_CANCELADA:
              item.ds_status = this.language.translateMessage("cancelada");
              break;
            case STATUS_ETIQUETA_EXCLUIDA:
              item.ds_status = this.language.translateMessage("excluida");
              break;
            case STATUS_ETIQUETA_DEVOLVIDA:
              item.ds_status = this.language.translateMessage("devolvida");
              break;
            case STATUS_ETIQUETA_CREDITO:
              item.ds_status = this.language.translateMessage("credito");
              break;
          }
          if (status > STATUS_ETIQUETA_PENDENTE) {
            item.ds_status = this.language.translateMessage("pendente");
          }
        });
        return response.data.acompanhamento;
      },
      "falha_buscar_acompanhamento",
    );
  }

  selectStatus(event) {
    const id = event.value;
    switch (id) {
      case ETIQUETAS_PENDENTE:
        this.dataSource.filter(["cd_status", ">", STATUS_ETIQUETA_PENDENTE]);
        this.dataSource.load();
        break;
      case ETIQUETAS_ENTREGUE:
        this.dataSource.filter(["cd_status", "=", STATUS_ETIQUETA_ENTREGUE]);
        this.dataSource.load();
        break;
      case ETIQUETAS_TODAS:
      default:
        this.dataSource.filter(null);
        this.dataSource.load();
        break;
    }
  }

  onToolbarPreparing(e) {
    const items: any[] = e.toolbarOptions.items;

    items.splice(
      0, 0, {
      location: 'after',
      widget: 'dxButton',
      name: 'Imprimir',
      options: {
        icon: 'print',
        onClick: () => { window.print() }
      }
    },
      {
        location: "center",
        template: "dx-title"
      }
    );
  }
}
