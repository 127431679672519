import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { baseUrl } from "src/app/config";

@Injectable({
    providedIn: "root"
})
export class SubgruposService {    
    private url = baseUrl;
    constructor(private http: HttpClient) { }

    getSubgrupos(form: FormData): Observable<any> {
        return this.http.post<any>(`${this.url}/Subgrupos`, form);
    }

    insertSubgrupo(form: FormData): Observable<any> {
        return this.http.post(`${this.url}/InsertSubgrupo`, form);
    }

    updateSubgrupo(form: FormData): Observable<any> {
        return this.http.post(`${this.url}/UpdateSubgrupo`, form);
    }

    deleteSubgrupo(form: FormData): Observable<any> {
        return this.http.post(`${this.url}/DeleteSubgrupo`, form);
    }
    
}