<div *ngIf="havePermission" class="m-3">

    <dx-data-grid 
        id="data-grid-employees"
        [dataSource]="dataSource" 
        [columnAutoWidth]="true" 
        [columnHidingEnabled]="true"
        showBorders="true"
        [allowColumnReordering]="true" 
        [allowColumnResizing]="true"
        [remoteOperations]="true"
        (onRowUpdating)="onRowUpdating($event)"
        (onToolbarPreparing)="onToolbarPreparing($event)">

        <dxo-search-panel 
            [visible]="true" 
            [width]="240" 
            [placeholder]="'index.procurar' | translate">
        </dxo-search-panel>

        <dxo-editing
            mode="batch"
            [allowAdding]="true"
            [allowUpdating]="true"
            [allowDeleting]="true">
        </dxo-editing>

        <dxo-scrolling mode="virtual"></dxo-scrolling>

        <dxi-column 
            dataField="ds_nome"
            dataType="string"
            [caption]="'index.nome' | translate">
            <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>

        <dxi-column 
            dataField="cd_usuario"
            [editorOptions]="{ showClearButton: true }"
            calculateDisplayValue="ds_nome_usuario"
            [caption]="'index.usuario' | translate">
            <dxo-lookup
                [dataSource]="dataSourceUsers"
                valueExpr="cd_codigo"
                displayExpr="ds_nome">
            </dxo-lookup>
        </dxi-column>

        <div *dxTemplate="let data of 'dx-title'">
            <div class="text-center">
                <p class="title mb-0">{{'index.funcionarios' | translate}}</p>
            </div>
        </div>

    </dx-data-grid>

</div>
<denied-access *ngIf="!havePermission" [formCode]="formCode"></denied-access>
