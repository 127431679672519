import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { baseUrl } from "src/app/config";
import { LanguagueService } from "src/app/shared/helpers/language.service";

@Injectable({
    providedIn: 'root'
})
export class ResumoTecnicoService {

    private url = baseUrl;

    constructor(
        private http: HttpClient,
        private language: LanguagueService
    ) { }

    technicalSummary(form: FormData): Observable<Blob> {
        this.setupLabels(form);
        return this.http.post(`${this.url}/TechnicalSummary`, form, { responseType: 'blob' });
    }

    setupLabels(formData: FormData) {
        formData.append("language", this.language.currentLang());
        const labels = {
            ds_title: this.language.translateMessage("resumo_tecnico"),
            ds_imagem: this.language.translateMessage("foto"),
            ds_produto: this.language.translateMessage("produto"),
            ds_obs: this.language.translateMessage("observacao"),
            ds_largura: this.language.translateMessage("largura"),
            ds_altura: this.language.translateMessage("altura"),
            ds_qtde: this.language.translateMessage("quantidade"),
            ds_sem_itens: this.language.translateMessage("sem_itens"),
            ds_vidros: this.language.translateMessage("vidros"),
            ds_vao: this.language.translateMessage("vao"),
            ds_acabamento: this.language.translateMessage("acabamento"),
            ds_itens_avulsos: this.language.translateMessage("itens_avulsos"),
            ds_vidro: this.language.translateMessage("vidro"),
            ds_descricao: this.language.translateMessage("descricao"),
            ds_m2: this.language.translateMessage("m2"),
            ds_m2_calc: this.language.translateMessage("m2_calc"),
            ds_ferragens: this.language.translateMessage("ferragens"),
            ds_pecas: this.language.translateMessage("pecas"),
            ds_perfis: this.language.translateMessage("perfis"),
            ds_tam_cobranca: this.language.translateMessage("tam_cobranca"),
            ds_acessorios: this.language.translateMessage("acessorios_gerais"),
            ds_indefinido: this.language.translateMessage("indefinido"),
            ds_custo: this.language.translateMessage("custo"),
            ds_venda: this.language.translateMessage("venda"),
            ds_custoTotal: this.language.translateMessage("custo_total"),
            ds_vendaTotal: this.language.translateMessage("valor_total"),
            ds_pedido: this.language.translateMessage("ds_pedido"),
            ds_custoTotalGeral: this.language.translateMessage("custo_total_geral"),
            ds_vendaTotalGeral: this.language.translateMessage("valor_total_geral"),
            dt_data: this.language.translateMessage("emissao"),
            language: this.language.currentLang(),
        }
        formData.append("labels", JSON.stringify(labels));
    }
}