<div *ngIf="havePermission" class="m-3">

    <dx-data-grid 
        id="data-grid-produtos-vidraceiros"
        [dataSource]="dataSource" 
        [columnAutoWidth]="true" 
        showBorders="true"
        [allowColumnReordering]="true" 
        [allowColumnResizing]="true"
        [remoteOperations]="true"
        (onToolbarPreparing)="onToolbarPreparing($event)">

        <dxo-search-panel 
            [visible]="true" 
            [width]="240" 
            [placeholder]="'index.procurar' | translate">
        </dxo-search-panel>

        <dxo-editing
            mode="row"
            [allowAdding]="false"
            [allowUpdating]="false"
            [allowDeleting]="false">
        </dxo-editing>

        <dxo-scrolling mode="virtual"></dxo-scrolling>

        <dxi-column 
            dataField="cd_codigo"
            dataType="text"
            sortOrder="desc"
            [allowEditing]="false"
            [caption]="'index.cd_codigo' | translate">
        </dxi-column>

        <dxi-column 
            dataField="ds_codigo"
            [caption]="'index.ds_codigo' | translate">
        </dxi-column>

        <dxi-column 
            dataField="ds_descricao"
            [caption]="'index.ds_descricao' | translate">
        </dxi-column>

        <dxi-column 
            dataField="cd_grupo"
            [caption]="'index.cd_grupo' | translate">
        </dxi-column>

        <dxi-column 
            dataField="cd_subgrupo"
            [caption]="'index.cd_subgrupo' | translate">
        </dxi-column>

        <dxi-column 
            dataField="cd_tipo"
            [caption]="'index.cd_tipo' | translate">
        </dxi-column>

        <dxi-column 
            dataField="cd_unidade"
            [caption]="'index.cd_unidade' | translate">
        </dxi-column>

        <dxi-column 
            dataField="cd_grupo_cad"
            [caption]="'index.cd_grupo_cad' | translate">
            <dxo-lookup
                [dataSource]="gruposS"
                valueExpr="cd_codigo"
                displayExpr="ds_nome">
            </dxo-lookup>
        </dxi-column>

        <dxi-column 
            dataField="vl_custo"
            [caption]="'index.vl_custo' | translate">
        </dxi-column>

        <dxi-column 
            dataField="vl_venda"
            [caption]="'index.vl_venda' | translate">
        </dxi-column>

        <dxi-column 
            dataField="cd_empresa" 
            [caption]="'index.empresa' | translate"
            [groupIndex]="0" 
            calculateDisplayValue="ds_empresa"
            [allowEditing]="false">
            <dxo-lookup
                [dataSource]="dataSourceCompanies"
                valueExpr="cd_empresa"
                displayExpr="ds_empresa">
            </dxo-lookup>
        </dxi-column>

        <div *dxTemplate="let data of 'dx-title'">
            <div class="text-center">
                <p class="title mb-0">{{'index.produtos' | translate}}</p>
            </div>
        </div>

    </dx-data-grid>

</div>
<denied-access *ngIf="!havePermission" [formCode]="formCode"></denied-access>