<app-loader *ngIf="loading"></app-loader>
<dx-toolbar 
    class="toolbar-full"
    [items]="toolbarContent">
</dx-toolbar>

<dx-drawer 
    id="drawer"
    openedStateMode="shrink" 
    position="left" 
    revealMode="slide"
    template="template" 
    opened="true"
    [closeOnOutsideClick]="true">    
    <div *dxTemplate="let data of 'template'" class="tree-view2">
        <a *ngIf="logoUrl" [routerLink]="['/pagina']">
            <img class="displayed" id="img-logo" [src]="logoUrl" />
        </a>
        <dx-tree-view 
            id="simple-treeview" 
            [items]="menu_itens" 
            dataStructure="plain" 
            parentIdExpr="categoryId"
            keyExpr="ID" 
            displayExpr="name"
            (onItemClick)="selectItem($event)">
        </dx-tree-view>
        <div id="panel-img-reitech" *ngIf="drawerIsOpened">
            <img id="img-reitech" [src]="urlLogoReitech" />
        </div>
    </div>
</dx-drawer>
