import { Component, OnInit } from "@angular/core";
import { uploadgcadservices } from "./uploadgcad.service";
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { RequestCallbackService } from 'src/app/shared/helpers/request-callback.service';
import { LanguagueService } from 'src/app/shared/helpers/language.service';
import { ToastService } from 'src/app/shared/helpers/toast.service';


@Component({
  selector: "app-uploadgcad",
  templateUrl: "./uploadgcad.component.html",
  styleUrls: ["./uploadgcad.component.scss"]
})
export class UploadgcadComponent implements OnInit {

  loading: boolean = false;

  constructor(
    private service: uploadgcadservices,
    private toast: ToastService,
    private language: LanguagueService,
    private requestCallbackService: RequestCallbackService
  ) {
    this.language.globalize();
  }

  ngOnInit() { }

  onSubmit(file: File) {

    const formData = new FormData();
    let obj = {
      gcadTemp: "",
      cd_cliente: 0,
      gcadOrigem: "",
      p_origem: 1,
      gcadOp: 1,
      numero_pedido: "0",
      retorno: 1
    };
    let json = JSON.stringify(obj);
    formData.append("obj", json);
    formData.append("files", file);
    this.showLoad();
    this.service.update(formData).subscribe(
      (res: ApiResponse<{ success: boolean, message: string }>) => {
        this.hideLoad();
        this.requestCallbackService.okResponse(res,
          () => {
            this.toast.successToast("added");
          });
      },
      error => {
        this.hideLoad();
        this.requestCallbackService.failResponse(error, "errorA");
      }
    );
  }

  showLoad() {
    this.loading = true;
  }

  hideLoad() {
    this.loading = false;
  }

  addIdParameter(e) {
    e.value.forEach(file => {
      if (this.fileTypeIsNotValid(file)) {
        this.toast.warningToast("arquivo_invalido");
        return;
      }
      this.onSubmit(file)
    });
  }

  fileTypeIsNotValid(file: File): boolean {
    return !(file.name.includes(".gcad"));
  }

}
