import { Component, OnInit } from '@angular/core';
import { WITHOUT_PERMISSION_KEY } from 'src/app/shared/helpers/constants';
import { RequestCallbackService } from 'src/app/shared/helpers/request-callback.service';
import { ToastService } from 'src/app/shared/helpers/toast.service';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { PainelService } from '../painel/painel.service';
import { FuncoesService } from './funcoes.service';

@Component({
  selector: 'app-funcoes',
  templateUrl: './funcoes.component.html',
  styleUrls: ['./funcoes.component.scss']
})
export class FuncoesComponent implements OnInit {

  loading: boolean = false;
  responseStatus: number;

  constructor(
    private funcoesService: FuncoesService,
    private requestCallbackService: RequestCallbackService,
    private toastService: ToastService,
    private painelService: PainelService) { }

  ngOnInit() {
    this.isUserProfileReitech();
  }

  showLoad() {
    this.loading = true;
  }

  hideLoad() {
    this.loading = false;
  }

  private isUserProfileReitech() {
    this.painelService.isUserProfileReitech().subscribe(
      (res: ApiResponse<{ success: boolean, message: string }>) => {
        this.requestCallbackService.okResponse(res,
          () => {
            this.hideLoad();
            if (res.key == WITHOUT_PERMISSION_KEY) {
              this.responseStatus = 2;
            } else {
              if (res.data.st_reitech) {
                this.responseStatus = 1;
              } else {
                this.responseStatus = 2;
              }
            }
          });
      },
      error => {
        this.hideLoad();
        this.responseStatus = 3;
        this.requestCallbackService.failResponse(error);
      }
    );
  }

  persistirConjuntos() {
    const formData = new FormData();
    formData.append("pre", "zao");
    this.showLoad();
    this.funcoesService.PersistSets(formData).subscribe(
      (res: ApiResponse<{ success: boolean, message: string }>) => {
        this.hideLoad();
        this.requestCallbackService.okResponse(res,
          () => {
            this.toastService.successToast("salvar_sucesso");
          });
      },
      (error) => {
        this.hideLoad();
        this.requestCallbackService.failResponse(error);
      });
  }

}
