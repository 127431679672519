import { Component, OnInit, ViewChild } from "@angular/core";
import { ClientesService } from "./clientes.service";
import { RequestCallbackService } from "src/app/shared/helpers/request-callback.service";
import { getTiposDePessoa, calcMinWidthToDropDownMenu, sleep } from "src/app/shared/helpers/util";
import { ENTIDADE_CLIENTE, ENTIDADE_FORNECEDOR, ENTIDADE_CLIENTE_FORNECEDOR, TIPO_FORNECEDOR_VIDRO, TIPO_FORNECEDOR_FERRAGEM, TIPO_FORNECEDOR_PERFIL, TIPO_FORNECEDOR_ACESSORIO } from 'src/app/shared/helpers/constants';
import { FinanceiroService } from 'src/app/shared/services/financeiro.service';
import { BaseLazyComponent } from 'src/app/shared/helpers/BaseLazyComponent';
import { LoadOptions } from 'devextreme/data/load_options';
import { DxDataGridComponent } from 'devextreme-angular';
import { LazyDataSource } from 'src/app/shared/models/LazyDataSource';
import { LanguagueService } from 'src/app/shared/helpers/language.service';
import { validateRegex } from 'src/app/components/telefone-mascara/TelefoneMascaraUtils';
import { HeaderNewService } from "src/app/components/header-new/header_new.service";
import { ApiResponse } from "src/app/shared/models/ApiResponse";
import { isUserProfileIndependentGlazing } from "src/app/shared/models/UserProfile";

class TipoEntidade {
  ID: number;
  Name: string;
}
@Component({
  selector: "app-clientes",
  templateUrl: "./clientes.component.html",
  styleUrls: ["./clientes.component.scss"]
})
export class ClientesComponent extends BaseLazyComponent implements OnInit {

  loading: boolean = false;
  havePermission: boolean = true;
  formCode: number;
  tipo: TipoEntidade[] = [];
  ramos: TipoEntidade[] = [];
  dataSourceSellers: LazyDataSource;
  dataSourceCities: LazyDataSource;
  tipoPessoa: any[] = [];
  dataSourceCountries: LazyDataSource;

  @ViewChild(DxDataGridComponent, { static: false })
  dataGridClients: DxDataGridComponent;

  constructor(
    private service: ClientesService,
    private requestCallbackService: RequestCallbackService,
    private financeiroService: FinanceiroService,
    private language: LanguagueService,
    private headerService: HeaderNewService
  ) {
    super("cd_codigo");
    this.language.globalize();
  }

  ngOnInit() {
    this.getUserData();
    this.setupDataSourceCountries();
    this.setupDataSourceCidades();
    this.setupDataSourceSellers();
    this.setupTipoEntidade();
    this.setupTipoPessoa();
  }

  getUserData() {
    this.headerService.getUserData().subscribe((res: ApiResponse<{ success: boolean, message: string }>) => {
      this.requestCallbackService.okResponse(res,
        () => {
          const m_CodigoPerfilUsuario = res.data.cd_perfil;
          const isIndependentGlazing = isUserProfileIndependentGlazing(m_CodigoPerfilUsuario);
          let ramos = this.setupRamos();
          if (!isIndependentGlazing)
            ramos = ramos.filter(a => a.ID != TIPO_FORNECEDOR_VIDRO);
          this.ramos = ramos;
        }
      );
    }, error => {
      this.requestCallbackService.failResponse(error, "falha_buscar_info_usuario");
    });
  }

  setupDataSourceCountries() {
    this.dataSourceCountries = this.service.setupDataSourceCountries();
  }

  private setupTipoPessoa() {
    sleep(200).then(() => {
      this.tipoPessoa = getTiposDePessoa(this.language);
    });
  }

  private setupTipoEntidade() {
    sleep(200).then(() => {
      this.tipo = [
        {
          ID: ENTIDADE_CLIENTE,
          Name: this.language.translateMessage("cliente")
        },
        {
          ID: ENTIDADE_FORNECEDOR,
          Name: this.language.translateMessage("fornecedor")
        },
        {
          ID: ENTIDADE_CLIENTE_FORNECEDOR,
          Name: this.language.translateMessage("ambos")
        }
      ];
    });
  }

  private setupRamos(): any[] {
    return [
        {
          ID: TIPO_FORNECEDOR_VIDRO,
          Name: this.language.translateMessage("vidro")
        },
        {
          ID: TIPO_FORNECEDOR_FERRAGEM,
          Name: this.language.translateMessage("ferragem")
        },
        {
          ID: TIPO_FORNECEDOR_PERFIL,
          Name: this.language.translateMessage("perfil")
        },
        {
          ID: TIPO_FORNECEDOR_ACESSORIO,
          Name: this.language.translateMessage("acessorios")
        }
      ];
  }

  showLoad() {
    this.loading = true;
  }

  hideLoad() {
    this.loading = false;
  }

  setupDataSourceSellers() {
    this.dataSourceSellers = this.financeiroService.getDataSourceSellers();
  }

  setupDataSourceCidades() {
    this.dataSourceCities = this.service.getDataSourceCidade();
  }

  load(loadOptions: LoadOptions): Promise<any> {
    const formData = this.createFormData([{ key: "loadOptions", value: loadOptions }]);
    return this.requestCallbackService.load(
      this.service.getClientes(formData),
      (response) => {
        if (this.requestCallbackService.isResponseWithoutPermission(response)) {
          this.formCode = response.data.form;
          this.havePermission = false;
        } else {
          let clientes: any[] = response.data.clientes.data;
          clientes.forEach(element => {
            if (element.st_cliente && element.st_fornece) {
              element.tipo_entidade = ENTIDADE_CLIENTE_FORNECEDOR;
            } else if (element.st_fornece) {
              element.tipo_entidade = ENTIDADE_FORNECEDOR;
            } else if (element.st_cliente) {
              element.tipo_entidade = ENTIDADE_CLIENTE;
            }
          });
        }
        return response.data.clientes;
      },
      "falha_buscar_clientes",
    );
  }

  insert(values: any): Promise<void> {
    this.defineClientEntity(values);
    const formData = this.createFormData([{ key: "values", value: values }]);
    return this.requestCallbackService.insert(this.service.insert(formData), this.dataGridClients);
  }

  update(key: any, values: any): Promise<void> {
    this.defineClientEntity(values);
    const formData = this.createFormData([{ key: "key", value: key }, { key: "values", value: values }]);
    return this.requestCallbackService.update(this.service.update(formData), this.dataGridClients);
  }

  delete(key: any): Promise<void> {
    const formData = this.createFormData([{ key: "key", value: key }]);
    return this.requestCallbackService.delete(this.service.delete(formData), this.dataGridClients);
  }

  private defineClientEntity(client: any) {
    switch (client.tipo_entidade) {
      case ENTIDADE_CLIENTE: {
        client.st_cliente = true;
        client.st_fornece = false;
        break;
      }
      case ENTIDADE_FORNECEDOR: {
        client.st_cliente = false;
        client.st_fornece = true;
        break;
      }
      default: {
        client.st_fornece = true;
        client.st_cliente = true;
        break;
      }
    }
  }

  onRowUpdating(options) {
    options.newData = Object.assign(options.oldData, options.newData);
  }

  onEditorPreparing(e: any) {

    if (e.parentType == "dataRow") {
      e.editorOptions.inputAttr = { autocomplete: "none" };
    }
  }

  onToolbarPreparing(e) {

    let items: any[] = e.toolbarOptions.items;

    // busca a barra de pesquisa
    const searchItem = items[items.length - 1];
    // permite collapse menu
    searchItem.locateInMenu = "auto";

    items.splice(
      1,
      0,
      {
        location: "after",
        widget: "dxButton",
        name: "Imprimir",
        options: {
          icon: "print",
          onClick: () => {
            window.print();
          }
        }
      },
      {
        location: "center",
        template: "dx-title"
      }
    );
  }

  // tarefa #29487
  regex(e): boolean {
    return validateRegex(e)
  }

  minWidthSelectBox(): number {
    return calcMinWidthToDropDownMenu(400);
  }
}
