<app-loader *ngIf="loading"></app-loader>
<div class="container">
  <div class="border rounded mt-2 h-100 p-5" id="fileuploader-container">
    <h3>Upload GCAD</h3>

    <div>
      <dx-file-uploader
        name="file"
        (onValueChanged)="addIdParameter($event)"
        uploadMode="useForm"
        accept=".gcad"
        [multiple]="true"
        [allowedFileExtensions]="['.gcad']"
        readyToUploadMessage="Upload com sucesso"
      >
      </dx-file-uploader>
    </div>
  </div>
</div>
