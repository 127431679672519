import { Component, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { LoadOptions } from 'devextreme/data/load_options';
import { BaseLazyComponent } from 'src/app/shared/helpers/BaseLazyComponent';
import { LanguagueService } from 'src/app/shared/helpers/language.service';
import { RequestCallbackService } from 'src/app/shared/helpers/request-callback.service';
import { sleep } from 'src/app/shared/helpers/util';
import { LazyDataSource } from 'src/app/shared/models/LazyDataSource';
import { ProdutosService } from '../produtos/produtos.service';
import { UsuariosService } from '../usuarios/usuarios.service';
import { ProdutosVidraceirosService } from './produtos-vidraceiros.service';

@Component({
  selector: 'app-produtos-vidraceiros',
  templateUrl: './produtos-vidraceiros.component.html',
  styleUrls: ['./produtos-vidraceiros.component.scss']
})
export class ProdutosVidraceirosComponent extends BaseLazyComponent implements OnInit {

  havePermission: boolean = true;
  formCode: number;
  dataSourceCompanies: LazyDataSource;
  gruposS: any[] = [];

  @ViewChild(DxDataGridComponent, { static: false })
  dataGridProdutosVidraceiros: DxDataGridComponent;

  constructor(
    private language: LanguagueService,
    private requestCallbackService: RequestCallbackService,
    private produtosVidraceirosService: ProdutosVidraceirosService,
    private serviceUser: UsuariosService,
    private produtosService: ProdutosService
  ) {
    super("cd_codigo");
    this.language.globalize();
  }

  ngOnInit() {
    this.setupDataSourceCompanies();
    this.setupGruposS();
  }

  private setupGruposS() {
    sleep(200).then(() => {
      this.gruposS = this.produtosService.getGruposGcad();
    });
  }

  private setupDataSourceCompanies() {
    this.dataSourceCompanies = this.serviceUser.setupDataSourceCompanies();
  }

  load(loadOptions: LoadOptions): Promise<any> {
    const formData = this.createFormData([{ key: "loadOptions", value: loadOptions }]);
    return this.requestCallbackService.load(
      this.produtosVidraceirosService.getProdutosVidraceiros(formData),
      (response) => {
        if (this.requestCallbackService.isResponseWithoutPermission(response)) {
          this.formCode = response.data.form;
          this.havePermission = false;
        }
        return response.data.produtos;
      },
      "falha_buscar_produtos",
    );
  }

  onToolbarPreparing(e) {

    const items: any[] = e.toolbarOptions.items;
    const searchItem = items[items.length - 1];
    searchItem.locateInMenu = "auto";

    items.unshift({
      location: "center",
      template: "dx-title"
    });
  }

}
