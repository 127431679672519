import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'denied-access',
  templateUrl: './denied-access.component.html',
  styleUrls: ['./denied-access.component.scss']
})
export class DeniedAccessComponent implements OnInit {

  @Input()
  formCode: string;

  constructor() { }

  ngOnInit() {
  }

}
