import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { cleanLocalStorage } from './util';
import { ApiResponse } from '../models/ApiResponse';
import { WITHOUT_AUTHENTICATION_KEY, ERRO_ORIGEM_REQUISICAO, WITHOUT_PERMISSION_KEY } from './constants';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from 'src/app/config';
import { Observable } from 'rxjs';
import { DxDataGridComponent } from 'devextreme-angular';
import { defaultLazyResponse } from '../models/LazyResponse';
import { LanguagueService } from './language.service';
import { ToastService } from './toast.service';

@Injectable({
    providedIn: 'root'
})
export class RequestCallbackService {

    private url = baseUrl;

    constructor(
        private router: Router,
        private language: LanguagueService,
        private toast: ToastService,
        private http: HttpClient) { }

    private redirectToErrorPage() {
        this.router.navigate(['/error']);
    }

    okResponse(res: ApiResponse<{ success: boolean, message: string }>, sucessCallback?: () => void, errorCallback?: () => void) {
        if (res && res.success) {
            sucessCallback();
        } else {
            if (res.key == WITHOUT_AUTHENTICATION_KEY || res.key == ERRO_ORIGEM_REQUISICAO) {
                cleanLocalStorage();
                this.redirectToErrorPage();
                return;
            }
            const errormsg: string = this.language.translateMessage(res.key);
            const concatMessage: string = "<br> ".concat(res.concatMessage);
            if (!errormsg.includes("index")) {
                this.toast.errorToast(errormsg.concat(concatMessage), res.message);
            } else {
                this.toast.errorToast(res.key.concat(concatMessage), res.message);
            }
            if (errorCallback) {
                errorCallback();
            }
        }
    }

    failResponse(error: any, message?: string) {
        let errorDetailMessage = error.message.toString();
        if (typeof error.error === 'string') {
            errorDetailMessage = errorDetailMessage.concat(" -- ", error.error.toString());
        }
        let errorToastMessage = message ? this.language.translateMessage(message) : this.language.translateMessage("request_erro");
        this.toast.errorToast(errorToastMessage, errorDetailMessage);
    }

    // region tarefa #30571
    getServerDate() {
        return this.http.get(`${this.url}/getServerDate`);
    }
    // end region tarefa #30571


    private defaultCrudResponse(
        request: Observable<any>,
        dataGrid?: DxDataGridComponent,
        successCallback?: (response: ApiResponse<{ success: boolean, message: string }>) => void,
        errorCallback?: () => void,
        sucessResourceKey?: string,
        failResourceKey?: string): Promise<void> {

        dataGrid?.instance.beginCustomLoading("");
        return request.toPromise().then(
            (response: ApiResponse<{ success: boolean, message: string }>) => {
                dataGrid?.instance.endCustomLoading();
                this.okResponse(response,
                    () => {
                        if (this.isResponseWithoutPermission(response)) {
                            this.toast.warningToast("perm");
                            this.toast.warningPermission(response);
                        } else {
                            this.toast.successToast(sucessResourceKey);
                        }
                        if (successCallback) {
                            successCallback(response);
                        }
                    });
            }, error => {
                dataGrid?.instance.endCustomLoading();
                this.failResponse(error, failResourceKey);
                if (errorCallback) {
                    errorCallback();
                }
            });
    }

    isResponseWithoutPermission(response: ApiResponse<{ success: boolean, message: string }>): boolean {
        return response.key == WITHOUT_PERMISSION_KEY;
    }

    insert(request: Observable<any>, dataGrid?: DxDataGridComponent, successCallback?: (response: ApiResponse<{ success: boolean, message: string }>) => void, errorCallback?: () => void): Promise<void> {
        return this.defaultCrudResponse(request, dataGrid, successCallback, errorCallback, "added", "errorA");
    }

    update(request: Observable<any>, dataGrid?: DxDataGridComponent, successCallback?: () => void, errorCallback?: () => void): Promise<void> {
        return this.defaultCrudResponse(request, dataGrid, successCallback, errorCallback, "edit", "errorU");
    }

    delete(request: Observable<any>, dataGrid?: DxDataGridComponent, successCallback?: () => void, errorCallback?: () => void): Promise<void> {
        return this.defaultCrudResponse(request, dataGrid, successCallback, errorCallback, "deleted", "errorD");
    }

    load(request: Observable<any>, successCallback: (response: ApiResponse<{ success: boolean, message: string }>) => any, failResourceKey: string, isByKey: boolean = false): Promise<any> {
        let loadResponse = isByKey ? undefined : defaultLazyResponse;
        return request.toPromise().then(
            (response: ApiResponse<{ success: boolean, message: string }>) => {
                this.okResponse(response,
                    () => {
                        const data = successCallback(response);
                        if (!this.isResponseWithoutPermission(response)) {
                            loadResponse = data;
                        }
                    });
                return loadResponse;
            }, error => {
                this.failResponse(error, failResourceKey);
                return loadResponse;
            });
    }

    doRequestWithoutLoader(
        request: Observable<any>,
        successCallback: (response: ApiResponse<{ success: boolean, message: string }>) => any,
        failResourceKey?: string,
        failCallback?: (response) => void
    ) {
        this.doRequest(request, successCallback, null, null, failResourceKey, failCallback)
    }

    doRequest(
        request: Observable<any>,
        successCallback: (response: ApiResponse<{ success: boolean, message: string }>) => any,
        showLoader?: () => void,
        hideLoader?: () => void,
        failResourceKey?: string,
        failCallback?: (response) => void) {
        if (showLoader) {
            showLoader();
        }
        request.subscribe(
            (response: ApiResponse<{ success: boolean, message: string }>) => {
                if (hideLoader) {
                    hideLoader();
                }
                this.okResponse(response,
                    () => {
                        successCallback(response);
                    });
            }, error => {
                if (hideLoader) {
                    hideLoader();
                }
                if (failResourceKey) {
                    this.failResponse(error, failResourceKey);
                } else {
                    this.failResponse(error);
                }
                if (failCallback) {
                    failCallback(error);
                }
            });
    }
}
