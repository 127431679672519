<app-loader *ngIf="loading"></app-loader>
<app-loader *ngIf="loadingPainted"></app-loader>
<app-loader *ngIf="loadingManualValue"></app-loader>
<app-loader *ngIf="loadingArvore"></app-loader>
<div class="panel">

    <div class="text-center no-print">
        <span class="pull-left">
            <dx-button class="btn-back" icon="product" [text]="'index.pedidoA' | translate" [hint]="'index.pedidoA' | translate" (onClick)="redirectToPedido()" *ngIf="ped_orca > 0"></dx-button>
            <!-- #30053 nao deve exibir o botao de biblioteca quando for edicao de um item do pedido -->
            <dx-button *ngIf="!showSaveAndBackButton" class="btn-back" icon="pinright" [text]="'index.biblioteca' | translate" [hint]="'index.biblioteca' | translate" (onClick)="redirectToBiblioteca()"></dx-button>
        </span>
        <span class="title">{{'index.editor' | translate }}</span>
        <br/><br/>
    </div>
    <div class="row custom-row">
        <div class="col-sm-12 col-md-3 first-panel-margin no-print">
            <div class="config border panel-heigth">
              <div>
                <dx-tree-view
                  [items]="itemsArvore">
                  <div *dxTemplate="let data of 'item'" class="item">
                    <span class="group-name">
                      {{data.text}}
                    </span>
                    <span class="action-buttons pull-right">
                      <i class="dx-icon dx-icon-plus" (click)="handleAddItemArvore(data)" *ngIf="data.tipo != tipoItemFerragem"></i>
                      <i class="dx-icon dx-icon-edit" (click)="handleEditItemArvore(data)" *ngIf="data.id != -1"></i>
                      <i class="dx-icon dx-icon-trash" (click)="handleDeleteItemArvore(data)" *ngIf="data.id != -1"></i>
                    </span>
                  </div>
                </dx-tree-view>
              </div>
              <hr>
              <div *ngIf="conjunto.available">
                <div class="form-group">
                  <label for="cor">{{'index.descricao' | translate }}</label>
                  <div id="cor" (mouseenter)="toggleDescriptionTooltipVisible()" (mouseleave)="toggleDescriptionTooltipVisible()" >
                    <dx-text-box [(value)]="conjunto.descricao">
                    </dx-text-box>
                  </div>
                  <dx-tooltip
                    target="#cor"
                    position="top"
                    [(visible)]="descriptionTooltipVisible"
                    [closeOnOutsideClick]="false">
                    <div *dxTemplate="let data = data of 'content'">
                        {{ conjunto.descricao }}
                    </div>
                  </dx-tooltip>
                </div>
                <div class="form-group">
                  <label for="cor">{{'index.esp_vidro' | translate }}</label>
                  <div id="cor">
                    <dx-select-box [dataSource]="dataSourceVidros"
                                   [displayExpr]="displayExprPrd"
                                   searchMode="contains"
                                   [searchEnabled]="true"
                                   [value]="conjunto.cor_vidro"
                                   valueExpr="cd_vidro"
                                   [dropDownOptions]="{ minWidth: minWidthSelectBox }"
                                   (onSelectionChanged)="onVidroSelected($event)">
                    </dx-select-box>
                  </div>
                </div>
                <div class="form-group">
                  <label for="cor">{{'index.cor_ferragem' | translate }}</label>
                  <div id="cor">
                    <dx-select-box [dataSource]="dataSourceCoresFer"
                                   displayExpr="ds_etiqueta"
                                   searchMode="contains"
                                   [searchEnabled]="true"
                                   [value]="conjunto.cor_ferragem"
                                   valueExpr="cd_codigo"
                                   [dropDownOptions]="{ minWidth: minWidthSelectBox }"
                                   (onSelectionChanged)="onFerragemSelected($event)">
                    </dx-select-box>
                  </div>
                </div>
                <div class="form-group">
                  <label for="cor">{{'index.cor_perfil' | translate }}</label>
                  <div id="cor">
                    <dx-select-box [dataSource]="dataSourceCoresPer"
                                   displayExpr="ds_etiqueta"
                                   searchMode="contains"
                                   [searchEnabled]="true"
                                   [value]="conjunto.cor_perfil"
                                   valueExpr="cd_codigo"
                                   [dropDownOptions]="{ minWidth: minWidthSelectBox }"
                                   (onSelectionChanged)="onPerfilSelected($event)">
                    </dx-select-box>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row">
                    <div class="col-6">
                      <span>{{'index.valor_m2' | translate }}</span>
                    </div>
                    <div class="col-6">
                      <span>{{currency_symbol}}{{conjunto.vlm2vidro}}</span>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row">
                    <div class="col-6">
                      <span>{{'index.vidro' | translate }}</span>
                    </div>
                    <div class="col-6">
                      <span>{{currency_symbol}}{{conjunto.valorTotalM2}}</span>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row">
                    <div class="col-6">
                      <span>{{'index.ferragem' | translate }}</span>
                    </div>
                    <div class="col-6">
                      <span>{{currency_symbol}}{{conjunto.valorFerragem}}</span>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row">
                    <div class="col-6">
                      <span>{{'index.perfil' | translate }}</span>
                    </div>
                    <div class="col-6 text-truncate">
                      <span>{{currency_symbol}}{{conjunto.valorPerfil}}</span>
                    </div>
                  </div>
                </div>
                <hr />
                <div [class]="cssClassVlVenda">
                  <div class="form-group">
                    <div class="row align-items-center">
                      <div class="col-6">
                        <span><u>{{'index.venda' | translate }}</u></span>
                        <dx-check-box
                          id="cb_venda"
                          [readOnly]="planoEhLight"
                          [(value)]="conjunto.st_calculoManual"
                          (onValueChanged)="toggleVenda($event)">
                        </dx-check-box>
                      </div>
                      <div class="col-6">
                        <div class="text-truncate">
                          <span *ngIf="!this.conjunto.st_calculoManual">{{currency_symbol}}{{conjunto.valorTotal}}</span>
                          <dx-number-box
                            [readOnly]="planoEhLight"
                            *ngIf="this.conjunto.st_calculoManual"
                            [(value)]="conjunto.valorTotalSemCalculo"
                            (onChange)="onChangevalorTotal($event)"
                            [format]="currency_format">
                          </dx-number-box>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group" *ngIf="!ocultarCustos">
                  <div class="row">
                    <div class="col-6">
                      <span><u>{{'index.custo' | translate }}</u></span>
                    </div>
                    <div class="col-6">
                      <span>{{currency_symbol}}{{conjunto.valorCustoTotal}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-9 print-md-12 center-panel-margin">
            <div class="text-center align-middle border panel-heigth">

                <dx-tab-panel
                    #tabPanel
                    [dataSource]="tabs"
                    [selectedIndex]="0"
                    [loop]="false"
                    [animationEnabled]="true"
                    [focusStateEnabled]="false"
                    (onSelectionChanged)="onSelectionTabChanged($event)">

                    <div *dxTemplate="let tab of 'title'">
                        <span>{{tab.Name}}</span>
                    </div>
                    <div *dxTemplate="let tab of 'item'">
                        <div class="tabpanel-item mt-2 mr-2">
                            <!-- EDITOR ABA 1 ... -->
                            <div *ngIf="tab.ID == 1">
                                <div class="container">
                                  <div class="row no-gutters">
                                    <div class="col-lg-9">
                                      <!-- tarefa #33716 -->
                                      <div id="canvasPanel">
                                        <canvas
                                          id="imageConj" width='550' height='500'
                                          (mousemove)="setMouseMoveEvent($event)"
                                          (contextmenu)="setContextmenuEvent($event)"
                                          (dblclick)="dblclickCanvasEvent($event)"
                                          (click)="clickCanvasEvent($event)">
                                        </canvas>
                                        <dx-context-menu
                                            [dataSource]="setContextMenuItems"
                                            target="#canvasPanel"
                                            (onItemClick)="setContextMenuItemClick($event)">
                                        </dx-context-menu>
                                      </div>
                                    </div>
                                    <div class="col-lg-3">
                                        <!-- region tarefa #30053 -->
                                        <div class="form-group text-center" *ngIf="showSaveAndBackButton">
                                            <dx-button
                                                stylingMode="contained"
                                                [text]="'index.salvar_e_voltar' | translate"
                                                type="normal"
                                                [hint]="'index.salvar_e_voltar' | translate"
                                                icon="save"
                                                (onClick)="openDialogPedido(true)">
                                            </dx-button>
                                        </div>
                                        <!-- end region tarefa #30053 -->
                                        <div class="form-group text-center" *ngIf="ped_orca > 0">
                                            <dx-button
                                                stylingMode="contained"
                                                [text]="'index.adicionar_ao_pedido' | translate"
                                                type="normal"
                                                [hint]="'index.adicionar_ao_pedido' | translate"
                                                icon="plus"
                                                (onClick)="openDialogPedido()">
                                            </dx-button>
                                        </div>
                                        <span>{{'index.parametros' | translate }}</span>
                                        <hr>
                                        <div *ngIf="conjunto.st_folgas">
                                        <div class="form-group ml-2">
                                            <label for="larg" class="pull-left"><strong>{{'index.larg_vao' | translate }}</strong></label>
                                            <div id="larg">
                                                <dx-number-box
                                                    mode="number"
                                                    (onChange)="recalculateSet($event)"
                                                    format="###0"
                                                    [min]="0"
                                                    [(value)]="conjunto.larg">
                                                </dx-number-box>
                                            </div>
                                        </div>
                                        <div class="form-group ml-2">
                                            <label for="alt" class="pull-left"><strong>{{'index.alt_vao' | translate }}</strong></label>
                                            <div id="alt">
                                                <dx-number-box
                                                    mode="number"
                                                    (onChange)="recalculateSet($event)"
                                                    format="###0"
                                                    [min]="0"
                                                    [(value)]="conjunto.alt">
                                                </dx-number-box>
                                            </div>
                                        </div>
                                      </div>
                                        <div class="form-group ml-2">
                                            <div id="formulas"></div>
                                        </div>
                                        <div class="text-center">
                                            <div class="form-group">
                                                <dx-button
                                                    stylingMode="contained"
                                                    [text]="'index.calcular' | translate"
                                                    icon="formula"
                                                    [hint]="'index.calcular' | translate"
                                                    (onClick)="saveConjunto()"
                                                    type="normal">
                                                </dx-button>
                                            </div>
                                            <div *ngIf="!showSaveAndBackButton">
                                                <div class="form-group" *ngIf="conjunto.proprio">
                                                    <dx-button
                                                        stylingMode="contained"
                                                        [text]="'index.salvar_alteracoes' | translate"
                                                        type="normal"
                                                        [hint]="'index.salvar_alteracoes' | translate"
                                                        icon="save"
                                                        (onClick)="saveGCad()">
                                                    </dx-button>
                                                </div>
                                                <div class="form-group">
                                                    <dx-button
                                                        stylingMode="contained"
                                                        [text]="'index.adicionar_biblioteca' | translate"
                                                        type="normal"
                                                        [hint]="'index.adicionar_biblioteca' | translate"
                                                        icon="image"
                                                        (onClick)="openDialogBiblioteca()">
                                                    </dx-button>
                                                </div>
                                                <div class="form-group" *ngIf="conjunto.proprio">
                                                    <dx-button
                                                        stylingMode="contained"
                                                        [text]="'index.excluir' | translate"
                                                        icon="trash"
                                                        [hint]="'index.excluir' | translate"
                                                        type="normal"
                                                        (onClick)="openDialogDeleteConjunto()">
                                                    </dx-button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <!-- FIM DITOR ABA 1... -->

                            <!-- ABA 2 ... -->
                            <div *ngIf="tab.ID == 2">
                                <div class="mt-4">
                                  <app-perfis-acessorios [conjunto]="conjunto" (notifySaveGcadEvent)="saveGcadEvent($event)"></app-perfis-acessorios>
                                </div>
                            </div>
                            <!-- FIM ABA 2 ... -->

                            <!-- ABA 3 ... -->
                            <div *ngIf="tab.ID == 3">
                              <app-resumo-tecnico [conjunto]="conjunto" (notifyLoadEvent)="loadEvent($event)"></app-resumo-tecnico>
                            </div>
                            <!-- FIM ABA 3 ... -->
                        </div>
                    </div>
                </dx-tab-panel>
            </div>
        </div>
    </div>
</div>

<dx-popup
    [width]="'auto'"
    minHeight="400px"
    minWidth="400px"
    height="70%"
    [showTitle]="true"
    [title]="'index.selecionar_grupo_biblioteca' | translate"
    [dragEnabled]="false"
    [closeOnOutsideClick]="false"
    (onHidden)="onHideDialogStatus()"
    [(visible)]="dialogBibliotecaVisible">

    <div *dxTemplate="let data of 'content'">
        <div class="row">
          <div class="col-12">
            <div class="text-center mb-3">
                <dx-button
                    stylingMode="contained"
                    [text]="'index.salvar' | translate"
                    icon="save"
                    type="normal"
                    (onClick)="saveConjuntoOnBiblioteca()">
                </dx-button>
            </div>
        </div>
        <div class="col-12">
          <dx-scroll-view
            [showScrollbar]="onScroll"
            style="height: calc(95%);">
            <dx-tree-view
                class="border"
                [items]="dataSourceLibraryGroup"
                dataStructure="plain"
                parentIdExpr="cd_pai"
                keyExpr="id"
                displayExpr="ds_grupo"
                (onItemClick)="selectBibliotecaItem($event)">
            </dx-tree-view>
          </dx-scroll-view>
        </div>
      </div>
    </div>
</dx-popup>

<dx-popup
  [width]="'auto'"
  [height]="'auto'"
  [showTitle]="true"
  [title]="'index.deletar_conjunto' | translate"
  [dragEnabled]="false"
  [closeOnOutsideClick]="true"
  [(visible)]="popupDeleteVisible"
>
  <div *dxTemplate="let data of 'content'">
    <div class="option">
      <span>{{ "index.msg_delete_conjunto" | translate }}</span>
    </div>
    <br>
    <div class="row">
      <div class="col d-flex text-center">
        <dx-button class="button-info"
                   [text]="'index.sim' | translate"
                   (onClick)="deleteConjunto()">
        </dx-button>
      </div>
      <div class="col d-flex text-center">
          <dx-button
            class="button-info"
            [text]="'index.cancelar' | translate"
            (onClick)="closeDialogDelete()">
        </dx-button>
      </div>
    </div>
  </div>
</dx-popup>

<dx-popup
  [width]="'auto'"
  [height]="'auto'"
  [showTitle]="true"
  [title]="titleDialogPedido"
  [dragEnabled]="false"
  [closeOnOutsideClick]="true"
  [(visible)]="popupPedidoVisible">

  <dx-popup [width]="'auto'" [height]="'auto'" [showTitle]="true" [title]="titleDialogPedido" [dragEnabled]="false" [closeOnOutsideClick]="true" [(visible)]="popupPedidoVisible">
    <div *dxTemplate="let data of 'content'">
      <div class="option">
        <p class="mb-2">Ad:{{ "index.msg_adicionar_ao_pedido" | translate }}</p>

        <form (submit)="showSaveAndBackConfirmButton ? saveAndBack($event) : saveConjuntoOnPedido($event)">
          <dx-form id="form" [formData]="dataSourceConjuntoPedido" labelLocation="left">

            <dxi-item
              dataField="ds_vidro"
              [label]="{ text: 'index.esp_vidro' | translate }"
              [editorOptions]="{ disabled: true}">
            </dxi-item>

            <dxi-item *ngIf="conjunto.qtdeFers > 0"
              dataField="st_ferragem"
              [label]="{ text: 'index.ferragem' | translate }"
              editorType="dxCheckBox">
            </dxi-item>

            <dxi-item *ngIf="conjunto.qtdeServs > 0"
              dataField="st_perfil"
              [label]="{ text: 'index.perfil' | translate }"
              editorType="dxCheckBox">
            </dxi-item>

            <dxi-item
              dataField="qtd_peca"
              [label]="{ text: 'index.qtde_pecas' | translate }"
              editorType="dxNumberBox"
              [editorOptions]="{ min: 1, showSpinButtons: true, format: '###0' }">
              <dxi-validation-rule type="required"> </dxi-validation-rule>
            </dxi-item>

            <dxi-item
              itemType="button"
              horizontalAlignment="center"
              [buttonOptions]="{ useSubmitBehavior: true, text: 'index.sim' | translate }">
            </dxi-item>

          </dx-form>
        </form>
      </div>
    </div>
  </dx-popup>

<dx-popup
  [width]="'auto'"
  [height]="'auto'"
  [showTitle]="true"
  [title]="'index.ferragens' | translate"
  [dragEnabled]="true"
  (onShown)="onShownPopupFerragem($event)"
  [(visible)]="popupItemFerragemVisible">
  <div *dxTemplate="let data of 'content'">
    <dx-scroll-view
      [scrollByContent]="true"
      [scrollByThumb]="true"
      [showScrollbar]="onScroll">

      <div class="form-group">
        <label for="codigo_descritivo">{{ "index.codigo_descritivo" | translate }}</label>
        <div id="codigo_descritivo">
          <dx-select-box
              [inputAttr]="{ id: 'selectBoxFerragem' }"
              [dataSource]="dataSourceFerragens"
              displayExpr="ds_comple"
              valueExpr="_codigo"
              [searchEnabled]="true"
              (onValueChanged)="onValueChangedFerragem($event)"
              [(value)]="popupItemFerragem.cd_ferragem">
          </dx-select-box>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-6">
            <label for="codigo_descritivo">X:</label>
            <div id="codigo_descritivo">
              <dx-number-box
                [(value)]="popupItemFerragem.x"
                [readOnly]="popupItemFerragem.st_form_x"
                [showSpinButtons]="true">
              </dx-number-box>
            </div>
          </div>
          <div class="col-6">
            <label for="codigo_descritivo">{{ "index.formula" | translate }}_X:</label>
            <div id="codigo_descritivo">
              <dx-text-box
                [(value)]="popupItemFerragem.Formula_X"
                (onValueChanged)="onValueChangedFormulaXFerragem($event)">
              </dx-text-box>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-6">
            <label for="codigo_descritivo">Y:</label>
            <div id="codigo_descritivo">
              <dx-number-box
                  [(value)]="popupItemFerragem.y"
                  [readOnly]="popupItemFerragem.st_form_y"
                  [showSpinButtons]="true">
                </dx-number-box>
            </div>
          </div>
          <div class="col-6">
            <label for="codigo_descritivo">{{ "index.formula" | translate }}_Y:</label>
            <div id="codigo_descritivo">
              <dx-text-box
                [(value)]="popupItemFerragem.Formula_Y"
                (onValueChanged)="onValueChangedFormulaYFerragem($event)">
              </dx-text-box>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row align-items-center switch-custom">
          <div class="col-6 ">
            <span class="text-center mr-2">{{ "index.esquerda" | translate }}</span>
            <dx-switch [value]="!popupItemFerragem.Esquerda" (onValueChanged)="switchValueChangedFerragem($event)"></dx-switch>
            <span class="text-center ml-2">{{ "index.direita" | translate }}</span>
          </div>
          <div class="col-6">
            <span class="text-center mr-2">{{ "index.base" | translate }}</span>
            <dx-switch [(value)]="popupItemFerragem.Topo"></dx-switch>
            <span class="text-center ml-2">{{ "index.topo" | translate }}</span>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-6">
            <dx-check-box [(value)]="popupItemFerragem.nomeFerragem"></dx-check-box>
            <span class="ml-2">{{ "index.exibir_nome_ferragem" | translate }}</span>
          </div>
          <div class="col-6">
            <dx-check-box [(value)]="popupItemFerragem.st_orcar"></dx-check-box>
            <span class="ml-2">{{ "index.movimentar_estoque_orcar" | translate }}</span>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-6">
            <dx-check-box [(value)]="popupItemFerragem.legendaHorizontal"></dx-check-box>
            <span class="ml-2">{{ "index.exibir_distancia_lateral" | translate }}</span>
          </div>
          <div class="col-6">
            <dx-check-box [(value)]="popupItemFerragem.legendaVertical"></dx-check-box>
            <span class="ml-2">{{ "index.exibir_distancia_altura" | translate }}</span>
          </div>
        </div>
      </div>
      <div class="form-group text-center mt-3">
        <dx-button
          icon="save"
          [text]="'index.salvar' | translate"
          [hint]="'index.salvar' | translate"
          (onClick)="salvarFerragemPopup()">
        </dx-button>
      </div>

    </dx-scroll-view>
  </div>
</dx-popup>

<dx-popup
  [width]="'auto'"
  [height]="'auto'"
  [showTitle]="true"
  [title]="'index.msg_delete_ferragem_title' | translate"
  [dragEnabled]="true"
  [(visible)]="popupDeleteItemFerragemVisible">

  <div *dxTemplate="let data of 'content'">
    <div class="option">
      <span>{{ "index.msg_delete_ferragem" | translate }} {{ popupItemFerragem.ds_text }} ?</span>
    </div>
    <br>
    <div class="row mt-2">
      <div class="col d-flex text-center">
        <dx-button
          class="button-info"
          [text]="'index.sim' | translate"
          (onClick)="deletarFerragemWS()">
        </dx-button>
      </div>
      <div class="col d-flex text-center">
          <dx-button
            class="button-info"
            [text]="'index.cancelar' | translate"
            (onClick)="fecharPopupDeletarFerragem()">
        </dx-button>
      </div>
    </div>
  </div>
</dx-popup>

<dx-popup
  [width]="'auto'"
  [height]="'auto'"
  [showTitle]="true"
  [title]="'index.vidros' | translate"
  [dragEnabled]="true"
  [(visible)]="popupItemVidroVisible">
  <div *dxTemplate="let data of 'content'">
    <dx-scroll-view
      [scrollByContent]="true"
      [scrollByThumb]="true"
      [showScrollbar]="onScroll">

      <div class="form-group">
        <label for="codigo_descritivo">{{ "index.item" | translate }}:</label>
        <div id="codigo_descritivo">
          <dx-text-box [value]="popupItemVidro.ds_nome" [readOnly]="true"></dx-text-box>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-6">
            <label for="codigo_descritivo">{{ "index.largura" | translate }}:</label>
            <div id="codigo_descritivo">
              <dx-number-box
                [(value)]="popupItemVidro.width"
                [readOnly]="popupItemVidro.st_form_larg"
                [showSpinButtons]="true">
              </dx-number-box>
            </div>
          </div>
          <div class="col-6">
            <label for="codigo_descritivo">{{ "index.formula" | translate }}_{{ "index.largura" | translate }}:</label>
            <div id="codigo_descritivo">
              <dx-text-box
                [(value)]="popupItemVidro.Formula_W"
                (onValueChanged)="onValueChangedFormulaLargVidro($event)">
              </dx-text-box>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-6">
            <label for="codigo_descritivo">{{ "index.altura" | translate }}:</label>
            <div id="codigo_descritivo">
              <dx-number-box
                  [(value)]="popupItemVidro.height"
                  [readOnly]="popupItemVidro.st_form_alt"
                  [showSpinButtons]="true">
                </dx-number-box>
            </div>
          </div>
          <div class="col-6">
            <label for="codigo_descritivo">{{ "index.formula" | translate }}_{{ "index.altura" | translate }}:</label>
            <div id="codigo_descritivo">
              <dx-text-box
                [(value)]="popupItemVidro.Formula_H"
                (onValueChanged)="onValueChangedFormulaAltVidro($event)">
              </dx-text-box>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-6">
            <label for="codigo_descritivo">X:</label>
            <div id="codigo_descritivo">
              <dx-number-box
                [(value)]="popupItemVidro.x"
                [readOnly]="popupItemVidro.st_form_x"
                [showSpinButtons]="true">
              </dx-number-box>
            </div>
          </div>
          <div class="col-6">
            <label for="codigo_descritivo">{{ "index.formula" | translate }}_X:</label>
            <div id="codigo_descritivo">
              <dx-text-box
                [(value)]="popupItemVidro.Formula_X"
                (onValueChanged)="onValueChangedFormulaXVidro($event)">
              </dx-text-box>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-6">
            <label for="codigo_descritivo">Y:</label>
            <div id="codigo_descritivo">
              <dx-number-box
                  [(value)]="popupItemVidro.y"
                  [readOnly]="popupItemVidro.st_form_y"
                  [showSpinButtons]="true">
                </dx-number-box>
            </div>
          </div>
          <div class="col-6">
            <label for="codigo_descritivo">{{ "index.formula" | translate }}_Y:</label>
            <div id="codigo_descritivo">
              <dx-text-box
                [(value)]="popupItemVidro.Formula_Y"
                (onValueChanged)="onValueChangedFormulaYVidro($event)">
              </dx-text-box>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-6">
            <label for="codigo_descritivo">{{ "index.horizontal" | translate }}:</label>
            <div id="codigo_descritivo">
              <dx-number-box
                  [(value)]="popupItemVidro.Horizontal"
                  [showSpinButtons]="true">
              </dx-number-box>
            </div>
          </div>
          <div class="col-6">
            <label for="codigo_descritivo">{{ "index.vertical" | translate }}:</label>
            <div id="codigo_descritivo">
              <dx-number-box
                  [(value)]="popupItemVidro.Vertical"
                  [showSpinButtons]="true">
              </dx-number-box>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group text-center mt-3">
        <dx-button
          icon="save"
          [text]="'index.salvar' | translate"
          [hint]="'index.salvar' | translate"
          (onClick)="salvarVidroPopup()">
        </dx-button>
      </div>

    </dx-scroll-view>
  </div>
</dx-popup>

<dx-popup
  [width]="'auto'"
  [height]="'auto'"
  [showTitle]="true"
  [title]="'index.msg_delete_vidro_title' | translate"
  [dragEnabled]="true"
  [(visible)]="popupDeleteItemVidroVisible">

  <div *dxTemplate="let data of 'content'">
    <div class="option">
      <span>{{ "index.msg_delete_vidro" | translate }} {{ popupItemVidro.ds_nome }} ?</span>
    </div>
    <br>
    <div class="row mt-2">
      <div class="col d-flex text-center">
        <dx-button
          class="button-info"
          [text]="'index.sim' | translate"
          (onClick)="deletarVidroWS()">
        </dx-button>
      </div>
      <div class="col d-flex text-center">
          <dx-button
            class="button-info"
            [text]="'index.cancelar' | translate"
            (onClick)="fecharPopupDeletarVidro()">
        </dx-button>
      </div>
    </div>
  </div>
</dx-popup>

<!-- Esquadro -->
<dx-popup
  [showTitle]="true"
  [title]="'index.edicao_esquadro' | translate"
  [dragEnabled]="true"
  [width]="'auto'"
  [height]="'auto'"
  [(visible)]="popupItemEdicaoEsquadroVisible">
  <div *dxTemplate="let data of 'content'">
    <dx-scroll-view
      [scrollByContent]="true"
      [scrollByThumb]="true"
      [showScrollbar]="onScroll">
      <table id="tabelaEsquadro" style="border-collapse: collapse; ">
          <tbody>
              <tr>
                  <td></td>
                  <td class="tabEsquadraoBorderBottom">
                    <div class="dx-field">
                      <div class="dx-field-label p-0 label-top">X1:</div>
                      <div class="dx-field-value">
                        <dx-number-box
                          id="x1"
                          [width]="50"
                          [(value)]="popupItemEdicaoEsquadro.x1"
                          (focusout)="animarEsquadro($event)">
                        </dx-number-box>
                      </div>
                    </div>
                  <td class="tabEsquadraoBorderBottom"></td>
                  <td class="tabEsquadraoBorderBottom">
                    <div class="dx-field">
                      <div class="dx-field-label p-0 label-top">X2:</div>
                      <div class="dx-field-value">
                        <dx-number-box
                          id="x2"
                          [width]="50"
                          [(value)]="popupItemEdicaoEsquadro.x2"
                          (focusout)="animarEsquadro($event)">
                        </dx-number-box>
                      </div>
                    </div>
                  </td>
                  <td></td>
              </tr>
              <tr>
                  <td class="tabEsquadraoBorderRight">
                    <div class="mr-2">
                      <div class="dx-field">
                        <div class="dx-field-label">Y1:</div>
                        <div class="dx-field-value">
                            <dx-number-box
                                id="y1"
                                [width]="50"
                                [(value)]="popupItemEdicaoEsquadro.y1"
                                (focusout)="animarEsquadro($event)">
                            </dx-number-box>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td id="svgDiv" rowspan="3" colspan="3">
                      <div [ngStyle]="{'width': popupItemEdicaoEsquadro.largura + 'px', 'height': '99%', 'margin': '30px'}">
                        <svg [attr.viewbox]=" '0' + ' ' + '0' + ' ' + popupItemEdicaoEsquadro.largura + ' ' +popupItemEdicaoEsquadro.altura" [ngStyle]="{'height': popupItemEdicaoEsquadro.altura + 'px'}">
                          <polygon class="polygon" id="shp"
                            [attr.points]="popupItemEdicaoEsquadro.p1 +','+ popupItemEdicaoEsquadro.p2 +','+ popupItemEdicaoEsquadro.p3 +','+ popupItemEdicaoEsquadro.p4 +','+
                            popupItemEdicaoEsquadro.p5 +','+ popupItemEdicaoEsquadro.p6 +','+ popupItemEdicaoEsquadro.p7 +','+ popupItemEdicaoEsquadro.p8">
                          </polygon>
                        </svg>
                      </div>
                  </td>
                  <td class="tabEsquadraoBorderLeft">
                    <div class="ml-2">
                      <div class="dx-field">
                        <div class="dx-field-label">Y2:</div>
                        <div class="dx-field-value">
                            <dx-number-box
                                id="y2"
                                [width]="50"
                                [(value)]="popupItemEdicaoEsquadro.y2"
                                (focusout)="animarEsquadro($event)">
                            </dx-number-box>
                        </div>
                      </div>
                    </div>
                  </td>
              </tr>
              <tr>
                  <td class="tabEsquadraoBorderRight"></td>
                  <td class="tabEsquadraoBorderLeft"></td>
              </tr>
              <tr>
                  <td class="tabEsquadraoBorderRight">
                    <div class="mr-2">
                      <div class="dx-field">
                        <div class="dx-field-label">Y4:</div>
                        <div class="dx-field-value">
                            <dx-number-box
                                id="y4"
                                [width]="50"
                                [(value)]="popupItemEdicaoEsquadro.y4"
                                (focusout)="animarEsquadro($event)">
                            </dx-number-box>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="tabEsquadraoBorderLeft">
                    <div class="ml-2">
                      <div class="dx-field">
                        <div class="dx-field-label">Y3:</div>
                        <div class="dx-field-value">
                            <dx-number-box
                                id="y3"
                                [width]="50"
                                [(value)]="popupItemEdicaoEsquadro.y3"
                                (focusout)="animarEsquadro($event)">
                            </dx-number-box>
                        </div>
                      </div>
                    </div>
                  </td>
              </tr>
              <tr>
                  <td></td>
                  <td class="tabEsquadraoBorderTop">
                    <div class="dx-field">
                      <div class="dx-field-label p-0 label-top">X4:</div>
                      <div class="dx-field-value">
                        <dx-number-box
                          id="x4"
                          [width]="50"
                          [(value)]="popupItemEdicaoEsquadro.x4"
                          (focusout)="animarEsquadro($event)">
                        </dx-number-box>
                      </div>
                    </div>
                  </td>
                  <td class="tabEsquadraoBorderTop"></td>
                  <td class="tabEsquadraoBorderTop">
                    <div class="dx-field">
                      <div class="dx-field-label p-0 label-top">X3:</div>
                      <div class="dx-field-value">
                        <dx-number-box
                          id="x3"
                          [width]="50"
                          [(value)]="popupItemEdicaoEsquadro.x3"
                          (focusout)="animarEsquadro($event)">
                        </dx-number-box>
                      </div>
                    </div>
                  </td>
                  <td></td>
              </tr>
          </tbody>
      </table>
      <div class="text-center mt-4 mb-3">
        <dx-button
            class="button-info"
            id="salvarEsquadro"
            [text]="'index.salvar' | translate"
            (onClick)="calcularEsquadro()">
        </dx-button>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>
<!-- Cantos -->
<dx-popup
  [showTitle]="true"
  [title]="'index.edicao_cantos' | translate"
  [dragEnabled]="true"
  [width]="'auto'"
  [height]="'auto'"
  [(visible)]="popupItemEdicaoCantosVisible">
  <div *dxTemplate="let data of 'content'">
    <dx-scroll-view
      [scrollByContent]="true"
      [scrollByThumb]="true"
      [showScrollbar]="onScroll">
      <table id="tabelaEsquadro" style="border-collapse: collapse; ">
          <tbody>
            <tr>
              <td>
              <table id="tabelaEsquadro" style="border-collapse: collapse; ">
              <tbody>
              <tr>
                  <td style="width: 216px;">
                    <div class="form-group">
                    <div class="row align-items-center switch-custom" style="max-width: 100%;">
                      <div class="col-6 " style="max-width: 100%;flex: 100%">
                      <span class="text-center mr-2">{{ "index.chanfro" | translate }}</span>

                      <dx-switch [value]="(popupItemEdicaoEsquadro.CantoTipo1==1)"
                      (onValueChanged)="switchValueChangedVidroCanto($event,1)"></dx-switch>

                      <span class="text-center ml-2">{{ "index.raio" | translate }}</span>
                    </div>
                  </div></div>
                    <div class="dx-field">
                      <div class="dx-field-label p-0 label-top">C1:</div>
                      <div class="dx-field-value">
                        <dx-number-box
                          id="x1"
                          [width]="50"
                          [(value)]="popupItemEdicaoEsquadro.rf1"
                          (focusout)="animarEsquadro($event)">
                        </dx-number-box>
                      </div>
                    </div>
                  </td>
                  <td style="width: 216px;">
                    <div class="form-group">
                      <div class="row align-items-center switch-custom" style="max-width: 100%;">
                        <div class="col-6 " style="max-width: 100%;flex: 100%">
                        <span class="text-center mr-2">{{ "index.chanfro" | translate }}</span>

                        <dx-switch [value]="(popupItemEdicaoEsquadro.CantoTipo2==1)"
                        (onValueChanged)="switchValueChangedVidroCanto($event,2)"></dx-switch>

                        <span class="text-center ml-2">{{ "index.raio" | translate }}</span>
                      </div>
                    </div></div>
                  <div class="dx-field">
                    <div class="dx-field-label p-0 label-top">C2:</div>
                    <div class="dx-field-value">
                      <dx-number-box
                        id="x1"
                        [width]="50"
                        [(value)]="popupItemEdicaoEsquadro.rf2"
                        (focusout)="animarEsquadro($event)">
                      </dx-number-box>
                    </div>
                  </div></td>
              </tr>
          </tbody>
          </table>
          </td></tr>
              <tr>
                  <td id="svgDiv">
                      <div [ngStyle]="{'left': '100px', 'width':'100%-'+popupItemEdicaoEsquadro.largura + 'px', 'height': '99%', 'margin': '30px'}">
                        <svg [attr.viewbox]=" '0' + ' ' + '0' + ' ' + popupItemEdicaoEsquadro.largura + ' ' +popupItemEdicaoEsquadro.altura" [ngStyle]="{'height': popupItemEdicaoEsquadro.altura + 'px'}">
                          <polygon class="polygon" id="shp"
                            [attr.points]="popupItemEdicaoEsquadro.p1 +','+ popupItemEdicaoEsquadro.p2 +','+ popupItemEdicaoEsquadro.p3 +','+ popupItemEdicaoEsquadro.p4 +','+
                            popupItemEdicaoEsquadro.p5 +','+ popupItemEdicaoEsquadro.p6 +','+ popupItemEdicaoEsquadro.p7 +','+ popupItemEdicaoEsquadro.p8">
                          </polygon>
                        </svg>
                      </div>
                  </td>
              </tr>
              <tr>
                <td>
                <table id="tabelaEsquadro" style="border-collapse: collapse; ">
                <tbody>
                <tr>
                  <td style="width: 216px;">
                      <div class="form-group" style="width: 200;">
                      <div class="row align-items-center switch-custom" style="max-width: 100%;">
                        <div class="col-6 " style="max-width: 100%;flex: 100%">
                        <span class="text-center mr-2">{{ "index.chanfro" | translate }}</span>

                        <dx-switch [value]="(popupItemEdicaoEsquadro.CantoTipo1==1)"
                        (onValueChanged)="switchValueChangedVidroCanto($event,4)"></dx-switch>

                        <span class="text-center ml-2">{{ "index.raio" | translate }}</span>
                      </div>
                    </div></div>
                      <div class="dx-field">
                        <div class="dx-field-label p-0 label-top">C4:</div>
                        <div class="dx-field-value">
                          <dx-number-box
                            id="x1"
                            [width]="50"
                            [(value)]="popupItemEdicaoEsquadro.rf4"
                            (focusout)="animarEsquadro($event)">
                          </dx-number-box>
                        </div>
                      </div>
                    </td>
                    <td style="width: 216px;">
                      <div class="form-group" style="width: 200;">
                        <div class="row align-items-center switch-custom" style="max-width: 100%;">
                          <div class="col-6 " style="max-width: 100%;flex: 100%">
                          <span class="text-center mr-2">{{ "index.chanfro" | translate }}</span>

                          <dx-switch [value]="(popupItemEdicaoEsquadro.CantoTipo3==1)"
                          (onValueChanged)="switchValueChangedVidroCanto($event,3)"></dx-switch>

                          <span class="text-center ml-2">{{ "index.raio" | translate }}</span>
                        </div>
                      </div></div>
                    <div class="dx-field">
                      <div class="dx-field-label p-0 label-top">C3:</div>
                      <div class="dx-field-value">
                        <dx-number-box
                          id="x1"
                          [width]="50"
                          [(value)]="popupItemEdicaoEsquadro.rf3"
                          (focusout)="animarEsquadro($event)">
                        </dx-number-box>
                      </div>
                    </div></td>
                </tr>
            </tbody>
            </table>
            </td></tr>

          </tbody>
      </table>
      <div class="text-center mt-4 mb-3">
        <dx-button
            class="button-info"
            id="salvarEsquadro"
            [text]="'index.salvar' | translate"
            (onClick)="calcularEsquadro()">
        </dx-button>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<dx-popup
  [width]="'auto'"
  [height]="'auto'"
  [showTitle]="true"
  [title]="'index.recortes_alvenaria' | translate"
  [dragEnabled]="true"
  [(visible)]="popupItemRecorteAlvenariaVisible">
  <div *dxTemplate="let data of 'content'">
    <dx-scroll-view
      [scrollByContent]="true"
      [scrollByThumb]="true"
      [showScrollbar]="onScroll">
      <div class="form-group">
        <div class="row align-items-center switch-custom">
          <div class="col-6 ">
            <span class="text-center mr-2">{{ "index.esquerda" | translate }}</span>
            <dx-switch [value]="!popupRecorteAlvenaria.Esquerda" (onValueChanged)="switchValueChangedRecorteAlvenaria($event)"></dx-switch>
            <span class="text-center ml-2">{{ "index.direita" | translate }}</span>
          </div>
          <div class="col-6">
            <span class="text-center mr-2">{{ "index.base" | translate }}</span>
            <dx-switch [(value)]="popupRecorteAlvenaria.Topo"></dx-switch>
            <span class="text-center ml-2">{{ "index.topo" | translate }}</span>
          </div>
        </div>

      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-6">
            <label for="codigo_descritivo">X:</label>
            <div id="codigo_descritivo">
              <dx-number-box
                [(value)]="popupRecorteAlvenaria.Ponto1_X"
                [showSpinButtons]="true">
              </dx-number-box>
            </div>
          </div>
          <div class="col-6">
            <label for="codigo_descritivo">Y:</label>
            <div id="codigo_descritivo">
              <dx-number-box
                  [(value)]="popupRecorteAlvenaria.Ponto1_Y"
                  [showSpinButtons]="true">
                </dx-number-box>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-6">
            <label for="codigo_descritivo">Larg:</label>
            <div id="codigo_descritivo">
              <dx-number-box
                  [(value)]="popupRecorteAlvenaria.Ponto3_X"
                  [showSpinButtons]="true">
                </dx-number-box>
            </div>
          </div>
          <div class="col-6">
            <label for="codigo_descritivo">Alt:</label>
            <div id="codigo_descritivo">
              <dx-number-box
                  [(value)]="popupRecorteAlvenaria.Ponto3_Y"
                  [showSpinButtons]="true">
                </dx-number-box>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-6">
            <label for="codigo_descritivo">Raio dos cantos:</label>
            <div id="codigo_descritivo">
              <dx-number-box
                  [(value)]="popupRecorteAlvenaria.Raio"
                  [showSpinButtons]="true">
                </dx-number-box>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group text-center mt-3">
        <dx-button
          icon="save"
          [text]="'index.salvar' | translate"
          [hint]="'index.salvar' | translate"
          (onClick)="salvarRecorteAlvenariaPopup()">
        </dx-button>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<dx-popup
  [width]="'auto'"
  [height]="'auto'"
  [showTitle]="true"
  [title]="'index.msg_delete_recorte_alvenaria_title' | translate"
  [dragEnabled]="true"
  [(visible)]="popupDeleteItemRecorteAlvenariaVisible">

  <div *dxTemplate="let data of 'content'">
    <div class="option">
      <span>{{ "index.msg_delete_recorte_alvenaria" | translate }}</span>
    </div>
    <br>
    <div class="row mt-2">
      <div class="col d-flex text-center">
        <dx-button
          class="button-info"
          [text]="'index.sim' | translate"
          (onClick)="salvarRecorteAlvenariaPopup(true)">
        </dx-button>
      </div>
      <div class="col d-flex text-center">
          <dx-button
            class="button-info"
            [text]="'index.cancelar' | translate"
            (onClick)="fecharPopupDeletarRecorteAlvenaria()">
        </dx-button>
      </div>
    </div>
  </div>
</dx-popup>

<dx-popup
  [width]="'auto'"
  [height]="'auto'"
  [showTitle]="true"
  [title]="'index.escrita' | translate"
  [dragEnabled]="true"
  (onShown)="onShownPopupEscrita($event)"
  [(visible)]="popupItemEscritaVisible">
  <div *dxTemplate="let data of 'content'">
      <div class="form-group">
        <div class="row">
          <div class="col-12">
            <label for="codigo_descritivo">{{ "index.descricao" | translate }}</label>
            <div id="codigo_descritivo">
              <dx-text-box
              [inputAttr]="{ id: 'selectBoxEscrita' }"
                [(value)]="popupEscrita.ds_text">
              </dx-text-box>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group text-center mt-3">
        <dx-button
          icon="save"
          [text]="'index.salvar' | translate"
          [hint]="'index.salvar' | translate"
          (onClick)="salvarEscrita()">
        </dx-button>
      </div>
  </div>
</dx-popup>

<dx-popup
  [width]="'auto'"
  [height]="'auto'"
  [showTitle]="true"
  [title]="'index.msg_delete_escrita_title' | translate"
  [dragEnabled]="true"
  [(visible)]="popupDeleteItemEscritaVisible">

  <div *dxTemplate="let data of 'content'">
    <div class="option">
      <span>{{ "index.msg_delete_escrita" | translate }}</span>
    </div>
    <br>
    <div class="row mt-2">
      <div class="col d-flex text-center">
        <dx-button
          class="button-info"
          [text]="'index.sim' | translate"
          (onClick)="deletarEscrita()">
        </dx-button>
      </div>
      <div class="col d-flex text-center">
          <dx-button
            class="button-info"
            [text]="'index.cancelar' | translate"
            (onClick)="fecharPopupDeletarEscrita()">
        </dx-button>
      </div>
    </div>
  </div>
</dx-popup>
