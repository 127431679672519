export class Biblioteca {
  id: number
  Nome: string
  Grupo: number
  Descricao: string
  cd_cliente: number
  Imagem: string
  ImagemDetalhe: string
}
export class Grupo {
  id: number
  ds_grupo: string
  cd_empresa: number
  cd_pai: number
  expanded: boolean = false
}
