import { Injectable } from "@angular/core";
import { baseUrl } from "./../../config";
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { RequestCallbackService } from 'src/app/shared/helpers/request-callback.service';
import { LazySelectService } from 'src/app/shared/helpers/lazy-select.service';
import { LazyDataSource } from 'src/app/shared/models/LazyDataSource';
import { CountryService } from "src/app/shared/helpers/country.service";

@Injectable({
  providedIn: "root"
})
export class ClientesService {

  private url = baseUrl;
  private endpointCity: string;

  constructor(
    private http: HttpClient,
    private requestCallbackService: RequestCallbackService,
    private lazyService: LazySelectService,
    private countryService: CountryService) {
    this.endpointCity = `${this.url}/getCidades`;
  }

  insert(form: FormData): Observable<any> {
    return this.http.post(`${this.url}/insertClientes`, form);
  }

  update(form: FormData): Observable<any> {
    return this.http.post(`${this.url}/updateClientes`, form);
  }

  delete(form: FormData): Observable<any> {
    return this.http.post(`${this.url}/deleteClientes`, form);
  }

  getClientes(form: FormData): Observable<any> {
    return this.http.post<any>(`${this.url}/getClientes`, form);
  }

  getCidades(): Observable<any> {
    return this.http.get<any>(this.endpointCity);
  }

  //  region tarefa #31033
  getDataSourceCidade(): LazyDataSource {
    return this.lazyService.setupLazyDataSource(
      "cd_cidade",
      "ds_cidade",
      (loadOptions) => {
        const formData = new FormData();
        formData.append("loadOptions", JSON.stringify(loadOptions));
        return this.loadCities(formData);
      },
      (formData) => {
        return this.loadCityByKey(formData);
      }
    );
  }

  private loadCities(formData: FormData): Promise<any> {
    return this.requestCallbackService.load(
      this.http.post(this.endpointCity, formData),
      (response) => {
        return response.data.cidades;
      },
      "falha_buscar_cidades"
    );
  }

  private loadCityByKey(formData: FormData): Promise<any> {
    return this.requestCallbackService.load(
      this.http.post(this.endpointCity, formData),
      (response) => {
        return response.data.cidades.data[0];
      },
      "falha_buscar_cidades",
      true
    );
  }
  // end region tarefa #31033


  setupDataSourceCountries() {
    return this.lazyService.setupLazyDataSource(
      "cd_codigo",
      "ds_nome",
      (loadOptions) => {
        const formData = new FormData();
        formData.append("loadOptions", JSON.stringify(loadOptions));
        return this.loadCountries(formData);
      },
      (formData) => {
        return this.loadCountryKey(formData);
      }
    );
  }

  private loadCountries(formData: FormData): Promise<any> {
    return this.requestCallbackService.load(
      this.countryService.getCountries(formData),
      (response) => {
        return response.data.paises;
      },
      "falha_buscar_paises"
    );
  }

  private loadCountryKey(formData: FormData): Promise<any> {
    return this.requestCallbackService.load(
      this.countryService.getCountries(formData),
      (response) => {
        return response.data.paises.data[0];
      },
      "falha_buscar_paises",
      true
    );
  }

}
