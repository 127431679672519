import { Component, OnInit, ViewChild } from "@angular/core";
import { PermissaoService } from "./permissao.service";
import { DxDataGridComponent } from "devextreme-angular";
import { RequestCallbackService } from "src/app/shared/helpers/request-callback.service";
import { LanguagueService } from 'src/app/shared/helpers/language.service';
import { BaseLazyComponent } from 'src/app/shared/helpers/BaseLazyComponent';
import { LoadOptions } from 'devextreme/data/load_options';

@Component({
  selector: "app-permissoes",
  templateUrl: "./permissoes.component.html",
  styleUrls: ["./permissoes.component.scss"]
})
export class PermissoesComponent extends BaseLazyComponent implements OnInit {

  @ViewChild(DxDataGridComponent, { static: false })
  dataGridPermissions: DxDataGridComponent;

  havePermission: boolean = true;
  loading: boolean = false;

  constructor(
    private service: PermissaoService,
    private language: LanguagueService,
    private requestCallbackService: RequestCallbackService
  ) {
    super("cd_codigo");
    this.language.globalize();
  }

  ngOnInit() {
  }

  showLoad() {
    this.loading = true;
  }

  hideLoad() {
    this.loading = false;
  }

  load(loadOptions: LoadOptions): Promise<any> {
    const formData = this.createFormData([{ key: "loadOptions", value: loadOptions }]);
    return this.requestCallbackService.load(
      this.service.getPermissoes(formData),
      (response) => {
        if (this.requestCallbackService.isResponseWithoutPermission(response)) {
          this.havePermission = false;
        } else {
          let permissoes = response.data.permissoes.data;
          permissoes.forEach(p => {
            if (!p.key) {
              p.ds_tags = this.language.translateMessage(p.ds_tags); // tarefa #30996
            }
          });
        }
        return response.data.permissoes;
      },
      "falha_buscar_permissoes",
    );
  }

  update(key: any, values: any): Promise<void> {
    const formData = this.createFormData([{ key: "key", value: key }, { key: "values", value: values }]);
    return this.requestCallbackService.update(this.service.update(formData), this.dataGridPermissions);
  }

  onToolbarPreparing(e) {
    let items: any[] = e.toolbarOptions.items;

    // busca a barra de pesquisa
    const searchItem = items[items.length - 1];
    // permite collapse menu
    searchItem.locateInMenu = "auto";

    items.unshift({
      location: "center",
      template: "dx-title"
    });
  }

  // tarefa #29969
  toogleAllPermissions(data, check: boolean) {
    const items: any[] = data.data.items;
    this.handleChange(items, check);
  }

  handleChange = (items: any[], check: boolean) => {
    const dataGrid = this.dataGridPermissions.instance;
    dataGrid.beginUpdate();
    items.forEach(item => {
      const rowIndex = dataGrid.getRowIndexByKey(item.cd_codigo);
      this.changePermissionValueIfNecessary(rowIndex, "st_menu", check);
      this.changePermissionValueIfNecessary(rowIndex, "st_adicionar", check);
      this.changePermissionValueIfNecessary(rowIndex, "st_edita", check);
      this.changePermissionValueIfNecessary(rowIndex, "st_excluir", check);
    });
    dataGrid.endUpdate();
  }

  onContentReady(event, data) {
    const dataGrid = this.dataGridPermissions.instance;
    const isRowExpanded = dataGrid.isRowExpanded(data.key);
    event.component.option("visible", isRowExpanded);
  }

  onRowUpdating(options) {
    options.newData = Object.assign(options.oldData, options.newData);
  }

  private changePermissionValueIfNecessary(rowIndex: number, dxField: string, newValue: boolean) {
    const dataGrid = this.dataGridPermissions.instance;
    let currentMenuValue = dataGrid.cellValue(rowIndex, dxField);
    if (currentMenuValue != newValue) {
      dataGrid.cellValue(rowIndex, dxField, newValue);
    }
  }
  // end region tarefa #29969
}
