import { Component, OnInit, ChangeDetectorRef, ViewChild } from "@angular/core";
import { CondicoesService } from "./condicoes.service";
import { RequestCallbackService } from "src/app/shared/helpers/request-callback.service";
import { DxDataGridComponent } from 'devextreme-angular';
import { BaseLazyComponent } from 'src/app/shared/helpers/BaseLazyComponent';
import { LoadOptions } from 'devextreme/data/load_options';
import { ToastService } from 'src/app/shared/helpers/toast.service';
import { LanguagueService } from 'src/app/shared/helpers/language.service';

class PercentaDay {
  Dia: number;
  Perc: number;
}

@Component({
  selector: "app-condicoes",
  templateUrl: "./condicoes.component.html",
  styleUrls: ["./condicoes.component.scss"]
})
export class CondicoesComponent extends BaseLazyComponent implements OnInit {

  @ViewChild("dataGridConditions", { static: false })
  dataGridCondition: DxDataGridComponent;

  visibilityPopupPercentageDays: boolean = false;
  loading: boolean = false;
  havePermission: boolean = true;
  formCode: number;

  selectedCondition: any = {};
  dataSourcePercentageDay: PercentaDay[] = [];

  constructor(
    private service: CondicoesService,
    private toast: ToastService,
    private requestCallbackService: RequestCallbackService,
    private changeDetectorRefs: ChangeDetectorRef,
    private language: LanguagueService
  ) {
    super("cd_codigo");
    this.language.globalize();
  }

  ngOnInit(): void {

  }

  showLoad() {
    this.loading = true;
  }

  hideLoad() {
    this.loading = false;
  }

  openGridPercentageDays = (e) => {

    const condition = e.row.data;

    if (!condition.ds_descricao) {
      this.toast.warningToast("preencha_descricao");
      return;
    }

    this.dataSourcePercentageDay = [];
    this.selectedCondition = condition;

    if (condition.vl_dias_perc) {

      let percentageDays = condition.vl_dias_perc.split(";");
      percentageDays.pop();

      percentageDays.forEach(percentageDayComposite => {
        const percentageDaySplited = percentageDayComposite.split("|");

        const percentaDay = new PercentaDay();
        percentaDay.Dia = percentageDaySplited[0];
        percentaDay.Perc = percentageDaySplited[1];

        this.dataSourcePercentageDay.push(percentaDay);
      });
    }

    this.changeDetectorRefs.detectChanges();
    this.visibilityPopupPercentageDays = true;
  }

  load(loadOptions: LoadOptions): Promise<any> {
    const formData = this.createFormData([{ key: "loadOptions", value: loadOptions }]);
    return this.requestCallbackService.load(
      this.service.getData(formData),
      (response) => {
        if (this.requestCallbackService.isResponseWithoutPermission(response)) {
          this.formCode = response.data.form;
          this.havePermission = false;
        }
        return response.data.condicoes;
      },
      "falha_buscar_condicoes",
    );
  }

  onHidden() {
    this.changeDetectorRefs.detectChanges();
    this.visibilityPopupPercentageDays = false;
  }

  insert(values: any, successCallback?: () => void): Promise<void> {
    if (!values.vl_dias_perc) {
      values.vl_dias_perc = "";
      values.vl_qtde = 0;
    }
    const formData = this.createFormData([{ key: "values", value: values }]);
    return this.requestCallbackService.insert(
      this.service.insert(formData),
      this.dataGridCondition,
      (response) => {
        this.selectedCondition = response.data.condicao;
        if (successCallback) {
          successCallback();
        }
      });
  }

  update(key: any, values: any): Promise<void> {
    const formData = this.createFormData([{ key: "key", value: key }, { key: "values", value: values }]);
    return this.requestCallbackService.update(this.service.update(formData), this.dataGridCondition);
  }

  delete(key: any): Promise<void> {
    const formData = this.createFormData([{ key: "key", value: key }]);
    return this.requestCallbackService.delete(this.service.delete(formData), this.dataGridCondition);
  }

  onRowUpdating(options) {
    options.newData = Object.assign(options.oldData, options.newData);
  }

  editPercentageDay() {
    const selectedCondition = this.selectedCondition;
    let vlDiasPerc: string = "";
    let countVlDiasPerc: number = 0;
    this.dataSourcePercentageDay.forEach(percentaDay => {
      const percDay = percentaDay.Dia.toString().concat("|").concat(percentaDay.Perc.toString()).concat(";");
      vlDiasPerc = vlDiasPerc.concat(percDay);
      countVlDiasPerc++;
    });

    selectedCondition.vl_dias_perc = vlDiasPerc;
    selectedCondition.vl_qtde = countVlDiasPerc;

    if (selectedCondition.cd_codigo) {
      this.update(selectedCondition.cd_codigo, selectedCondition);
    } else {
      this.insert(selectedCondition, () => {
        this.dataGridCondition.instance.cancelEditData();
        this.dataSource.reload();
      });
    }
  }

  onToolbarPreparing(e) {
    let items: any[] = e.toolbarOptions.items;

    // busca a barra de pesquisa
    const searchItem = items[items.length - 1];
    // permite collapse menu
    searchItem.locateInMenu = "auto";

    items.unshift({
      location: "center",
      template: "dx-title"
    });
  }

  customizeComissionText(cellInfo) {
    if (cellInfo.value || cellInfo.value == 0) {
      return `${cellInfo.value}%`;
    }
    return "";
  }
}
