import { Component, OnInit } from "@angular/core";
import { ConfiguracoesService } from "./configuracoes.service";
import { getCompletePathNoImage } from "src/app/shared/helpers/util";
import { DomSanitizer } from '@angular/platform-browser';
import { RequestCallbackService } from 'src/app/shared/helpers/request-callback.service';
import { HeaderNewService } from 'src/app/components/header-new/header_new.service';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { WITHOUT_PERMISSION_KEY } from 'src/app/shared/helpers/constants';
import * as $ from "jquery";
import { ToastService } from 'src/app/shared/helpers/toast.service';
import { LanguagueService } from 'src/app/shared/helpers/language.service';
import { isUserProfileProvider } from "src/app/shared/models/UserProfile";

@Component({
  selector: "app-configuracoes",
  templateUrl: "./configuracoes.component.html",
  styleUrls: ["./configuracoes.component.scss"]
})
export class ConfiguracoesComponent implements OnInit {
  constructor(
    private service: ConfiguracoesService,
    private toast: ToastService,
    private sanitizer: DomSanitizer,
    private requestCallbacService: RequestCallbackService,
    private headerService: HeaderNewService,
    private language: LanguagueService
  ) {
    this.language.globalize();
  }

  havePermission: boolean = true;
  formCode: number;
  company: any;
  showAllFields: boolean = false;
  ds_logo: string | ArrayBuffer;
  ds_logo_peq: string | ArrayBuffer;
  loading: boolean = false;
  isMasterAdm: boolean = false;

  ngOnInit() {
    this.getConfiguracoes();
    this.getUserData();
  }

  showLoad() {
    this.loading = true;
  }

  hideLoad() {
    this.loading = false;
  }

  getUserData() {
    this.headerService.getUserData().subscribe((res: ApiResponse<{ success: boolean, message: string }>) => {

      this.requestCallbacService.okResponse(res,
        () => {
          const userData = res.data;
          this.isMasterAdm = isUserProfileProvider(userData.cd_perfil);
        }
      );
    }, error => {
      this.requestCallbacService.failResponse(error, "falha_buscar_info_usuario");
    });
  }

  private resetLogos() {
    this.ds_logo = "";
    this.ds_logo_peq = "";
  }

  getConfiguracoes() {

    this.resetLogos();

    this.showLoad();
    this.service.load().subscribe(
      (res: ApiResponse<{ success: boolean, message: string }>) => {
        this.hideLoad();

        this.requestCallbacService.okResponse(res,
          () => {
            if (res.key == WITHOUT_PERMISSION_KEY) {
              this.formCode = res.data.form;
              this.havePermission = false;
            } else {
              const company = res.data.empresa;

              // region tarefa #29627
              this.ds_logo = this.loadImage(company.ds_logo, company.caminho);
              this.ds_logo_peq = this.loadImage(company.ds_logo_peq, company.caminho, true);
              // end region

              //altera a logo do drawer apenas se for um usuário da vidrocar..
              if (this.isMasterAdm) {
                $("#img-logo").attr("src", this.ds_logo);
              }

              this.company = company;
              this.havePermission = true;

              this.showAllFields = company.empresa === 0;
            }
          });
      },
      (error) => {
        this.hideLoad();
        this.requestCallbacService.failResponse(error, "falha_buscar_dados_empresa");
      }
    );
  }

  reload() {
    this.getConfiguracoes();
  }

  private loadImage(logo: string, src: string, isBgImage = false): string {
    if (logo.trim() != src.trim()) {
      const srcImage = logo.trim() + "?" + new Date().getTime();
      if (isBgImage) {
        $("#bg").attr("src", srcImage);
      }
      return srcImage + '?' + (new Date()).getTime();
    } else {
      return getCompletePathNoImage();
    }
  }

  submit(e) {
    // tarefa #30215
    e.preventDefault();

    const company = this.company;

    if (!company) {
      return;
    }

    const companyToUpdate: any = {
      ds_home: company.ds_home,
      cd_empresa: company.cd_empresa,
      ds_empresa: company.ds_empresa,
      ds_email: company.ds_email,
      ds_fantasia: company.ds_fantasia,
      ds_fone: company.ds_fone,
      ds_tipo: company.ds_tipo,
      ds_cnpj: company.ds_cnpj,
      ds_endereco: company.ds_endereco,
      ds_cidade: company.ds_cidade,
      ds_cep: company.ds_cep,
      ds_bairro: company.ds_bairro,
      ds_estado: company.ds_estado,
    };

    const ds_logo = this.ds_logo.toString();
    if (ds_logo.includes("data:image")) {
      companyToUpdate.ds_logo = this.normalizeImage(ds_logo);
    }

    const ds_logo_peq = this.ds_logo_peq.toString();
    if (ds_logo_peq.includes("data:image")) {
      companyToUpdate.ds_logo_peq = this.normalizeImage(ds_logo_peq);
    }

    const formData = new FormData();
    formData.append("form", JSON.stringify(companyToUpdate));

    this.showLoad();
    this.service.update(formData).subscribe(
      (res: ApiResponse<{ success: boolean, message: string }>) => {

        this.requestCallbacService.okResponse(res,
          () => {
            if (res.key == WITHOUT_PERMISSION_KEY) {
              this.toast.warningToast("perm");
              this.toast.warningPermission(res.data);
            } else {
              this.toast.successToast("edit");
            }
          }
        );
        this.getConfiguracoes();
        this.hideLoad();
      },
      (error) => {
        this.hideLoad();
        this.requestCallbacService.failResponse(error, "falha_atualizar_dados_empresa");
        this.getConfiguracoes();
      }
    );
  }


  private normalizeImage(image: string): string {
    let normalizedImage = image.toString().replace("data:image/jpeg;base64,", "");
    normalizedImage = normalizedImage.replace("data:image/png;base64,", "");
    normalizedImage = normalizedImage.replace("data:image/gif;base64,", "");
    return normalizedImage;
  }

  getBase64(event: any, prop: string) {
    try {
      let file: File = event.value[0];
      if (this.fileTypeIsNotValid(file)) {
        this.toast.warningToast("arquivo_invalido");
        return;
      }
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        switch (prop) {
          case 'ds_logo':
            this.ds_logo = reader.result;
            break;
          case 'ds_logo_peq':
            this.ds_logo_peq = reader.result;
            break;
        }
      };
    } catch (e) {
      this.toast.warningToast("arquivo_invalido");
    }
  }

  fileTypeIsNotValid(file: File): boolean {
    return !(file.type.includes("image/png") || file.type.includes("image/jpeg") || file.type.includes("image/gif"));
  }
}
