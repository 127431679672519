<app-loader *ngIf="loading"></app-loader>
<div *ngIf="havePermission" class="m-3">
  <dx-popup
    [showTitle]="true"
    [title]="'index.diasPorcentagem' | translate"
    [dragEnabled]="true"
    [closeOnOutsideClick]="true"
    [visible]="visibilityPopupPercentageDays"
    (onHidden)="onHidden($event)"
    [width]="'auto'"
    [height]="'auto'"
    maxWidth="500px"
    minHeight="400px"
    maxHeight="700px">

    <div *dxTemplate="let data of 'content'">

      <dx-scroll-view
        [scrollByContent]="true"
        [scrollByThumb]="true"
        [showScrollbar]="onScroll">

        <dx-data-grid
          id="gridContainer"
          [dataSource]="dataSourcePercentageDay"
          [showBorders]="true"
          (onRowUpdated)="editPercentageDay($event)"
          (onRowInserted)="editPercentageDay($event)"
          (onRowRemoved)="editPercentageDay($event)">

          <dxo-scrolling mode="virtual"></dxo-scrolling>

          <dxo-editing
            [allowUpdating]="true"
            [allowDeleting]="true"
            [allowAdding]="true"
            mode="row">
          </dxo-editing>

          <dxi-column
            dataField="Dia"
            dataType="number"
            [caption]="'index.dia' | translate"
            sortOrder="asc"
            format="##">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dxi-column>

          <dxi-column  
            dataField="Perc" 
            dataType="number"
            [caption]="'index.porcentagem' | translate"
            [customizeText]="customizeComissionText">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dxi-column>

          <dxo-summary>
            <dxi-total-item
                column="Dia"
                summaryType="max">
            </dxi-total-item>

            <dxi-total-item
                column="Perc"
                summaryType="sum">
            </dxi-total-item>
          </dxo-summary>

        </dx-data-grid>
      </dx-scroll-view>
    </div>
  </dx-popup>

  <dx-data-grid
    id="data-grid-conditions"
    #dataGridConditions
    [dataSource]="dataSource"
    showBorders="true"
    ShowGroupPanel="false"
    [columnHidingEnabled]="true"
    [allowColumnReordering]="true"
    [allowColumnResizing]="true"
    [remoteOperations]="true"
    (onRowUpdating)="onRowUpdating($event)"
    (onToolbarPreparing)="onToolbarPreparing($event)"
  >
    <dxo-search-panel
      [visible]="true"
      [width]="240"
      [placeholder]="'index.procurar' | translate"
    >
    </dxo-search-panel>

    <dxo-scrolling mode="virtual"></dxo-scrolling>

    <dxo-editing
      mode="row"
      [allowAdding]="true"
      [allowUpdating]="true"
      [allowDeleting]="true"
      [selectTextOnEditStart]="true"
      [useIcons]="true"
    >
    </dxo-editing>

    <dxi-column
      dataField="ds_descricao"
      [caption]="'index.descricao' | translate"
      [editorOptions]="{ maxLength: 50 }"
    >
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column
      dataField="vl_qtde"
      [caption]="'index.qtdParcelas' | translate"
      dataType="number"
      [allowEditing]="false"
    >
    </dxi-column>

    <dxi-column type="buttons" [caption]="'index.diasPorcentagem' | translate">
      <dxi-button
        [hint]="'index.importar' | translate"
        icon="export"
        [onClick]="openGridPercentageDays"
      >
      </dxi-button>
    </dxi-column>
    
    <dxi-column type="buttons">
      <dxi-button name="edit"></dxi-button>
      <dxi-button name="delete"></dxi-button>
    </dxi-column>

    <div *dxTemplate="let data of 'dx-title'">
      <div class="text-center">
        <p class="title mb-0">{{ "index.condicaoPagamento" | translate }}</p>
      </div>
    </div>
  </dx-data-grid>
</div>
<denied-access *ngIf="!havePermission" [formCode]="formCode"></denied-access>
