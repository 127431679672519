import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { baseUrl } from "./../../config";
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: "root"
})
export class CountryService {

  private url = baseUrl;

  constructor(private http: HttpClient) { }

  getCountries(formData: FormData): Observable<any> {
    return this.http.post<any>(`${this.url}/getCountry`, formData);
  }

}
