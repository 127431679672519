import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { baseUrl } from "src/app/config";

@Injectable({
    providedIn: "root"
})
export class FornecedoresGrupoCadService {

    private url = baseUrl;

    constructor(private http: HttpClient) {
    }

    getFornecedores(form: FormData): Observable<any> {
        return this.http.post(`${this.url}/getFornec`, form);
    }
}