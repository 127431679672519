import { Injectable } from '@angular/core';
import { baseUrl } from 'src/app/config';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PedidosService {

  private url = baseUrl;
  constructor(private http: HttpClient) { }

  getPedidos(formData: FormData): Observable<any> {
    return this.http.post<any>(`${this.url}/getPedidos`, formData);
  }

}
