<div *ngIf="havePermission" class="m-3">

    <dx-data-grid 
        id="data-grid-tipos"
        [dataSource]="dataSource" 
        [columnAutoWidth]="true" 
        showBorders="true"
        [allowColumnReordering]="true" 
        [allowColumnResizing]="true"
        [remoteOperations]="true"
        (onRowUpdating)="onRowUpdating($event)"
        (onToolbarPreparing)="onToolbarPreparing($event)">

        <dxo-search-panel 
            [visible]="true" 
            [width]="240" 
            [placeholder]="'index.procurar' | translate">
        </dxo-search-panel>

        <dxo-editing
            mode="row"
            [allowAdding]="true"
            [allowUpdating]="cm_allowEditAndDelete"
            [allowDeleting]="cm_allowEditAndDelete">
        </dxo-editing>

        <dxo-scrolling mode="virtual"></dxo-scrolling>

        <dxi-column 
            dataField="cd_codigo"
            dataType="text"
            sortOrder="desc"
            [allowEditing]="false"
            [caption]="'index.cd_codigo' | translate">
        </dxi-column>

        <dxi-column 
            dataField="ds_produto"
            dataType="string"
            [editorOptions]="{ maxLength: 60 }"
            [caption]="'index.ds_produto' | translate">
            <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>

        <dxi-column 
            dataField="ds_etiqueta"
            dataType="string"
            [editorOptions]="{ maxLength: 60 }"
            [caption]="'index.ds_etiqueta' | translate">
            <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>

        <dxi-column 
            dataField="vl_espessura"
            dataType="number"
            [caption]="'index.vl_espessura' | translate">
        </dxi-column>

        <dxi-column 
            dataField="vl_peso"
            dataType="number"
            [caption]="'index.vl_peso' | translate">
        </dxi-column>

        <dxi-column 
            dataField="ds_codigo"
            dataType="string"
            [editorOptions]="{ maxLength: 20 }"
            [caption]="'index.ds_codigo' | translate">
        </dxi-column>

        <dxi-column 
            dataField="ds_composicao"
            dataType="string"
            [caption]="'index.ds_composicao' | translate">
        </dxi-column>

        <dxi-column 
            dataField="st_ferragem"
            dataType="boolean"
            [caption]="'index.st_ferragem' | translate">
        </dxi-column>

        <dxi-column 
            dataField="st_perfil"
            dataType="boolean"
            [caption]="'index.st_perfil' | translate">
        </dxi-column>

        <dxi-column 
            dataField="cd_empresa" 
            [caption]="'index.empresa' | translate"
            [groupIndex]="0" 
            calculateDisplayValue="ds_empresa"
            [allowEditing]="false">
            <dxo-lookup
                [dataSource]="dataSourceCompanies"
                valueExpr="cd_empresa"
                displayExpr="ds_empresa">
            </dxo-lookup>
        </dxi-column>

        <div *dxTemplate="let data of 'dx-title'">
            <div class="text-center">
                <p class="title mb-0">{{'index.tipos' | translate}}</p>
            </div>
        </div>

    </dx-data-grid>

</div>
<denied-access *ngIf="!havePermission" [formCode]="formCode"></denied-access>